import { Container as SemanticContainer } from "semantic-ui-react"
import styled from "styled-components"

const Full = styled(SemanticContainer).attrs(() => ({
  fluid: true,
}))``

const Big = styled(SemanticContainer)`
  &&& {
    max-width: 1200px !important;
  }
`

const Normal = styled(SemanticContainer)``

const Medium = styled(SemanticContainer)`
  &&& {
    max-width: 820px !important;
  }
`

const Base = styled(SemanticContainer)`
  &&& {
    max-width: 650px !important;
  }
`

const Small = styled(SemanticContainer)`
  &&& {
    max-width: 480px !important;
  }
`

const Tiny = styled(SemanticContainer)`
  &&& {
    max-width: 400px !important;
  }
`

export const Container = {
  Full,
  Big,
  Normal,
  Base,
  Medium,
  Small,
  Tiny,
}
