export const checkout = {
  Providence: "State / Province / Region",
  PostalCode: "Postal Code",
  Country: "Country",
  Plan: "Plan",
  Name: "Name",
  Email: "Email",
  SinglePayment: "one time payment",
  UpTo: "Up to",
  Of: "of",
  Off: "Off",
  StartsAt: "Starts at",
  CreditCard: "Credit Card",
  BankSlip: "Bank Slip",
  BankSlipTooltip: `Important: When buying using bank slip, your payment can take up to 48 hours to be validated. After that we will send you a link for accessing your group by email and you can also see it in the "My Groups" menu.`,
  Pix: "Pix",
  PixTooltip: `The QR Code and Pix code will be generated as soon as you click on "Finish".`,
  PaymentDisabled: "Payment method temporarily disabled",
  Total: "total",
  Month: "month",
  BankSlipHeader: "Data for bank slip",
  PersonalInfo: "Personal info",
  PurchaseDetail: "Purchase details",
  TermsAgreed:
    "I confirm that I am at least 18 years old. Minors can use our Services only with the involvement, supervision and approval of their parents or guardians. That is the",
  TermsAgreedLink: "Hubla's Privacy Policy",
  TermsAgreedBefore: "By proceeding with the purchase, you agree to",
  TermsAgreedAfter:
    "Minors under the age of 18 may use our Services only with the supervision and approval of a parent or guardian.",
  CardHeader: "Card info",
  CardNumber: "Card number",
  CardName: "Holder name",
  CardNameError: "Invalid name",
  CardExpires: "Expiration date",
  CardExpiresError: "Invalid date",
  CardCVV: "CVV",
  CardDocument: "Holder Document",
  IsForeign: "I'm not a Brazilian citizen",
  Installments: "Installments",
  Installment: "Installment",
  BillingAddress: "Billing Address",
  Zip: "Zip",
  ZipError: "Invalid Zip",
  Neighborhood: "Neighborhood",
  Address: "Address",
  Number: "Number",
  Complement: "Complement (optional)",
  City: "City",
  State: "State",
  Finish: "Finish",
  PlanFooter: "This plan is renewed automatically when the subscription period expires.",
  BillingFooter:
    "Your payment information is processed through Iugu. To complete transactions, we store the card type, the last four digits, expiration and name on the card. By continuing you agree to Hubla Terms of Use, Privacy Policy and Cookie Policy. Our policies explain how we will use and store your data, and how you can control that use.",
  Loading: "We are finishing your purchase",
  AlreadyPurchased: "Already purchased?",
  Access: "Access",
  BankSlipModal: {
    button: "Okay, got it!",
    title: "Bank slips",
    text1: "1. Bank slips take up to",
    text1bold: "48 hours to be compensated",
    text1_2: "and thus release your access to the group.",
    text2: "2. After the payment is compensated, you will receive an email with access to the group.",
    text3: "3. You can also access your purchase from",
    text3bold: "my groups",
  },
  MigrationPlan: "Migration plan",
}
