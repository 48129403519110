"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Engine = void 0;
var Engine;
exports.Engine = Engine;

(function (Engine) {
  Engine["default"] = "default";
  Engine["firestore"] = "firestore";
})(Engine || (exports.Engine = Engine = {}));