export enum AuthActionTypes {
  ASK_CODE = "ASK_CODE",
  LOGIN = "LOGIN",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
}

export interface IAskCodeAction {
  type: AuthActionTypes.ASK_CODE
  phoneNumber: string
}

export interface ILoginAction {
  type: AuthActionTypes.LOGIN
  verificationId: string
  code: number
}

export interface ILoginSuccessAction {
  type: AuthActionTypes.LOGIN_SUCCESS
  userId: string
}

export interface ILoginFailureAction {
  type: AuthActionTypes.LOGIN_FAILURE
  reason: string
}

export interface ILogoutAction {
  type: AuthActionTypes.LOGOUT
}

export const askCode = (phoneNumber: string): IAskCodeAction => {
  return {
    type: AuthActionTypes.ASK_CODE,
    phoneNumber,
  }
}

export const login = (verificationId: string, code: number): ILoginAction => {
  return {
    type: AuthActionTypes.LOGIN,
    verificationId,
    code,
  }
}

export const loginSuccess = (userId: string): ILoginSuccessAction => {
  return {
    type: AuthActionTypes.LOGIN_SUCCESS,
    userId,
  }
}

export const loginFailure = (reason: string): ILoginFailureAction => {
  return {
    type: AuthActionTypes.LOGIN_FAILURE,
    reason,
  }
}

export const logout = (): ILogoutAction => {
  return {
    type: AuthActionTypes.LOGOUT,
  }
}

export type AuthAction = IAskCodeAction | ILoginAction | ILoginSuccessAction | ILoginFailureAction | ILogoutAction
