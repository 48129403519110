export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value)
}

export const formatCurrencyWithInstallments = (
  installments: number = 1,
  total: number = 0,
  installmentPrice: number = 0,
) => {
  if (installments > 1) {
    return `${installments}x de ${formatCurrency(installmentPrice)}`
  }
  return formatCurrency(total)
}
