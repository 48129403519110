export const ProductForm = {
  create: {
    title: "Qual tipo de produto deseja criar?",
  },
  product: {
    permanent: {
      title: "Permanente",
      description: "Ótimo para criar um ambiente de troca constante e bem próximo da sua audiência.",
    },
    cohort: {
      title: "Turmas",
      description: "Ótimo para criar um desafio fitness, mentoria ou curso.",
    },
    channel: {
      title: "Sala de Sinais",
      description: "Ótimo para criar uma sala de sinais ou copy trade.",
    },
  },
}
