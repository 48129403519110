import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit"

import { fetchGroups } from "./GroupsActions"

import { fetchGroupsReducer, pendingReducer, rejectedReducer } from "./GroupsReducers"

import { Document } from "@chatpay/common"
import { IState } from "./GroupsState"

const initialState: IState = {
  currentUser: null,
  allGroups: {},
  asAdminGroups: [],
  all: [],
  status: "idle",
  error: undefined,
}

const GroupsSlice: Slice<IState> = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase<any, PayloadAction>(fetchGroups.pending, pendingReducer)

    builder.addCase<any, PayloadAction<Error>>(fetchGroups.rejected, rejectedReducer)

    builder.addCase<
      any,
      PayloadAction<{
        groups: Document.Group[]
        currentUser: Document.User
      }>
    >(fetchGroups.fulfilled, fetchGroupsReducer)

    builder.addDefaultCase((state) => {
      return { ...state, initialState }
    })
  },
})

const { reducer, actions } = GroupsSlice

export { reducer, actions }
