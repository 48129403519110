import moment from "moment-timezone"
import { Location } from "../dtos/group"

export class FormatAddress {
  private location: Location | null

  public constructor(location: Location) {
    this.location = location
  }

  public format() {
    if (!this.location) {
      return ""
    }

    return `${this.location.name}, ${this.location.street}, ${this.location.number}, ${
      this.location.complement ? this.location.complement + ", " : ""
    }${this.location.neighborhood}, ${this.location.city}, ${this.location.state} - ${this.location.country} - ${
      this.location.zipCode
    }`
  }
}
