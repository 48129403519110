import { Document, IntegrationType, Interface } from "@chatpay/common"
import API from "./API"
import { DB } from "../Service"

class User extends API implements Interface.UserInfo.Function.ITemplate {
  private db = new DB(Document.Integrations)

  public async setProfile(data: Interface.UserInfo.Function.ISetProfile): Promise<Document.User> {
    const newData = {
      ...data,
      username: data.username === "" ? null : data.username,
    }
    const response = await this.call(Interface.UserInfo.Function.Name.setProfile, newData)
    return new Document.User(response.data)
  }

  public async setBasicInfo(data: Interface.UserInfo.Function.ISetBasicInfo): Promise<Document.User> {
    const response = await this.call(Interface.UserInfo.Function.Name.setBasicInfo, data)
    return new Document.User(response.data)
  }

  public async setCredentials(data: Interface.UserInfo.Function.ISetCredentials): Promise<Document.User> {
    const response = await this.call(Interface.UserInfo.Function.Name.setCredentials, data)
    return new Document.User(response.data)
  }

  public async setIntegrations(data: Interface.UserInfo.Function.ISetIntegrations): Promise<Document.User> {
    const response = await this.call(Interface.UserInfo.Function.Name.setIntegrations, data)
    return new Document.User(response.data)
  }

  public async updateNotificationSettings(
    data: Partial<Document.NotificationSettings>,
  ): Promise<Document.NotificationSettings> {
    const response = await this.call(Interface.UserInfo.Function.Name.updateNotificationSettings, data)
    return response.data as Document.NotificationSettings
  }

  public async addDeviceToken(data: Interface.UserInfo.Function.ISetDevice): Promise<boolean> {
    const response = await this.call(Interface.UserInfo.Function.Name.addDeviceToken, data)
    return response.data as boolean
  }

  public async removeDeviceToken(data: Interface.UserInfo.Function.ISetDevice): Promise<boolean> {
    const response = await this.call(Interface.UserInfo.Function.Name.removeDeviceToken, data)
    return response.data as boolean
  }

  public async generateApiKey(data: Interface.UserInfo.Function.ISetUserApiKey): Promise<Document.User> {
    const response = await this.call(Interface.UserInfo.Function.Name.generateApiKey, data)
    return new Document.User(response.data)
  }

  private async call(func: Interface.UserInfo.Function.Name, params?: Interface.UserInfo.Function.Params) {
    return await this.callFunction(`userInfo/${func}`, params)
  }

  public async getIntegrationByUserAndType(
    userId: string,
    type: IntegrationType,
  ): Promise<Document.Integrations | null> {
    return this.db.getFirst({
      where: [
        { field: "userId", op: "==", value: userId },
        { field: "type", op: "==", value: type },
      ],
    })
  }
}

export default User
