export const migrationRequest = {
  intro: {
    title: "Você ja é dono de um grupo pago?",
    subtitle:
      "Se você ja possui um grupo pago e não quer perder sua audiência, mas ainda ganhar todos os benefícios que a Hubla pode trazer para a sua gestão, aqui é o lugar certo!",
    button: "Próximo",
  },
  form: {
    title: "Nos informe o link de acesso ao seu grupo",
    subtitle:
      "Precisamos que nos informe o link de acesso ao seu grupo de WhatsApp ou Telegram, para que possamos colocar um de nossos robôs nele.",
    button: "Continuar",
  },
  done: {
    title: "Migração em andamento",
    subtitle: "Em breve alguem da nossa equipe entrará em contato para te ajudar a realizar os próximos passos.",
    button: "Fechar",
  },
  migrationPending: {
    title: "Você já possui uma migração em andamento",
    subtitle:
      "Aguarde que em breve alguem da nossa equipe entrará em contato para te ajudar a realizar os próximos passos.",
    button: "Fechar",
  },
  invalidLink: "Esse não é um link de um grupo no WhatsApp ou Telegram válido.",
}
