export const newExperienceAlert = {
  title: "Chegou a nova experiência! Mais clara, mais fácil, pronta para você.",
  buttonText: "Experimente agora →",
  modal: {
    title: "A experiência intuitiva que você merece",
    text: "Mais fácil, mais claro e com um visual que inspira. Conheça a nova interface, pensada para oferecer o máximo de simplicidade e elegância.",
    cancel: "Cancelar",
    confirm: "Experimentar agora",
  },
}
