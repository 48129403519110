export const copyToClipboard = (data: string): void => {
  const copy = (text: string) => {
    const TEXT = document.createElement("input")

    TEXT.value = text
    document.body.appendChild(TEXT)
    TEXT.select()
    document.execCommand("copy")
    document.body.removeChild(TEXT)
  }

  copy(data)
}
