export const EditNotification = {
  SectionTitle: "Notificações",
  SectionSubtitle:
    "Receba notificações sobre atividades dos membros no seu app Hubla, confirmações de pagamentos, entre outros.",
  SectionText: "Receber notificações sobre",
  DataTitle: "Informações nas notificações",
  SoundTitle: "Som nas notificações",
  adminJoined: "Admin entrou no grupo",
  memberJoined: "Um membro entrou em seu grupo",
  nonMemberJoined: "Um usuário não identificado entrou em seu grupo",
  purchased: "Vendas do grupo",
  removed: "Um usuário foi removido do grupo",
  whitelisted: "Um usuário foi adicionado à whitelist",
  paymentMethod: "Método de pagamento",
  productName: "Nome do produto",
  userName: "Nome do usuário",
  userEmail: "Email do usuário",
  userPhone: "Telefone do usuário",
  sound: "Habilitar?",
  DownloadApp: "Baixar o App",
  postOwnerCommentAlert: "Um usuário comentou em um conteúdo que sou produtor",
  threadParticipationReplyAlert: "Um usuário respondeu uma thread em que estou participando ou sou dono",
}
