export const productGroups = {
  emptyState: {
    title: "Este produto ainda não tem um grupo",
    description: "Que tal criar um grupo e gerar mais conexão com seus membros?",
    createGroupButton: "Criar um grupo",
    cards: {
      whatsapp: {
        title: "WhatsApp",
        subtitle: "Grupo no Whatsapp para seus membros",
      },
      telegram: {
        title: "Telegram",
        subtitle: "Grupo ou canal de transmissão no Telegram para seus membros",
      },
    },
    advantages: {
      apps: "Integrações com WhatsApp e Telegram",
      members: "Gestão de membros automatizada",
      networking: "Conecte-se diretamente com os seus membros",
    },
  },
  groupResourceModal: {
    back: "Voltar",
    title: "Adicione um grupo ou canal",
    subtitle: "Selecione a opção que mais combina com o seu produto.",
    feeMessage: "Custo adicional de R$ 0,99 por venda aprovada",
    telegram: {
      title: "Telegram",
      resourceType: "Grupo",
      description: {
        1: "Suporta até {{maxMemberCount}} membros.",
        2: "Novos membros conseguem ter acesso às mensagens antigas.",
        3: "O administrador pode ativar e desativar mensagens entre membros.",
      },
    },
    telegram_channel: {
      title: "Telegram",
      resourceType: "Canal de Transmissão",
      description: {
        1: "Suporta até {{maxMemberCount}} membros.",
        2: "Apenas administradores podem mandar mensagens.",
        3: "Membros ficam invisíveis uns dos outros.",
      },
    },
    whatsapp: {
      title: "WhatsApp",
      resourceType: "Grupo",
      description: {
        1: "Suporta até {{maxMemberCount}} membros.",
        2: "Opção mais acessível para o público brasileiro.",
        3: "Maior taxa de resposta e de fácil acesso para os seus membros.",
      },
    },
    button: {
      cancel: "Cancelar",
      add: "Criar grupo",
    },
  },
}
