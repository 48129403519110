import React, { useEffect, useState } from "react"
import { Button, Form, Input } from "semantic-ui-react"
import * as Fields from "components/Fields"
import { useTranslation } from "react-i18next"
import { ISetIntegrations } from "@chatpay/common/src/Interfaces/UserInfo/IFunction"
import { API, Service } from "@chatpay/components"
import { IntegrationType, Zapier } from "@chatpay/common"
import ActionPopup from "components/Dialogs/ActionPopup"
import { copyToClipboard } from "components/Helpers/CopyToClipboard"
import { Settings } from "components/Settings"
import "./ZapierIntegration.scss"
import { Spacer } from "components"
interface IForm {
  userJwt?: string | undefined
}
interface IProps {
  value: Partial<IForm | undefined>
}

export const ZapierIntegration: React.FunctionComponent<IProps> = (props) => {
  const { value } = props

  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<Error | undefined>()

  const [userJwt, setUserJwt] = useState<string | undefined>("")

  const { t } = useTranslation()

  useEffect(() => {
    if (value && value.userJwt) {
      setUserJwt(value.userJwt)
    }
  }, [value])

  const onGenerateAPIToken = async (isGenerateKey: boolean) => {
    setLoading(true)

    const newDataZapier: ISetIntegrations = {
      zapier: {
        isGenerateKey: isGenerateKey,
      },
    }
    const currentUser = Service.Firebase.currentUser
    try {
      if (currentUser) {
        await new API.UserInfo().setIntegrations(newDataZapier)

        if (isGenerateKey) {
          const zapierIntegration = await new API.UserInfo().getIntegrationByUserAndType(
            currentUser.id,
            IntegrationType.zapier,
          )

          const fields = zapierIntegration?.fields as Zapier

          if (fields && fields.userJwt) {
            setUserJwt(fields.userJwt)
          }
        } else {
          setUserJwt(undefined)
        }
      }
    } catch (error: any) {
      setError(error)
      setLoading(false)
    } finally {
      setError(undefined)
      setLoading(false)
    }
  }

  return (
    <Settings.Section
      content={
        <div>
          <div className="cp text big black bold compact">
            <a href="#zapierapi-integration" id="zapierapi-integration" target="_blank"></a>
            {t("UserForm.integrations.zapier.title")}
          </div>
          <div className="cp text large">{t("UserForm.integrations.zapier.description")}</div>
          <Spacer />
          <a className="cp text blue large" href="https://app.hub.la/zapier-integration" target="_blank">
            {t("UserForm.integrations.zapier.documentation")}
          </a>
        </div>
      }
      form={
        <Form>
          <Fields.InputLabel title={t("UserForm.integrations.zapier.apiKeyTitle")}>
            <Spacer size="small" />
            <div className="buttonContainer">
              <Button
                loading={loading}
                disabled={false}
                size="small"
                basic={true}
                className="rounded"
                onClick={() => onGenerateAPIToken(true)}
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      {userJwt
                        ? t("UserForm.integrations.zapier.buttonRefreshAPIToken")
                        : t("UserForm.integrations.zapier.buttonRequestAPIToken")}
                    </div>
                  </div>
                }
              />
              {userJwt ? (
                <Button
                  basic={true}
                  icon="trash"
                  content={t("UserForm.integrations.zapier.buttonDeleteAPIToken")}
                  disabled={loading}
                  className="rounded"
                  size="small"
                  color="red"
                  onClick={() => onGenerateAPIToken(false)}
                />
              ) : null}
            </div>
            <Spacer size="small" />
          </Fields.InputLabel>
          {userJwt ? (
            <div>
              <div className="clipboardButton">
                <Form.Input value={userJwt} rows={6} disabled={true} />
                <ActionPopup text={t("UserForm.integrations.zapier.actionPopup.tokenCopied")}>
                  <Button
                    basic={true}
                    icon="copy outline"
                    disabled={loading}
                    size="small"
                    onClick={() => copyToClipboard(userJwt)}
                  />
                </ActionPopup>
              </div>
              <Spacer size="small" />
            </div>
          ) : null}
        </Form>
      }
      error={error}
    />
  )
}
export { ZapierIntegration as Form }
export type { IProps, IForm }
