import { useQuery } from "@tanstack/react-query"
import { ListMemberSubscriptions } from "../../usecases/list-member-subscriptions"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useContainer } from "@hub-la/fe-container"
import { QueryKey } from "modules/user-subscriptions/domain/enums/query-key"

export const useListMemberSubscriptions = () => {
  const container = useContainer()

  return useQuery(
    [QueryKey.listMemberSubscriptions],
    () => new ListMemberSubscriptions(container.get(HttpClient)).execute(),
    {
      refetchOnWindowFocus: false,
      initialData: [],
      retry: false,
    },
  )
}
