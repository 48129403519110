import { Interface, Receiver } from "@chatpay/common"
import API from "./API"

class Partner extends API {
  private async call(func: Interface.Receiver.Function.Name, params?: Interface.Receiver.Function.Params) {
    return await this.callFunction(`receiver/${func}`, params)
  }
  public async listPartnersForGroupOwner(
    params: Interface.Receiver.Function.IListPartnersForGroupOwner,
  ): Promise<Receiver.Document[]> {
    return (await this.call(Interface.Receiver.Function.Name.listPartnersForGroupOwner, params)).data
  }
  public async listForPartner(params: Interface.Receiver.Function.IListForPartner): Promise<Receiver.Document[]> {
    return (await this.call(Interface.Receiver.Function.Name.listForPartner, params)).data
  }
  public async get(params: Interface.Receiver.Function.IGet): Promise<Receiver.Document> {
    return (await this.call(Interface.Receiver.Function.Name.get, params)).data
  }
  public async invite(params: Interface.Receiver.Function.IInvite): Promise<Receiver.Document> {
    return (await this.call(Interface.Receiver.Function.Name.invite, params)).data
  }
  public async edit(params: Interface.Receiver.Function.IEdit): Promise<Receiver.Document> {
    return (await this.call(Interface.Receiver.Function.Name.edit, params)).data
  }
  public async accept(params: Interface.Receiver.Function.IAcceptInvite): Promise<void> {
    return (await this.call(Interface.Receiver.Function.Name.accept, params)).data
  }
  public async requestCancellation(params: Interface.Receiver.Function.IRequestCancellation): Promise<void> {
    return (await this.call(Interface.Receiver.Function.Name.requestCancellation, params)).data
  }
  public async acceptCancellation(params: Interface.Receiver.Function.IRequestCancellation): Promise<void> {
    return (await this.call(Interface.Receiver.Function.Name.acceptCancellation, params)).data
  }
  public async rejectCancellation(params: Interface.Receiver.Function.IRequestCancellation): Promise<void> {
    return (await this.call(Interface.Receiver.Function.Name.rejectCancellation, params)).data
  }
  public async cancelRequestCancellation(params: Interface.Receiver.Function.IRequestCancellation): Promise<void> {
    return (await this.call(Interface.Receiver.Function.Name.cancelRequestCancellation, params)).data
  }
  public async reject(params: Interface.Receiver.Function.IReject): Promise<void> {
    return (await this.call(Interface.Receiver.Function.Name.reject, params)).data
  }
}

export default Partner
