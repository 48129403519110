export const GroupStatusButton = {
  offerWaitlistWarning: {
    modal: {
      title: "Enabling waitlist also closes the open class",
      description:
        "By deactivating your product, you are ending sales for your current class. This action is irreversible. That is, if you want to open for sales again, you will be opening a new class.",
      buttons: {
        maybeLater: "Maybe later",
        createWaitlist: "Open waiting list and close open class",
      },
    },
  },
  accountVerificationInput: {
    modal: {
      title: "Verify your account to start selling",
      description: "We need a bank account for you to receive payments from your members",
      buttons: {
        maybeLater: "Maybe later",
        checkAccount: "Verify account",
      },
    },
  },
  accountVerificationPending: {
    modal: {
      title: "We are processing your account information",
      description: "As soon as everything is right, you will be able to sell your product",
      buttons: {
        close: "Close",
      },
    },
  },
  offerNotSellingWarning: {
    modal: {
      title: "Disabling product sales",
      description: "We are disabling sales from your product",
      confirm: {
        title: "Your product sales has been disabled",
        description: "You can reactivate it at any time again",
      },
    },
  },
  resourceDisabled: {
    modal: {
      title: "Instability on {{resource_name}}",
      description:
        "We are experiencing instability on {{resource_name}} so it is not possible to activate sales at this time.<br>Please wait and try again later",
      buttons: {
        close: "Close",
      },
    },
  },
}
