"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _Models = require("../../Models");

var _ = require("../");

var _Tag = require("../Tag");

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var tagFixture = function tagFixture(args) {
  var name = _faker["default"].random.word();

  var key = name.toLowerCase();
  return new _.Tag(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    name: name,
    key: key,
    ownerId: (0, _utils.firebaseIdFixture)(),
    color: _faker["default"].random.objectElement(_Models.Colors),
    type: _faker["default"].random.objectElement(_Tag.Type)
  }, args));
};

var _default = tagFixture;
exports["default"] = _default;