"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _ = require("../../");

var _Common = require("../../Interfaces/Gateway/Common");

var _IAccount = require("../../Interfaces/Gateway/Common/IAccount");

var _IBankAccount = require("../../Interfaces/Gateway/Common/IBankAccount");

var _Models = require("../../Models");

var _Gateway = require("../Gateway/Gateway");

var _Document = _interopRequireDefault(require("./Document"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var gatewayFixture = function gatewayFixture(args) {
  return new _.Document.Gateway(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    carrier: faker.random.objectElement(_Gateway.Carrier),
    customerId: faker.random.alphaNumeric(10),
    creditCards: [{
      id: faker.datatype.number(5).toString(),
      brand: faker.random.objectElement(_Common.Brand),
      year: Number.parseInt(faker.date.future(9).getFullYear().toString().slice(2), 10),
      month: faker.date.future().getMonth() + 1,
      holder: faker.finance.accountName(),
      number: faker.finance.creditCardNumber()
    }],
    bankAccount: {
      id: faker.datatype.number(5).toString(),
      type: faker.random.objectElement(_IBankAccount.Type),
      holder: {
        name: faker.finance.accountName(),
        type: faker.random.objectElement(_IAccount.Type)
      }
    },
    address: {
      city: faker.address.city(),
      complement: faker.address.secondaryAddress(),
      country: faker.random.objectElement(_Models.Country),
      neighborhood: faker.address.county(),
      number: faker.datatype.number(5).toString(),
      street: faker.address.streetName(),
      state: faker.address.state(),
      zip: faker.address.zipCode()
    },
    account: {
      id: faker.random.alphaNumeric(10),
      currency: faker.random.objectElement(_Models.Currency),
      country: faker.random.objectElement(_Models.Country),
      isLive: faker.datatype["boolean"](),
      accessToken: faker.random.alphaNumeric(10),
      config: _.GatewayFixtures.IConfig()
    }
  }, args));
};

var _default = gatewayFixture;
exports["default"] = _default;