"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CountryCurrency = void 0;
var CountryCurrency;
exports.CountryCurrency = CountryCurrency;

(function (CountryCurrency) {
  CountryCurrency["bd"] = "BDT";
  CountryCurrency["be"] = "EUR";
  CountryCurrency["bf"] = "XOF";
  CountryCurrency["bg"] = "BGN";
  CountryCurrency["ba"] = "BAM";
  CountryCurrency["bb"] = "BBD";
  CountryCurrency["wf"] = "XPF";
  CountryCurrency["bl"] = "EUR";
  CountryCurrency["bm"] = "BMD";
  CountryCurrency["bn"] = "BND";
  CountryCurrency["bo"] = "BOB";
  CountryCurrency["bh"] = "BHD";
  CountryCurrency["bi"] = "BIF";
  CountryCurrency["bj"] = "XOF";
  CountryCurrency["bt"] = "BTN";
  CountryCurrency["jm"] = "JMD";
  CountryCurrency["bv"] = "NOK";
  CountryCurrency["bw"] = "BWP";
  CountryCurrency["ws"] = "WST";
  CountryCurrency["bq"] = "USD";
  CountryCurrency["br"] = "BRL";
  CountryCurrency["bs"] = "BSD";
  CountryCurrency["je"] = "GBP";
  CountryCurrency["by"] = "BYR";
  CountryCurrency["bz"] = "BZD";
  CountryCurrency["ru"] = "RUB";
  CountryCurrency["rw"] = "RWF";
  CountryCurrency["rs"] = "RSD";
  CountryCurrency["tl"] = "USD";
  CountryCurrency["re"] = "EUR";
  CountryCurrency["tm"] = "TMT";
  CountryCurrency["tj"] = "TJS";
  CountryCurrency["ro"] = "RON";
  CountryCurrency["tk"] = "NZD";
  CountryCurrency["gw"] = "XOF";
  CountryCurrency["gu"] = "USD";
  CountryCurrency["gt"] = "GTQ";
  CountryCurrency["gs"] = "GBP";
  CountryCurrency["gr"] = "EUR";
  CountryCurrency["gq"] = "XAF";
  CountryCurrency["gp"] = "EUR";
  CountryCurrency["jp"] = "JPY";
  CountryCurrency["gy"] = "GYD";
  CountryCurrency["gg"] = "GBP";
  CountryCurrency["gf"] = "EUR";
  CountryCurrency["ge"] = "GEL";
  CountryCurrency["gd"] = "XCD";
  CountryCurrency["gb"] = "GBP";
  CountryCurrency["ga"] = "XAF";
  CountryCurrency["sv"] = "USD";
  CountryCurrency["gn"] = "GNF";
  CountryCurrency["gm"] = "GMD";
  CountryCurrency["gl"] = "DKK";
  CountryCurrency["gi"] = "GIP";
  CountryCurrency["gh"] = "GHS";
  CountryCurrency["om"] = "OMR";
  CountryCurrency["tn"] = "TND";
  CountryCurrency["jo"] = "JOD";
  CountryCurrency["hr"] = "HRK";
  CountryCurrency["ht"] = "HTG";
  CountryCurrency["hu"] = "HUF";
  CountryCurrency["hk"] = "HKD";
  CountryCurrency["hn"] = "HNL";
  CountryCurrency["hm"] = "AUD";
  CountryCurrency["ve"] = "VEF";
  CountryCurrency["pr"] = "USD";
  CountryCurrency["ps"] = "ILS";
  CountryCurrency["pw"] = "USD";
  CountryCurrency["pt"] = "EUR";
  CountryCurrency["sj"] = "NOK";
  CountryCurrency["py"] = "PYG";
  CountryCurrency["iq"] = "IQD";
  CountryCurrency["pa"] = "PAB";
  CountryCurrency["pf"] = "XPF";
  CountryCurrency["pg"] = "PGK";
  CountryCurrency["pe"] = "PEN";
  CountryCurrency["pk"] = "PKR";
  CountryCurrency["ph"] = "PHP";
  CountryCurrency["pn"] = "NZD";
  CountryCurrency["pl"] = "PLN";
  CountryCurrency["pm"] = "EUR";
  CountryCurrency["zm"] = "ZMK";
  CountryCurrency["eh"] = "MAD";
  CountryCurrency["ee"] = "EUR";
  CountryCurrency["eg"] = "EGP";
  CountryCurrency["za"] = "ZAR";
  CountryCurrency["ec"] = "USD";
  CountryCurrency["it"] = "EUR";
  CountryCurrency["vn"] = "VND";
  CountryCurrency["sb"] = "SBD";
  CountryCurrency["et"] = "ETB";
  CountryCurrency["so"] = "SOS";
  CountryCurrency["zw"] = "ZWL";
  CountryCurrency["sa"] = "SAR";
  CountryCurrency["es"] = "EUR";
  CountryCurrency["er"] = "ERN";
  CountryCurrency["me"] = "EUR";
  CountryCurrency["md"] = "MDL";
  CountryCurrency["mg"] = "MGA";
  CountryCurrency["mf"] = "EUR";
  CountryCurrency["ma"] = "MAD";
  CountryCurrency["mc"] = "EUR";
  CountryCurrency["uz"] = "UZS";
  CountryCurrency["mm"] = "MMK";
  CountryCurrency["ml"] = "XOF";
  CountryCurrency["mo"] = "MOP";
  CountryCurrency["mn"] = "MNT";
  CountryCurrency["mh"] = "USD";
  CountryCurrency["mk"] = "MKD";
  CountryCurrency["mu"] = "MUR";
  CountryCurrency["mt"] = "EUR";
  CountryCurrency["mw"] = "MWK";
  CountryCurrency["mv"] = "MVR";
  CountryCurrency["mq"] = "EUR";
  CountryCurrency["mp"] = "USD";
  CountryCurrency["ms"] = "XCD";
  CountryCurrency["mr"] = "MRO";
  CountryCurrency["im"] = "GBP";
  CountryCurrency["ug"] = "UGX";
  CountryCurrency["tz"] = "TZS";
  CountryCurrency["my"] = "MYR";
  CountryCurrency["mx"] = "MXN";
  CountryCurrency["il"] = "ILS";
  CountryCurrency["fr"] = "EUR";
  CountryCurrency["io"] = "USD";
  CountryCurrency["sh"] = "SHP";
  CountryCurrency["fi"] = "EUR";
  CountryCurrency["fj"] = "FJD";
  CountryCurrency["fk"] = "FKP";
  CountryCurrency["fm"] = "USD";
  CountryCurrency["fo"] = "DKK";
  CountryCurrency["ni"] = "NIO";
  CountryCurrency["nl"] = "EUR";
  CountryCurrency["no"] = "NOK";
  CountryCurrency["na"] = "NAD";
  CountryCurrency["vu"] = "VUV";
  CountryCurrency["nc"] = "XPF";
  CountryCurrency["ne"] = "XOF";
  CountryCurrency["nf"] = "AUD";
  CountryCurrency["ng"] = "NGN";
  CountryCurrency["nz"] = "NZD";
  CountryCurrency["np"] = "NPR";
  CountryCurrency["nr"] = "AUD";
  CountryCurrency["nu"] = "NZD";
  CountryCurrency["ck"] = "NZD";
  CountryCurrency["xk"] = "EUR";
  CountryCurrency["ci"] = "XOF";
  CountryCurrency["ch"] = "CHF";
  CountryCurrency["co"] = "COP";
  CountryCurrency["cn"] = "CNY";
  CountryCurrency["cm"] = "XAF";
  CountryCurrency["cl"] = "CLP";
  CountryCurrency["cc"] = "AUD";
  CountryCurrency["ca"] = "CAD";
  CountryCurrency["cg"] = "XAF";
  CountryCurrency["cf"] = "XAF";
  CountryCurrency["cd"] = "CDF";
  CountryCurrency["cz"] = "CZK";
  CountryCurrency["cy"] = "EUR";
  CountryCurrency["cx"] = "AUD";
  CountryCurrency["cr"] = "CRC";
  CountryCurrency["cw"] = "ANG";
  CountryCurrency["cv"] = "CVE";
  CountryCurrency["cu"] = "CUP";
  CountryCurrency["sz"] = "SZL";
  CountryCurrency["sy"] = "SYP";
  CountryCurrency["sx"] = "ANG";
  CountryCurrency["kg"] = "KGS";
  CountryCurrency["ke"] = "KES";
  CountryCurrency["ss"] = "SSP";
  CountryCurrency["sr"] = "SRD";
  CountryCurrency["ki"] = "AUD";
  CountryCurrency["kh"] = "KHR";
  CountryCurrency["kn"] = "XCD";
  CountryCurrency["km"] = "KMF";
  CountryCurrency["st"] = "STD";
  CountryCurrency["sk"] = "EUR";
  CountryCurrency["kr"] = "KRW";
  CountryCurrency["si"] = "EUR";
  CountryCurrency["kp"] = "KPW";
  CountryCurrency["kw"] = "KWD";
  CountryCurrency["sn"] = "XOF";
  CountryCurrency["sm"] = "EUR";
  CountryCurrency["sl"] = "SLL";
  CountryCurrency["sc"] = "SCR";
  CountryCurrency["kz"] = "KZT";
  CountryCurrency["ky"] = "KYD";
  CountryCurrency["sg"] = "SGD";
  CountryCurrency["se"] = "SEK";
  CountryCurrency["sd"] = "SDG";
  CountryCurrency["do"] = "DOP";
  CountryCurrency["dm"] = "XCD";
  CountryCurrency["dj"] = "DJF";
  CountryCurrency["dk"] = "DKK";
  CountryCurrency["vg"] = "USD";
  CountryCurrency["de"] = "EUR";
  CountryCurrency["ye"] = "YER";
  CountryCurrency["dz"] = "DZD";
  CountryCurrency["us"] = "USD";
  CountryCurrency["uy"] = "UYU";
  CountryCurrency["yt"] = "EUR";
  CountryCurrency["um"] = "USD";
  CountryCurrency["lb"] = "LBP";
  CountryCurrency["lc"] = "XCD";
  CountryCurrency["la"] = "LAK";
  CountryCurrency["tv"] = "AUD";
  CountryCurrency["tw"] = "TWD";
  CountryCurrency["tt"] = "TTD";
  CountryCurrency["tr"] = "TRY";
  CountryCurrency["lk"] = "LKR";
  CountryCurrency["li"] = "CHF";
  CountryCurrency["lv"] = "EUR";
  CountryCurrency["to"] = "TOP";
  CountryCurrency["lt"] = "LTL";
  CountryCurrency["lu"] = "EUR";
  CountryCurrency["lr"] = "LRD";
  CountryCurrency["ls"] = "LSL";
  CountryCurrency["th"] = "THB";
  CountryCurrency["tf"] = "EUR";
  CountryCurrency["tg"] = "XOF";
  CountryCurrency["td"] = "XAF";
  CountryCurrency["tc"] = "USD";
  CountryCurrency["ly"] = "LYD";
  CountryCurrency["va"] = "EUR";
  CountryCurrency["vc"] = "XCD";
  CountryCurrency["ae"] = "AED";
  CountryCurrency["ad"] = "EUR";
  CountryCurrency["ag"] = "XCD";
  CountryCurrency["af"] = "AFN";
  CountryCurrency["ai"] = "XCD";
  CountryCurrency["vi"] = "USD";
  CountryCurrency["is"] = "ISK";
  CountryCurrency["ir"] = "IRR";
  CountryCurrency["am"] = "AMD";
  CountryCurrency["al"] = "ALL";
  CountryCurrency["ao"] = "AOA";
  CountryCurrency["as"] = "USD";
  CountryCurrency["ar"] = "ARS";
  CountryCurrency["au"] = "AUD";
  CountryCurrency["at"] = "EUR";
  CountryCurrency["aw"] = "AWG";
  CountryCurrency["in"] = "INR";
  CountryCurrency["ax"] = "EUR";
  CountryCurrency["az"] = "AZN";
  CountryCurrency["ie"] = "EUR";
  CountryCurrency["id"] = "IDR";
  CountryCurrency["ua"] = "UAH";
  CountryCurrency["qa"] = "QAR";
  CountryCurrency["mz"] = "MZN";
})(CountryCurrency || (exports.CountryCurrency = CountryCurrency = {}));