"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmptyBalance = void 0;
// TODO: @anyone After the full 'Saldo Perfeito" rollout, many of the fields in this interface are no longer needed.
var EmptyBalance = {
  amount: 0,
  available: 0,
  receivable: 0,
  commission: 0
};
exports.EmptyBalance = EmptyBalance;