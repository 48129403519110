export const Receiver = {
  notificationCard: {
    action_title: "You have pending partnership actions",
    action_subtitle: "You have pending invites and requests",
    action_button: "View pendencies",

    invite_title: "New partnership invite",
    invite_subtitle: "You have a partnership invite to accept",
    invite_button: "Show invite",

    request_title: "Partnership Cancellation Request",
    request_subtitle: "You have a request to cancel partnership",
    request_button: "View request",
  },
  inviteForm: {
    invite_title: "Invite partner",
    edit_title: "Edit partner",
    back_button: "Go back",
    fields: {
      email: "Email of partner",
      commission: "Commission of partner",
      sales_percentage: "Sales percent",
      end_date_partnership: "End date of partnership",
      end_date: {
        title: "End date",
        with_end_date: "With end date",
        without_end_date: "Without end date",
      },
      terms_of_use: {
        prefix: "I accept the",
        link: "terms of use",
        suffix: "of partnership.",
      },
    },
    defaultName: "New partner",
    invite_submit_button: "Invite partner",
    edit_submit_button: "Save",
    instruction: "The partnership begins when the partner accepts the invitation.",
    edit_instruction: "When saving, the new conditions will only take effect after acceptance by the partner.",
  },
  inviteList: {
    accept: "Accept",
    reject: "Reject",
    noExpires: "No expires",
    desktopList: {
      header: {
        productName: "Products",
        expires: "End date",
        commission: "% of sales",
        status: "Status",
        actions: "Actions",
      },
    },
  },
  partnersList: {
    noExpires: "No expires",
  },
  acceptCancellationModal: {
    title: "Do you want to accept partnership cancellation?",
    conditions: "Conditions of partnership",
    commission: "Percentage in sales of {{commission}}%",
    expires: "Until {{date}}",
    noExpires: "No end date",
    notes: "+ Conditions for emit notes",
    confirm: "Accept",
    termsOfUse: {
      link: "Terms of use",
      suffix: "of partnership.",
    },
  },
  rejectCancellationModal: {
    title: "Decline partnership cancellation?",
    goBack: "Go back",
    reject: "Reject request",
    expires: "until {{expires}}",
    noExpires: "without expires date",
  },
  acceptInviteModal: {
    title: "Confirm partnership",
    conditions: "Conditions of partnership",
    commission: "Percentage in sales of {{commission}}%",
    expires: "Until {{date}}",
    noExpires: "No end date",
    notes: "+ Conditions for emit notes",
    acceptTerms: "To accept the partnership is necessary with the terms of use.",
    confirm: "Confirm",
  },
  rejectInviteModal: {
    title: "Reject partnership invite?",
    goBack: "Go back",
    reject: "Reject invite",
    expires: "until {{expires}}",
    noExpires: "without expires date",
  },
  cancellationRequestModal: {
    title: "Do you want to cancel partner relationship?",
    alert: "To effect the cancellation, the partner will have to accept.",
    no: "No",
    yes: "Yes",
    expires: "until {{expires}}",
    noExpires: "without expires date",
  },
  cancelCancellationRequestModal: {
    title: "Cancel the partnership cancellation request?",
    no: "No",
    yes: "Yes",
  },
  errors: {
    ReceiverUserNotFound: "User not found",
    ReceiverInviteEmptyEmailOrUserid: "You need to specify an email or user id",
    ReceiverInviteSameEmailThatCreator: "You need to specify an email different from your registered email",
    ReceiverGroupNotFound: "Group not found",
    ReceiverGroupHasAnotherOwner: "You are not the owner of Group specified",
    ReceiverInviteExpiresAtInvalid: "'Closing date' need to be a future date",
    ReceiverCommissionGreaterThanZero: "Commission need to be greater than zero",
    ReceiverRenewalCommissionNeedGreaterZero: "Renewal commission need to be greater than zero",
    ReceiverRenewalCommissionGreater100:
      "The sum of the receivers commissions and your commission cannot be greater than 100%",
    ReceiverSellCommissionNeedGreaterZero: "Sell commission need to be greater than zero",
    ReceiverSellCommissionGreater100:
      "The sum of the receivers commissions and your commission cannot be greater than 100%",
    ReceiverNotExists: "Receiver with id not exists",
    ReceiverHasNotGroup: "Receiver has not a group",
    ReceiverForbiddenUser: "Action forbidden: User",
    ReceiverAcceptInviteForbiddenStatus: "Action forbidden: Status",
    ReceiverOtherUser: "Receiver not exists or not belongs to this user",
    ReceiverInvalidStatus: "Receiver doesn't have a valid status",
    ReceiverEditGroupRequired: "Group id is required",
    ReceiverInviteUserAlreadyInvited: "This user has already been invited to a co-production in this group",
    ReceiverAcceptInviteUserAlreadyCoproduction: "This user has already been invited to a co-production in this group",
    ReceiverInviteAccountIdInvalid: "This user has not a valid account id",
    general: "We did not identify the error that occurred... Please try again",
  },
}
