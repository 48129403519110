"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InstallmentAlgoVersion = void 0;
var InstallmentAlgoVersion;
exports.InstallmentAlgoVersion = InstallmentAlgoVersion;

(function (InstallmentAlgoVersion) {
  InstallmentAlgoVersion["v1"] = "v1";
  InstallmentAlgoVersion["v2"] = "v2";
  InstallmentAlgoVersion["v3"] = "v3";
})(InstallmentAlgoVersion || (exports.InstallmentAlgoVersion = InstallmentAlgoVersion = {}));