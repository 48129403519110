export const paymentModals = {
  copyCode: "Copiar código",
  codeCopied: "Código copiado!",
  bankSlip: {
    title: "Importante",
    subtitle: "O boleto demora até 48h para compensar.",
    acceptsReceipt: "Você já terá acesso ao grupo, porém se não realizar o pagamento hoje, será removido amanhã.",
    sentTo: "Boleto enviado para:",
    download: "Baixar boleto",
  },
  pix: {
    title: "Importante",
    li1: "1. Abra o aplicativo do seu banco no celular.",
    li2_1: "2. Selecione a opção de pagar com",
    li2_2: "PIX Copia e Cola",
    li2_3: "ou escaneie o QR Code.",
    li3: `3. Após o pagamento, clique no botão ”Já fiz meu pagamento”.`,
    li4: `4. Seu pagamento pode demorar até 5 minutos para ser reconhecido.`,
    expires: "Vencimento:",
    paymentDone: "Já fiz meu pagamento",
  },
  backToGroups: "Voltar para lista de grupos",
  succesPlanChange: "Sua solicitação de troca de plano foi enviada!",
}
