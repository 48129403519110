"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Params = exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["enable"] = "enable";
  Name["disable"] = "disable";
  Name["login"] = "login";
  Name["logout"] = "logout";
  Name["getHealth"] = "getHealth";
  Name["getBots"] = "getBots";
})(Name || (exports.Name = Name = {}));

var Params;
exports.Params = Params;

(function (_Params) {})(Params || (exports.Params = Params = {}));