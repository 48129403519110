"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _Models = require("../../Models");

var _ = require("../");

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function generateAssets(qnt) {
  var assets = [];

  for (var i = 0; i < qnt; i++) {
    assets.push(assetFixture());
  }

  return assets;
}

function generateGroups(qnt) {
  var groups = {};

  for (var i = 0; i < qnt; i++) {
    groups[(0, _utils.firebaseIdFixture)()] = _faker["default"].datatype["boolean"]();
  }

  return groups;
}

function generateTags(qnt) {
  var groups = {};

  for (var i = 0; i < qnt; i++) {
    var name = _faker["default"].random.word();

    var tagId = name.toLowerCase();
    groups[tagId] = {
      name: name,
      tagId: tagId,
      color: _faker["default"].random.objectElement(_Models.Colors)
    };
  }

  return groups;
}

var postFixture = function postFixture(args) {
  return new _.Post(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    order: _faker["default"].datatype.number(10),
    title: _faker["default"].lorem.sentence(),
    subtitle: _faker["default"].lorem.sentences(),
    isPublic: _faker["default"].datatype["boolean"](),
    body: _faker["default"].lorem.paragraphs(),
    author: _faker["default"].name.findName(),
    assets: generateAssets(_faker["default"].datatype.number(3)),
    cover: assetFixture(),
    type: _faker["default"].random.objectElement(_.PostType),
    groups: generateGroups(_faker["default"].datatype.number(5)),
    likeCount: _faker["default"].datatype.number(999),
    tags: generateTags(_faker["default"].datatype.number(5))
  }, args));
};

var _default = postFixture;
exports["default"] = _default;

var assetFixture = function assetFixture() {
  return {
    id: (0, _utils.firebaseIdFixture)(),
    name: _faker["default"].system.fileName(),
    url: _faker["default"].image.imageUrl(),
    thumbnailUrl: _faker["default"].image.imageUrl(),
    type: _faker["default"].random.objectElement(_.PostAssetType)
  };
};