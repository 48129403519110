import API from "./API"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import { Document, Interface } from "@chatpay/common"
import { DB } from "../Service"

export default class Material extends API {
  constructor(
    private materialDB = new DB(Document.Material),
    private root: firebase.storage.Reference = firebase.storage().ref().child("private"),
  ) {
    super()
  }

  public async create(data: {
    id?: string
    name: string
    owner: Interface.UserReference
    type: Document.MaterialFormat
    location: Document.MaterialLocation
    url: string
    data?: Document.MaterialData
  }): Promise<Document.Material> {
    const response = await this.callFunction("materialCreate", data)
    return new Document.Material(response.data)
  }

  public async upload(
    file: File,
    location: Document.MaterialLocation,
    type: Document.MaterialFormat,
    progress?: (n: number) => any,
  ): Promise<Document.Material> {
    return new Promise((resolve, reject) => {
      // create an id
      const id = this.materialDB.makeId()
      const uploadTask = this.root.child("material").child(id).put(file)

      /**
       * @function on handles all the callbacks in firebase.
       */
      uploadTask.on(
        "state_changed",
        progress
          ? (snapshot) => {
              /**
               * When there is progress update, the @callback next function will be called.
               * The upload progress equal (bytes transfered / file size)
               */
              progress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            }
          : undefined,

        /* Handle error */
        (error: Error) => reject(error),
        /**
         * On completion we will pass in the URL to the @callback complete
         */
        async () => {
          try {
            await uploadTask.snapshot.ref.getDownloadURL().then(async (URL) => {
              const material = await this.create({
                id,
                name: file.name,
                owner: API.currentUser!.toReference(),
                type,
                location,
                url: URL,
              })
              resolve(material)
            })
          } catch (error) {
            reject(error as Error)
          }
        },
      )
    })
  }
}
