"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["createUpload"] = "createUpload";
  Name["getUpload"] = "getUpload";
  Name["getAsset"] = "getAsset";
  Name["getLatestAssetStateFromUpload"] = "getLatestAssetStateFromUpload";
  Name["updateAssetMP4Support"] = "updateAssetMP4Support";
})(Name || (exports.Name = Name = {}));