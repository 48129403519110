import { Reducer } from "redux"

import { AuthAction, AuthActionTypes } from "./AuthActions"
import { IAuthState } from "./AuthState"

const initialAuthState: IAuthState = {
  userId: null,
  isLoading: false,
  loginError: null,
}

export const AuthReducer: Reducer<IAuthState, AuthAction> = (
  state: IAuthState = initialAuthState,
  action: AuthAction,
) => {
  switch (action.type) {
    case AuthActionTypes.ASK_CODE:
      return {
        ...state,
      }
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        isLoading: true,
      }
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userId: action.userId,
      }
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        userId: null,
        loginError: action.reason,
      }
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        isLoading: false,
        userId: null,
      }
    default:
      return state
  }
}
