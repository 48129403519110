import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { GetInvoicesInput } from "../../domain/dtos/get-invoices-input"
import { GetInvoices } from "../../usecases/get-invoices"
import { QueryKey } from "modules/user-invoices/domain/enums/query-key"

export const useGetInvoices = (input: GetInvoicesInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getInvoices],
    ({ signal }) => new GetInvoices(container.get(HttpClient)).execute(input, signal),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!input.offers,
      initialData: {
        page: 1,
        pageSize: 0,
        total: 0,
        lastPage: 1,
        items: [],
      },
    },
  )
}
