import React from "react"
import { MenuItem, Select, Stack, Text } from "@hub-la/design-system"
import { useFormikContext } from "formik"
import { memo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Values } from "."
import { CPF } from "./cpf"
import { useField } from "modules/user-subscriptions/presentation/hooks/use-field"
import { useYunoSecureFields } from "modules/user-subscriptions/presentation/hooks/use-yuno-secure-fields"
import { StyledTextField } from "./index.styled"
import { Installment } from "modules/user-subscriptions/domain/dtos/installment"
import { formatCurrency } from "modules/user-subscriptions/domain/vos/format-currency"

export const YunoCreditCard: React.FC<{ installments: Installment[] }> = memo(({ installments }) => {
  const { t } = useTranslation()

  const { values, handleBlur, handleChange, setFieldValue } = useFormikContext<Values>()
  const { isValid } = useField()
  const { isPanValid, isExpirationValid, isCvvValid, generateToken, error } = useYunoSecureFields(values.sessionId)

  const isFormValid =
    isPanValid && isExpirationValid && isCvvValid && isValid("document") && isValid("creditCard.holder")

  useEffect(() => {
    if (!isFormValid || !values.sessionId) {
      return
    }
    generateToken()
  }, [isFormValid, values.sessionId])

  return (
    <Stack>
      <Stack direction="column" gap={2}>
        <Stack direction="column" mb={1}>
          <div style={{ height: "42px" }} id="pan" />
          <Stack direction="row">
            <div style={{ height: "42px", width: "100%" }} id="expiration" />
            <div style={{ height: "42px", width: "100%" }} id="cvv" />
          </Stack>
          <Text id="txt-card-error-j1Qd" color="error" fontSize={12}>
            {error}
          </Text>
        </Stack>

        <StyledTextField
          marginBottom={1.5}
          label={t("userSubscriptions.changePaymentMethodModal.creditCard.holder")}
          id="creditCard.holder"
          size="small"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.creditCard?.holder}
        />

        <CPF />

        {/** Removed temporarily because payments don't allow it */}
        {/* <Select
          fullWidth
          size="normal"
          label="Opções de parcelamento"
          value={values.creditCard.installments}
          onChange={(e) => setFieldValue("creditCard.installments", parseInt(String(e.target.value), 10))}
        >
          {installments.map((installment: Installment) => {
            const shouldRenderAdvanceFee = installment.advanceFee === 0

            return (
              <MenuItem key={installment.installmentAmount} value={installment.installmentAmount}>
                {installment.installmentAmount}x de {formatCurrency(installment.installmentPrice)}{" "}
                {shouldRenderAdvanceFee ? "sem juros" : ""}
              </MenuItem>
            )
          })}
        </Select> */}
      </Stack>
    </Stack>
  )
})
