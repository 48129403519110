"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["createMember"] = "createMember";
  Name["setWelcomeMessage"] = "setWelcomeMessage";
  Name["getGroupActiveSubscriptions"] = "getGroupActiveSubscriptions";
  Name["executeBotCommandParams"] = "executeBotCommandParams";
  Name["setUserDefaultCreditCard"] = "setUserDefaultCreditCard";
  Name["listUserCreditCardsParams"] = "listUserCreditCardsParams";
  Name["revokeAllRefreshTokens"] = "revokeAllRefreshTokens";
  Name["contestInvoiceDispute"] = "contestInvoiceDispute";
  Name["userGatewayAccountVerification"] = "userGatewayAccountVerification";
})(Name || (exports.Name = Name = {}));