import { BillingAPI, Document, Interface } from "@chatpay/common"
import axios from "axios"
import moment from "moment-timezone"
import { DB, Firebase } from "../Service"
import API from "./API"

class Transaction extends API implements Interface.Transaction.Function.ITemplate {
  private db = new DB(Document.Transaction)

  private async getUrl() {
    return `${await this.getBillingUrl()}/api/v1/dashboard`
  }

  public async create(data: Interface.Transaction.Function.ICreate): Promise<Interface.Transaction.Function.IResponse> {
    return (await this.call(Interface.Transaction.Function.Name.create, data)).data
  }

  public async insertReceipt(
    data: Interface.Transaction.Function.IInsertReceipt,
  ): Promise<Document.Data<Document.Member>> {
    return (await this.call(Interface.Transaction.Function.Name.insertReceipt, data)).data
  }

  /* Invoice Actions */
  public async invoiceCancel(
    data: Interface.Transaction.Function.IInvoiceAction,
  ): Promise<Interface.Gateway.Common.IInvoice> {
    return (await this.call(Interface.Transaction.Function.Name.invoiceCancel, data)).data
  }

  public async invoiceDuplicate(
    data: Interface.Transaction.Function.IInvoiceAction,
  ): Promise<Interface.Gateway.Common.IInvoice> {
    return (await this.call(Interface.Transaction.Function.Name.invoiceDuplicate, data)).data
  }

  public async invoiceLogs(
    data: Interface.Transaction.Function.IInvoiceGet,
  ): Promise<Interface.Transaction.Function.IResponseInvoiceLog[] | null> {
    const { id } = data
    const transaction = await this.db.getById(id)
    data.id = transaction?.invoiceId ?? ""
    const invoice = (await this.call(Interface.Transaction.Function.Name.invoiceLogs, data)).data
    return invoice.logs
  }

  // CLOUD RUN
  public async list(
    start: number,
    limit: number,
    status?: BillingAPI.IDashboard.DashboardStatus,
    createdAt?: [Date, Date],
    query?: string,
    orderBy?: "ASC" | "DESC",
    groupId?: string,
    netAmount?: boolean,
    role?: "creator" | "affiliate",
  ): Promise<BillingAPI.IDashboard.ListSalesOutput> {
    const params: BillingAPI.IDashboard.ListSalesInput = {
      page: start,
      perPage: limit,
      textSearch: query ?? undefined,
      orderBy,
      byDate: createdAt
        ? {
            from: createdAt[0].toISOString(),
            to: createdAt[1].toISOString(),
            timezone: moment.tz.guess() ?? "America/Sao_Paulo",
          }
        : undefined,
      groupId: groupId ?? undefined,
      byStatus: status ?? undefined,
      netAmount,
      role,
    }
    const AuthToken = Firebase.accessToken
    const url = await this.getUrl()
    const response = await axios.request({
      url: `${url}/sale`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` },
      params,
    })
    const data: BillingAPI.IDashboard.ListSalesOutput = response.data
    return data
  }

  public async getDetail(transactionId?: string, localize?: string): Promise<BillingAPI.IDashboard.SaleDetail> {
    const AuthToken = Firebase.accessToken
    const url = await this.getUrl()

    const response = await axios.request({
      url: `${url}/sale/${transactionId}`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` },
      params: { localize: localize ?? "pt" },
    })
    const data: BillingAPI.IDashboard.SaleDetail = response.data
    return data
  }

  public async chart(
    createdAt: [Date, Date],
    groupId?: string,
    netAmount?: boolean,
    role?: "creator" | "affiliate",
  ): Promise<BillingAPI.IDashboard.ChartOutput[]> {
    const params: BillingAPI.IDashboard.ChartInput = {
      from: createdAt[0].toISOString(),
      to: createdAt[1].toISOString(),
      timezone: moment.tz.guess() ?? "America/Sao_Paulo",
      groupId: groupId,
      netAmount,
      role,
    }
    const AuthToken = Firebase.accessToken
    const url = await this.getUrl()

    const response = await axios.request({
      url: `${url}/chart`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` },
      params,
    })
    const data: BillingAPI.IDashboard.ChartOutput[] = response.data
    return data
  }

  // HELPERS
  public async saveReceipt(
    id: string,
    fileName: string,
    data: string,
  ): Promise<Interface.Transaction.Function.IInsertReceipt> {
    const url = await this.saveData(data, `private/${Firebase.currentUser?.id}/receipts`, fileName)
    return { id, receiptUrl: url }
  }

  private async call(func: Interface.Transaction.Function.Name, params?: Interface.Transaction.Function.Params) {
    return await this.callFunction(`transaction/${func}`, params)
  }

  // QUERIES
  public async fetch(id: string, include: string[] = []): Promise<Document.Transaction | null> {
    return this.db.getById(id, include)
  }

  public async getNewerTransactionForGroup(groupId: string): Promise<Document.Transaction | null> {
    try {
      if (!API.currentUser) {
        return null
      }
      const transactions = await this.db.get({
        where: [
          { field: "userId", op: "==", value: API.currentUser?.id },
          { field: "item.id", op: "==", value: groupId },
        ],
        order: [{ by: "createdAt", direction: "desc" }],
      })
      return transactions[0]
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public async getByGroupId(groupId: string): Promise<Document.Transaction[] | null> {
    try {
      return await this.db.get({
        where: [
          { field: "item.id", op: "==", value: groupId },
          { field: "userId", op: "==", value: API.currentUser?.id },
        ],
        order: [{ by: "createdAt", direction: "desc" }],
      })
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public async getPaidByGroupId(groupId: string): Promise<Document.Transaction[] | null> {
    try {
      return await this.db.get({
        where: [
          { field: "userId", op: "==", value: API.currentUser?.id },
          { field: "item.id", op: "==", value: groupId },
          { field: "status", op: "==", value: Document.TransactionStatus.paid },
        ],
      })
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export default Transaction
