import i18n from "i18n"
import { UpgradePaymentMethods } from "../domain/dtos/payment-method"

export class PaymentMethodTextBuilder {
  public execute(paymentMethod?: UpgradePaymentMethods, last4?: string, cardBrand?: string): string {
    switch (paymentMethod) {
      case UpgradePaymentMethods.PAYMENT_METHOD_PIX:
        return i18n.t("userSubscriptions.paymentMethod.pix")
      case UpgradePaymentMethods.PAYMENT_METHOD_CARD:
        if (last4 && cardBrand) {
          return i18n.t("userSubscriptions.paymentMethod.last4AndCardBrand", {
            brand: i18n.t(`userSubscriptions.cardBrand.${cardBrand}`),
            last4,
          })
        } else if (last4) {
          return i18n.t("userSubscriptions.paymentMethod.last4Only", { last4 })
        } else {
          return i18n.t("userSubscriptions.paymentMethod.creditCard")
        }
      default:
        return ""
    }
  }
}
