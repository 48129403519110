import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "translations/en"
import pt from "translations/pt"
import { isDebug } from "utils/SessionUtils"

// tslint:disable-next-line: no-floating-promises
i18n.use(initReactI18next).init({
  lng: "pt",
  fallbackLng: "pt",
  debug: isDebug(),
  resources: {
    en: {
      translation: en,
    },
    pt: {
      translation: pt,
    },
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
