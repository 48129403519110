import { Document, Interface } from "@chatpay/common"
import API from "./API"

class Resource extends API implements Interface.Resource.Function.ITemplate {
  public async getAllResources(): Promise<{ resources: Document.Resource[] | [] }> {
    const response = await this.call(Interface.Resource.Function.Name.getAllResources)
    return response.data
  }

  public async getResourcesByUser(
    data: Interface.Resource.Function.IGetResourcesByUser,
  ): Promise<{ resources: Document.Resource[] | [] }> {
    const response = await this.call(Interface.Resource.Function.Name.getResourcesByUser, data)
    return response.data
  }

  private async call(func: Interface.Resource.Function.Name, params?: Interface.Resource.Function.Params) {
    return await this.callFunction(`resource/${func}`, params)
  }
}

export default Resource
