import { Spacer } from "components"
import React from "react"

export const SectionHeader = (title: string, subtitle: string) => {
  return (
    <div>
      <div className="cp text big black bold compact">{title}</div>
      <span className="cp text large">{subtitle}</span>
      <Spacer />
    </div>
  )
}
