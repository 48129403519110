import { Document, Interface } from "@chatpay/common"
import API from "./API"

class Product extends API implements Interface.Product.Function.ITemplate {
  private async call(func: Interface.Product.Function.Name, params?: Interface.Product.Function.Params) {
    return await this.callFunction(`product/${func}`, params)
  }

  public async create(data: Interface.Product.Function.ICreate): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.create, data)
    return new Document.Product(response.data)
  }

  public async get(params: Interface.Product.Function.IGet): Promise<Document.Product | null> {
    const response = await this.call(Interface.Product.Function.Name.get, params)
    return response.data
  }

  public async list(params: Interface.Product.Function.IList): Promise<Interface.Product.Function.IListResult> {
    const response = await this.call(Interface.Product.Function.Name.list, params)
    return response.data
  }

  public async setInfo(data: Interface.Product.Function.ISetInfo): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setInfo, data)
    return new Document.Product(response.data)
  }

  public async setResourceTypesIds(data: Interface.Product.Function.ISetResourceTypesIds): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setResourceTypesIds, data)
    return new Document.Product(response.data)
  }

  public async setEntryFee(data: Interface.Product.Function.ISetEntryFee): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setEntryFee, data)
    return new Document.Product(response.data)
  }

  public async setWelcome(data: Interface.Product.Function.ISetWelcome): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setWelcome, data)
    return new Document.Product(response.data)
  }

  public async setAboutAssets(data: Interface.Product.Function.ISetAboutAssets): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setAboutAssets, data)
    return new Document.Product(response.data)
  }

  public async setAboutDescription(data: Interface.Product.Function.ISetAboutDescription): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setAboutDescription, data)
    return new Document.Product(response.data)
  }

  public async setAboutAdvantages(data: Interface.Product.Function.ISetAboutAdvantages): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setAboutAdvantages, data)
    return new Document.Product(response.data)
  }

  public async setAboutFaq(data: Interface.Product.Function.ISetAboutFaq): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setAboutFaq, data)
    return new Document.Product(response.data)
  }

  public async setAffiliate(data: Interface.Product.Function.ISetAffiliate): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setAffiliate, data)
    return new Document.Product(response.data)
  }

  public async setPrice(data: Interface.Product.Function.ISetPrice): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setPrice, data)
    return new Document.Product(response.data)
  }

  public async publish(data: Interface.Product.Function.IPublish): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.publish, data)
    return new Document.Product(response.data)
  }

  public async saveOrder(data: Interface.Product.Function.ISaveOrder): Promise<boolean> {
    const response = await this.call(Interface.Product.Function.Name.saveOrder, data)
    return response.data
  }

  public async getAllAvailableInCreatorsPage(): Promise<Document.ProductWithCurrentOffer[]> {
    const response = await this.call(Interface.Product.Function.Name.getAllAvailableInCreatorsPage)
    return response.data
  }

  public async listGroups(
    params: Interface.Product.Function.IListGroups,
  ): Promise<Interface.Product.Function.IListGroupsResult[]> {
    const response = await this.call(Interface.Product.Function.Name.listGroups, params)
    return response.data
  }

  public async getCurrentOffer(params: Interface.Product.Function.IGetCurrentOffer): Promise<Document.Group | null> {
    const response = await this.call(Interface.Product.Function.Name.getCurrentOffer, params)
    return response.data
  }

  public async setSmartInstallmentsSettings(
    data: Interface.Product.Function.ISetSmartInstallmentsSettings,
  ): Promise<Document.Product> {
    const response = await this.call(Interface.Product.Function.Name.setSmartInstallmentsSettings, data)
    return new Document.Product(response.data)
  }
}

export default Product
