import i18next from "i18next"

export interface Date {
  /**
   * Converts a date to a string by using the current or specified locale.
   * @param locales A locale string or array of locale strings that contain one or more language or locale tags. If you include more than one locale string, list them in descending order of priority so that the first entry is the preferred locale. If you omit this parameter, the default locale of the JavaScript runtime is used.
   * @param options An object that contains one or more properties that specify comparison options.
   */
  toLocaleDateString(locales?: string | string[], options?: Intl.DateTimeFormatOptions): string
}
// eslint-disable-next-line no-extend-native
Date.prototype.toLocaleDateString = function (
  locales?: string | string[],
  options?: Intl.DateTimeFormatOptions,
): string {
  return Intl.DateTimeFormat(locales ?? i18next.language, options).format(this)
}
