import { Document, Events } from "@chatpay/common"
import { ClarityProvider } from "./ClarityProvider"
import { KondutoProvider } from "./KondutoProvider"
import { LogProvider } from "./LogProvider"
import { RudderStackProvider } from "./RudderStackProvider"
import { isDebug } from "./Utils"
import { AppcuesProvider } from "./AppcuesProvider"

export type AnalyticsEventParams = Record<string, unknown>
export interface IAnalyticsProvider {
  init(): void
  identify(user?: Document.User | null, providers?: AnalyticsProviderType[]): void
  identifyFB(user: firebase.default.User, providers?: AnalyticsProviderType[]): void
  page(name: string, params?: AnalyticsEventParams, providers?: AnalyticsProviderType[]): void
  track(
    event: string,
    params?: AnalyticsEventParams,
    entities?: IAnalyticsTrackableItem[],
    providers?: AnalyticsProviderType[],
  ): void
  reset(providers?: AnalyticsProviderType[]): void
  type(): AnalyticsProviderType
}

export interface IAnalyticsTrackableItem {
  toTrack(): AnalyticsEventParams
}

export enum AnalyticsProviderType {
  RUDDER_STACK,
  LOG,
  ZENDESK,
  KONDUTO,
  CLARITY,
  APPCUES,
}

export class Tracker {
  private providers: IAnalyticsProvider[]

  constructor(...providers: IAnalyticsProvider[]) {
    this.providers = providers
  }

  private onEach(callback: (n: IAnalyticsProvider) => any, providersParam?: AnalyticsProviderType[]) {
    try {
      this.providers.forEach((it) => {
        try {
          providersParam = providersParam || []
          if (providersParam.length === 0 || providersParam.includes(it.type())) {
            callback(it)
          }
        } catch (e) {
          console.error(e)
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  page = (name: string, params?: AnalyticsEventParams, providers?: AnalyticsProviderType[]) =>
    this.onEach((p) => p.page(name, params))
  identify = (user?: Document.User | null, providers?: AnalyticsProviderType[]) => {
    this.onEach((p) => {
      try {
        p.identify(user)
      } catch (e) {
        console.error(e)
      }
    })
  }
  identifyFB = (user: firebase.default.User, providers?: AnalyticsProviderType[]) => {
    if (isDebug()) console.log("Tracker identifyFB")
    this.onEach((p) => p.identifyFB(user))
  }
  track = (event: string, params?: AnalyticsEventParams, providers?: AnalyticsProviderType[]) => {
    if (isDebug()) console.log("tracker track ")
    if (Events.EventsSent.length === 0 || Events.EventsSent.find((e: any) => e === event)) {
      this.onEach((p) => p.track(event, params))
    }
  }

  reset = (providers?: AnalyticsProviderType[]): void => {
    if (isDebug()) console.log("Tracker reset")
    this.onEach((p) => p.reset())
  }

  init = (): void => {
    if (isDebug()) console.log("Tracker init")
    this.onEach((p) => p.init())
  }
}

let providers: IAnalyticsProvider[] = []

if (!isDebug()) {
  providers = [new RudderStackProvider(), new KondutoProvider(), new ClarityProvider(), new AppcuesProvider()]
} else {
  providers = [new LogProvider(), new RudderStackProvider(), new KondutoProvider()]
}

const analytics = new Tracker(...providers)

export const Analytics = new Tracker(...providers)
