export const userSubscriptions = {
  title: "My subscriptions",
  error: "There was an error bringing in the signatures, please reload the page or contact support.",
  activeSubscriptions: "Active",
  inactiveSubscriptions: "Inactive",
  subscriptionStatus: {
    active: "Active",
    inactive: "Inactive",
    canceled: "Cancelled",
    incomplete: "Incomplete",
  },
  invoiceStatus: {
    draft: "Scheduled",
    pending: "Open",
    paid: "Paid",
    expired: "No payment",
    refunded: "Refunded",
    in_protest: "Disputed",
    chargeback: "Chargeback",
  },
  actions: {
    cancel: "Cancel subscription",
  },
  table: {
    total: "Total",
    status: "Status",
    dueDate: "Due date",
  },
  invoiceHistory: "Invoice history",
  empty: "No data found",
  cancelSubscriptionHelper:
    "If you cancel your subscription now, you will still be able to access the product until <0>{{nextDueAt}}</0>.",
  back: "Back",
}
