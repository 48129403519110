import React, { ComponentClass } from "react"
import Firebase from "./Firebase"

export const FirebaseContext = React.createContext<Firebase>(Firebase.shared)

export interface IProps {
  firebase: Firebase
}

export const withFirebase = (Component: React.FC<IProps> | React.ComponentClass<IProps>) => (props: any) => {
  return (
    <FirebaseContext.Consumer>
      {(firebase) => <Component {...props} firebase={firebase as Firebase} />}
    </FirebaseContext.Consumer>
  )
}
