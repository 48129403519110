import React from "react"
import { AuthProvider, WelcomeExperiencePage as AuthWelcomeExperiencePage } from "@hub-la/fe-auth"

const RecoverPasswordTokenPage = () => {
  return (
    <AuthProvider>
      <AuthWelcomeExperiencePage />
    </AuthProvider>
  )
}

export default RecoverPasswordTokenPage
