export const activity = {
  new: "New",
  removed: "Removed",
  all: "All",
  product: "Product",
  contact: "Contact",
  client: "Client",
  community: "Community",
  analysis: "Analysis",
  status: "Status",
  date: "Date",
  removeIn: "Remove In",
  noData: "No data",
  Analysis: {
    unknown: "User not identified by Hubla.",
    member: "Up to date paying member.",
    paymentIdentified: "Payment identified.",
    notAMember: "User did not purchase the group.",
    whitelisted: "Added to whitelist.",
    admin: "Admin Entry.",
    expired: "Subscription expired.",
    removedByBot: "Removed by bot.",
    removedByAdmin: "Removed by the Administrator.",
  },
  Status: {
    unknown: "Not Identified",
    member: "Paid",
    paymentIdentified: "Paid",
    notAMember: "Not Identified",
    whitelisted: "Free member",
    admin: "Admin",
    removedByBot: "Removed",
    removedByAdmin: "Removed",
  },
  StatusFilter: {
    removed: "Removed",
    paid: "Paid",
    admin: "Admin",
    whitelist: "Free members",
    unknown: "Not Identified",
  },
  Subtitle: {
    enterGreen: "Group entry with authorization.",
    enterRed: "Group entry without authorization.",
    leaveRed: "User removed from group.",
  },
  SubHeader: "Find out who joined or left your groups.",
  disclaimer: "* This list represents members who joined or were removed from your group after Jan. 8,",
}
