import React, { useEffect, useState } from "react"
import { Button, Form } from "semantic-ui-react"
import * as Fields from "components/Fields"
import { useTranslation } from "react-i18next"
import { ISetIntegrations } from "@chatpay/common/src/Interfaces/UserInfo/IFunction"
import { API, Service } from "@chatpay/components"
import { IntegrationType, PublicApi } from "@chatpay/common"
import ActionPopup from "components/Dialogs/ActionPopup"
import { copyToClipboard } from "components/Helpers/CopyToClipboard"
import { Settings } from "components/Settings"
import "./PublicApiIntegration.scss"
import { Spacer } from "components"
interface IForm {
  apiKey?: string | undefined
}
interface IProps {
  value: Partial<IForm | undefined>
}

export const PublicApiIntegration: React.FunctionComponent<IProps> = (props) => {
  const { value } = props

  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<Error | undefined>()

  const [publicApiKey, setPublicApiKey] = useState<string | undefined>("")

  const { t } = useTranslation()

  useEffect(() => {
    if (value && value.apiKey) {
      setPublicApiKey(value.apiKey)
    }
  }, [value])

  const onGenerateAPIToken = async (isGenerateKey: boolean) => {
    setLoading(true)

    const newData: ISetIntegrations = {
      publicApi: {
        isGenerateKey: isGenerateKey,
      },
    }
    const currentUser = Service.Firebase.currentUser
    try {
      if (currentUser) {
        await new API.UserInfo().setIntegrations(newData)

        if (isGenerateKey) {
          const publicApiIntegration = await new API.UserInfo().getIntegrationByUserAndType(
            currentUser.id,
            IntegrationType.publicApi,
          )

          const fields = publicApiIntegration?.fields as PublicApi

          if (fields && fields.apiKey) {
            setPublicApiKey(fields.apiKey)
          }
        } else {
          setPublicApiKey(undefined)
        }
      }
    } catch (error: any) {
      setError(error)
      setLoading(false)
    } finally {
      setError(undefined)
      setLoading(false)
    }
  }

  return (
    <Settings.Section
      content={
        <div>
          <div className="cp text big black bold compact">
            <a href="#publicapi-integration" id="publicapi-integration"></a>
            {t("UserForm.integrations.publicApi.title")}
          </div>
          <div className="cp text large">{t("UserForm.integrations.publicApi.description")}</div>
          <Spacer />
          <a className="cp text blue large" href="https://app.hub.la/zapier-integration" target="_blank">
            {t("UserForm.integrations.publicApi.documentation")}
          </a>
        </div>
      }
      form={
        <Form>
          <Fields.InputLabel title={t("UserForm.integrations.publicApi.apiKeyTitle")}>
            <Spacer size="small" />
            <div className="buttonContainer">
              {!publicApiKey && (
                <Button
                  loading={loading}
                  disabled={false}
                  className="rounded"
                  size="small"
                  basic={true}
                  onClick={() => onGenerateAPIToken(true)}
                  children={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>{t("UserForm.integrations.publicApi.buttonRequestAPIToken")}</div>
                    </div>
                  }
                />
              )}
            </div>
            <Spacer size="small" />
          </Fields.InputLabel>
          {publicApiKey && (
            <div>
              <div className="clipboardButton">
                <Form.Input value={publicApiKey} rows={6} disabled={true} />
                <ActionPopup text={t("UserForm.integrations.publicApi.actionPopup.tokenCopied")}>
                  <Button
                    basic={true}
                    icon="copy outline"
                    disabled={loading}
                    size="small"
                    onClick={() => copyToClipboard(publicApiKey)}
                  />
                </ActionPopup>
              </div>
              <Spacer size="small" />
            </div>
          )}
        </Form>
      }
      error={error}
    />
  )
}
export { PublicApiIntegration as Form }
export type { IProps, IForm }
