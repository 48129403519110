import { Basic } from "./Basic"
import { TelegramVerification } from "./TelegramVerification"
import { TelegramOfficialVerification } from "./TelegramOfficialVerification"
import { WhatsAppVerification } from "./WhatsAppVerification"
import { MigrationRequest } from "./MigrationRequest"
export * from "./Export/Export"
export * from "./Auth/Auth"

export const Modals = {
  Basic,
  TelegramVerification,
  TelegramOfficialVerification,
  WhatsAppVerification,
  MigrationRequest,
}
