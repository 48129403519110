import API from "./API"
import get from "request-promise-native"
import { Interface, Model } from "@chatpay/common"

class ZIPCode extends API {
  public async brazil(code: string): Promise<Interface.Address> {
    const response = await get(`https://viacep.com.br/ws/${code}/json/`)
    const json = JSON.parse(response)

    const address: Readonly<Interface.Address> = {
      zip: (json.cep as string) ?? "",
      street: (json.logradouro as string) ?? "",
      complement: (json.complemento as string) ?? "",
      neighborhood: (json.bairro as string) ?? "",
      number: "",
      state: (json.uf as string) ?? "",
      city: (json.localidade as string) ?? "",
      country: Model.Country.br,
    }

    return address
  }
}

export default ZIPCode
