import { Box, Button, Card, Divider, Grid, Stack, Stats, Text } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { Skeleton } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const Loading: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box pb={40} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userSubscriptions.back")}
        </Button>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Card variant="filled" hasHover={false}>
            <Stack>
              <Stack direction="column" gap={6} mb={6}>
                <Stats variant="neutral" size="small">
                  <Skeleton width={30} />
                </Stats>
                <Stack direction="column" gap={0.5}>
                  <Text variant="body2" color="onSurfaceVariant">
                    <Skeleton width={110} />
                  </Text>
                  <Text variant="h3">
                    <Skeleton width={120} />
                  </Text>
                  <Text variant="body2" color="onSurfaceVariant">
                    <Skeleton width={170} />
                  </Text>
                </Stack>
              </Stack>

              <Divider />

              <Stack direction="column" gap={4} mt={4}>
                <Stack direction="column">
                  <Text variant="body1">De</Text>
                  <Text variant="body1" color="onSurfaceVariant">
                    <Skeleton width={110} />
                  </Text>
                </Stack>
                <Stack direction="column">
                  <Text variant="body1">Para</Text>
                  <Text variant="body1" color="onSurfaceVariant">
                    <Skeleton width={110} />
                  </Text>
                </Stack>
              </Stack>
              <Stack mt={4}>
                <Button variant="text" hierarchy="secondary" fullWidth>
                  {t("userInvoices.actions.download")}
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card variant="filled" hasHover={false} fullHeight>
            <Stack direction="column" gap={4}>
              <Stack direction="column" gap={2}>
                <Text variant="body1">Método de pagamento</Text>
                <Text variant="body1" color="onSurfaceVariant">
                  <Skeleton width={110} />
                </Text>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} marginBottom={6}>
          <Stack height="100%" width="100%">
            <Card hasHover={false} variant="filled" fullHeight>
              <Stack flexDirection="column" justifyContent="space-between" gap={5}>
                <Stack direction="column" width="100%" gap={2}>
                  <Text variant="body1" fontWeight="bold">
                    Detalhes da fatura
                  </Text>
                </Stack>
                <Stack direction="column" width="100%" gap={2}>
                  <Skeleton width={120} height={24} />
                  <Divider />
                  <Skeleton width={76} height={24} />
                  <Divider />
                  <Skeleton width={80} height={24} />
                  <Divider />
                  <Skeleton width={93} height={24} />
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Loading
