import * as React from "react"
import FormGrid from "components/Forms/FormGrid/FormGrid"
import ReactDOM from "react-dom"

export interface IValidityData {
  [field: string]: boolean
}
export type Data<T> = { [K in keyof T]: T[K] }

export interface IProps<I> {
  name?: string
  error?: boolean
  isDisabled?: boolean
  data?: Data<I> | null
  validate?: boolean
  onChange?: (form: BaseForm, data?: Data<I> | null, validity?: IValidityData | null) => void
}

export interface IState<T> {
  validity: IValidityData
  error?: boolean
  isDisabled?: boolean
  data: Data<T>
}

const InitialState = {
  validity: {},
  data: {},
}

abstract class BaseForm<
  I extends IProps<any> = {},
  T extends IState<{}> = {
    validity: {}
    data: {}
  }
> extends React.Component<I, T> {
  public state: Readonly<T> = InitialState as T
  protected abstract formGridRef: React.RefObject<FormGrid>

  public get name(): string | undefined | null {
    return this.props.name
  }

  public get isValid(): boolean {
    return Object.values(this.fieldsValidity).find((v) => !v) === undefined
  }

  protected get fieldsValidity(): IValidityData {
    return this.validity()
  }

  public static getDerivedStateFromProps(props: IProps<any>, state: IState<any>) {
    const newState: IState<any> = {} as any
    if (props.isDisabled !== state.isDisabled) {
      newState.isDisabled = props.isDisabled ?? false
    }

    if (!Object.is(props.data, state.data)) {
      newState.data = Object.keys(state.data ?? {}).length ? state.data : props.data ?? {}
      if (JSON.stringify(state.data) !== JSON.stringify(props.data) && props.data) {
        newState.data = {
          ...props.data,
          ...state.data,
        }
      }
    }
    return Object.keys(newState).length === 0 ? null : newState
  }

  protected validity(fieldName?: string): IValidityData {
    const element = ReactDOM.findDOMNode(this.formGridRef.current) as HTMLElement
    const validity: IValidityData = {}
    const elements: NodeListOf<HTMLTextAreaElement | HTMLInputElement> = element.querySelectorAll(
      fieldName ? `input[name=${fieldName}], textarea[name=${fieldName}]` : `input, textarea`,
    )

    elements.forEach((e) => {
      if (!e.name?.trim()) {
        return
      }
      const { name, isValid } = this.validateField(e.name, e.value, e.validity.valid, e)
      Object.assign(validity, { [name]: isValid })
    })

    return validity
  }

  protected textareaElement(name: string): HTMLTextAreaElement[] {
    const element = ReactDOM.findDOMNode(this.formGridRef.current) as HTMLElement
    const fields: HTMLTextAreaElement[] = []
    const elements: NodeListOf<HTMLTextAreaElement> = element.querySelectorAll(`textArea[name=${name}]`)

    elements.forEach((e) => {
      fields.push(e)
    })

    return fields
  }

  protected inputElement(name: string): HTMLInputElement[] {
    const element = ReactDOM.findDOMNode(this.formGridRef.current) as HTMLElement
    const fields: HTMLInputElement[] = []
    const elements: NodeListOf<HTMLInputElement> = element.querySelectorAll(`input[name=${name}]`)

    elements.forEach((e) => {
      fields.push(e)
    })

    return fields
  }

  protected selectElement(name: string): HTMLSelectElement[] {
    const element = ReactDOM.findDOMNode(this.formGridRef.current) as HTMLElement
    const fields: HTMLSelectElement[] = []
    const elements: NodeListOf<HTMLSelectElement> = element?.querySelectorAll(`select[name=${name}]`)

    elements.forEach((e) => {
      fields.push(e)
    })

    return fields
  }

  protected fixAutoCompleteOff() {
    const element = ReactDOM.findDOMNode(this.formGridRef.current) as HTMLElement
    const elements: NodeListOf<HTMLInputElement> = element?.querySelectorAll(`input[autocomplete=off]`)

    elements.forEach((e) => {
      e.autocomplete = "no"
    })
  }

  protected setFocus(name: string, flag: boolean = true) {
    this.textareaElement(name)?.forEach((t) => (flag ? t.focus() : t.blur()))
    this.inputElement(name)?.forEach((t) => (flag ? t.focus() : t.blur()))
    this.selectElement(name)?.forEach((t) => (flag ? t.focus() : t.blur()))
  }

  public performValidation(showError: boolean = false, onComplete: () => void = () => void 0) {
    if (showError) {
      this.setState(
        {
          validity: this.fieldsValidity,
        },
        () => {
          onComplete()
          this.sendOnChange(this.state.data as T, this.state.validity)
        },
      )
      return
    }
    this.sendOnChange(this.state.data as T, this.fieldsValidity)
  }

  protected sendOnChange(data?: Data<any> | null, validity?: IValidityData | null) {
    if (this.props.onChange) {
      this.props.onChange(this, data, validity)
    }
  }

  protected validateField(
    name: string,
    value: any,
    isValid: boolean = true,
    field?: HTMLInputElement | HTMLTextAreaElement,
  ): { name: string; isValid: boolean } {
    return { name, isValid }
  }
  protected formatField(
    name: string,
    value: any,
    isValid: boolean = true,
    field?: HTMLInputElement | HTMLTextAreaElement | null,
  ): any {
    return value
  }

  protected fieldDidChange({
    target,
    isValid = true,
    onComplete,
  }: {
    target: HTMLInputElement | HTMLTextAreaElement
    isValid?: boolean
    onComplete?: () => void
  }) {
    const { name, value, validity } = target
    this.fieldChanged(name, value, validity.valid && isValid, target, false, onComplete)
  }

  protected fieldDidBlur({
    target,
    isValid = true,
    onComplete,
  }: {
    target: HTMLInputElement | HTMLTextAreaElement
    isValid?: boolean
    onComplete?: () => void
  }) {
    const { name, value, validity } = target
    if (!name.trim()) {
      if (onComplete) {
        onComplete()
      }
      return
    }
    const { name: newName, isValid: newIsValid } = this.validateField(name, value, validity.valid && isValid, target)
    this.setState(
      {
        validity: {
          ...this.state.validity,
          [newName]: newIsValid,
        },
      },
      onComplete,
    )
  }

  protected onFieldChange = ({
    target,
    isValid = true,
  }: {
    target: HTMLInputElement | HTMLTextAreaElement
    isValid?: boolean
  }) => {
    this.fieldDidChange({ target, isValid })
  }

  protected onFieldBlur = ({
    target,
    isValid = true,
  }: {
    target: HTMLInputElement | HTMLTextAreaElement
    isValid?: boolean
  }) => {
    this.fieldDidBlur({ target, isValid })
  }

  protected fieldChanged(
    name: string,
    value: any,
    isValid: boolean = true,
    field?: HTMLInputElement | HTMLTextAreaElement | null,
    forceValidation: boolean = false,
    onComplete?: (() => void) | null,
    ignoreFormat?: boolean,
  ) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: ignoreFormat ? value : this.formatField(name, value, isValid, field),
        },
        validity: {
          ...this.state.validity,
          [name]: forceValidation ? isValid : true,
        },
      },
      () => {
        this.sendOnChange(this.state.data as T, this.state.validity)
        if (onComplete) {
          onComplete()
        }
      },
    )
  }

  protected updateFields(data: Data<{}>, onComplete?: () => void) {
    this.setState(
      {
        data: {
          ...this.state.data,
          ...data,
        },
      },
      () => {
        this.sendOnChange(this.state.data as T, this.state.validity)
        if (onComplete) {
          onComplete()
        }
      },
    )
  }

  public isFieldValid(name: string): boolean {
    return this.state.validity[name] === undefined || this.state.validity[name]
  }
}

export default BaseForm
