import { Document } from "@chatpay/common"
import { Spacer, TextArea } from "components"
import * as Fields from "components/Fields"
import { IUseFormProps } from "components/Hooks"
import { IFileAsset } from "components/Interfaces/IFileAsset"
import React from "react"
import { useTranslation } from "react-i18next"
import { Form, Input } from "semantic-ui-react"

type keys = "picture" | "firstName" | "lastName" | "about" | "email" | "socialMedia"

interface IForm {
  picture: IFileAsset
  firstName: string
  lastName: string
  about: string
  email: string
  socialMedia: Document.UserSocialMedia
}

interface IProps extends IUseFormProps<IForm> {
  disabled?: Partial<Record<keys, boolean>>
}

const CreateProfile: React.FunctionComponent<IProps> = (props) => {
  const { disabled, values, setField, setFieldTouched, errors, touched } = props

  const { t } = useTranslation()

  return (
    <Form>
      <Fields.InputLabel title={t("GetStarted.Profile.label1")}>
        <Fields.InputPicture
          value={values?.picture}
          onChange={(file) => setField("picture", file)}
          aspectRatio={0.52}
        />
      </Fields.InputLabel>
      <Spacer />
      <div style={{ display: "flex" }}>
        <Fields.InputLabel
          title={t("GetStarted.Profile.label2") + " *"}
          errorText={errors.firstName !== undefined && touched.firstName ? errors.firstName : undefined}
        >
          <Input
            error={errors.firstName !== undefined && touched.firstName}
            placeholder={t("GetStarted.Profile.placeholder1")}
            disabled={disabled?.firstName}
            value={values?.firstName ?? ""}
            onChange={(_, data) => setField("firstName", data.value)}
            onBlur={() => setFieldTouched("firstName", true)}
            fluid={true}
            style={{ paddingRight: "1em" }}
          />
        </Fields.InputLabel>
        <Fields.InputLabel
          title={t("GetStarted.Profile.label3") + " *"}
          errorText={errors.lastName !== undefined && touched.lastName ? errors.lastName : undefined}
        >
          <Input
            placeholder={t("GetStarted.Profile.placeholder2")}
            disabled={disabled?.lastName}
            error={errors.lastName !== undefined && touched.lastName}
            value={values?.lastName ?? ""}
            onBlur={() => setFieldTouched("lastName", true)}
            onChange={(_, data) => setField("lastName", data.value)}
            fluid={true}
          />
        </Fields.InputLabel>
      </div>
      <Spacer />
      <Fields.InputLabel
        title={t("GetStarted.Profile.label4") + " *"}
        errorText={errors.email !== undefined && touched.email ? errors.email : undefined}
      >
        <Input
          type="email"
          placeholder={t("GetStarted.Profile.placeholder3")}
          disabled={disabled?.email}
          error={errors.email !== undefined && touched.email}
          fluid={true}
          value={values?.email ?? ""}
          onBlur={() => setFieldTouched("email", true)}
          onChange={(_, data) => setField("email", data.value)}
        />
      </Fields.InputLabel>
      <Spacer />
      <Fields.InputLabel title={t("GetStarted.Profile.label5")}>
        <TextArea
          placeholder={t("GetStarted.Profile.placeholder4")}
          disabled={disabled?.about}
          value={values?.about ?? ""}
          onBlur={() => setFieldTouched("about", true)}
          onChange={(_, data) => setField("about", String(data.value))}
          error={errors.about !== undefined && touched.about}
          maxLength={700}
          minLength={10}
        />
      </Fields.InputLabel>
      <Spacer />
      <Fields.InputLabel
        title={t("GetStarted.Profile.label8")}
        errorText={(errors.socialMedia as Document.UserSocialMedia)?.instagram ?? undefined}
      >
        <Input
          error={!!(errors.socialMedia as Document.UserSocialMedia)?.instagram ?? false}
          placeholder={t("GetStarted.Profile.placeholder6")}
          disabled={disabled?.socialMedia}
          value={values?.socialMedia?.instagram ?? ""}
          onChange={(_, data) => setField("socialMedia", { ...values.socialMedia, instagram: data.value })}
          onBlur={() => setFieldTouched("socialMedia", true)}
          fluid={true}
          icon="instagram"
          label="instagram.com/"
        />
      </Fields.InputLabel>
    </Form>
  )
}

export { CreateProfile as Form }
export type { IProps, IForm }
