import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { Envs } from "../envs"

export enum ErrorCode {
  INVITE_ALREADY_PHONE_NUMBER = "invite::already-phone-number",
  INVITE_EMAIL_MISMATCH = "invite::email-mismatch",
  INVITE_NOT_FOUND = "invite::not-found",
  INVITE_INVALID_TYPE = "invite::invalid-type",
  INVITE_UNSUPPORTED_STATUS = "invite::unsupported-status",
  INVITE_NOT_UPDATED = "invite::invite-free-subscription-not-updated",
  MISSING_USER = "missing-user",
  NO_RESULT = "no-result",
}

export class PostRejectInvite {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(inviteId: string, signal?: AbortSignal): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/invites/${inviteId}/reject`,
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.INVITE_ALREADY_PHONE_NUMBER:
        throw new Error(
          "O telefone informado já está associado a outra conta! Escolha outro telefone ou entre em contato com o produtor para alteração do e-mail de convite.",
        )
      case ErrorCode.INVITE_EMAIL_MISMATCH:
        throw new Error("O e-mail do usuário informado não corresponde ao e-mail do convite.")
      case ErrorCode.INVITE_NOT_FOUND:
        throw new Error("O convite não foi encontrado.")
      case ErrorCode.INVITE_INVALID_TYPE:
        throw new Error("O convite não possui um tipo válido.")
      case ErrorCode.INVITE_UNSUPPORTED_STATUS:
        throw new Error("O status do convite não está mais disponível para ser rejeitado.")
      case ErrorCode.MISSING_USER:
        throw new Error("Usuário não encontrado.")
      case ErrorCode.NO_RESULT:
        throw new Error("Nenhum resultado ao rejeitar o convite.")
      case ErrorCode.INVITE_NOT_UPDATED:
        throw new Error("O convite não foi rejeitado.")
      default:
        throw new Error("Erro inesperado ao rejeitar convite.")
    }
  }
}
