"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["setRenewal"] = "setRenewal";
  Name["changePaymentMethod"] = "changePaymentMethod";
  Name["changePlan"] = "changePlan";
  Name["changePlanSimulation"] = "changePlanSimulation";
})(Name || (exports.Name = Name = {}));