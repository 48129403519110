export const editGroup = {
  mobilePageTitle: "Product",
  tabs: {
    introduction: "Introduction",
    groups: "Grupos",
    basic: "Basic",
    description: "Sales page",
    partner: "Co-producer",
    tools: "Tools",
    partners: "Co-producer",
    content: "Contents",
    waitlist: "Waitlist",
    settings: "SETTINGS",
    materials: "Welcome kit",
  },
  introduction: {
    header: {
      title: "Where do you want to start?",
      description: "With Hubla you can create a community that has groups, courses, or both...",
    },
    features: {
      memberArea: {
        title: "Member area with exclusive content",
        cta: "Add content",
        imageAlt:
          "Representing the product page of Hubla, with thumbnails of content, class materials, posts, and videos.",
      },
      group: {
        title: "Group to interact with my members",
        cta: "Create group",
        imageAlt:
          "Mockup of an iPhone with Whatsapp open in the group 'Cards, Miles, and Travel', with the message 'Another thank you to all' and an image of a black card.",
      },
      migration: {
        title: "Migrate",
        cta: "Migrate",
        imageAlt: "Some Hubla logos in the form of square bubbles.",
      },
    },
  },
  description: {
    title: "product Description",
    subtitle: "Share content that helps describes your product.",
    about: "To check out our best practices when setting up your sales page",
    link: "click here.",
    warning: "We modified our product subscription editor. Click the button below to edit your description.",
  },
  aboutDescription: {
    title: "Describe your community",
    subtitle:
      "Your product description can significantly increase the purchase conversion on your sales page. Be clear, brief and tell a little about your product.",
  },
  advantages: {
    title: "Advantages",
    subtitle:
      "Add a list of benefits to clarify to the customer, what type of access, content and other benefits they will gain from being part of your community.",
  },
  FAQ: {
    title: "Frequently Asked Questions",
    subtitle:
      "By creating a list of frequently asked questions, you reduce the number of customers who call your support team, or even stop participating for your product due to a question.",
  },
  info: {
    title: "Basic information",
    subtitle:
      "Basic information is an important part of your product. Add a title, subtitle and photo to show more credibility.",
    about: "You can learn more about setting up your product introduction",
    link: "clicking here.",
  },
  linkedGroup: {
    title: "Linked products",
    subtitle: "Your client will automatically have access to your selected products once he buys this product.",
    about: "If you want to understand exactly how linked products work",
    link: "click here.",
    disabledByCohortType:
      "For now, the linked groups experience is only available for groups belonging to products of the permanent type.",
  },
  trackers: {
    title: "Tracking pixel",
    subtitle: "Connect with external pixels",
    facebook: {
      pixel: "Meta Pixel",
      conversionApi: "Conversion Api",
      domain: "Domain",
      notSetled: "Not setled",
      verified: "Verified",
      notVerified: "Not Verified",
      remove: "Remove",
      configureDomain: "Configure domain",
      configurationDetail: "Configuration detail",
      ie: "i.e.:",
      cancel: "Cancel",
      undo: "Undo",
      invalidDomain: "Invalid domain name",
      yourdomain: "yourdomain.com",
      domainNotFound: "Domain {{domain}} not found in CNAME record",
      verify: "Verify",
      texts: {
        apple: `With the Facebook updates for Apple's iOS 14 requirements, you can only run ads that are optimized for conversion events with verified domains.`,
        1: {
          1: "1. Enter the domain you use in",
          2: "Facebook Business Manager",
        },
        2: {
          1: "The domain must be verified in the",
          2: "Business Manager",
          3: "If you have any questions, please chat with us on the support.",
        },
        3: {
          1: "2. Follow the steps to set up your CNAME with your",
          2: "domain registrar",
        },
        4: "A CNAME is used to map a subdomain that will be used in the conversion event flow.",
        5: "2.1. Go to DNS configuration on your domain registrar.",
        6: {
          1: "2.2. Set up CNAME",
          2: "pointing to",
        },
        7: "2.3. Save the DNS.",
        8: "If you are not sure about this process, please contact your domain registrar.",
        9: 'Click on "Verify" tho make sure the configuration was successful',
        10: "A certificate will be generated automatically after the verification. The DNS propagation can take up to 72 hours.",
      },
    },
  },
  price: {
    title: "Price",
    subtitle: "Set how much you want to charge for your product.",
  },
  welcome: {
    title: "Product page",
    subtitle: "Create a message for clients who buy your product and add materials such as pdfs, spreadsheets etc.",
    about: "Find out more about our welcome material",
    link: "here.",
  },
  faq: {
    title: "Any questions?",
    about: "Check out our complete guide on",
    subtitle:
      "We also sent you a welcome email with several tips and videos explaining your next steps, be sure to check the spam box and also do not hesitate to contact us for support.",
    link: "how to use Hubla",
    about2: "or watch our videos that we created especially for you",
    link2: "on YouTube.",
  },
  publishButton: {
    activateButton: "Activate",
    activateModalTitle: "You are one step away from launching your product",
    activateModalSubtitle: "Now you can choose the best way to launch your product",
    deleteModal: {
      title: "Delete product",
      subTitle:
        "When you delete your product, it will remain inactive and will no longer be displayed to you. Also, the product sales page will be disabled.",
      warningMessage: "You will not be able to reverse this action.",
      btnNext: "Delete",
      confirmDelete: "Your product has been deleted",
      confimButton: "Ready",
      tooltipButtonDescription: "Delete product",
    },
    hideModal: {
      title: "Hide profile product",
      subTitle: "By hiding the product from your profile, it will not be displayed on your creator page on Hubla.",
      description:
        "Despite this, the sales page will still be active, so whoever has the link will still be able to access it directly.",
      confirmDelete: "Your product has been hidden from your profile",
      descriptionDelete: "You can display it again on your profile at any time.",
      btnNext: "Hide profile product",
      confimButton: "Ready",
      tooltipButtonDescription: "Hide product from profile",
    },
    showModal: {
      title: "Show product in profile",
      subTitle: "When you display the product on your profile, it will appear on your creator page on Hubla.",
      description: "",
      confirmDelete: "Your product is displayed on your profile",
      descriptionDelete: "Now you can find the sales page more easily",
      btnNext: "View from profile",
      confimButton: "Done",
      tooltipButtonDescription: "Show product in profile",
    },
    maybeLater: "Talvez mais tarde",
    activateSales: "Activating sales",
    activateSalesResourceType: "Creating your {{resourceName}} {{resourceType}}",
    activateSalesResourceTypeLabel: {
      group: "group",
      channel: "channel",
    },
    activateWaitList: "Activating waitlist",
    activeSales: "Your product is active for sales ;)",
    activeWaitlist: "The waitlist has been activated ;)",
    doneSales: "Now you can share your product and start selling",
    doneWaitlist: "Your product is ready to receive new contacts",
    buttonSales: "Go to the sales page",
    buttonWaitlist: "Close",
    salesOptionTitle: "Activate sales",
    salesOptionSubtitle: "Customers can find and buy your product",
    waitlistOptionTitle: "Enable waitlist",
    waitlistOptionSibtitle: "Customers can find your community and put their contacts on the waitlist",
    activateMyProduct: "Activate my product",
    verifyAccount: "You need to verify your account.",

    activateModalCohortSettingsTitle: "Activate sales for a new class",
    activateModalCohortSettingsSubtitle:
      "When you activate your product sales you will open a new class for it, creating new chat groups. Members of one class will not have access to the chat groups of the previous class.",
    activateCohortSettingsTotalSalesLimitTitle: "Sales limit",
    activateCohortSettingsTotalSalesLimitOptions: {
      withoutTotalSalesLimit: "Without sales limit",
      withTotalSalesLimit: "With sales limit",
    },
    defineTotalLimitSales: {
      label: "Set your community's sales limit",
    },
    activateCohortSales: "Activating new class",
    doneCohortSales: "Your class has been created ;)",
    activeCohortSales: "Now you can join the first group of the class",
    activeteCohortSettingsButton: "Next",
    activateCohortSettingsTotalMembersLimitTitle: "Limit of members",
    activateCohortSettingsTotalSalesLimitInfo: "When a group is full, we automatically create another one for you.",
  },
  groups: {
    title: "Groups",
    subtitle: "Create unique communities or broadcast channels for your members",
    createAGroup: "Create a group",
    table: {
      row: {
        status: {
          idle: "Idle",
          ready: "Ready",
          full: "Full",
        },
        join: "Join",
        see: "See",
        copyToClipboard: "Copy access link",
        freeMembers: "Free members",
        quantityMembers: "Qty Members",
        quantity: "Quantity",
        actions: "Actions",
        resourceStatus: "Status",
      },
    },
    Empty: "You still don't have groups",
    accordion: {
      title: "Class",
    },
    getInMessage: {
      youHave: "You have ",
      newGroups: "new groups.",
      newGroup: "new group.",
      clickOnBotton: "Click on the button ",
      getInButton: "Get in",
      toAccess: " to access them",
    },
  },
  partner: {
    title: "Partners",
    subtitle: "Invite all partners that are part of this product.",
    invite: "Invite partner",
    edit: "Edit",
    cancel: "Cancel request",
    status: {
      invited: "Waiting for acceptance",
      cancellation_requested: "Cancellation requested",
      rejected: "Refused",
      accepted: "Active",
      canceled: "Canceled",
      finished: "Finished",
    },
  },
}
