"use strict";

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

require("moment/locale/pt-br");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// tslint:disable-next-line: no-import-side-effect
// DATE
Date.prototype.toISODateString = function () {
  var _this$toISOString$spl;

  return (_this$toISOString$spl = this.toISOString().split("T")[0]) !== null && _this$toISOString$spl !== void 0 ? _this$toISOString$spl : "";
};

Date.prototype.localized = function () {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "L";
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "pt-br";
  var timeZone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "America/Sao_Paulo";
  return (0, _momentTimezone["default"])(this).locale(locale).tz(timeZone).format(format);
};

Date.prototype.isToday = function () {
  var timeZone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "America/Sao_Paulo";
  return (0, _momentTimezone["default"])(this).tz(timeZone).isSame(_momentTimezone["default"].tz(timeZone), "day");
};

Date.prototype.nextWeekDay = function (value) {
  var timeZone = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "America/Sao_Paulo";
  var date = (0, _momentTimezone["default"])(this).tz(timeZone).toDate();
  date.setDate(date.getDate() + (value - 1 - date.getDay() + 7) % 7 + 1);
  return date;
};

Date.prototype.nextMonthDay = function (value) {
  var timeZone = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "America/Sao_Paulo";
  var today = (0, _momentTimezone["default"])(this).tz(timeZone).toDate();
  var date = (0, _momentTimezone["default"])(this).tz(timeZone).toDate();
  date.setDate(value);

  if (today.getDate() >= value) {
    date.setMonth(today.getMonth() + 1);
  }

  return date;
};

Date.prototype.isSame = function (date) {
  var unit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "day";
  var timeZone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "America/Sao_Paulo";
  return (0, _momentTimezone["default"])(this).tz(timeZone).isSame((0, _momentTimezone["default"])(date).tz(timeZone), unit);
};

Date.prototype.diff = function (date) {
  var unit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "days";
  var timeZone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "America/Sao_Paulo";
  var toDate = date !== null && date !== void 0 ? date : new Date();

  if (!date) {
    toDate.setHours(this.getHours());
    toDate.setMinutes(this.getMinutes());
    toDate.setSeconds(this.getSeconds());
  }

  return (0, _momentTimezone["default"])(this).tz(timeZone).diff((0, _momentTimezone["default"])(toDate).tz(timeZone), unit);
};

Date.prototype.timeZoned = function () {
  var timeZone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "America/Sao_Paulo";
  return (0, _momentTimezone["default"])(this).tz(timeZone).toDate();
};

Date.prototype.timeAgo = function () {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "pt-br";
  var timeZone = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "America/Sao_Paulo";
  return (0, _momentTimezone["default"])(this).locale(locale).tz(timeZone).fromNow(true);
};

Date.prototype.key = function () {
  var dateString = (0, _momentTimezone["default"])(this).locale("pt-br").format("L");
  var splitted = dateString.split("/");
  dateString = "".concat(splitted[2]).concat(splitted[1]).concat(splitted[0]);
  return parseInt(dateString, 10);
};

Date.prototype.add = function (amount) {
  var unit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "days";
  var timeZone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "America/Sao_Paulo";
  return (0, _momentTimezone["default"])(this).tz(timeZone).add(amount, unit).toDate();
};

String.prototype.isDate = function () {
  if (String.call(this) === "[object Date]") {
    return true;
  }

  if (typeof this.replace === "function") {
    this.replace(/^\s+|\s+$/gm, "");
  }

  var dateFormat = /(^\d{1,4}[.|\\/|-]\d{1,2}[.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/;
  return dateFormat.test(this);
};