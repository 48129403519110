import React, { ComponentClass } from "react"
import AuthUserContext from "./Context"
import { withFirebase, IProps as IContextProps } from "../Service/withFirebase"
import { Button } from "semantic-ui-react"
import { Document } from "@chatpay/common"

const needsEmailVerification = (authUser: Document.User): boolean => {
  return (
    (authUser &&
      !authUser.isEmailVerified &&
      authUser.providerData &&
      authUser.providerData.map((p) => (p && p.providerId) || "").includes("password")) ||
    false
  )
}

interface IState {
  isSent: boolean
}

export const withEmailVerification = (Component: ComponentClass): any => {
  class WithEmailVerification extends React.Component<IContextProps, IState> {
    constructor(props: IContextProps) {
      super(props)

      this.setState({ isSent: false })
    }

    private onSendEmailVerification = async () => {
      try {
        await this.props.firebase.doSendEmailVerification()
        this.setState({ isSent: true })
      } catch (e) {
        console.error(e)
      }
    }

    public render() {
      return (
        <AuthUserContext.Consumer>
          {(state) =>
            state.authUser && needsEmailVerification(state.authUser) ? (
              <div>
                {this.state.isSent ? (
                  <p>
                    E-Mail confirmation sent: Check your E-Mails (Spam folder included) for a confirmation E-Mail.
                    Refresh this page once you have confirmed your E-Mail.
                  </p>
                ) : (
                  <p>
                    Verify your E-Mail: Check your E-Mails (Spam folder included) for a confirmation E-Mail or send
                    another confirmation E-Mail.
                  </p>
                )}

                <Button type="button" onClick={this.onSendEmailVerification} disabled={this.state.isSent}>
                  Send confirmation E-Mail
                </Button>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return withFirebase(WithEmailVerification)
}

export default withEmailVerification
