import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { v4 } from "uuid"
import { InitUpgradePlanOutput } from "../domain/dtos/init-upgrade-plan-output"
import { UpgradeAlreadyInProgressError } from "../domain/errors/upgrade-already-in-progress"
import { Envs } from "../envs"

enum ErrorCode {
  SUBSCRIPTION_UPGRADE_ALREADY_IN_PROGRESS = "SUBSCRIPTION_UPGRADE_ALREADY_IN_PROGRESS",
}

export class InitUpgradePlan {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(subscriptionId: string): Promise<InitUpgradePlanOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/pay/init-upgrade`,
      body: this.makeBody(subscriptionId),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    switch (response.data.code) {
      case ErrorCode.SUBSCRIPTION_UPGRADE_ALREADY_IN_PROGRESS:
        throw new UpgradeAlreadyInProgressError()
      default:
        throw new Error(response.data?.code ?? "GENERAL")
    }
  }

  private makeBody(subscriptionId: string) {
    const id = v4()
    return {
      id,
      subscriptionId,
    }
  }
}
