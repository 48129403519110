import { CardVerificationValue, CreditCardExpiration, CreditCardHolder, CreditCardNumber } from "@hub-la/fe-tokenizer"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import * as Yup from "yup"

export const creditCardValidationSchema = Yup.object().shape({
  creditCard: Yup.object().when(["paymentMethod"], {
    is: (paymentMethod) => paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD,
    then: Yup.object().shape({
      number: Yup.string().test({
        name: "number",
        message: "userSubscriptions.changePaymentMethodModal.creditCard.errors.number",
        test: function (value = "") {
          return CreditCardNumber.build(value).isValid()
        },
      }),
      holder: Yup.string().test({
        name: "holder",
        message: "userSubscriptions.changePaymentMethodModal.creditCard.errors.holder",
        test: (value = "") => {
          return CreditCardHolder.build(value).isValid()
        },
      }),
      expiration: Yup.string().test({
        name: "expiration",
        message: "userSubscriptions.changePaymentMethodModal.creditCard.errors.expiration",
        test: (value = "") => {
          return CreditCardExpiration.build(value).isValid()
        },
      }),
      token: Yup.string().required("userSubscriptions.changePaymentMethodModal.creditCard.errors.token"),
      cvv: Yup.string().test({
        name: "cvv",
        message: "userSubscriptions.changePaymentMethodModal.creditCard.errors.cvv",
        test: function (value = "") {
          const number = this.parent.number
          return number && CardVerificationValue.build(value, CreditCardNumber.build(number).getValue()).isValid()
        },
      }),
    }),
  }),
})
