import { Document } from "@chatpay/common"
import { Spacer } from "components"
import { default as React, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Checkbox, Form } from "semantic-ui-react"

type IForm = Document.NotificationSettingsData

interface IProps {
  value: Document.NotificationSettingsData
  onChange: (data: Document.NotificationSettingsData, valid: boolean) => any
}

const NotificationData: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange } = props

  const [form, setForm] = useState<IForm>(value)

  const { t } = useTranslation()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const setFormValue = (name: string, value: boolean) => {
    let newData: IForm = {
      ...form,
      [name]: value,
    }

    console.log({ newData })

    setForm(newData)
    if (onChange) {
      onChange(newData, true)
    }
  }

  return (
    <Form>
      <Checkbox
        checked={form?.paymentMethod}
        label={t("EditNotification.paymentMethod")}
        onChange={(_, data) => setFormValue("paymentMethod", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.productName}
        label={t("EditNotification.productName")}
        onChange={(_, data) => setFormValue("productName", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.userName}
        label={t("EditNotification.userName")}
        onChange={(_, data) => setFormValue("userName", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.userEmail}
        label={t("EditNotification.userEmail")}
        onChange={(_, data) => setFormValue("userEmail", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.userPhone}
        label={t("EditNotification.userPhone")}
        onChange={(_, data) => setFormValue("userPhone", data.checked ?? false)}
      />
    </Form>
  )
}

export { NotificationData as Form }
export type { IProps, IForm }
