"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_DUNNING_STEPS = void 0;
var DEFAULT_DUNNING_STEPS = [{
  day: 1,
  action: "expire"
}];
exports.DEFAULT_DUNNING_STEPS = DEFAULT_DUNNING_STEPS;