import API from "./API"
import { DB } from "../Service"
import { Document } from "@chatpay/common"

export default class ShortLink extends API {
  constructor(private db = new DB(Document.ShortLink)) {
    super()
  }

  public async get(id: string): Promise<Document.ShortLink> {
    const result = await this.db.getById(id)
    if (!result) {
      throw new Error("Redirect link not found")
    }
    return result
  }
}
