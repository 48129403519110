const JoinNotificationGroupSection = {
  title: "Your notification group was created successfully 🚀",
  subtitle: "If you haven't been added to the group already, click on the button below to join.",
  action: "Join",
  enter: "Enter group",
}

const WaitingForGroupCreationSection = {
  title: "Just a moment. We are currently creating your group.",
  subtitle: "We will add you automatically once the group is created",
}

const CreateNotificationGroupFromImid = {
  title: "Create your notification group on WhatsApp 🚀",
  subtitle: "Enter your WhatsApp below so that we can create your notification group",
  placeholder: "Enter your number",
  action: "Create group",
}

const CreateNotificationGroup = {
  title: "Create your notification group on WhatsApp 🚀",
  subtitle: "You will receive notifications that you need in order to better manage your groups.",
  action: "Create group",
  loading: "Creating your group...",
}

const SomethingHappened = {
  title: "Something happened",
  subtitle: "It looks like we couldn't load your notification group link. Please, try to reload this page.",
  action: "Reload",
}

export const NotificationGroup = {
  JoinNotificationGroupSection,
  WaitingForGroupCreationSection,
  CreateNotificationGroupFromImid,
  CreateNotificationGroup,
  SomethingHappened,
}
