export const intervalDescription = {
  name: {
    monthly: "month",
    quarterly: "quarter",
    quaterly: "quarter",
    semiannually: "semester",
    annually: "year",
  },
  interval: {
    monthly: "monthly",
    quarterly: "quarterly",
    quaterly: "quarterly",
    semiannually: "semiannually",
    annually: "annually",
  },
}
