import { Model, Email } from "@chatpay/common"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Input, Message } from "semantic-ui-react"

type keys = "email" | "country"

interface IForm {
  email: string
  country: Model.Country
}

interface IProps {
  disabled?: Partial<Record<keys, boolean>>
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const Credentials: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props

  const [form, setForm] = useState<Partial<IForm>>()

  const { t } = useTranslation()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const setFormValue = (name: string, value: any) => {
    let newData: Partial<IForm>
    newData = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    if (onChange) {
      onChange(newData, valid)
    }
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return (disabled?.email || Email.isValid(data.email)) && (disabled?.country || data.country !== undefined)
  }

  return (
    <Form>
      <Fields.InputLabel title="Email">
        <Input
          disabled={disabled?.email}
          value={form?.email ?? ""}
          onChange={(_, data) => setFormValue("email", data.value)}
          fluid={true}
        />
      </Fields.InputLabel>
      <Spacer />
      <Fields.InputLabel title={t("AccountVerification.info.country") + " *"}>
        <Form.Select
          disabled={disabled?.country}
          upward={true}
          search={true}
          type="hidden"
          id="Form"
          value={form?.country ?? ""}
          placeholder={t("AccountVerification.info.countryPlaceholder")}
          name="country"
          fluid={true}
          options={Object.values(Model.Countries).map((country) => ({
            key: country.id,
            text: country.name,
            value: country.id,
          }))}
          closeOnBlur={true}
          onChange={(_, data) => setFormValue("country", data.value)}
        />
      </Fields.InputLabel>
      {disabled ? <Message content={t("UserForm.locale.m1")} /> : null}
    </Form>
  )
}

export { Credentials as Form }
export type { IProps, IForm }
