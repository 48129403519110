import React, { useRef, useState, useEffect } from "react"
import { Button, Image } from "semantic-ui-react"

import "./InputPicture.scss"
import { FileAsset, FileAssetType } from "components/Interfaces"
import axios from "axios"

interface IProps {
  value?: FileAsset
  aspectRatio: 0.52 | 1
  onChange: (file?: FileAsset) => any
}

async function processImageWithImaginary(file: File): Promise<string> {
  const imaginaryUrl = `${process.env.REACT_APP_IMAGINARY_SERVER_URL}/resize?width=200`
  const formData = new FormData()
  formData.append("file", file)
  formData.append("operation", "resize")

  try {
    const response = await axios.post(imaginaryUrl, formData, { responseType: "blob" })
    return response.data
  } catch (error) {
    console.error("Error uploading and processing image with Imaginary:", error)
    throw error
  }
}

const InputPicture: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange } = props

  const [file, setFile] = useState<FileAsset>()
  const [blob, setBlob] = useState<string>()
  const [hovered, setHovered] = useState<boolean>()

  useEffect(() => {
    if (JSON.stringify(file) !== JSON.stringify(value)) {
      if (value !== undefined && value?.type === FileAssetType.local && value.file instanceof Blob) {
        setFileBlob(value.file!)
      }
      setFile(value)
    }
  }, [value, file])

  const inputRef = useRef<HTMLInputElement>(null)

  const updateFile = async (file: File) => {
    const processedImageBlob = await processImageWithImaginary(file)

    const processedFile = new File([processedImageBlob], file.name, { type: FileAssetType.local })

    setFileBlob(processedFile)

    const newFileData = {
      file: processedFile,
      name: processedFile.name,
      type: FileAssetType.local,
    }

    setFile(newFileData)

    if (onChange) {
      onChange(newFileData)
    }
  }

  const setFileBlob = (file: File) => {
    const reader = new FileReader()
    reader.onloadend = (event: ProgressEvent<FileReader>) => {
      setBlob(event.target?.result as string)
    }
    reader.readAsDataURL(file)
  }

  const onButtonClick = () => {
    inputRef.current?.click()
  }

  const onRemoveClick = () => {
    setFile(undefined)
    if (onChange) {
      onChange(undefined)
    }
  }

  if (inputRef.current) {
    inputRef.current.value = ""
  }

  return (
    <div className="input-picture">
      <div
        className="content"
        onTouchEnd={() => setHovered(true)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {file ? (
          <React.Fragment>
            {file.type === FileAssetType.local ? <Image className="picture-pic" src={blob} /> : null}
            {file.type === FileAssetType.web ? <Image className="picture-pic" src={file.URL} /> : null}
          </React.Fragment>
        ) : null}
        <div className="inner-div">
          {!file ? (
            <Button
              icon="camera retro"
              style={{ marginRight: "0" }}
              hidden={!hovered && file}
              basic={true}
              size="small"
              onClick={() => onButtonClick()}
            />
          ) : null}
          {file && hovered && (
            <Button
              icon="trash"
              style={{ marginRight: "0" }}
              basic={true}
              size="small"
              onClick={() => onRemoveClick()}
            />
          )}
        </div>
      </div>
      <input
        disabled={false}
        ref={inputRef}
        hidden={true}
        multiple={false}
        type="file"
        accept="image/png, image/gif, image/jpeg, image/bmp, image/svg+xml"
        onChange={(event) => updateFile(event.target.files![0])}
      />
    </div>
  )
}

export default InputPicture
