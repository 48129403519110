"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaxInvoicePaymentMethod = exports.IssueTaxInvoice = void 0;
var IssueTaxInvoice;
exports.IssueTaxInvoice = IssueTaxInvoice;

(function (IssueTaxInvoice) {
  IssueTaxInvoice[IssueTaxInvoice["sale"] = 0] = "sale";
  IssueTaxInvoice[IssueTaxInvoice["afterWarranty"] = 1] = "afterWarranty";
  IssueTaxInvoice[IssueTaxInvoice["never"] = 2] = "never";
})(IssueTaxInvoice || (exports.IssueTaxInvoice = IssueTaxInvoice = {}));

var TaxInvoicePaymentMethod;
exports.TaxInvoicePaymentMethod = TaxInvoicePaymentMethod;

(function (TaxInvoicePaymentMethod) {
  TaxInvoicePaymentMethod[TaxInvoicePaymentMethod["bankSlip"] = 0] = "bankSlip";
  TaxInvoicePaymentMethod[TaxInvoicePaymentMethod["creditCard"] = 1] = "creditCard";
  TaxInvoicePaymentMethod[TaxInvoicePaymentMethod["other"] = 2] = "other";
})(TaxInvoicePaymentMethod || (exports.TaxInvoicePaymentMethod = TaxInvoicePaymentMethod = {}));