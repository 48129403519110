export const EmptyStates = {
  DashboardProducts: {
    title: "Your journey as a creator starts here",
    subtitle: "Add your first product and start building connections",
    button: "Create a new product",
    advantages: {
      content: "Develop exclusive content for your community",
      groups: "Create paid groups and have automated member management",
      lives: "Host live events and have automatic invitation sending",
      branding: "Personalize your member's area with your own branding",
    },
  },
  EditPartner: {
    title: "No co-productor registered",
    subtitle1: "Here you can add co-producers to split the billing for this product.",
    subtitle2: "The co-producer already needs to have an account at Hubla.",
    button: "Add partner",
  },
}
