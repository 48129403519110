"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["setProfile"] = "setProfile";
  Name["setCredentials"] = "setCredentials";
  Name["setBasicInfo"] = "setBasicInfo";
  Name["updateNotificationSettings"] = "updateNotificationSettings";
  Name["setIntegrations"] = "setIntegrations";
  Name["generateApiKey"] = "generateApiKey";
  Name["addDeviceToken"] = "addDeviceToken";
  Name["removeDeviceToken"] = "removeDeviceToken";
  Name["createNotificationId"] = "createNotificationId";
})(Name || (exports.Name = Name = {}));