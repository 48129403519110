import * as React from "react"
import { Grid, GridProps, Form, FormProps } from "semantic-ui-react"

import "./FormGrid.scss"

/**
 * FormGrid component can handle form submit.
 * e.g:
 * <Forms.Grid onSubmit={this.handleSubmit()}>
 * ...children and inputs
 * </Forms.Grid>
 */
interface IProps extends GridProps {
  onSubmit?: ((event: React.FormEvent<HTMLFormElement>, data: FormProps) => void) | undefined
  children?: React.ReactElement
}

class FormGrid extends React.Component<IProps, {}> {
  public render() {
    return (
      <Form noValidate={true} onSubmit={this.props.onSubmit}>
        <Grid {...this.props} padded={true} className="FormGrid">
          {this.props.children}
        </Grid>
      </Form>
    )
  }
}

export default FormGrid
