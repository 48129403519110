"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["accountRequestVerification"] = "accountRequestVerification";
  Name["updateBankAccount"] = "updateBankAccount";
  Name["updateCreditCard"] = "updateCreditCard";
  Name["listCreditCards"] = "listCreditCards";
  Name["retrieveBankAccount"] = "retrieveBankAccount";
  Name["accountValidations"] = "accountValidations";
  Name["accountInfo"] = "accountInfo";
})(Name || (exports.Name = Name = {}));