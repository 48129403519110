import React from "react"
import { Stats, StatsVariant } from "@hub-la/design-system"
import { useTranslation } from "react-i18next"
import { InvoiceStatus } from "modules/user-invoices/domain/enums/invoice-status"

type InvoiceStatusStatsProps = {
  status: InvoiceStatus
  size: "large" | "small" | "tiny"
}

export const InvoiceStatusStats = ({ status, size }: InvoiceStatusStatsProps) => {
  const { t } = useTranslation()

  const getVariant = () => {
    switch (status) {
      case InvoiceStatus.DISPUTED:
      case InvoiceStatus.PAID:
        return StatsVariant.positive
      case InvoiceStatus.UNPAID:
      case InvoiceStatus.DRAFT:
        return StatsVariant.informative
      case InvoiceStatus.EXPIRED:
      case InvoiceStatus.CANCELED:
        return StatsVariant.negative

      case InvoiceStatus.RETURNED:
      case InvoiceStatus.CHARGEBACK:
        return StatsVariant.attention

      default:
        return StatsVariant.positive
    }
  }
  return (
    <Stats variant={getVariant()} size={size}>
      {t(`userInvoices.invoiceStatus.${status}`)}
    </Stats>
  )
}
