"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["request"] = "request";
  Name["reject"] = "reject";
  Name["accept"] = "accept";
  Name["cancel"] = "cancel";
  Name["reactivate"] = "reactivate";
})(Name || (exports.Name = Name = {}));