export const GroupForm = {
  affiliates: {
    salesCommission: "Sales Commission",
    recurrentSalesCommission: "Recurrence Commission",
    anyone: "Anyone can become an affiliate",
    onlyMembers: "Only product members can become affiliates",
    error: "Must be lower than 70%",
    errorMax: "Must be lower than 70%",
    errorMin: "Must be bigger than 0%",
    title: "Affiliates",
    subtitle: "Offer a commission for people promoting your product",
    sales: "% commission per sale",
    recurrentSales: "% commission per recurrent sale",
    affiliatesSetting: "Affiliates settings:",
    cancelButton: "Cancel",
    saveButton: "Save",
  },
  entryFee: {
    entryFee: "Membership fee",
    installments: {
      disabled: "Disabled",
      upTo: "up to ",
    },
    text: "For example: if your monthly subscription costs $59 per month and a membership fee of $100, your customers will pay $100 on their first purchase and then $59 in the following months.",
    chooseInstallments: "Installments",
    renewalTolerance: {
      text: "Number of days the user can rejoin the group without having to pay the membership fee again. Example 1: User paid $100 membership fee in a $10/month subscription group. If the renewal tolerance period is defined as 7 days, they will pay only $10 to return to the group within 7 days of leaving and R$100 after this period. Example 2: The user is currently paying $10/month and the group administrator has increased the subscription price to $20/month. If the renewal tolerance period is defined as 7 days, they can return to the group paying $10/month within 7 days and, after this period, they must pay R$20/month.",
      title: "Number of grace days",
      disabled: "Disabled",
    },
  },
  info: {
    cover: "Background cover photo",
    picture: "Product cover",
    title: "Title",
    subtitle: "Subtitle",
    type: "Type",
    remaining: "characters left",
    placeholder1: "e.g. Basic guitar course",
    placeholder2: "e.g. In the coming months you will be playing in your circle of friends.",
  },
  assets: {
    remaining: "medias left",
    editMedia: "Edit media",
    editOrder: "Reorder media",
  },
  linkedGroup: {
    linkedGroups: "Linked groups",
    choose: "Choose groups that you want to connect",
    text1: "When your users purchase this group they will automatically gain access to the groups selected above.",
    text2: "Important",
    text3:
      "If you can't find a group above, remember that you need to publish the group before you can link it as a child group.",
  },
  Trackers: {
    title: "Edit Landing Page",
    pixel: "Choose which pixel you want to add.",
  },
  welcome: {
    label: "Welcome message",
    placeholder: "",
  },
  warning: "💡 You can change your group settings later, before publishing your group.",
}
