"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeStatus = makeStatus;

var _Documents = require("../Documents");

var _BankSlipHelpers = _interopRequireDefault(require("./BankSlipHelpers"));

var DateHelper = _interopRequireWildcard(require("./DateHelper"));

var _lodash = _interopRequireDefault(require("lodash"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/** @beta - This function is in beta, and is not used for change any data of the referenced subscription */
function makeStatus(aSubscription, newValues, calledFrom) {
  var _subscription$transac, _subscription$item, _subscription$item$pl, _subscription$item$pl2, _subscription$item$pl3, _subscription$transac2, _subscription$item2, _subscription$item2$m, _validTransactionItem, _result$object$transa2;

  var subscription = new _Documents.Subscription(_objectSpread(_objectSpread({}, _lodash["default"].cloneDeep(aSubscription.toData())), newValues));
  var lastTransaction = (_subscription$transac = subscription.transactions) === null || _subscription$transac === void 0 ? void 0 : _subscription$transac.first();
  var transactionExpiresAtDate = DateHelper.getDate(lastTransaction === null || lastTransaction === void 0 ? void 0 : lastTransaction.expiresAt);
  var result = {
    module: "[SUBSCRIPTION MAKE STATUS".concat(calledFrom ? " CALLED FROM " + calledFrom.toUpperCase() : "", "]"),
    object: {
      type: aSubscription.documentName,
      id: subscription.id,
      expiresAt: DateHelper.getDate(subscription.expiresAt),
      status: subscription.status,
      transaction: lastTransaction && transactionExpiresAtDate ? {
        id: lastTransaction.id,
        expiresAt: transactionExpiresAtDate,
        status: lastTransaction.status,
        paymentMethod: lastTransaction.paymentMethod
      } : null
    },
    changes: {}
  };

  if (subscription.status === _Documents.SubscriptionStatus.created || subscription.status === _Documents.SubscriptionStatus.renewed) {
    result.message = "Subscription ignored because is in ";

    if (subscription.status === _Documents.SubscriptionStatus.created) {
      result.message += "creation";
    } else {
      result.message += "renewal";
    }

    result.message += " process";
    return result;
  }

  if (!result.object.transaction) {
    result.message = "Subscription without transactions";
    return result;
  }

  if (!subscription.expiresAt) {
    result.message = "Subscription without expiresAt";
    return result;
  }

  if ((_subscription$item = subscription.item) !== null && _subscription$item !== void 0 && (_subscription$item$pl = _subscription$item.plan) !== null && _subscription$item$pl !== void 0 && _subscription$item$pl.change && !((_subscription$item$pl2 = subscription.item.plan) !== null && _subscription$item$pl2 !== void 0 && (_subscription$item$pl3 = _subscription$item$pl2.change) !== null && _subscription$item$pl3 !== void 0 && _subscription$item$pl3.performedAt)) {
    result.message = "Subscription ignored because is in migration process";
    return result;
  }

  var transactionItems = ((_subscription$transac2 = subscription.transactions) !== null && _subscription$transac2 !== void 0 ? _subscription$transac2 : []).sort(function (t1, t2) {
    var t1ExpiresAtDate = DateHelper.getDate(t1.expiresAt);
    var t2ExpiresAtDate = DateHelper.getDate(t2.expiresAt);

    if (t1ExpiresAtDate && t2ExpiresAtDate && t1ExpiresAtDate.getTime() > t2ExpiresAtDate.getTime()) {
      return -1;
    }

    return 1;
  }).sort(function (t1, t2) {
    var t1ExpiresAtDate = DateHelper.getDate(t1.expiresAt);
    var t2ExpiresAtDate = DateHelper.getDate(t2.expiresAt);

    if (t1ExpiresAtDate && t2ExpiresAtDate && t1.status === _Documents.TransactionStatus.paid && t2.status !== _Documents.TransactionStatus.paid && Math.abs(t1ExpiresAtDate.diff(t2ExpiresAtDate)) <= 5) {
      return -1;
    }

    return 1;
  });
  var validTransactionItem = transactionItems.find(function (t) {
    return t.expiresAt && (t.status === _Documents.TransactionStatus.paid || t.status === _Documents.TransactionStatus.pending || t.status === _Documents.TransactionStatus.inProtest);
  });
  var expiresAt = DateHelper.getDate(subscription.expiresAt);
  var membershipExpiresAt = DateHelper.getDate((_subscription$item2 = subscription.item) === null || _subscription$item2 === void 0 ? void 0 : (_subscription$item2$m = _subscription$item2.membershipFee) === null || _subscription$item2$m === void 0 ? void 0 : _subscription$item2$m.expiresAt);
  var validTransactionItemExpiresAt = DateHelper.getDate((_validTransactionItem = validTransactionItem) === null || _validTransactionItem === void 0 ? void 0 : _validTransactionItem.expiresAt);

  var applyStatusChangeIfNeeded = function applyStatusChangeIfNeeded(status, aResult) {
    if (subscription.status === status) {
      return;
    }

    aResult.changes = _objectSpread(_objectSpread({}, aResult.changes), {}, {
      status: status
    });
  };

  if (!validTransactionItem || !validTransactionItemExpiresAt) {
    var _subscription$transac3, _validTransactionItem2, _result$object$transa;

    validTransactionItem = (_subscription$transac3 = subscription.transactions) === null || _subscription$transac3 === void 0 ? void 0 : _subscription$transac3.find(function (t) {
      var expiredAtDate = DateHelper.getDate(t.expiresAt);
      return expiredAtDate && t.paymentMethod === _Documents.PaymentMethod.bankSlip && t.status === _Documents.TransactionStatus.expired && _BankSlipHelpers["default"].fromExpiration(expiredAtDate).diff() >= 0;
    });
    validTransactionItemExpiresAt = DateHelper.getDate((_validTransactionItem2 = validTransactionItem) === null || _validTransactionItem2 === void 0 ? void 0 : _validTransactionItem2.expiresAt);

    if (!validTransactionItem || !validTransactionItemExpiresAt) {
      result.message = "No valid transaction found";
      applyStatusChangeIfNeeded(_Documents.SubscriptionStatus.canceled, result);
      return result;
    }

    validTransactionItem.expiresAt = DateHelper.getTimestamp(_BankSlipHelpers["default"].fromExpiration(validTransactionItemExpiresAt));
    result.message = "Using an expired bankslip transaction";

    if (!((_result$object$transa = result.object.transaction) !== null && _result$object$transa !== void 0 && _result$object$transa.expiresAt) || !validTransactionItemExpiresAt.isSame(result.object.transaction.expiresAt)) {
      result.message += ";\nNeeds to be scheduled a task to expire subscription at new expires date";
      result.changes = _objectSpread(_objectSpread({}, result.changes), {}, {
        expiresAt: validTransactionItemExpiresAt,
        schedule: {
          to: validTransactionItemExpiresAt,
          status: _Documents.SubscriptionStatus.expired
        }
      });
    }
  } else {
    result.message = "Using a valid transaction";
  }

  result.validTransaction = {
    id: validTransactionItem.id,
    expiresAt: validTransactionItemExpiresAt,
    status: validTransactionItem.status,
    paymentMethod: validTransactionItem.paymentMethod
  };
  var isExpiresOutdated = validTransactionItemExpiresAt.isSame(expiresAt, "month"); // Only for logs purpose

  var cycleDate = membershipExpiresAt ? membershipExpiresAt : validTransactionItem.status === _Documents.TransactionStatus.pending ? validTransactionItemExpiresAt : validTransactionItemExpiresAt.add(subscription.interval, "months");
  var purchasedDays = cycleDate.diff(validTransactionItemExpiresAt);
  var earnedDays = Math.max(0, expiresAt.diff(cycleDate));
  var remainingDays = expiresAt.diff();
  var expiredDays = validTransactionItem.status === _Documents.TransactionStatus.pending ? validTransactionItemExpiresAt.diff() : expiresAt.diff();
  var membershipFeeDays = membershipExpiresAt ? membershipExpiresAt.diff(validTransactionItemExpiresAt) : 0;

  if (isExpiresOutdated) {
    result.message += ";\nExpires of the subscription is outdated";
  }

  var isAllCyclePaid = true;

  if (subscription.cycledAt && expiredDays > 0) {
    isAllCyclePaid = !!subscription.transactions.find(function (t) {
      return !t.isRenewing && t.status === _Documents.TransactionStatus.paid;
    });

    if (isAllCyclePaid) {
      var _paidRenewalTransacti, _paidRenewalTransacti2, _paidRenewalTransacti3;

      var paidRenewalTransactionItems = subscription.transactions.filter(function (t) {
        return t.isRenewing && (t.status === _Documents.TransactionStatus.paid || t.status === _Documents.TransactionStatus.pending);
      });
      var anchorDate = (_paidRenewalTransacti = (_paidRenewalTransacti2 = paidRenewalTransactionItems.first()) === null || _paidRenewalTransacti2 === void 0 ? void 0 : (_paidRenewalTransacti3 = _paidRenewalTransacti2.expiresAt) === null || _paidRenewalTransacti3 === void 0 ? void 0 : _paidRenewalTransacti3.toDate()) !== null && _paidRenewalTransacti !== void 0 ? _paidRenewalTransacti : cycleDate;
      var cycleCount = Math.round(expiresAt.diff(anchorDate, "days") / subscription.interval / 30);
      isAllCyclePaid = paidRenewalTransactionItems.length >= cycleCount;
      result.logs = {
        cycles: {
          paidRenewalTransactions: paidRenewalTransactionItems.length,
          expiresAt: expiresAt,
          cycleCount: cycleCount,
          anchorDate: anchorDate,
          isPaid: isAllCyclePaid
        }
      };
    }
  }

  var expiredInvoiceDays = cycleDate.diff(expiresAt);

  if (expiredDays < 0 || expiredInvoiceDays > 5 || !isAllCyclePaid) {
    if (expiredInvoiceDays > 5) {
      result.message += ";\nExpires days tolerance limit of the last transaction was not satisfied";
    }

    if (expiredDays < 0) {
      result.message += ";\nSubscription expired";
    }

    if (!isAllCyclePaid) {
      result.message += ";\nSubscription with unpaid cycle";
    }

    applyStatusChangeIfNeeded(_Documents.SubscriptionStatus.canceled, result);
    result.logs = _objectSpread(_objectSpread({}, result.logs), {}, {
      dates: {
        cycleDate: cycleDate
      },
      days: {
        expiredInvoiceDays: expiredInvoiceDays,
        expiredDays: Math.abs(expiredDays),
        membershipFee: membershipFeeDays,
        purchased: purchasedDays,
        earned: earnedDays,
        remaining: remainingDays
      }
    });
    return result;
  }

  if (!result.object.transaction || result.object.transaction.id !== validTransactionItem.id) {
    result.changes = _objectSpread(_objectSpread({}, result.changes), {}, {
      transaction: {
        id: validTransactionItem.id,
        expiresAt: validTransactionItemExpiresAt,
        status: validTransactionItem.status,
        paymentMethod: validTransactionItem.paymentMethod
      }
    });
  }

  if (!((_result$object$transa2 = result.object.transaction) !== null && _result$object$transa2 !== void 0 && _result$object$transa2.expiresAt) || !validTransactionItemExpiresAt.isSame(result.object.transaction.expiresAt)) {
    var _result$changes;

    result.changes = _objectSpread(_objectSpread({}, result.changes), {}, {
      transaction: _objectSpread(_objectSpread({}, (_result$changes = result.changes) === null || _result$changes === void 0 ? void 0 : _result$changes.transaction), {}, {
        expiresAt: validTransactionItemExpiresAt
      })
    });
  }

  if (aSubscription.isCancelRequested && aSubscription.status === _Documents.SubscriptionStatus.suspended) {
    result.message += ";\nStatus is suspended";
    applyStatusChangeIfNeeded(_Documents.SubscriptionStatus.suspended, result);
    return result;
  }

  applyStatusChangeIfNeeded(_Documents.SubscriptionStatus.activated, result);
  return result;
}