import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber"
const phoneUtil: PhoneNumberUtil = new PhoneNumberUtil()
export function formatPhoneNumber(phoneNumber: string) {
  try {
    const pn = phoneUtil.parseAndKeepRawInput(phoneNumber)
    return phoneUtil.format(pn, PhoneNumberFormat.NATIONAL)
  } catch (e) {
    return phoneNumber
  }
}
