export const Product = {
  title: "Communities",
  searchBar: {
    placeholder: "Search",
  },
  quantity: "{{totalProduct}} products",
  table: {
    header: {
      product: "Product",
      type: "Type",
      status: "Status",
      nextAction: "Sugested action",
      members: "Qty. of Members",
    },
    row: {
      members: "Members",
      member: "Member",
      status: {
        active: "Active",
        full: "Full",
      },
      communityType: {
        channel: "Channel",
        cohort: "Cohort",
        permanent: "Permanent",
      },
    },
  },
  communityType: {
    channel: "Channel",
    cohort: "Cohort",
    permanent: "Permanent",
  },
  Type: "Type",
  Members: "Members",
  Empty: "You still don't have communities",
}
