import React from "react"
import Dropzone, { DropzoneRef, DropEvent, FileRejection } from "react-dropzone"
import { Button, Icon } from "semantic-ui-react"
import loadImage, { LoadImageOptions } from "blueimp-load-image"
import { Translation } from "react-i18next"
import sanitize from "sanitize-filename"

import "./InputFile.scss"

export enum Type {
  image,
  pdf,
}

interface IState {
  files?: File[] | null
  isLoading: boolean
  isValid: boolean
  filesData?: string[] | null
  error?: string
  type?: Type
}

interface IProps {
  name?: string
  type?: Type
  error?: boolean
  disabled?: boolean
  required?: boolean
  maxFiles?: number
  filesData?: string[] | null
  onChange: (field: InputFile) => void
  onError?: (field: InputFile, error?: string | null) => void
}

class InputFile extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    files: [],
    isLoading: false,
    isValid: false,
    type: Type.image,
  }

  private dropzoneRef = React.createRef<DropzoneRef>()

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    if ((props.filesData && !state.filesData) || (props.type && state.type !== props.type)) {
      return {
        filesData: state.filesData ?? props.filesData,
        type: props.type ?? state.type,
      }
    }
    return null
  }

  get maxFiles(): number {
    return this.props.maxFiles ?? 1
  }

  get maxFileSize(): number {
    return 5000000
  }

  get supportedFileTypes(): string[] {
    const { type } = this.state
    return type === Type.image ? ["image/png", "image/jpg", "image/jpeg"] : ["application/pdf"]
  }

  get value(): string[] | null | undefined {
    return this.state.filesData
  }

  get base64(): string[] | null | undefined {
    return this.state.filesData?.map((f) => f.split("base64,")[1])
  }

  get mimeType(): string[] | null | undefined {
    return this.state.filesData?.map((f) => f.split("base64,")[0])
  }

  get fileNames(): string[] | null | undefined {
    const { filesData } = this.state
    return filesData
      ?.filter((f) => f)
      .map((f) => {
        if (f.startsWith("http") || f.startsWith("https")) {
          const mt = /(%2F)(?!.*%2F)(.*)\?/.exec(f)
          return mt ? mt[2] ?? "" : ""
        }
        return f.split("name=")[1]?.split(";")[0] ?? ""
      })
  }

  get files(): File[] {
    return this.state.files ?? []
  }

  get isValid(): boolean {
    return !this.props.required || (this.value && this.value.length > 0) || false
  }

  get name(): string | undefined {
    return this.props.name
  }

  get error(): string | undefined {
    return this.state.error
  }

  private async imageCompression(files: File[]): Promise<File[]> {
    const options: LoadImageOptions = {
      maxWidth: 1024,
      maxHeight: 1024,
      contain: true,
      orientation: true,
      canvas: true,
    }

    const promises: Promise<File>[] = []
    for (const file of files) {
      promises.push(
        new Promise<File>((resolve, reject) => {
          loadImage(
            file,
            (img) => {
              ;(img as HTMLCanvasElement).toBlob((blob) => {
                if (!blob) {
                  reject(Error("Parse image failed"))
                }
                resolve(blob as File)
              }, file.type)
            },
            options,
          )
        }),
      )
    }

    return Promise.all(promises)
  }

  private onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
    event.preventDefault()
    this.processRejectedFiles(rejectedFiles.slice(0, this.props.maxFiles ?? 1))
    this.processAcceptedFiles(acceptedFiles.slice(0, this.props.maxFiles ?? 1))
  }

  private async processAcceptedFiles(files: File[]) {
    if (!files.length) {
      return
    }

    const fileNames = files.map((f) => f.name.replace(/[\s;,]/g, "_"))

    const processedFiles = this.state.type === Type.image ? await this.imageCompression(files) : files
    processedFiles.forEach((file, idx) => {
      const reader = new FileReader()
      reader.onabort = () => {
        this.callChange(undefined)
      }
      reader.onerror = () => {
        this.callChange(undefined)
      }

      reader.onload = ((idx) => {
        return (ev: ProgressEvent<FileReader>) => {
          const result = ev.target?.result as string
          if (!result) {
            return
          }

          let filesData = this.state.filesData ?? []

          const data = result.replace(";base64", `;name=${sanitize(fileNames[idx]).toLowerCase()};base64`)

          if (this.maxFiles > 1) {
            filesData.push(data)
          } else {
            filesData = [data]
          }

          this.callChange(filesData, files)
        }
      })(idx)

      reader.readAsDataURL(file)
    })
  }

  private processRejectedFiles(files: FileRejection[]) {
    if (!files.length) {
      return
    }

    let error: string | null = null
    if (files.length > 1) {
      error = `Alguns arquivos não podem ser enviados.`
    } else {
      const { type } = this.state
      const file = files[0]

      if (!this.supportedFileTypes.includes(file.file.type)) {
        const extensions = this.supportedFileTypes
          .map((t) => t.split("/")[1])
          .join(", ")
          .replace(/, ([^, ]*)$/, " ou $1")
        error = `O arquivo deve ser 
          ${type === Type.image ? "uma imagem" : "um documento"} 
          do${this.supportedFileTypes.length !== 1 ? "s" : ""} tipo${
          this.supportedFileTypes.length !== 1 ? "s:" : ""
        } ${extensions}`
        this.setState(
          {
            error,
          },
          () => {
            if (this.props.onError) {
              this.props.onError(this, error)
            }
          },
        )
      }
    }

    if (error) {
      this.setState(
        {
          error,
        },
        () => {
          if (this.props.onError) {
            this.props.onError(this, error)
          }
        },
      )
    }
  }

  private onButtonRemoveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const { files, filesData } = this.state
    files?.splice(index, 1)
    filesData?.splice(index, 1)

    this.callChange(filesData?.length === 0 ? null : filesData, files?.length === 0 ? null : files)
  }

  private callChange(filesData?: string[] | null, files?: File[] | null) {
    this.setState({
      files,
      error: undefined,
      filesData,
    })
    this.props.onChange(this)
  }

  public render() {
    const { filesData, type, error } = this.state
    const filesCount = filesData?.length ?? 0
    const fileNames = this.fileNames ?? []
    return (
      <Translation>
        {(t, { i18n }) => (
          <div
            className={`InputFile${error ? " error" : ""}`}
            style={filesData && type === Type.image ? { backgroundImage: `url(${filesData[0]})` } : undefined}
          >
            {this.maxFiles > 1 ? (
              <div className={`counter text${filesCount === this.maxFiles ? " full" : ""}`}>
                {filesCount}/{this.maxFiles}
              </div>
            ) : null}
            <Dropzone
              onDrop={this.onDrop}
              multiple={this.maxFiles > 1}
              accept={this.supportedFileTypes}
              disabled={this.props.disabled || (filesCount === this.maxFiles && this.maxFiles > 1)}
              preventDropOnDocument={true}
              ref={this.dropzoneRef}
            >
              {({ getRootProps, getInputProps }) => {
                const text = type === Type.image ? t("ImageFile") : t("PdfFile")
                return (
                  <section>
                    {this.maxFiles === 1 && filesData?.length ? (
                      <Button
                        style={filesData && filesCount && type === Type.image ? { opacity: 0.54 } : null}
                        className="remove"
                        icon="trash alternate outline"
                        size="huge"
                        onClick={(e) => this.onButtonRemoveClick(e, 0)}
                      />
                    ) : null}
                    <span {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        style={filesData && filesCount && type === Type.image ? { opacity: 0.54 } : null}
                        size="small"
                        disabled={this.props.disabled || (filesCount === this.maxFiles && this.maxFiles > 1)}
                        icon={type === Type.image ? "camera" : "file pdf outline"}
                        content={
                          filesData
                            ? `${t(this.maxFiles > 1 ? "AddFile" : "ChangeFile")} ${text}`
                            : `${t("InsertFile")} ${text}`
                        }
                        basic={!filesData}
                      />
                      {this.maxFiles === 1 && type !== Type.image && filesData?.length ? (
                        <div className="filename text small">{fileNames[0]}</div>
                      ) : null}
                    </span>
                  </section>
                )
              }}
            </Dropzone>
            {this.maxFiles > 1 ? (
              <div className="files">
                {fileNames.map((f, idx) => (
                  <div key={idx}>
                    <Button
                      icon="trash alternate outline"
                      size="small"
                      onClick={(e) => this.onButtonRemoveClick(e, idx)}
                    />
                    <Icon name="file pdf outline" size="big" />
                    <span>{f}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </Translation>
    )
  }
}

export default InputFile
