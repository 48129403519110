import { Box, Button, Text, Divider, Grid, Stack, Snackbar } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { useGetSubscription } from "../../hooks/use-get-subscription"
import { Header } from "./components/header"
import { SubscriptionStatus } from "../../../domain/enums/subscription-status"
import { PlanDetails } from "./components/plan-details"
import { PaymentDetails } from "./components/payment-details"
import { InvoiceTable } from "./components/invoice-table"
import moment from "moment"
import { Trans, useTranslation } from "react-i18next"
import Loading from "./loading"
import { PlanType } from "modules/user-subscriptions/domain/enums/plan-type"
import { SubscriptionType } from "modules/user-subscriptions/domain/enums/subscription-type"
import { useDisableAutorenew } from "../../hooks/use-disable-autorenew"
import { PendingInvoiceCard } from "./components/pending-invoice-card"

const UserSubscriptionDetail: React.FC = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const { data: subscription, error, isFetching } = useGetSubscription(subscriptionId)
  const { mutateAsync: disableAutorenew, isLoading } = useDisableAutorenew()
  const { t } = useTranslation()
  const history = useHistory()

  if (isFetching && !subscription) {
    return <Loading />
  }

  const showPlanDetail = subscription?.type === SubscriptionType.TYPE_RECURRING
  const showPaymentDetail = subscription?.type !== SubscriptionType.TYPE_FREE
  const showTable = subscription?.type !== SubscriptionType.TYPE_FREE
  const canCancel =
    subscription?.status === SubscriptionStatus.ACTIVE &&
    [SubscriptionType.TYPE_GROUPED, SubscriptionType.TYPE_RECURRING].includes(subscription?.type)

  /** This is a workaround for Underblock and Raquel Vieira that will try to manage the subscriptions by their own */
  const isCancelNotEnabledForCreator = [
    "Business-Kqv1ZiWeAddjYshOer0Qr8wF2Rp1",
    "Business-qIkxHWuTnKaW5AbLrmA8NT5toHq2",
  ].includes(subscription?.seller?.id ?? "")

  return (
    <Box pb={40} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userSubscriptions.back")}
        </Button>
      </Stack>

      <Grid container spacing={4}>
        <PendingInvoiceCard
          subscriptionId={subscription?.id}
          cancelEta={moment(new Date()).add(subscription?.credits, "day").toISOString()}
          status={subscription?.status}
        />

        <Grid item xs={12} md={12}>
          <Header
            subscription={{
              id: subscription?.id ?? "",
              status: subscription?.status ?? SubscriptionStatus.ACTIVE,
              type: subscription?.type ?? SubscriptionType.TYPE_RECURRING,
              nextDueAt: subscription?.nextDueAt ?? "",
              canceledAt: subscription?.canceledAt ?? "",
              createdAt: subscription?.createdAt ?? "",
              inactivatedAt: subscription?.inactivatedAt ?? "",
              credits: subscription?.credits ?? 0,
            }}
            product={{
              id: subscription?.items[0]?.offerId ?? "",
              name: subscription?.items[0]?.productName ?? "",
              picture: subscription?.items[0]?.picture,
            }}
          />
        </Grid>

        {showPlanDetail && (
          <Grid item xs={12} md={true}>
            <PlanDetails
              planType={subscription?.planType ?? PlanType.MONTHLY}
              subscriptionId={subscription?.id ?? ""}
              status={subscription?.status ?? SubscriptionStatus.INACTIVE}
            />
          </Grid>
        )}

        {showPaymentDetail && (
          <Grid item xs={12} md={true}>
            <PaymentDetails
              paymentMethod={subscription?.paymentMethod}
              cardDetails={subscription?.cardDetails}
              subscriptionId={subscription?.id ?? ""}
              offerId={subscription?.items[0]?.offerId ?? ""}
            />
          </Grid>
        )}
      </Grid>

      {showTable && <InvoiceTable offerId={subscription?.items[0]?.offerId} />}

      {canCancel && !isCancelNotEnabledForCreator && (
        <Stack pt={6} gap={6}>
          <Divider />

          <Stack direction="column" gap={3} width="100%">
            <Button
              variant="filled"
              hierarchy="negative"
              fullWidth
              onClick={() => disableAutorenew(subscription?.id!)}
              loading={isLoading}
            >
              {t("userSubscriptions.actions.cancel")}
            </Button>
            <Text variant="caption" color="onSurfaceVariant">
              <Trans
                i18nKey="userSubscriptions.cancelSubscriptionHelper"
                values={{
                  nextDueAt: moment(subscription?.nextDueAt ?? "").format("D MMM. YYYY"),
                }}
                components={[<b />]}
              />
            </Text>
          </Stack>
        </Stack>
      )}
      <Snackbar open={!!error} variant="negative" closeable={false}>
        {t((error as Error)?.message) ?? ""}
      </Snackbar>
    </Box>
  )
}

export default UserSubscriptionDetail
