import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { Envs } from "../envs"

export class DisableSubscriptionAutorenew {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(id: string): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/pay/disable-subscription-auto-renew`,
      body: this.makeBody(id),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return
    }

    throw new Error(response.data?.code ?? "GENERAL")
  }

  private makeBody(id: string): { subscriptionId: string } {
    return {
      subscriptionId: id,
    }
  }
}
