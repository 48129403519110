"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sellableItemFixture = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _Documents = require("../../Documents");

var _utils = require("../../Documents/__fixtures__/utils");

var _Models = require("../../Models");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var sellableItemFixture = function sellableItemFixture(args) {
  var _args$plan$interval, _args$plan, _args$name, _args$value;

  var hasMembershipFee = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _faker["default"].datatype["boolean"]();
  var hasPlan = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _faker["default"].datatype["boolean"]();
  var interval = (_args$plan$interval = args === null || args === void 0 ? void 0 : (_args$plan = args.plan) === null || _args$plan === void 0 ? void 0 : _args$plan.interval) !== null && _args$plan$interval !== void 0 ? _args$plan$interval : _faker["default"].random.arrayElement(Object.values(_Documents.PlanInterval));
  var itemName = (_args$name = args === null || args === void 0 ? void 0 : args.name) !== null && _args$name !== void 0 ? _args$name : _faker["default"].commerce.productName();
  var price = (_args$value = args === null || args === void 0 ? void 0 : args.value) !== null && _args$value !== void 0 ? _args$value : _faker["default"].datatype.number();
  var plan = hasPlan ? {
    id: (0, _utils.iuguIdFixture)(),
    interval: interval,
    name: interval,
    identifier: interval,
    value: hasMembershipFee ? _faker["default"].datatype.number(price / 2) : price
  } : undefined;
  return _objectSpread({
    id: (0, _utils.groupIdFixture)("whatsapp"),
    name: _faker["default"].commerce.productName(),
    plan: plan,
    userId: (0, _utils.firebaseIdFixture)(),
    description: hasMembershipFee ? "Membership Fee - ".concat(itemName) : "Plan ".concat(interval, " (").concat(itemName, ")"),
    value: price,
    currency: _Models.Currency.brl,
    membershipFee: hasMembershipFee ? {
      expiresAt: _utils.FirebaseInjections.timestampFromDate(_faker["default"].date.soon())
    } : null
  }, args);
};

exports.sellableItemFixture = sellableItemFixture;