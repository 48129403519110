"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentMethod = void 0;
var PaymentMethod;
exports.PaymentMethod = PaymentMethod;

(function (PaymentMethod) {
  PaymentMethod["creditCard"] = "credit_card";
  PaymentMethod["bankSlip"] = "bank_slip";
  PaymentMethod["pix"] = "pix";
})(PaymentMethod || (exports.PaymentMethod = PaymentMethod = {}));