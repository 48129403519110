export const Product = {
  title: "Produtos",
  searchBar: {
    placeholder: "Pesquisar",
  },
  quantity: "{{totalProduct}} produtos",
  table: {
    header: {
      product: "Produto",
      type: "Tipo",
      status: "Status",
      nextAction: "Ação sugerida",
      members: "Qtd. de Membros",
    },
    row: {
      members: "Membros",
      member: "Membro",
      status: {
        active: "Ativo",
        full: "Lotado",
      },
      communityType: {
        channel: "Sala de Sinais",
        cohort: "Turmas",
        permanent: "Permanente",
      },
    },
  },
  communityType: {
    channel: "Sala de Sinais",
    cohort: "Turmas",
    permanent: "Permanente",
  },
  Type: "Tipo",
  Members: "Membros",
  Empty: "Você ainda não possui produtos",
}
