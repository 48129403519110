import React from "react"

export const OrderIcon = ({ color }: { color: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1549_39039"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1549_39039)">
        <path
          d="M14.6429 16.0656H15.4762V12.7323H14.6429V16.0656ZM14.6429 17.5H15.4762V16.6667H14.6429V17.5ZM5 7.5H15V5.83333H5V7.5ZM15 19.1667C13.8472 19.1667 12.8646 18.7604 12.0521 17.9479C11.2396 17.1354 10.8333 16.1528 10.8333 15C10.8333 13.8472 11.2396 12.8646 12.0521 12.0521C12.8646 11.2396 13.8472 10.8333 15 10.8333C16.1528 10.8333 17.1354 11.2396 17.9479 12.0521C18.7604 12.8646 19.1667 13.8472 19.1667 15C19.1667 16.1528 18.7604 17.1354 17.9479 17.9479C17.1354 18.7604 16.1528 19.1667 15 19.1667ZM2.5 18.3333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V9.72917C17.2361 9.60417 16.9653 9.5 16.6875 9.41667C16.4097 9.33333 16.125 9.27083 15.8333 9.22917V4.16667H4.16667V15.875H9.22917C9.29861 16.3056 9.40625 16.7153 9.55208 17.1042C9.69792 17.4931 9.88889 17.8611 10.125 18.2083L10 18.3333L8.75 17.0833L7.5 18.3333L6.25 17.0833L5 18.3333L3.75 17.0833L2.5 18.3333ZM5 14.1667H9.22917C9.27083 13.875 9.33333 13.5903 9.41667 13.3125C9.5 13.0347 9.60417 12.7639 9.72917 12.5H5V14.1667ZM5 10.8333H10.9167C11.4444 10.3194 12.059 9.91319 12.7604 9.61458C13.4618 9.31597 14.2083 9.16667 15 9.16667H5V10.8333Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
