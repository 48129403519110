import { Events, routes } from "@chatpay/common"
import { Analytics, Service, Session } from "@chatpay/components"
import { CookieConsent } from "components/CookieConsent"
import { createBrowserHistory } from "history"
import RecoverPasswordPage from "modules/auth/exports/recover-password"
import RecoverPasswordTokenPage from "modules/auth/exports/recover-password-token"
import SignInPage from "modules/auth/exports/signin"
import SignInEmailPage from "modules/auth/exports/signin-email"
import SignInSmsPage from "modules/auth/exports/signin-sms"
import SignInWhatsappPage from "modules/auth/exports/signin-whatsapp"
import SignUpPage from "modules/auth/exports/signup"
import WelcomeExperiencePage from "modules/auth/exports/welcome-experience"
import UserGroupsPage from "modules/user-groups/exports/user-groups"
import EventDetailsPage from "modules/user-events/exports/event-details"
import UserDownloadInvoicePage from "modules/user-invoices/exports/user-download-invoice"
import UserInvoiceDetailPage from "modules/user-invoices/exports/user-invoice-detail"
import UserInvoicesPage from "modules/user-invoices/exports/user-invoices"
import UserPendenciesPage from "modules/user-pendencies/exports/user-pendencies"
import UserRefundsPage from "modules/user-refunds/exports/user-refunds"
import UserSubscriptionDetailPage from "modules/user-subscriptions/exports/user-subscription-detail"
import UserSubscriptionsPage from "modules/user-subscriptions/exports/user-subscriptions"
import { FreeMembersPage } from "pages/FreeMembers/FreeMembers"
import WhitelistPage from "pages/User/Whitelist"
import * as React from "react"
import { Translation } from "react-i18next"
import { Route, Router, Switch } from "react-router-dom"
import "utils/Date"
import { getSiteUrl, isDebug, lazyRetry } from "utils/SessionUtils"

import "./App.scss"

// tslint:disable-next-line: no-unused-expression
new Service.Firebase()

const UserDashboardPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Dashboard" */ "pages/User/Dashboard"), "Dashboard"),
)
const EditGroupPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EditGroup" */ "pages/EditGroup"), "EditGroup"),
)
const EditOfferPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EditOffer" */ "pages/EditOffer"), "EditOffer"),
)
const CreateOfferPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "CreateOffer" */ "pages/CreateOffer"), "CreateOffer"),
)
const EditTicketPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EditTicket" */ "pages/EditTicket"), "EditTicket"),
)
const CreateTicketPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "CreateTicket" */ "pages/CreateTicket"), "CreateTicket"),
)
const EditCohortPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EditCohort" */ "pages/EditCohort"), "EditCohort"),
)
const CreateCohortPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EditOffer" */ "pages/CreateCohort"), "CreateCohort"),
)
const RoleplayInit = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "roleplay" */ "modules/roleplay/exports/roleplay"), "roleplay"),
)
const UnsubscribeTokenPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "unsubscribe-notification" */ "modules/unsubscribe-notification/exports/unsubscribe-token"
      ),
    "unsubscribe-token",
  ),
)
const ListCollaborator = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "collaborators-list" */ "modules/roleplay/exports/collaborators/list"),
    "collaborators-list",
  ),
)
const AcceptInvitePage = React.lazy(() =>
  lazyRetry(() => import("modules/invites/exports/accept-invite"), "accept-invite"),
)

const EditCollaborator = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "edit-collaborator" */ "modules/roleplay/exports/collaborators/edit"),
    "edit-collaborator",
  ),
)
const CreateCollaborator = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "create-post" */ "modules/roleplay/exports/collaborators/create"),
    "create-collaborator",
  ),
)
const ExportPage = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "Export" */ "pages/Export"), "Export"))
const AuthPage = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "Auth" */ "pages/Auth"), "Auth"))
const SettingsPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Settings" */ "pages/Settings"), "Settings"),
)
const CreatePost = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "create-post" */ "modules/create-post/exports/create-post"),
    "create-post",
  ),
)
const EventLive = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "live" */ "modules/post-detail/exports/live"), "live"),
)
const PostDetail = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "post-detail" */ "modules/post-detail/exports/post-detail"),
    "post-detail",
  ),
)
const UserGroupsOrderPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "GroupsOrder" */ "pages/User/GroupsOrder"), "GroupsOrder"),
)
const TermsOfUsePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "TermsOfUse" */ "pages/TermsOfUse"), "TermsOfUse"),
)
const PrivacyPolicyPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "PrivacyPolicy" */ "pages/PrivacyPolicy"), "PrivacyPolicy"),
)
const GetStarted = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "GetStarted" */ "pages/GetStarted"), "GetStarted"),
)
const AutomaticAuth = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "AutomaticAuth" */ "pages/AutomaticAuth"), "AutomaticAuth"),
)
const GetAffiliate = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "GetAffiliate" */ "modules/get-affiliate/exports/get-affiliate-page"),
    "GetAffiliate",
  ),
)
const LogoutPage = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "Logout" */ "pages/Logout"), "Logout"))
const CheckoutResolver = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "CheckoutResolver" */ "pages/Checkout/CheckoutResolver"),
    "CheckoutResolver",
  ),
)
const CoProductionPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "co-production-page" */ "modules/co-production/exports/co-production-page"),
    "co-production-page",
  ),
)
const VerificationTelegramPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "verification-telegram-page" */ "modules/verification-telegram/exports/verification-telegram-page"
      ),
    "verification-telegram-page",
  ),
)
const FirstExperience = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "FirstExperience" */ "pages/FirstExperience"), "FirstExperience"),
)

const history = createBrowserHistory()

export const getHistory = () => history

class AppComponent extends React.Component {
  static contextType = Session.Context

  public componentDidMount() {
    const urlSearch = new URLSearchParams(window.location.search)
    const campaign = urlSearch.get("utm_campaign")
    const trackingParams: Record<string, string | null> = {
      campaign: campaign ? decodeURI(campaign) : null,
      source: urlSearch.get("utm_source"),
      medium: urlSearch.get("utm_medium"),
    }

    if (trackingParams.campaign || trackingParams.source || trackingParams.medium) {
      Analytics.track(Events.TRACKING_REDIRECT, trackingParams)
    }
  }

  public shouldShowCookieConsent = !window.location.pathname.includes(routes.CHECKOUT.LINK)

  public render() {
    return (
      <Translation>
        {() => (
          <>
            {this.shouldShowCookieConsent && <CookieConsent />}
            <Router history={history}>
              <React.Suspense fallback={<div />}>
                <Switch>
                  <Route exact={true} path={routes.AUTOMATIC_AUTH.PATH} component={AutomaticAuth} />
                  <Route exact={true} path={routes.CREATE_COHORT.PATH} component={CreateCohortPage} />
                  <Route exact={true} path={routes.EDIT_COHORT.PATH} component={EditCohortPage} />
                  <Route exact={true} path={routes.CREATE_OFFER.PATH} component={CreateOfferPage} />
                  <Route exact={true} path={routes.EDIT_OFFER.PATH} component={EditOfferPage} />
                  <Route exact={true} path={routes.CREATE_TICKET.PATH} component={CreateTicketPage} />
                  <Route exact={true} path={routes.EDIT_TICKET.PATH} component={EditTicketPage} />
                  <Route path={routes.EDIT_PRODUCT.PATH} component={EditGroupPage} />
                  <Route exact={true} path={routes.CREATE_HUB_POST.PATH} component={CreatePost} />
                  <Route exact={true} path={routes.EVENT_LIVE.PATH} component={EventLive} />
                  <Route exact={true} path={routes.POST_DETAIL.PATH} component={PostDetail} />
                  <Route exact={true} path={routes.CREATE_COLLABORATOR.LINK} component={CreateCollaborator} />
                  <Route exact={true} path={routes.COLLABORATORS.LINK} component={ListCollaborator} />
                  <Route exact={true} path={routes.COLLABORATORS.PATH} component={EditCollaborator} />
                  <Route exact={true} path={routes.ROLEPLAY.LINK} component={RoleplayInit} />
                  <Route exact={true} path={routes.UNSUBSCRIBE_NOTIFICATION.PATH} component={UnsubscribeTokenPage} />
                  {/**@deprecated: Remove this redirect when users stop sharing this url */}
                  <Route
                    exact={true}
                    path={routes.OLD_GROUP_POST.PATH}
                    component={() => {
                      window.location.href = routes.USER_GROUPS.PATH
                      return null
                    }}
                  />
                  <Route
                    exact={true}
                    path={routes.DETAIL_HUB_POST.PATH}
                    component={() => {
                      const postId = window.location.pathname.split("/").pop()
                      window.location.href = routes.POST_DETAIL.LINK + "/" + postId
                      return null
                    }}
                  />
                  <Route
                    exact={true}
                    path={routes.HOME.PATH}
                    component={() => {
                      if (isDebug()) {
                        history.push(routes.USER_GROUPS.PATH)
                        return null
                      }
                      window.location.href = getSiteUrl("")
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.TERMS_OF_USE.PATH} component={TermsOfUsePage} />
                  <Route exact={true} path={routes.PRIVACY_POLICY.PATH} component={PrivacyPolicyPage} />
                  <Route exact={true} path={routes.SETTINGS.PATH} component={SettingsPage} />
                  <Route exact={true} path={routes.DASHBOARD.PATH} component={UserDashboardPage} />
                  <Route exact={true} path={routes.EXPORT.PATH} component={ExportPage} />
                  <Route
                    exact={true}
                    path={routes.AFFILIATE.PATH}
                    component={() => {
                      history.push(`${routes.DASHBOARD.LINK}?tab=affiliates#products`)
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.CO_PRODUCTION.PATH} component={CoProductionPage} />
                  <Route exact={true} path={routes.REFUND.PATH} component={UserRefundsPage} />
                  <Route exact={true} path={routes.FIRST_EXPERIENCE.PATH} component={FirstExperience} />
                  <Route exact={true} path={routes.ACCEPT_INVITE.PATH} component={AcceptInvitePage} />

                  {/**@todo: Remove this when every site redirects to /settings */}
                  <Route
                    exact={true}
                    path={routes.NOTIFICATION_GROUP.PATH}
                    component={() => {
                      history.push(routes.SETTINGS.PATH)
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.USER_GROUPS.PATH} component={UserGroupsPage} />
                  <Route exact={true} path={routes.EVENT_DETAILS.PATH} component={EventDetailsPage} />
                  <Route exact={true} path={routes.USER_SUBSCRIPTIONS.LINK} component={UserSubscriptionsPage} />
                  <Route exact={true} path={routes.USER_SUBSCRIPTIONS.PATH} component={UserSubscriptionDetailPage} />
                  <Route exact={true} path={routes.USER_INVOICES.LINK} component={UserInvoicesPage} />
                  <Route exact={true} path={routes.USER_INVOICES.PATH} component={UserInvoiceDetailPage} />
                  <Route exact={true} path={routes.USER_PENDENCIES.LINK} component={UserPendenciesPage} />
                  <Route exact={true} path={routes.USER_DOWNLOAD_INVOICE.PATH} component={UserDownloadInvoicePage} />
                  <Route exact={true} path={routes.GROUPS_ORDER.PATH} component={UserGroupsOrderPage} />
                  <Route exact={false} path={routes.GET_STARTED.PATH} component={GetStarted} />
                  <Route path={routes.AUTH.PATH} component={AuthPage} />
                  <Route path={routes.LOGOUT.PATH} component={LogoutPage} />

                  {/**@todo: Remove this when every site redirects to /become-a-creator */}
                  <Route
                    path={"/create_draft"}
                    component={() => {
                      history.push(routes.CREATE_GROUP.LINK)
                      return null
                    }}
                  />
                  <Route exact={true} path={routes.GROUP_AFFILIATE.PATH} component={GetAffiliate} />
                  <Route exact={true} path={routes.CHECKOUT.PATH} component={CheckoutResolver} />
                  {/**@todo: Remove the following 3 routes when the support team remove it from the FAQ */}
                  <Route
                    exact={false}
                    path={routes.ACCOUNT_VERIFICATION.PATH}
                    component={() => {
                      history.push(routes.DASHBOARD.LINK + "#products")
                      return null
                    }}
                  />
                  <Route
                    exact={true}
                    path={routes.ACCOUNT_VERIFICATION_DONE.PATH}
                    component={() => {
                      history.push(routes.DASHBOARD.LINK + "#products")
                      return null
                    }}
                  />
                  <Route
                    exact={true}
                    path={routes.ACCOUNT_BANK_ACCOUNT.PATH}
                    component={() => {
                      history.push(routes.DASHBOARD.LINK + "#products")
                      return null
                    }}
                  />

                  <Route
                    path={routes.INTEGRATIONS_ZAPIER.PATH}
                    component={() => {
                      window.location.href = routes.INTEGRATIONS_ZAPIER?.EXTERNAL_URL ?? ""
                      return null
                    }}
                  />
                  <Route
                    path={routes.INTEGRATIONS_WEBHOOK_EVENTS.PATH}
                    component={() => {
                      window.location.href = routes.INTEGRATIONS_WEBHOOK_EVENTS?.EXTERNAL_URL ?? ""
                      return null
                    }}
                  />
                  <Route path={routes.WHITELIST.PATH} component={WhitelistPage} />
                  <Route path={routes.FREE_MEMBERS.PATH} component={FreeMembersPage} />

                  <Route exact={false} path={routes.TELEGRAM_VERIFICATION.PATH} component={VerificationTelegramPage} />

                  <Route exact={true} path={routes.SIGNUP.LINK} component={SignUpPage} />
                  <Route exact={true} path={routes.SIGNIN.LINK} component={SignInPage} />
                  <Route exact={true} path={routes.SIGNIN_SMS.LINK} component={SignInSmsPage} />
                  <Route exact={true} path={routes.SIGNIN_WHATSAPP.LINK} component={SignInWhatsappPage} />
                  <Route exact={true} path={routes.SIGNIN_EMAIL.LINK} component={SignInEmailPage} />
                  <Route exact={true} path={routes.RECOVER_PASSWORD.LINK} component={RecoverPasswordPage} />
                  <Route exact={true} path={routes.RECOVER_PASSWORD.PATH} component={RecoverPasswordTokenPage} />
                  <Route exact={true} path={routes.WELCOME_EXPERIENCE.LINK} component={WelcomeExperiencePage} />

                  {/* Those routes needs to be the last ones, because they will only be reached if none of above matches */}
                  <Route
                    path={routes.USER_PROFILE_DEPRECATED.PATH}
                    component={() => {
                      window.location.href = `${getSiteUrl(window.location.pathname)}`
                      return null
                    }}
                  />
                  <Route
                    path={routes.USER_PROFILE.PATH}
                    component={() => {
                      window.location.href = `${getSiteUrl(window.location.pathname)}`
                      return null
                    }}
                  />
                </Switch>
              </React.Suspense>
            </Router>
          </>
        )}
      </Translation>
    )
  }
}

const App = Session.withAuthentication(AppComponent)
export default App
