import {
  AutomaticAuthErrors,
  EditNotification,
  NotificationGroup,
  Product,
  Receiver,
  Sales,
  TelegramVerification,
  WhatsAppVerification,
  createGroup,
  createProduct,
  productGroups,
  refundPage,
} from "./pt/index"

import { aboutUs } from "./pt/AboutUs"
import { AccountVerification } from "./pt/AccountVerification"
import { activity } from "./pt/Activity"
import { adminDashboard } from "./pt/AdminDashboard"
import { checkout } from "./pt/Checkout"
import { couponForm } from "./pt/CouponForm"
import { Dashboard } from "./pt/Dashboard"
import { DashboardWallet } from "./pt/DashboardWallet"
import { EditAbout } from "./pt/EditAbout"
import { editGroup } from "./pt/EditGroup"
import { EmptyStates } from "./pt/EmptyStates"
import { FreeMembers } from "./pt/FreeMembers"
import { GetStarted } from "./pt/GetStarted"
import { groupDetail } from "./pt/GroupDetail"
import { GroupForm } from "./pt/GroupForm"
import { GroupStatusButton } from "./pt/GroupStatusButton"
import { intervalDescription } from "./pt/IntervalDescription"
import { landingPage } from "./pt/LandingPage"
import { migrationRequest } from "./pt/MigrationRequest"
import { userGroups } from "./pt/UserGroups"
import { userEvents } from "./pt/UserEvents"
import { myGroups } from "./pt/MyGroups"
import { userSubscriptions } from "./pt/UserSubscriptions"
import { userInvoices } from "./pt/UserInvoices"
import { userPendencies } from "./pt/UserPendencies"
import { paymentModals } from "./pt/PaymentModals"
import { ProductForm } from "./pt/ProductForm"
import { purchaseDetail } from "./pt/PurchaseDetail"
import { subscription } from "./pt/Subscription"
import { newExperienceAlert } from "./pt/NewExperienceAlert"

const navBar = {
  Login: "Login",
  Tips: "Dicas e Recursos",
  MyProducts: "Meus Produtos",
  MyGroups: "Meus Produtos",
  MySales: "Minhas Vendas",
  Hello: "Olá",
  FAQ: "Ajuda",
  Learn: "Aprenda",
  Pricing: "Preços",
  Mode: {
    Creator: "Criador",
    Member: "Membro",
  },
  ChangeMode: {
    Creator: "Usar Modo Criador",
    Member: "Usar Modo Membro",
  },
}

const navBarMenu = {
  Dashboard: "Minhas Vendas",
  Affiliates: "Receitas de afiliados",
  TipsAndResources: "Dicas e recursos",
  OurBlog: "Nosso blog",
  FAQ: "Ajuda",
  Profile: "Perfil",
  EditProfile: "Editar perfil",
  AccountVerified: "Conta Verificada",
  BankAccount: "Conta Bancária",
  VerifyAccount: "Verificar Conta",
  Invite: "Convide um criador",
  Logout: "Sair",
}

const cookies = {
  Title: "Este site utiliza cookies para melhorar a experiência do usuário.",
  Button: "Ok, entendi!",
}

const pages = {
  Home: "Home",
  Blog: "Blog",
  Pricing: "Preços",
  Team: "Time",
  Faq: "Central de Ajuda",
  TermsOfUse: "Termos de Uso",
  Refunds: "Reembolsos",
  Invite: "Convide um Criador",
  Careers: "Carreiras",
  HowTo: "Guia da Hubla",
  AboutUs: "Quem somos nós?",
}

const common = {
  actives: "Ativos",
  inactives: "Inativos",
  sales: "Vendas",
  coupons: "Cupons",
  wallet: "Carteira",
}

const general = {
  becomeCreator: "Se torne um criador",
  CreateGroup: "Criar grupo",
  CreateProduct: "Criar produto",
  ContactSales: "Contatar Vendas",
  JoinGroup: "Entrar no Grupo",
  Join: "Entrar",
  LinkCopied: "Link copiado!",
  CopyLink: "Copiar Link",
  CopyProfileLink: "Copiar Link Do Perfil",
  Share: "Compartilhar",
  Groups: "grupos",
  product: "Produto",
  Next: "próximo",
  Save: "salvar",
  Saved: "salvo!",
  Price: "Preço",
  ImageFile: "Imagem",
  PdfFile: "pdf",
  InsertFile: "Inserir ou arrastar",
  ChangeFile: "Trocar",
  AddFile: "Adicionar",
  Monthly: "mensal",
  Quarterly: "trimestral",
  Semianually: "semestral",
  Anually: "anual",
  Month: "mês",
  Quater: "trimestre",
  Semianual: "semestre",
  Anual: "ano",
  Plan: "Plano",
  Publish: "Publicar",
  Available: "Disponível",
  WhatsApp: "WhatsApp",
  Created: "Criado",
  At: "em",
  Thanks: "Obrigado",
  Payment: "Pagamento",
  Paid: "pago",
  Copy: "Copiar",
  PhoneNumber: "Telefone",
  Today: "Hoje",
  New: "Novo",
  Confirm: "Confirmar",
  Cancel: "Cancelar",
  Discover: "Discover",
  group: "Grupo",
  Sales: "Vendas",
  Edit: "Editar",
  Delete: "Deletar",
  Advantages: "Vantagens",
  SeeAllMedias: "Ver todas as mídias",
  maybeLater: "Talvez mais tarde",
  View: "Visualizar",
  close: "Fechar",
}

const userDocument = {
  Personal: "CPF",
  Company: "CNPJ",
}

const helpPopup = {
  HelpPopup1: "Este é o saldo de suas vendas que será liberado para transferência em até 30 dias.",
  HelpPopup2: "Este é o valor que será descontado de seu saldo pela venda dos seus grupos por afiliados.",
  HelpPopup3: "Este é o saldo que você pode transferir.",
  HelpPopup4: "Este é o saldo de suas vendas como afiliado que ficará disponível para transferência em até 30 dias.",
  HelpPopup5:
    "Este é seu saldo disponível por vendas como afiliado, no entando você precisa verificar sua conta para sacá-lo.",
  HelpPopup6: "Este é seu crédito disponível na plataforma, no entando você precisa verificar sua conta para sacá-lo.",
  HelpPopup7:
    "Este é o saldo que será depositado em sua conta cadastrada. Normalmente esse processo demora no máximo 48h.",
  HelpPopup8:
    "O número de telefone registrado na plataforma deve ser o mesmo que você utiliza em seu WhatsApp ou Telegram, isso se dá necessário pela nossa verificação dentro dos grupos.",
  HelpPopup9: "Essas são suas vendas na Hubla, os valores não descontam as nossas taxas.",
  HelpPopup10: "Aqui você pode acompanhar a situação dos assinantes de seus grupos.",
  HelpPopup11: "Você pode inserir vários endereços de email separando-os por uma vírgula.",
  HelpPopup12: "Seu link de convite é único e é importante que seu convidado se registre a partir dele.",
}

const paymentMethod = {
  Title: "Método de Pagamento",
  credit_card: "Cartão de Crédito",
  debit_card: "Cartão de Débito",
  cash_tranfer: "Transferência Bancária",
  bank_slip: "Boleto Bancário",
  pix: "PIX",
  cash: "Dinheiro",
}

const termsOfUse = {
  title: "Nossos termos de uso mudaram!",
  subtitle: "Ao clicar em Aceito, você aceita e concorda com nossos",
  term: "Termos de Uso",
  accept: "Aceito",
}

const bankStatements = {
  withdrawsHistory: "Histórico de transferência",
  referralCommission: "Comissão de Indicação",
  amountTransfer: "Transferência de valores",
  refund: "Reembolso",
  affiliateCommission: "Comissão de Afiliado",
  bankSlipReturnCommission: "Reembolso de taxa de boleto",
  withdrawFee: "Taxa de transferência",
  withdrawalRequest: "Pedido de transferência",
  value: "Valor",
  date: "Data",
  status: "Status",
  feedback: "Feedback",
  reason: "Finalidade",
  fees: "Taxas",
  from: "De",
  to: "Para",
}

const wallet = {
  Balance: "Saldo:",
  Receivable: "Disponível em breve",
  Commissionspayable: "Comissões a pagar:",
  Available: "Disponível para transferir",
  AffiliatesReceivable: "Afiliados a Receber:",
  AffiliatesAvailable: "Afiliados disponível:",
  Credit: "Crédito:",
  InTransit: "A caminho do seu banco",
  Withdraw: "Fazer pedido de transferência",
  Text: "Seu saldo a receber pode demorar até 30 dias para ficar disponível para transferência. Com excessão de pagamentos por boleto bancário.",
}

const AccountBankAccount = {
  title: "Sua conta bancária",
  subtitle: "Se você quiser, você pode alterar os dados da sua conta",
  button: "atualizar",
  success: "Conta atualizada com sucesso",
  bradescoHint: "Para clientes Bradesco, é necessário saber o dígito da sua agência. Você pode encontrá-lo clicando",
  bradescoHintHere: "aqui.",
  caixaHint:
    "Para clientes Caixa Econômica, o dígito da conta pode ser: um número, 0 ou X. Ex: 00000000-5, 00000000-0 ou 00000000-X",
  bank: "Banco",
  agency: "Agência",
  agencyAndCheck: "Agência e Dígito",
  account: "Conta",
  accountAndCheck: "Conta e Dígito",
  operation: "Operação",
  accountType: "Tipo de Conta",
  checking: "Corrente",
  savings: "Poupança",
}

const invoice = {
  paid: "Paga",
  pending: "Pendente",
  expired: "Expirada",
  canceled: "Cancelada",
  credit_card: "Cartão de Crédito",
  bank_slip: "Boleto Bancário",
  pix: "PIX",
  refunded: "Estornado",
  notFound: "Nenhuma fatura encontrada",
  /** STATUS_UNSPECIFIED - Default value, do not use. */
  STATUS_UNSPECIFIED: "Não especificado",
  /** STATUS_DRAFT - Draft. Amounts, items and quantities can be modified. */
  STATUS_DRAFT: "Racunho",
  /** STATUS_UNPAID - Invoice is ready to be charged and will not be modified anymore. */
  STATUS_UNPAID: "Não pago",
  /** STATUS_PAID - Invoice was paid. */
  STATUS_PAID: "Pago",
  /** STATUS_PARTIALLY_PAID - Invoice was paid, but the paid amount was lower than amount_due_cents. */
  STATUS_PARTIALLY_PAID: "Parcialmente pago",
  /** STATUS_OVERDUE - Invoice wasn't paid before the due_date. */
  STATUS_OVERDUE: "Atrasado",
  /**
   * STATUS_VOID - Invoice canceled/discarded. Should not be paid. If, however, the payer manages to paid it, money will be automatically
   * returned (invoice status will continue to be INVOICE_STATUS_VOID). This status is final.
   */
  STATUS_VOID: "Cancelado/Descartado",
  /** STATUS_RETURNED - Invoice was paid but the full amount was returned to the payer. */
  STATUS_RETURNED: "Reembolsado",
  /** STATUS_PARTIALLY_RETURNED - Invoice was paid but part of the money was returned to the payer. */
  STATUS_PARTIALLY_RETURNED: "Parcialmente reembolsado",
}

const invoicePaymentMethod = {
  PAYMENT_METHOD_PIX: "PIX",
  PAYMENT_METHOD_CARD: "Cartão de crédito",
  PAYMENT_METHOD_BOLETO: "Boleto",
}

const Page = {
  avaliation: "Em Avaliação",
  accepted: "Aceito",
  rejected: "Rejeitado",
  failed: "Falha",
}

const affiliateDashboard = {
  Title: "Afiliados",
  Subtitle: "Veja suas vendas e saldos no <0>dashboard</0>.",
  Empty: "Você ainda não está afiliando nenhum produto",
  Sale: "venda",
  Sales: "Vendas",
  AsAffiliate: "como afiliado",
  LiquidVolume: "Volume Liquido",
  StepsTitle: "Como afiliar um produto?",
  Step1Title: "Copie seu link de afiliado",
  Step1Text: "Você possui um link único para cada produto. Copie o link do produto que deseja promover.",
  Step2Title: "Divulgue seu link",
  Step2Text: "Para as pessoas conseguirem comprar a vaga em seu produto, compartilhe esse link.",
  Step3Title: "Você recebe o bônus por compra confirmada",
  Step3Text: "Lembrando que a compra tem que ser feita usando o seu link.",
  Step4Title: "Veja seu saldo nesse painel",
  Step4Text:
    "Suas vendas por afiliados bem sucedidas vão ser exibidas nesse painel. Você pode transferir após 30 dias da venda.",
}

const Buttons = {
  EditPostButton: {
    edit: "Editar",
    delete: "Deletar",
  },
  GroupButton: {
    edit: "Editar",
    config: "Configurações",
    disableSales: "Encerrar vendas",
    hideProfile: "Esconder do perfil",
    showProfile: "Mostrar no perfil",
    deleteGroup: "Deletar",
    publishGroup: "Publicar",
    continueCreation: "Continuar criação",
    enableSales: "Reativar vendas",
    cancelSubscription: "Cancelar assinatura",
    extraInvoice: "Segunda via",
    cancelMembership: "Cancelar compra",
    payInvoice: "Pagar fatura",
    whitelist: "Membro gratuito",
    exportMembers: "Exportar membros",
    exportMembersTip: "Você precisa selecionar um produto para poder exportar os membros",
    reload: "Recarregar",
  },
}

const intervals = {
  mensal: "mensal",
  trimestral: "trimestral",
  semestral: "semestral",
  anual: "anual",
  mês: "mês",
  trimestre: "trimestre",
  semestre: "semestre",
  ano: "ano",
}

const payment = {
  Waiting: "Aguardando pagamento",
}

const join = {
  Error: "Houve um erro. Por favor atualize a página.",
  NoNumber: "não identificamos seu número, clique aqui para falar com o suporte",
  Join: "entrar no grupo",
  JoinBasic: "entrar",
  draftGroup: "Este grupo ainda está em rascunho.",
  FinishedSales: "vendas encerradas",
  Unavailable: "temporariamente indisponível",
  Full: "grupo cheio",
  Waiting: "Aguardando",
  Pending: "Pendente",
  Config: "configuração",
  Payment: "pagamento",
  Renew: "assinar",
  Buy: "comprar",
  UpTo: "em até",
  From: "a partir de",
  Or: "ou",
  Month: "mês",
  modalTitle: "Lembre de pagar seu boleto",
  modalSubtitle: "Caso o boleto não seja pago hoje, ele não irá compensar amanhã e você será removido do grupo.",
  WelcomeMessageTitle: "Esse produto te dá acesso a vários grupos",
}

const phone = {
  phoneHint: "Insira seu número",
  phoneConfirm: "Confirme seu número",
  phoneLabel: "Número do WhatsApp ou Telegram",
  phonePopupText:
    "O número de telefone registrado na plataforma deve ser o mesmo que você utiliza em seu WhatsApp ou Telegram, isso se dá necessário pela nossa verificação dentro dos grupos e canais.",
  accountHeader: "Junte-se a milhares de criadores transformando seus produtos digitais em negócios sólidos.",
  modalHeader: "Precisamos do seu número para continuar",
  noAccountHeader: "Verificar telefone",
  accountSubHeader: "",
  noAccountSubHeader: "Precisamos do seu telefone para\nliberar o seu acesso no grupo.",
  noAccountButtonHeader: "Enviaremos uma mensagem para confirmar seu número.",
  preTextReCaptcha: "Este site é protegido por reCAPTCHA e se aplicam as ",
  middleTextReCaptcha: " e ",
  postTextReCaptcha: " do Google.",
  privacyPolicyReCaptcha: "Políticas de privacidade",
  termsOfServiceReCaptcha: "Termos de serviço",
  termsOfUsePreText: "Ao iniciar a sessão, você aceita e concorda com nossos ",
  termsOfUse: "Termos de Uso.",
  codeHeader: "Insira o código enviado para o número",
  code: "Código",
  timer: "Aguarde {{time}} segundos para reenviar o código",
  resendCode: "Reenviar código",
  continue: "continuar",
  continueWithEmail: "entrar por email",
  continueWithPhone: "entrar por telefone",
  done: "pronto",
  enterWpp: "Insira seu número para entrar",
  numberHint: "Seu número te permite entrar como membro ou admin do seu grupo.",
  receiveWpp: "Receber código por WhatsApp",
  receiveSms: "Receber código por SMS",
  typeCode: "Digite o código",
  codeSentTo: "Enviamos um código para",
  changeNumber: "Alterar número",
  phoneFail: "Não conseguiu verificar por telefone?",
  loginWithEmail: "Entrar por email",
}

const email = {
  emailHint: "Insira seu email",
  emailLabel: "Email",
  emailPopupText:
    "O número de telefone registrado na plataforma deve ser o mesmo que você utiliza em seu WhatsApp ou Telegram, isso se dá necessário pela nossa verificação dentro dos grupos e canais.",
  passwordHint: "Insira sua senha",
  passwordLabel: "Senha",
  passwordLabelChange: "Digite Aqui Sua Nova Senha",
  passwordHeader: "Digite sua senha para continuar",
  passwordHeaderChange: "Defina uma senha",
  passwordCreate: "Ainda não tenho uma senha",
  passwordForgot: "Esqueci minha senha",
  passwordEmail: "Verifique seu email",
  passwordEmailSubtitle1: "Enviamos um email para",
  passwordEmailSubtitle2: "com um código de verificação para você definir sua senha.",
  passwordEmailHint1: "Não recebeu o email? Cheque o spam ou",
  passwordEmailHint2: "tente novamente.",
  code: "Código de Verificação",
  continue: "continuar",
  done: "pronto",
  suggestion: "Você quis dizer:",
  passwordError: "(Senha não cumpre os requisitos)",
  invalidPasswordError: "(Senha incorreta)",
  back: "Voltar para",
  change: "Alterar",
  passwordErrorHint:
    "Sua senha deve conter ao menos 6 caracteres, sendo eles um caractere maiúsculo e outro minúsculo.",
  invalidEmail: "O endereço de Email é inválido.",
  startSession: "Junte-se a milhares de criadores transformando seus produtos digitais em negócios sólidos.",
}

const coupon = {
  name: "Cupons",
  table: {
    status: "Status",
    code: "Código",
    discount: "Desconto",
    plans: "Plano",
    products: "Produtos",
    used: "Utilizados",
    expiration: "Expira",
    recurrent: "Recorrente",
  },
  status: {
    active: "Ativo",
    inactive: "Inativo",
    outOf: "Esgotado",
    expired: "Expirado",
  },
  delete: {
    title: "Apagar o cupom?",
    warning: "Essa ação é irreversível. Não será possível recuperar o cupom.",
  },
  buttons: {
    create: "Novo Cupom",
    share: "Compartilhar",
    delete: "Excluir",
    cancel: "Cancelar",
  },
  input: {
    placeholder: "Digite o seu cupom",
  },
  header: {
    add: "Adicionar cupom de desconto",
    create: "Criar novo cupom de desconto",
  },
  error: {
    add: "Não foi possível adicionar o seu cupom de desconto. Tente novamente.",
    migratedUser: "Tivemos um problema, mas nosso suporte pode te ajudar!",
  },
  link: {
    question: "Você tem um cupom?",
    add: "Adicionar cupom.",
    remove: "Remover cupom",
  },
  share: {
    title: "Compartilhe o link do cupom",
    subtitle: "Ao compartilhar o link, o cupom será adicionado automaticamente no checkout.",
    copied: "Link copiado com sucesso!",
  },
  success: "Cupom adicionado com sucesso",
  allGroups: "Todos os grupos",
}

const team = {
  title: "Nosso time",
  subtitle: "Conheça quem está por trás da Hubla",
  angeltitle: "Investidor Anjo",
  angelsubtitle: "Investidor e empreendedor com sólidos resultados",
}

const subscriptionPlanInterval = {
  title: "Alterar plano",
  creditCard: {
    title: "Cartões salvos",
  },
  couponCode: {
    title: "Cupom de desconto",
  },
  error: {
    subscriptionNotFound: "Identificador da assinatura não foi informado",
  },
  button: {
    apply: "Aplicar",
    remove: "Remover",
    submit: "atualizar assinatura",
    addNewCreditCard: "Adicionar novo cartão",
  },
}

const subscriptionPaymentMethod = {
  title: "Alterar método de pagamento",
  subtitle:
    "A atualização de seus dados de pagamento ou da forma de pagamento não ativa, faz upgrade ou estende sua assinatura.",
  linkText1: "Trocar",
  linkText2: "Adicionar",
  linkText3: "cartão de crédito",
  info1:
    "Quando você atualiza seu método de pagamento para boleto bancário, o mesmo não é emitido até a data do próximo pagamento de sua assinatura.",
  info2:
    "Caso seu pagamento seja hoje, uma fatura sera emitida e você pode acessar seu boleto na página anterior (Meus produtos > Assinaturas > Detalhe)",
  pixinfo1:
    "Quando você atualiza seu método de pagamento para pix, o mesmo não é emitido até a data do próximo pagamento de sua assinatura.",
  pixinfo2:
    "Caso seu pagamento seja hoje, uma fatura sera emitida e você pode acessar seu pix na página anterior (Meus produtos > Assinaturas > Detalhe)",
  button: "atualizar",
}

const exportRequest = {
  report: "Relatório",
  affiliates: "de Afiliados",
  invoices: "de Faturas",
  subscriptions: "de Assinaturas",
  members: "de Membros",
  refunds: "de Reembolsos",
  complete: "Baixar",
  error: "Erro",
  pending: "Processando...",
  status: "Situação",
  type: "Tipo",
  createdAt: "Data de Criação",
}

const maintenance = {
  title: "Estamos em manutenção.",
  subtitle: "Estamos trabalhando duro para voltar com o serviço o mais rápido possível!",
  button: "voltar",
}

const groupsOrder = {
  title: "Ordem dos grupos",
  subtitle: "Segure e arraste os grupos na ordem que deseja que eles apareçam em seu perfil.",
  error: "Erro ao buscar grupos. Por favor recarregue a página.",
  noGroups: "Você ainda não criou nenhum grupo.",
}

const missingPage = {
  title: "Perdemos essa página.",
  subtitle:
    "Pesquisamos por todo lugar, mas não encontramos o que você estava procurando. Vamos te levar para uma página mais interessante.",
  button: "ir para página principal",
}

const userProfile = {
  createdGroups: "grupos criados",
}

const myGroupSubscription = {
  details: "Detalhes",
  resolve: "Resolver pendência",
  buyAgain: "Comprar Novamente",
  suspended: "Assinatura suspensa.",
  canceled: "Assinatura cancelada.",
  subscription: "Assinatura",
  day: "dia",
  days: "dia",
  expired: "Assinatura expirada.",
  nextBilling: "Sua próxima cobrança será em",
  nextBillingAlternative: "Sua próxima cobrança será no dia",
  onPrice: ", no valor de",
  helpPopup1: "Em caso de cartão de crédito, a renovação é feita automaticamente.",
  error: "Erro ao carregar status de assinatura",
  daysLeft: "dias restantes",
  dayLeft: "dia restante",
  pendingPayment: "Pagamento pendente",
  payInvoice: "Pagar fatura",
  renewSubscription: "Assinar novamente",
  expiredAt: "Sua assinatura expirou",
  bankSlipText1: "O boleto para renovação ficará disponível",
  bankSlip5Days: "5 dias",
  bankSlipText2: "antes do vencimento de sua assinatura dia",
  pixText1: "O pix para renovação ficará disponível",
  pix5Days: "5 dias",
  pixText2: "antes do vencimento de sua assinatura dia",
  creditCardText1: "Sua próxima cobrança será realizada automaticamente no dia",
  creditCardText2: "no valor de",
  paymentFailed: "Falha no pagamento",
  paymentFailedText1: "Tentativa falha de cobrar seu cartão.",
  paymentFailedText2: "Assine novamente",
  paymentFailedText3: "para restabelecer seu acesso.",
  modal: {
    credit_card: {
      header: "Pagar com cartão",
      buttonAction: "Pagar",
    },
    bankslipPending: {
      header: "Já emitimos um boleto para você",
      text: "Código",
    },
    bankslip: {
      header: "Pagar com boleto",
      text: "O boleto é emitido todo mês 5 dias antes do vencimento de sua  assinatura. Somente nesse período você conseguirá realizar o pagamento da renovação.",
      buttonAction: "Atualizar",
    },
    pix: {
      header: "Pagar com PIX",
      text: "A chave do PIX é emitida todo mês 5 dias antes do vencimento de sua assinatura. Somente nesse período você conseguirá realizar o pagamento da renovação.",
      buttonAction: "Atualizar",
    },
    changeMethod: {
      text: "Mudar método de pagamento?",
      credit_card: " (Pagar com cartão)",
      bankslip: " (Pagar com boleto)",
      pix: " (Pagar com PIX)",
    },
  },
}

const withdraw = {
  accepted: "Transferência realizada",
  pending: "Transferência pendente",
  rejected: "Transferência recusada",
  title: "Solicitar transferência",
  subtitle: "Digite o valor que deseja transferir",
  available: "Saldo Disponível:",
  value: "Valor",
  tax: "Taxa",
  text1: "A velocidade da transferência varia e pode levar até 3 dias úteis. Uma taxa de ",
  text2: " é cobrada pela tranferência.",
  withdraw: "Transferir",
  error: {
    title: "Ocorreu um problema.",
    subtitle: "Por favor entre em contato com o suporte",
  },
  success: {
    title: "Sucesso! Sua transferência está em andamento.",
    subtitle: "A velocidade da transferência varia e pode levar até 3 dias úteis.",
  },
}

const settings = {
  title: "Ainda falta algo",
  subtitle: "Por favor, complete suas informações para continuar usando nosso sistema",
  firstName: "Nome",
  lastName: "Sobrenome",
  bio: "Bio",
  email: {
    title: "Confirme seu E-mail",
    error: "Email não coincide",
  },
}

const pictureEdit = {
  edit: "Trocar",
  add: "Adicionar foto de perfil",
  remove: "Remover",
}

const countryHeader = "Escolha o seu país"

const GetAffiliateContainer = {
  error: "Erro ao carregar seu link de afiliado.",
  tryagain: "Tente novamente ou entre em contato com o suporte.",
  perSales: "por vendas realizadas",
  perRenewedSubscription: "por assinatura renovada",
  shareLink: "Compartilhe seu link para afiliar a esse grupo",
  upTo: "até",
  loadingLink: "Carregando seu link de afiliado",
  accountVerification: {
    title: "Adicione uma conta bancária",
    subtitle: "Para se afiliar ao grupo, precisamos de sua conta bancária para te pagar",
  },
}

const AffiliateTransaction = {
  transactionReceipt: "fatura",
  buyDate: "Data da Compra",
  paymentDate: "Data do Pagamento",
  noInfo: "Sem informações",
  status: "Status",
  group: "Grupo",
  plan: "Plano",
  paymentMethod: "Método de Pagamento",
  price: "Preço",
  commission: "Comissão",
  coupon: "Cupom",
  totalCommission: "Total da Comissão",
  transactionReleased: "Pagamento Liberado",
  releasedAt: "Liberado em",
}

const Common = {
  Active: "Ativo",
  Draft: "Rascunho",
  Disabled: "Inativo",
  ViewPage: "Visualizar área de membros",
  at: "ás",
  created: "criado",
  day: "dia",
  upTo: "até",
  walletHeader: "carteira",
}

const TextArea = {
  atLeast: `Você precisa escrever pelo menos {{minLength}} caracteres`,
  charactersLeft: `{{length}} de {{maxLength}} caracteres sobrando`,
  writeMore: `Escreva mais {{length}} caracteres`,
}

const Payments = {
  m1: "A renovação será feita de forma automática pelo método de pagamento selecionado. Você pode cancelar a assinatura a qualquer momento.",
  safe: "100% Seguro",
  onetime: "à vista",
  m3: "o valor do plano será cobrado após o primeiro",
  m4: "Pagamento Único",
  x1: "1x Vaga no Grupo",
  then: "e depois",
}

const UserInfo = {
  name: "Nome",
  lastname: "Sobrenome",
  email: "Email",
}

const Plans = {
  monthly: "Mensal",
  mensal: "Mensal",
  Mensal: "Mensal",
  quarterly: "Trimestral",
  trimestral: "Trimestral",
  Trimestral: "Trimestral",
  semiannually: "Semestral",
  semestral: "Semestral",
  Semestral: "Semestral",
  annually: "Anual",
  anual: "Anual",
  Anual: "Anual",
  single: "Pagamento único",
  all: "Todos os planos",
}

const InputPlan = {
  errors: {
    lower: "Preço precisa ser menor que ",
    higher: "Preço precisa ser maior que ",
  },
  onetime: "Pagamento único",
  subscription: "Assinatura",
  installments: "Parcelamento",
  price: "Preço",
  monthly: "Mensal",
  quarterly: "Trimestral",
  semiannually: "Semestral",
  annually: "Anual",
  plan: "Plano",
  totalPrice: "Total",
  accessMonths: {
    "0": "Indeterminado",
    "1": "1 mês",
    "3": "3 meses",
    "6": "6 meses",
    "9": "9 meses",
    "12": "1 ano",
    "24": "2 anos",
    "36": "3 anos",
  },
  chooseAccessTime: "Tempo de acesso ao produto",
}

const InstallmentOptions = {
  upTo: "em até ",
  disabled: "Desabilitado",
}

const InputFiles = {
  files: "Arquivos",
  add: "Adicionar",
}

const InputDate = {
  months: {
    January: "Janeiro",
    February: "Fevereiro",
    March: "Março",
    April: "Abril",
    May: "Maio",
    June: "Junho",
    July: "Julho",
    August: "Agosto",
    September: "Setembro",
    October: "Outubro",
    November: "Novembro",
    December: "Dezembro",
  },
  weekDays: {
    Sunday: "Domingo",
    shortSunday: "D",
    Monday: "Segunda",
    shortMonday: "S",
    Tuesday: "Terça",
    shortTuesday: "T",
    Wednesday: "Quarta",
    shortWednesday: "Q",
    Thursday: "Quinta",
    shortThursday: "Q",
    Friday: "Sexta",
    shortFriday: "S",
    Saturday: "Sábado",
    shortSaturday: "S",
  },
  nextMonth: "Próximo Mês",
  previousMonth: "Mês Anterior",
  openMonthSelector: "Abrir Seletor de Mês",
  openYearSelector: "Abrir Seletor de Ano",
  closeMonthSelector: "Fechar Seletor de Mês",
  closeYearSelector: "Fechar Seletor de Ano",
  defaultPlaceholder: "Selecionar...",
  filterDate: "Filtrar por data",
  all: "Tudo",
  today: "Hoje",
  lastWeek: "Últimos 7 dias",
  lastMonth: "Últimos 30 dias",
  last15Days: "Últimos 15 dias",
  yesterday: "Ontem",
  thisMonth: "Esse mês",
}

const UserForm = {
  profile: {
    title: "Perfil",
    subtitle:
      "Seu perfil é uma parte importante do seu negócio. Coloque um nome, descrição e uma foto para passar mais credibilidade.",
    picture: "Foto",
    firstName: "Nome",
    lastName: "Sobrenome",
    bio: "Escreva a bio do seu perfil",
    site: "Crie o link de acesso para sua página",
  },
  credentials: {
    title: "Informações Pessoais",
    subtitle: "Edite suas informações pessoais na plataforma. Elas são únicas para cada usuário.",
  },
  locale: {
    title: "País",
    subtitle: "O país selecionado irá determinar qual á moeda do seu grupo.",
    m1: "Você pode escolher seu país só uma vez.",
  },
  integrations: {
    eNotas: {
      title: "eNotas: notas fiscais automáticas",
      subtitle1:
        "Ao integrar com o eNotas, serão emitidas notas fiscais automáticas para todos os clientes que comprarem os grupos selecionados ao lado.",
      subtitle2: "Crie uma conta no eNotas, adquira sua API Key e insira ela aqui.",
      tutorial: "Tutorial",
      apiKeyLabel: "API Key do eNotas",
      integrateButton: "Integrar",
      groupsLabel: "Grupos para emitir automaticamente",
      dropDownAllLabel: "Todos",
      removePopUpTitle: "Remover integração com eNotas",
      removePopUpMessage:
        "Tem certeza que deseja remover sua integração com o eNotas? Os seus grupos não emitirão mais notas fiscais automáticas.",
    },
    zapier: {
      title: "API de Integrações com Zapier (Beta)",
      description:
        "Você pode integrar Hubla com suas ferramentas existentes como ActiveCampaign, Hubspot, MailChimp e mais usando Zapier.",
      documentation: "Documentação",
      apiKeyTitle: "Zapier API Key",
      buttonRefreshAPIToken: "Atualizar token da API",
      buttonRequestAPIToken: "Solicitar token da API",
      buttonDeleteAPIToken: "Deletar token",
      buttonCopyAPIToken: "Copiar token",
      actionPopup: {
        tokenCopied: "Token copiado!",
      },
    },
    webhookEvents: {
      title: "Webhooks da Hubla",
      description:
        "Você pode receber webhooks da Hubla contendo informações de eventos específicos (compras, assinaturas, cancelamentos e etc.)",
      documentation: "Documentação",
      apiKeyTitle: "Hubla API Key",
      buttonRefreshAPIToken: "Atualizar token da API",
      buttonConfigAPIToken: "Configurar",
      buttonDeleteAPIToken: "Deletar token",
      buttonCopyAPIToken: "Copiar token",
      actionPopup: {
        tokenCopied: "Token copiado!",
      },
    },
    userApi: {
      title: "Chave de Autorização",
      description: "Você pode gerar uma chave única de autorização para efetuar consultas em nossa API.",
      documentation: "Documentação",
      labelTitle: "Bearer Token",
      buttonRefreshToken: "Atualizar token",
      buttonRequestToken: "Gerar token",
      buttonDeleteToken: "Deletar token",
      buttonCopyToken: "Copiar token",
      actionPopup: {
        tokenCopied: "Token copiado!",
      },
    },
    publicApi: {
      title: "API de Integrações (Beta)",
      description: "Você pode integrar Hubla com suas ferramentas existentes.",
      documentation: "Documentação",
      apiKeyTitle: "Public API Key",
      buttonRefreshAPIToken: "Atualizar token da API",
      buttonRequestAPIToken: "Solicitar token da API",
      buttonDeleteAPIToken: "Deletar token",
      buttonCopyAPIToken: "Copiar token",
      actionPopup: {
        tokenCopied: "Token copiado!",
      },
    },
    loginPreferences: {
      navigationTarget: {
        title: "Escolha como acessar",
        subtitle: "Para fazer a gestão dos produtos que você vende ou acessar um produto que comprou",
        userType: {
          creator: "Criador",
          buyer: "Comprador",
        },
      },
    },
  },
}

const refund = {
  avaliation: "Em Avaliação",
  accepted: "Aceito",
  rejected: "Rejeitado",
  failed: "Falha",
  processing: "Em Processamento",
}

const AV = {
  notCreated: {
    m1: "Falta pouco! ",
    m2: " para publicar seus grupos e poder receber.",
    l1: "Adicione uma conta bancária",
  },
  pending: {
    m1: "Sua conta bancária está ",
    m2: "sendo verificada. ",
    m3: "Te notificaremos por email e pelo próprio site em breve. ",
    l1: "clique aqui.",
  },
  accepted: {
    m1: "Sua conta bancária foi ",
    m2: "verificada com sucesso! ",
    m3: "Agora você pode publicar e vender seus grupos.",
  },
  rejected: {
    m1: "Não foi possível verificar sua conta bancária com os dados que você nos forneceu. Por favor,  ",
    l1: "tente novamente",
    m2: " ou entre em ",
    l2: "contato com o suporte.",
    m3: "%20Não%20consigo%20verificar%20a%20minha%20conta.%20",
  },
}

const Authentication = {
  Profile: {
    title: "Crie o seu perfil",
    subtitle: "Sua informação será usada para acessar a sua conta.",
  },
  CreateGroup: {
    title: "Crie o seu primeiro grupo",
    subtitle1: "Não se precisa acessar o WhatsApp ou Telegram para criar seu grupo. A Chatpay cria para você!",
    subtitle2: "Caso você já tenha um grupo ativo e deseja migra-lo para Chatpay, ",
    subtitleAction: "clique aqui",
    observation:
      "Obs: Não é preciso criar o grupo ou canal no app do WhatsApp ou Telegram. Assim que terminar esse fluxo, ele será automaticamente criado para você.",
    button: "Criar",
  },
  backTo: "Voltar para",
}

const InputCover = {
  pictureSize: "Tamanho recomendado: 819 x 438 pixels",
  coverSize: "Tamanho recomendado: 1440 x 1024 pixels",
}

const GroupCreation = {
  myGroups: "Meus Produtos",
  create: "Crie seu produto",
}

const ActivityDetail = {
  activity: "Atividade",
  info: "Informações",
  history: "Histórico",
  product: "Produto",
  name: "Nome",
  email: "Email",
  contact: "Contato",
  whitelist: "Membro gratuito",
  remove: "Remover",
  cancel: "Cancelar",
  linkedGroups: {
    header: "Parece que você tem grupos vinculados a este, de quais gostaria de realizar a ação?",
  },
}

const errors = {
  auth: {
    phonenumberInvalid: "Esse número não é válido. Digite outro número e tente novamente.",
    whatsappInvalid: "Esse número de WhatsApp não é válido. Digite outro número e tente novamente.",
    unknownError: "Não foi possível verificar o seu número. Recarregue a página e tente novamente.",
    disabledUser: "Houve um problema com a sua conta, entre em contato com o suporte para saber mais.",
  },
  groupCreation: {
    shortTitle: "Título precisa ter mais que 3 caracteres",
    longTitle: "Título não pode ter mais que 70 caracteres",
    required: "Campo obrigatório",
    longSubtitle: "Subtítulo não pode ter mais que 700 caracteres",
  },
}

const groupNotifications = {
  header: "Seu grupo de notificações está pronto!",
  subheader: "Clique no botão abaixo para entrar nele.",
  button: "Entrar",
}

const productDoesNotHaveGroupIMOrContentDialog = {
  title: "Crie um conteúdo ou adicione um grupo para começar a vender",
  subtitle: "É obrigatório ter pelo menos um conteúdo ou grupo atrelado ao seu produto antes de ativar as vendas.",
  buttonLabel: "Ok",
}

const OffersPane = {
  EntryFeeSwitch: {
    title: "Cobre uma taxa diferenciada de seus membros para o primeiro mês de acesso ao produto.",
    subtitle:
      "Por exemplo, se seu produto possui uma assinatura mensal de R$ 59,90/mês e uma taxa de " +
      "adesão de R$ 100,00, seus membros vão pagar somente o valor da taxa de adesão no momento " +
      "da compra, e depois de 30 dias corridos, o valor da assinatura mensal nos meses seguintes.",
  },
  SmartInstallment: {
    title: "Parcelamento inteligente",
    description:
      "Em situações em que o limite do cartão de crédito do seu cliente não é " +
      "suficiente no momento da compra, cobramos apenas o valor da primeira parcela, garantindo " +
      "que a venda não seja perdida. O restante do valor da compra será automaticamente dividido em " +
      "novas parcelas mensais, proporcionando uma experiência conveniente e flexível para o cliente.",
    banner:
      "Vendas realizadas via esta funcionalidade, serão sinalizadas com este ícone ao lado da fatura de cada parcela.",
    preferences: {
      title: "Preferências",
      description: "Determine o comportamento que mais faz sentido para a sua estratégia de venda.",
    },
    shouldActivateWhenOffSchedule: {
      true: {
        title: "Parcelamento sem dívida",
        description:
          "Se o cliente atrasar parcelas, ele não acumulará dívidas. O acesso ao produto será suspenso temporariamente, mas poderá ser retomado assim que o pagamento da alguma das parcelas atrasadas for efetuado.",
      },
      false: {
        title: "Parcelamento com dívida",
        description:
          "Se o cliente atrasar parcelas, ele acumulará dívidas. O acesso ao produto será suspenso temporariamente, e só poderá ser retomado mediante a regularizaçãode todas as parcelas atrasadas.",
      },
    },
    subscription: {
      monthly: "Assinatura mensal",
      quarterly: "Assinatura trimestral",
      semiannually: "Assinatura semestral",
      annually: "Assinatura anual",
    },
    allowOnPurchasesOf: "Permitir em compras de",
  },
  UpsellSwitch: {
    title: "Upsell de 1 clique",
    subtitle:
      "Oferte um novo produto na página de obrigado do seu produto principal, ou seja, " +
      "possibilite que seu cliente efetue uma nova compra com apenas um clique aproveitando as " +
      "informações pessoais e de pagamento fornecidas na primeira compra.",
    deleteUpsell: "Deseja excluir sua estratégia de upsell de 1 clique?",
    deleteUpsellButtonText: "Excluir upsell",
    priceStartingFrom: "A partir de {{startingPrice}}/mês",
  },
  UpsellModal: {
    ChooseProduct: {
      step: "Passo 1 de 3",
      title: "Adicionar produto",
      subtitle: "Selecione um novo produto que será ofertado como um upsell de 1 clique do seu produto principal.",
      priceStartingFrom: "A partir de {{startingPrice}}/mês",
    },
    SetupOffer: {
      step: "Passo 2 de 3",
      stepEditing: "Passo 1 de 2",
      title: "Configurar oferta",
      subtitle: "Edite ou confirme o preço que será cobrado pelo produto de acordo com sua estratégia de upsell.",
      price: "Preço",
      oneTimePayment: {
        installments: "Opções de parcelamento",
        installmentsDisabled: "Desativado",
        error: {
          shouldBeNumbersOnly: "Por favor, use apenas números",
          priceTooLow: "Preço precisa ser maior que R$ 10,00",
        },
        splitInXTimes: "em até {{times}}x",
      },
      subscription: {
        monthly: "Assinatura mensal",
        quarterly: "Assinatura trimestral",
        semiannually: "Assinatura semestral",
        annually: "Assinatura anual",
      },
      equivalentTo: "Equivalente a",
      pricePerMonth: "{{price}}/mês",
    },
    AddMedia: {
      step: "Passo 3 de 3",
      stepEditing: "Passo 2 de 2",
      title: "Personalizar upsell de 1 clique",
      subtitle:
        "Personalize a sua oferta de upsell com um vídeo. Adicionar um vídeo pode aumentar " +
        "significativamente a conversão da sua oferta, além de mostrar mais credibilidade.",
      mediaUrl: "URL do vídeo do YouTube",
      error: {
        invalidYoutubeUrl: "Por favor, forneça uma URL válida do YouTube.",
      },
      createUpsell: "Criar upsell",
      updateUpsell: "Salvar alterações",
    },
    cancelButton: "Cancelar",
    nextButton: "Próximo",
  },
  OrderBumpSwitch: {
    entryFee: "Taxa de adesão",
    title: "Order bump",
    subtitle:
      "Oferte um novo produto complementar durante o fluxo de checkout do seu cliente, ou seja, quando seu cliente está prestes a concluir a compra do produto principal.",
    deleteOrderBump: "Deseja excluir sua estratégia de order bump?",
    deleteOrderBumpButtonText: "Excluir order bump",
  },
  OrderBumpModal: {
    ChooseProduct: {
      step: "Passo 1 de 2",
      title: "Adicionar produto",
      subtitle:
        "Selecione um novo produto que será ofertado como um order bump durante o fluxo de checkout do seu produto principal.",
      warning:
        "Por enquanto a experiência de order bump está disponível apenas para produtos de pagamento único, que tenham a configuração de turmas desativada e com as vendas ativadas.",
    },
    SetupOffer: {
      step: "Passo 2 de 2",
      title: "Configurar order bump",
      subtitle: "Edite ou confirme o preço que será cobrado pelo produto de acordo com sua estratégia de order bump.",
      price: "Preço",
    },
  },
  table: {
    products: "Produtos",
    price: "Preço",
  },
  ChooseProduct: {
    noProduct: {
      altText: "Pessoa segurando megafone com olhos fechados",
      title: "Não tem nada aqui :(",
      subtitle: "Você ainda não possui produtos qualificados para configurar como upsell de 1 clique.",
    },
  },
  errors: {
    extraOfferIdIsNotAvailable:
      "O produto ofertado não está com suas vendas ativadas no momento. Ative suas vendas para regularizar a situação.",
    extraOfferWithMembershipFeeActive:
      "O produto escolhido não pode ser usado aqui por estar com a taxa de adesão ativada.",
  },
}

export default {
  ...Common,
  ...general,
  createProduct,
  productGroups,
  Receiver,
  TextArea,
  EditAbout,
  GetStarted,
  AutomaticAuthErrors,
  EditNotification,
  NotificationGroup,
  errors,
  GroupCreation,
  InputCover,
  Authentication,
  AV,
  refund,
  UserForm,
  InstallmentOptions,
  InputFiles,
  InputPlan,
  GroupForm,
  editGroup,
  Plans,
  UserInfo,
  Payments,
  AffiliateTransaction,
  GetAffiliateContainer,
  countryHeader,
  bankStatements,
  common,
  AccountBankAccount,
  AccountVerification,
  Dashboard,
  Buttons,
  userDocument,
  navBar,
  navBarMenu,
  cookies,
  helpPopup,
  wallet,
  adminDashboard,
  affiliateDashboard,
  groupDetail,
  createGroup,
  invoice,
  invoicePaymentMethod,
  Page,
  subscription,
  TelegramVerification,
  payment,
  join,
  checkout,
  phone,
  email,
  paymentMethod,
  coupon,
  couponForm,
  ...intervals,
  landingPage,
  ...pages,
  team,
  subscriptionPaymentMethod,
  subscriptionPlanInterval,
  exportRequest,
  maintenance,
  userGroups,
  userEvents,
  myGroups,
  userSubscriptions,
  userInvoices,
  newExperienceAlert,
  refundPage,
  groupsOrder,
  missingPage,
  userProfile,
  myGroupSubscription,
  withdraw,
  settings,
  pictureEdit,
  activity,
  ActivityDetail,
  InputDate,
  groupNotifications,
  WhatsAppVerification,
  purchaseDetail,
  intervalDescription,
  aboutUs,
  termsOfUse,
  paymentModals,
  migrationRequest,
  EmptyStates,
  ProductForm,
  Product,
  GroupStatusButton,
  FreeMembers,
  DashboardWallet,
  Sales,
  productDoesNotHaveGroupIMOrContentDialog,
  OffersPane,
  userPendencies,
}
