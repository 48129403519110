export enum CardType {
  /** CARD_TYPE_UNSPECIFIED - Default value, do not use. */
  CARD_TYPE_UNSPECIFIED = 0,
  /** CARD_TYPE_CREDIT - Credit Card. */
  CARD_TYPE_CREDIT = 1,
  /** CARD_TYPE_DEBIT - Debit Card. */
  CARD_TYPE_DEBIT = 2,
  /** CARD_TYPE_CHARGE - Charge Card. */
  CARD_TYPE_CHARGE = 3,
  /** CARD_TYPE_VOUCHER - Voucher Card. */
  CARD_TYPE_VOUCHER = 4,
}
