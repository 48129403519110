import * as Yup from "yup"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { CreditCardHolder } from "@hub-la/fe-tokenizer"

export const tokenValidationSchema = Yup.object().shape({
  creditCard: Yup.object().when(["paymentMethod"], {
    is: (paymentMethod) => paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD,
    then: Yup.object().shape({
      holder: Yup.string().test({
        name: "holder",
        message: "userSubscriptions.changePaymentMethodModal.creditCard.errors.holder",
        test: (value = "") => {
          return CreditCardHolder.build(value).isValid()
        },
      }),
      token: Yup.string().required("userSubscriptions.changePaymentMethodModal.creditCard.errors.token"),
    }),
  }),
})
