import { useField } from "./use-field"

export const useIsCreditCardFieldsValid = () => {
  const { isValid } = useField()

  const isNumberValid = isValid("creditCard.number")
  const isExpirationValid = isValid("creditCard.expiration")
  const isHolderValid = isValid("creditCard.holder")
  const isCvvValid = isValid("creditCard.cvv")
  const isDocumentValid = isValid("document")

  return isCvvValid && isNumberValid && isExpirationValid && isHolderValid && isDocumentValid
}
