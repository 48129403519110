import React, { createElement } from "react"
import { Checkbox } from "semantic-ui-react"

interface IProps {
  name?: string
  label?: string
  icon?: any
  value?: any
  checked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => any
}

export const InputRadio: React.FunctionComponent<IProps> = (props) => {
  const { name, label, icon, value, checked, disabled, onChange } = props

  return (
    <div style={{ display: "flex" }}>
      <Checkbox
        icon="file"
        radio={true}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(event, data) => {
          if (onChange) {
            onChange(data.radio ?? false)
          }
        }}
      />
      {createElement(icon, { style: { marginLeft: "20px", width: "1em", opacity: disabled ? 0.2 : 1 } })}
      <div style={{ marginLeft: "5px", opacity: disabled ? 0.2 : 1 }}>{label}</div>
    </div>
  )
}
