import { Button, Modal, Snackbar, Stack, Text } from "@hub-la/design-system"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { useIsMobile } from "hooks/use-is-mobile"
import { useInitChangePaymentMethod } from "modules/user-subscriptions/presentation/hooks/use-init-change-payment-method"
import { useSubmitChangePaymentMethod } from "modules/user-subscriptions/presentation/hooks/use-submit-change-payment-method"
import React from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { ErrorComponent } from "./error-component"
import { Loading } from "./loading"
import { ChoosePaymentMethods } from "./choose-payment-method"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { creditCardValidationSchema } from "./fill-credit-card.schema"
import { tokenValidationSchema } from "./credit-card-token.schema"
import { AvailableCarriers, useMultigatewayHandler } from "@hub-la/fe-tokenizer"
import { CardDetail } from "modules/user-subscriptions/domain/dtos/card-detail"

type Props = {
  open: boolean
  onClose: () => void
  subscriptionId: string
  offerId: string
}

export type Values = {
  paymentMethod: UpgradePaymentMethods
  document: string
  gateway: AvailableCarriers
  subscriptionId?: string
  sessionId?: string
  customerId?: string
  creditCard: {
    number: string
    cvv: string
    expiration: string
    type: string | undefined
    holder: string
    token: string
    installments: number
    cardDetails?: CardDetail
  }
}

export const ChangePaymentMethodModal: React.FC<Props> = ({ open, onClose, subscriptionId, offerId }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { data, isFetching, error: initError } = useInitChangePaymentMethod(subscriptionId, open)
  const { mutateAsync: changeMethod, reset, isSuccess, error: submitError, isLoading } = useSubmitChangePaymentMethod()
  const gateway = useMultigatewayHandler(offerId)

  const formik = useFormik<Values>({
    initialValues: {
      gateway,
      paymentMethod: data?.availablePaymentMethods[0] ?? UpgradePaymentMethods.PAYMENT_METHOD_CARD,
      document: "",
      creditCard: {
        number: "",
        cvv: "",
        expiration: "",
        type: undefined,
        token: "",
        holder: "",
        installments: 1,
      },
    },
    validationSchema: Yup.object().concat(
      gateway !== AvailableCarriers.YUNO ? creditCardValidationSchema : tokenValidationSchema,
    ),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = { ...values, gateway, subscriptionId }
      changeMethod(payload).then(() => {
        formik.resetForm()
        onClose()
      })
    },
  })

  if (isFetching && !data) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <Loading onClose={onClose} />
      </Modal>
    )
  }

  if (initError && !data) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <ErrorComponent onClose={onClose} error={(initError as Error).message} />
      </Modal>
    )
  }

  return (
    <>
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <Stack direction="column" gap={8}>
          {/** Header */}
          <Stack direction="column" gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text variant="h4">{t("userSubscriptions.changePaymentMethodModal.title")}</Text>
              <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Text color="outline">{t("userSubscriptions.changePaymentMethodModal.subtitle")}</Text>
          </Stack>

          {/** Form */}
          <Stack direction="column" gap={4}>
            <FormikProvider value={formik}>
              <ChoosePaymentMethods
                paymentMethods={data?.availablePaymentMethods ?? []}
                offerId={offerId}
                installments={data?.installments ?? []}
                isSmartInstallmentsInProgress={data?.isSmartInstallmentsInProgress ?? false}
              />
            </FormikProvider>
          </Stack>

          {/** Submit Button */}
          <Stack direction={["column", "row"]} gap={3}>
            <Button variant="outlined" hierarchy="secondary" onClick={onClose} fullWidth>
              {t("userSubscriptions.changePaymentMethodModal.cancel")}
            </Button>
            <Button onClick={formik.submitForm} fullWidth loading={isLoading}>
              {t("userSubscriptions.changePaymentMethodModal.save")}
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <Snackbar open={!!submitError} variant="negative" onClose={() => reset()}>
        {t("userSubscriptions.changePaymentMethodModal.errors." + (submitError as Error)?.message) ?? ""}
      </Snackbar>
      <Snackbar open={isSuccess} variant="positive" onClose={() => reset()}>
        {t("userSubscriptions.changePaymentMethodModal.success")}
      </Snackbar>
    </>
  )
}
