import { Reducer } from "redux"
import { IActions } from "./WhitelistActions"
import { IWhitelistState } from "./WhitelistState"
import { Document } from "@chatpay/common"

const InitialState: IWhitelistState = {
  whitelist: [],
  group: null,
  isLoading: false,
  error: undefined,
}

export const WhitelistReducer: Reducer<IWhitelistState, IActions> = (
  state: IWhitelistState = InitialState,
  action: IActions,
) => {
  switch (action.type) {
    case "LOAD_START":
      return {
        ...state,
        isLoading: true,
      }

    case "LOAD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        whitelist: action.whitelist,
        group: action.group,
      }

    case "REMOVE_START":
      return {
        ...state,
        isLoading: true,
      }

    case "REMOVE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        whitelist: state.whitelist.filter((item: Document.WhiteList) => item.id !== action.documentId),
      }

    case "LOAD_FAILURE" || "REMOVE_FAILURE":
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }

    default:
      return state
  }
}
