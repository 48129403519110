export const paymentModals = {
  copyCode: "Copy code",
  codeCopied: "Code copied!",
  bankSlip: {
    title: "Important",
    subtitle: "The bank slip takes up to 48 hours to compensate.",
    acceptsReceipt:
      "You will already have access to the group, but if you do not make the payment today, it will be removed tomorrow.",
    sentTo: "Bank slip sent to:",
    download: "Download Bank slip",
  },
  pix: {
    title: "Important",
    li1: "1. Open your bank's application on your cell phone.",
    li2_1: "2. Select the option to pay with",
    li2_2: "PIX Copy and Paste",
    li2_3: "or scan the QR Code.",
    li3: `3. After payment, click on the button "I already made my payment."`,
    li4: `4. Your payment can take up to 5 minutes to be accepted.`,
    expires: "Expiration:",
    paymentDone: "I already made my payment",
  },
  backToGroups: "Back to group list",
  succesPlanChange: "Your plan change request has been sent!",
}
