"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
var Status;
exports.Status = Status;

(function (Status) {
  Status["pending"] = "pending";
  Status["scheduled"] = "scheduled";
  Status["processing"] = "processing";
  Status["errored"] = "errored";
  Status["done"] = "done";
  Status["canceled"] = "canceled";
})(Status || (exports.Status = Status = {}));