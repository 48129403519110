import React from "react"
import { AxiosHttpClient, HttpClient } from "core"
import { Container } from "inversify"
import { createContext, useContext } from "react"

const container = new Container()

container.bind(HttpClient).to(AxiosHttpClient)

export default container

export const ContainerContext = createContext(container)

export const ContainerProvider = ({ children }) => (
  <ContainerContext.Provider value={container}>{children}</ContainerContext.Provider>
)

export const useContainer = () => {
  return useContext(ContainerContext)
}
