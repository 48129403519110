"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Country = exports.Countries = void 0;

var _Countries;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// export enum Country {
//   af = "AF", ax = "AX", al = "AL", dz = "DZ", as = "AS", ad = "AD", ao = "AO", ai = "AI", aq = "AQ",
//   ag = "AG", ar = "AR", am = "AM", aw = "AW", au = "AU", at = "AT", az = "AZ", bs = "BS", bh = "BH",
//   bd = "BD", bb = "BB", by = "BY", be = "BE", bz = "BZ", bj = "BJ", bm = "BM", bt = "BT", bo = "BO",
//   ba = "BA", bw = "BW", bv = "BV", br = "BR", io = "IO", bn = "BN", bg = "BG", bf = "BF", bi = "BI",
//   kh = "KH", cm = "CM", ca = "CA", cv = "CV", ky = "KY", cf = "CF", td = "TD", cl = "CL", cn = "CN",
//   cx = "CX", cc = "CC", co = "CO", km = "KM", cg = "CG", cd = "CD", ck = "CK", cr = "CR", ci = "CI",
//   hr = "HR", cu = "CU", cy = "CY", cz = "CZ", dk = "DK", dj = "DJ", dm = "DM", do = "DO", ec = "EC",
//   eg = "EG", sv = "SV", gq = "GQ", er = "ER", ee = "EE", et = "ET", fk = "FK", fo = "FO", fj = "FJ",
//   fi = "FI", fr = "FR", gf = "GF", pf = "PF", tf = "TF", ga = "GA", gm = "GM", ge = "GE", de = "DE",
//   gh = "GH", gi = "GI", gr = "GR", gl = "GL", gd = "GD", gp = "GP", gu = "GU", gt = "GT", gg = "GG",
//   gn = "GN", gw = "GW", gy = "GY", ht = "HT", hm = "HM", va = "VA", hn = "HN", hk = "HK", hu = "HU",
//   is = "IS", in = "IN", id = "ID", ir = "IR", iq = "IQ", ie = "IE", im = "IM", il = "IL", eh = "EH",
//   it = "IT", jm = "JM", jp = "JP", je = "JE", jo = "JO", kz = "KZ", ke = "KE", ki = "KI", ye = "YE",
//   kr = "KR", kp = "KP", kw = "KW", kg = "KG", la = "LA", lv = "LV", lb = "LB", ls = "LS", zm = "ZM",
//   lr = "LR", ly = "LY", li = "LI", lt = "LT", lu = "LU", mo = "MO", mk = "MK", mg = "MG", zw = "ZW",
//   mw = "MW", my = "MY", mv = "MV", ml = "ML", mt = "MT", mh = "MH", mq = "MQ", mr = "MR", wf = "WF",
//   mu = "MU", yt = "YT", mx = "MX", fm = "FM", md = "MD", mc = "MC", mn = "MN", me = "ME", vi = "VI",
//   ms = "MS", ma = "MA", mz = "MZ", mm = "MM", na = "NA", nr = "NR", np = "NP", nl = "NL", vg = "VG",
//   nc = "NC", nz = "NZ", ni = "NI", ne = "NE", ng = "NG", nu = "NU", nf = "NF", mp = "MP", vn = "VN",
//   no = "NO", om = "OM", pk = "PK", pw = "PW", ps = "PS", pa = "PA", pg = "PG", py = "PY", ve = "VE",
//   pe = "PE", ph = "PH", pn = "PN", pl = "PL", pt = "PT", pr = "PR", qa = "QA", re = "RE", vu = "VU",
//   ro = "RO", ru = "RU", rw = "RW", bl = "BL", sh = "SH", kn = "KN", lc = "LC", mf = "MF", uz = "UZ",
//   pm = "PM", vc = "VC", ws = "WS", sm = "SM", st = "ST", sa = "SA", sn = "SN", rs = "RS", uy = "UY",
//   sc = "SC", sl = "SL", sg = "SG", sk = "SK", si = "SI", sb = "SB", so = "SO", za = "ZA", um = "UM",
//   gs = "GS", es = "ES", lk = "LK", sd = "SD", ss = "SS", sr = "SR", sj = "SJ", sz = "SZ", us = "US",
//   se = "SE", ch = "CH", sx = "SX", sy = "SY", tw = "TW", tj = "TJ", tz = "TZ", th = "TH", gb = "GB",
//   tl = "TL", tg = "TG", tk = "TK", to = "TO", tt = "TT", tn = "TN", tr = "TR", tm = "TM", ae = "AE",
//   tc = "TC", tv = "TV", ug = "UG", ua = "UA"
// }
var Country;
exports.Country = Country;

(function (Country) {
  Country["ar"] = "AR";
  Country["au"] = "AU";
  Country["at"] = "AT";
  Country["be"] = "BE";
  Country["bo"] = "BO";
  Country["br"] = "BR";
  Country["bg"] = "BG";
  Country["ca"] = "CA";
  Country["cl"] = "CL";
  Country["co"] = "CO";
  Country["cy"] = "CY";
  Country["cz"] = "CZ";
  Country["ch"] = "CH";
  Country["dk"] = "DK";
  Country["de"] = "DE";
  Country["ec"] = "EC";
  Country["eg"] = "EG";
  Country["ee"] = "EE";
  Country["es"] = "ES";
  Country["fi"] = "FI";
  Country["fr"] = "FR";
  Country["gr"] = "GR";
  Country["gb"] = "GB";
  Country["hk"] = "HK";
  Country["id"] = "ID";
  Country["ie"] = "IE";
  Country["it"] = "IT";
  Country["jp"] = "JP";
  Country["lv"] = "LV";
  Country["lt"] = "LT";
  Country["lu"] = "LU";
  Country["mt"] = "MT";
  Country["mx"] = "MX";
  Country["nl"] = "NL";
  Country["nz"] = "NZ";
  Country["no"] = "NO";
  Country["py"] = "PY";
  Country["pl"] = "PL";
  Country["pt"] = "PT";
  Country["ro"] = "RO";
  Country["sg"] = "SG";
  Country["sk"] = "SK";
  Country["si"] = "SI";
  Country["se"] = "SE";
  Country["th"] = "TH";
  Country["us"] = "US";
  Country["za"] = "ZA";
})(Country || (exports.Country = Country = {}));

var Countries = (_Countries = {}, _defineProperty(_Countries, Country.au, {
  id: Country.au,
  name: "Australia"
}), _defineProperty(_Countries, Country.at, {
  id: Country.at,
  name: "Austria"
}), _defineProperty(_Countries, Country.be, {
  id: Country.be,
  name: "Belgium"
}), _defineProperty(_Countries, Country.br, {
  id: Country.br,
  name: "Brazil"
}), _defineProperty(_Countries, Country.bg, {
  id: Country.bg,
  name: "Bulgaria"
}), _defineProperty(_Countries, Country.ca, {
  id: Country.ca,
  name: "Canada"
}), _defineProperty(_Countries, Country.cy, {
  id: Country.cy,
  name: "Cyprus"
}), _defineProperty(_Countries, Country.cz, {
  id: Country.cz,
  name: "Czech Republic"
}), _defineProperty(_Countries, Country.ch, {
  id: Country.ch,
  name: "Switzerland"
}), _defineProperty(_Countries, Country.dk, {
  id: Country.dk,
  name: "Denmark"
}), _defineProperty(_Countries, Country.de, {
  id: Country.de,
  name: "Germany"
}), _defineProperty(_Countries, Country.ee, {
  id: Country.ee,
  name: "Estonia"
}), _defineProperty(_Countries, Country.es, {
  id: Country.es,
  name: "Spain"
}), _defineProperty(_Countries, Country.fi, {
  id: Country.fi,
  name: "Finland"
}), _defineProperty(_Countries, Country.fr, {
  id: Country.fr,
  name: "France"
}), _defineProperty(_Countries, Country.gr, {
  id: Country.gr,
  name: "Greece"
}), _defineProperty(_Countries, Country.gb, {
  id: Country.gb,
  name: "United Kingdom"
}), _defineProperty(_Countries, Country.hk, {
  id: Country.hk,
  name: "Hong Kong"
}), _defineProperty(_Countries, Country.ie, {
  id: Country.ie,
  name: "Ireland"
}), _defineProperty(_Countries, Country.it, {
  id: Country.it,
  name: "Italy"
}), _defineProperty(_Countries, Country.jp, {
  id: Country.jp,
  name: "Japan"
}), _defineProperty(_Countries, Country.lv, {
  id: Country.lv,
  name: "Latvia"
}), _defineProperty(_Countries, Country.lt, {
  id: Country.lt,
  name: "Lithuania"
}), _defineProperty(_Countries, Country.lu, {
  id: Country.lu,
  name: "Luxembourg"
}), _defineProperty(_Countries, Country.mt, {
  id: Country.mt,
  name: "Malta"
}), _defineProperty(_Countries, Country.nl, {
  id: Country.nl,
  name: "Netherlands"
}), _defineProperty(_Countries, Country.nz, {
  id: Country.nz,
  name: "New Zealand"
}), _defineProperty(_Countries, Country.no, {
  id: Country.no,
  name: "Norway"
}), _defineProperty(_Countries, Country.pl, {
  id: Country.pl,
  name: "Poland"
}), _defineProperty(_Countries, Country.pt, {
  id: Country.pt,
  name: "Portugal"
}), _defineProperty(_Countries, Country.ro, {
  id: Country.ro,
  name: "Romania"
}), _defineProperty(_Countries, Country.sg, {
  id: Country.sg,
  name: "Singapore"
}), _defineProperty(_Countries, Country.sk, {
  id: Country.sk,
  name: "Slovakia"
}), _defineProperty(_Countries, Country.si, {
  id: Country.si,
  name: "Slovenia"
}), _defineProperty(_Countries, Country.se, {
  id: Country.se,
  name: "Sweden"
}), _defineProperty(_Countries, Country.us, {
  id: Country.us,
  name: "United States"
}), _Countries);
exports.Countries = Countries;