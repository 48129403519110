"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routes = exports["default"] = void 0;
var GET_STARTED_LINK = "/start";
var CREATE_GROUP_LINK = "/become-a-creator";
var ACCOUNT_VERIFICATION_LINK = "/account_verification";
var TELEGRAM_VERIFICATION_LINK = "/verification-telegram";
var routes = {
  AUTH: {
    LINK: "/auth",
    PATH: "/auth"
  },
  LOGOUT: {
    LINK: "/logout",
    PATH: "/logout"
  },
  SETTINGS: {
    LINK: "/settings",
    PATH: "/settings"
  },
  DASHBOARD: {
    LINK: "/dashboard",
    PATH: "/dashboard"
  },
  EXPORT: {
    LINK: "/export",
    PATH: "/export/:exportId?"
  },
  AFFILIATE: {
    LINK: "/affiliate",
    PATH: "/affiliate"
  },
  GROUP_AFFILIATE: {
    LINK: "/group_affiliate",
    PATH: "/group_affiliate/:groupId"
  },
  USER_GROUPS: {
    LINK: "/user_groups",
    PATH: "/user_groups"
  },
  EVENT_DETAILS: {
    LINK: "/user_events",
    PATH: "/user_events/:productId"
  },
  USER_SUBSCRIPTIONS: {
    LINK: "/user_subscriptions",
    PATH: "/user_subscriptions/:subscriptionId"
  },
  USER_INVOICES: {
    LINK: "/user_invoices",
    PATH: "/user_invoices/:invoiceId"
  },
  USER_PENDENCIES: {
    LINK: "/user_pendencies",
    PATH: "/user_pendencies"
  },
  USER_DOWNLOAD_INVOICE: {
    LINK: "/user_invoices",
    PATH: "/user_invoices/:invoiceId/pdf"
  },
  CO_PRODUCTION: {
    LINK: "/co-production",
    PATH: "/co-production/:groupId/:receiverId?"
  },
  REFUND: {
    LINK: "/refund",
    PATH: "/refund"
  },
  CREATE_HUB_POST: {
    LINK: "/hub/create_post",
    PATH: "/hub/create_post/:productId/:sectionId?"
  },

  /** @deprecated: use POST_DETAIL instead */
  OLD_GROUP_POST: {
    LINK: "/group_post",
    PATH: "/group_post/:groupId/:postId"
  },
  DETAIL_HUB_POST: {
    LINK: "/hub/post_detail",
    PATH: "/hub/post_detail/:productId/:sectionId/:postId"
  },
  POST_DETAIL: {
    LINK: "/post",
    PATH: "/post/:postId"
  },
  EVENT_LIVE: {
    LINK: "/live",
    PATH: "/live/:postId"
  },
  WHITELIST: {
    LINK: "/whitelist",
    PATH: "/whitelist/:groupId"
  },
  AUTH_EMAIL: {
    LINK: "/auth/email",
    PATH: "/auth/email"
  },
  NOTIFICATION_GROUP: {
    LINK: "/notification_group",
    PATH: "/notification_group"
  },
  AUTOMATIC_AUTH: {
    LINK: "/autoauth",
    PATH: "/autoauth/:id"
  },
  // STATIC PAGES
  MISSING_PAGE: {
    LINK: "/404",
    PATH: "/404"
  },
  TERMS_OF_USE: {
    LINK: "/terms_of_use",
    PATH: "/terms_of_use"
  },
  PRIVACY_POLICY: {
    LINK: "/privacy_policy",
    PATH: "/privacy_policy"
  },
  // NEXT PAGES (REDIRECT)
  HOME: {
    LINK: "/",
    PATH: "/"
  },
  PRICING: {
    LINK: "/pricing",
    PATH: "/pricing"
  },
  ABOUT_US: {
    LINK: "/about_us",
    PATH: "/about_us"
  },
  // USER, GROUP AND CHECKOUT PAGE
  USER_PROFILE: {
    LINK: "/",
    PATH: "/:username"
  },
  USER_PROFILE_DEPRECATED: {
    LINK: "/user",
    PATH: "/user/:username"
  },
  REVIEW: {
    LINK: "/review",
    PATH: "/review/:groupId"
  },
  GROUPS_ORDER: {
    LINK: "/groups_order",
    PATH: "/groups_order"
  },
  CHECKOUT: {
    LINK: "/checkout",
    PATH: "/checkout/:id/:planId?"
  },
  COLLABORATORS: {
    LINK: "/collaborators",
    PATH: "/collaborators/:id"
  },
  CREATE_COLLABORATOR: {
    LINK: "/collaborators/create",
    PATH: "/collaborators/create"
  },
  ROLEPLAY: {
    LINK: "/roleplay",
    PATH: "/roleplay"
  },
  // ONBOARDING
  GET_STARTED: {
    LINK: GET_STARTED_LINK,
    PATH: GET_STARTED_LINK
  },
  GET_STARTED_INFO: {
    LINK: "".concat(GET_STARTED_LINK, "/info"),
    PATH: "".concat(GET_STARTED_LINK, "/info")
  },
  GET_STARTED_PROFILE: {
    LINK: "".concat(GET_STARTED_LINK, "/profile"),
    PATH: "".concat(GET_STARTED_LINK, "/profile")
  },
  GET_STARTED_CURRENCY: {
    LINK: "".concat(GET_STARTED_LINK, "/currency"),
    PATH: "".concat(GET_STARTED_LINK, "/currency")
  },
  GET_STARTED_SEGMENTATION: {
    LINK: "".concat(GET_STARTED_LINK, "/segmentation"),
    PATH: "".concat(GET_STARTED_LINK, "/segmentation")
  },
  FIRST_EXPERIENCE: {
    LINK: "/first-experience",
    PATH: "/first-experience"
  },
  // GROUP CREATE
  CREATE_GROUP: {
    LINK: CREATE_GROUP_LINK,
    PATH: CREATE_GROUP_LINK
  },
  CREATE_GROUP_INFO: {
    LINK: "/info",
    PATH: "".concat(CREATE_GROUP_LINK, "/info")
  },
  CREATE_GROUP_DESCRIPTION: {
    LINK: "/description",
    PATH: "".concat(CREATE_GROUP_LINK, "/:groupId/description")
  },
  CREATE_GROUP_ADVANTAGES: {
    LINK: "/advantages",
    PATH: "".concat(CREATE_GROUP_LINK, "/:groupId/advantages")
  },
  CREATE_GROUP_FAQ: {
    LINK: "/faq",
    PATH: "".concat(CREATE_GROUP_LINK, "/:groupId/faq")
  },
  CREATE_GROUP_ASSETS: {
    LINK: "/assets",
    PATH: "".concat(CREATE_GROUP_LINK, "/:groupId/assets")
  },
  EDIT_PRODUCT: {
    LINK: "/edit",
    PATH: "/edit/:productId"
  },
  CREATE_OFFER: {
    LINK: "/edit",
    PATH: "/edit/:productId/offers/create"
  },
  EDIT_OFFER: {
    LINK: "/edit",
    PATH: "/edit/:productId/offers/:offerId"
  },
  CREATE_TICKET: {
    LINK: "/edit",
    PATH: "/edit/:productId/tickets/create"
  },
  EDIT_TICKET: {
    LINK: "/edit",
    PATH: "/edit/:productId/tickets/:ticketId"
  },
  CREATE_COHORT: {
    LINK: "/edit",
    PATH: "/edit/:productId/cohorts/create"
  },
  EDIT_COHORT: {
    LINK: "/edit",
    PATH: "/edit/:productId/cohorts/:cohortId"
  },
  // BANK ACCOUNT SETTINGS
  ACCOUNT_VERIFICATION: {
    LINK: ACCOUNT_VERIFICATION_LINK,
    PATH: ACCOUNT_VERIFICATION_LINK
  },
  ACCOUNT_VERIFICATION_INFO: {
    LINK: "".concat(ACCOUNT_VERIFICATION_LINK, "/info"),
    PATH: "".concat(ACCOUNT_VERIFICATION_LINK, "/info")
  },
  ACCOUNT_VERIFICATION_PENDING: {
    LINK: "".concat(ACCOUNT_VERIFICATION_LINK, "/pending"),
    PATH: "".concat(ACCOUNT_VERIFICATION_LINK, "/pending")
  },
  ACCOUNT_VERIFICATION_DONE: {
    LINK: "".concat(ACCOUNT_VERIFICATION_LINK, "/done"),
    PATH: "".concat(ACCOUNT_VERIFICATION_LINK, "/done")
  },
  ACCOUNT_VERIFICATION_REJECTED: {
    LINK: "".concat(ACCOUNT_VERIFICATION_LINK, "/rejected"),
    PATH: "".concat(ACCOUNT_VERIFICATION_LINK, "/rejected")
  },
  ACCOUNT_VERIFICATION_REDIRECT: {
    LINK: "".concat(ACCOUNT_VERIFICATION_LINK, "/redirect"),
    PATH: "".concat(ACCOUNT_VERIFICATION_LINK, "/redirect")
  },
  ACCOUNT_BANK_ACCOUNT: {
    LINK: "/account_bank_account",
    PATH: "/account_bank_account"
  },
  ACCOUNT_CREATION: {
    LINK: "/create_account",
    PATH: "/create_account"
  },
  USER_CREDIT_CARD: {
    LINK: "/credit_card",
    PATH: "/credit_card/:gateway?/:id?"
  },
  // FREE MEMBERS
  FREE_MEMBERS: {
    LINK: "/free_members",
    PATH: "/free_members/:groupResourceId/:productId/:groupId"
  },
  // integrations redirect
  INTEGRATIONS_ZAPIER: {
    LINK: "/zapier-integration",
    PATH: "/zapier-integration",
    EXTERNAL_URL: "https://hubla.notion.site/API-Documentation-5d03f9c1b86b4e67b737c5b67a78a66a"
  },
  // integrations redirect
  INTEGRATIONS_WEBHOOK_EVENTS: {
    LINK: "/webhook-events-integration",
    PATH: "/webhook-events-integration",
    EXTERNAL_URL: "https://hubla.notion.site/Webhook-Events-a41d890d1ce746499563cb37ee9f60d8"
  },
  //download app
  DOWNLOAD_APP: {
    LINK: "",
    PATH: "",
    EXTERNAL_URL: "https://hub-la.app.link/profile"
  },
  // TELEGRAM VERIFICATION
  TELEGRAM_VERIFICATION: {
    LINK: TELEGRAM_VERIFICATION_LINK,
    PATH: TELEGRAM_VERIFICATION_LINK
  },
  TELEGRAM_VERIFICATION_INTRO: {
    LINK: "".concat(TELEGRAM_VERIFICATION_LINK, "/intro"),
    PATH: "".concat(TELEGRAM_VERIFICATION_LINK, "/intro")
  },
  TELEGRAM_VERIFICATION_START: {
    LINK: "".concat(TELEGRAM_VERIFICATION_LINK, "/start"),
    PATH: "".concat(TELEGRAM_VERIFICATION_LINK, "/start")
  },
  TELEGRAM_VERIFICATION_QRCODE: {
    LINK: "".concat(TELEGRAM_VERIFICATION_LINK, "/qrcode"),
    PATH: "".concat(TELEGRAM_VERIFICATION_LINK, "/qrcode")
  },
  TELEGRAM_VERIFICATION_DONE: {
    LINK: "".concat(TELEGRAM_VERIFICATION_LINK, "/done"),
    PATH: "".concat(TELEGRAM_VERIFICATION_LINK, "/done")
  },
  ACCEPT_INVITE: {
    LINK: "/invites",
    PATH: "/invites/:inviteId/accept"
  },
  UNSUBSCRIBE_NOTIFICATION: {
    LINK: "/unsubscribe",
    PATH: "/unsubscribe/:token"
  },
  SIGNUP: {
    LINK: "/signup",
    PATH: "/signup"
  },
  SIGNIN: {
    LINK: "/signin",
    PATH: "/signin"
  },
  SIGNIN_EMAIL: {
    LINK: "/signin/email",
    PATH: "/signin/email"
  },
  SIGNIN_SMS: {
    LINK: "/signin/sms",
    PATH: "/signin/sms"
  },
  SIGNIN_WHATSAPP: {
    LINK: "/signin/whatsapp",
    PATH: "/signin/whatsapp"
  },
  RECOVER_PASSWORD: {
    LINK: "/recover-password",
    PATH: "/recover-password/:token"
  },
  WELCOME_EXPERIENCE: {
    LINK: "/welcome-experience",
    PATH: "/welcome-experience"
  }
};
exports.routes = routes;
var _default = routes;
exports["default"] = _default;