import { Box, Button, Stack, Text } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Filters } from "./components/filters"
import { useGetOffers } from "../../hooks/use-get-offers"
import { InvoiceTable } from "./components/invoice-table"

const UserInvoices: React.FC = () => {
  const { data: offers = [] } = useGetOffers()
  const [filters, setFilters] = useState<{ offerIds: string[] }>({ offerIds: ["all"] })
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userInvoices.back")}
        </Button>
      </Stack>
      <Stack marginBottom={4}>
        <Text variant="h1">{t("userInvoices.title")}</Text>
      </Stack>
      <Stack marginBottom={8}>
        <Filters offers={offers} filters={filters} setFilters={setFilters} />
      </Stack>
      <InvoiceTable offers={offers} filters={filters} />
    </Box>
  )
}

export default UserInvoices
