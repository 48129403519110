import React from "react"
import { Stack, TableCell, TableRow } from "@hub-la/design-system"
import { Skeleton } from "@mui/material"

export const InvoiceTableLoading = () => {
  return (
    <TableRow>
      <TableCell density="compact" padding="none" scope="row">
        <Stack gap={2} flexDirection="row">
          <Skeleton width="15%" height={24} />
          <Skeleton width="60%" height={24} />
        </Stack>
      </TableCell>
      <TableCell density="compact" padding="none" scope="row">
        <Skeleton width="70%" height={24} />
      </TableCell>
      <TableCell density="compact" padding="none" scope="row">
        <Skeleton width="70%" height={24} />
      </TableCell>
      <TableCell density="compact" padding="none" scope="row">
        <Skeleton width="70%" height={24} />
      </TableCell>
      <TableCell density="compact" padding="none" scope="row">
        <Skeleton width="70%" height={24} />
      </TableCell>
    </TableRow>
  )
}
