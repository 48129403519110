import { Box, Grid, Stack, Text } from "@hub-la/design-system"
import { Skeleton } from "@mui/material"
import times from "lodash/times"
import React from "react"
import { Header } from "./components/header"

export const Loading: React.FC = () => {
  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Header />

      <Grid container spacing={[4, 6]}>
        {times(6).map((_, idx) => (
          <Grid item xs={6} md={3} key={idx}>
            <Stack direction="column" gap={2} textAlign="center">
              <Skeleton
                animation="wave"
                variant="rounded"
                width="100%"
                height="auto"
                sx={{ aspectRatio: "218 / 142" }}
              />
              <Text variant="body2">
                <Skeleton animation="wave" />
              </Text>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
