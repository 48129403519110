import firebase from "firebase/compat/app"
import "firebase/compat/firestore"

import { Document } from "@chatpay/common"

export type Where = {
  field: string | firebase.firestore.FieldPath
  op: firebase.firestore.WhereFilterOp
  value: any
}

export type Pagination = {
  limit: number
  start?: number
  startAfter?: firebase.firestore.Timestamp
}

export type Order = {
  by: string | firebase.firestore.FieldPath
  direction?: firebase.firestore.OrderByDirection
}

export type Filter = {
  where?: Where | Where[]
  order?: Order | Order[]
  pagination?: Pagination
}

export type Error = firebase.firestore.FirestoreError
export class Unsubscriber {
  constructor(private unsubscriber: () => void) {
    this.unsubscriber = unsubscriber
  }

  public unsubscribe = () => this.unsubscriber()
}

export default class DB<T extends Document.Document> {
  public readonly firestore: firebase.firestore.Firestore
  public readonly collectionRef: firebase.firestore.CollectionReference

  constructor(protected doc: new (data?: Document.Data<T & any>) => T) {
    this.firestore = firebase.firestore()
    this.collectionRef = this.firestore.collection(this.doc.prototype.constructor.name)
  }

  public makeId = () => this.collectionRef.doc().id

  public static makeTimestamp = (date?: Date) => firebase.firestore.Timestamp.fromDate(date ?? new Date())

  public static makeTimestampFromJSON = (data: { nanoseconds: number; seconds: number }) =>
    new firebase.firestore.Timestamp(data.seconds, data.nanoseconds)

  public static makeServerTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()

  public static makeArrayUnion = (item: any) => firebase.firestore.FieldValue.arrayUnion(item)

  public static makeArrayRemove = (item: any) => firebase.firestore.FieldValue.arrayRemove(item)

  public listen(id: string, callback: (doc: T | null, error?: Error) => void) {
    const unsubscriber = this.collectionRef.doc(id).onSnapshot(
      async (snapshot) => {
        if (!snapshot.exists) {
          callback(null, {
            code: "not-found",
            message: `id '${id}' not found`,
            name: this.doc.prototype.constructor.name,
          })
          return
        }
        const data = snapshot.data()
        if (!data) {
          callback(null, {
            code: "data-loss",
            message: `data was lost or corrupted`,
            name: this.doc.prototype.constructor.name,
          })
          return
        }

        callback(new this.doc(snapshot.data() as Document.Data<T>))
      },
      (error) => callback(null, error),
    )

    return new Unsubscriber(unsubscriber)
  }

  private getDocId(doc: T | string): string | null {
    const id = typeof doc === "string" ? doc : doc.id ?? null
    return id
  }

  public async getSubCollectionById<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    id: string,
    doc: T | string,
  ): Promise<S | null> {
    const docId = this.getDocId(doc)
    if (!docId) {
      throw Error("id not defined")
    }
    const snapshot = await this.collectionRef.doc(docId).collection(collection.prototype.constructor.name).doc(id).get()

    if (!snapshot.exists) {
      return null
    }

    return new collection(snapshot.data() as Document.Data<S>)
  }

  public async getById(id: string, include: string[] = []): Promise<T | null> {
    const snapshot = await this.collectionRef.doc(id).get()
    if (!snapshot.exists) {
      return null
    }
    let document = new this.doc(snapshot.data() as Document.Data<T>)
    for (const i in include) {
      if (!include.hasOwnProperty(i)) {
        continue
      }
      const key = include[i]
      const obj = document as any
      const included = obj[key]
      if (!included) {
        continue
      }

      const objSnapshot = await this.firestore.collection(included.constructor.name).doc(included.id).get()

      try {
        obj[key] = new (Document as any)[included.constructor.name](objSnapshot.data())
      } catch (_) {
        obj[key] = new (Document as any)[included.constructor.name].Document(objSnapshot.data())
      }
      document = new this.doc(obj)
    }

    return document
  }

  public async getSubCollection<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    documentId: string,
    filter?: Filter,
  ): Promise<S[]> {
    const ref = this.collectionRef.doc(documentId).collection(collection.prototype.constructor.name)

    const querySnapshot = await this._get(filter, ref)
    if (!querySnapshot) {
      return []
    }
    const documents: S[] = []
    querySnapshot.forEach((s) => documents.push(new collection(s.data() as Document.Data<S>)))
    return documents
  }

  public async getFirstSubCollection<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    documentId: string,
    filter?: Filter,
  ): Promise<S | null> {
    const elements = await this.getSubCollection(collection, documentId, filter)
    return elements.length ? elements[0] : null
  }

  public async getParentSubCollection<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    filter?: Filter,
  ): Promise<T[]> {
    const ref = this.firestore.collectionGroup(collection.prototype.constructor.name)

    const querySnapshot = await this._get(filter, ref)
    if (!querySnapshot) {
      return []
    }

    const refs = querySnapshot.docs.map((doc) => doc.ref.parent.parent?.get())
    const snapshots = await Promise.all(refs)

    return snapshots.filter((s) => s).map((s) => new this.doc(s!.data()))
  }

  public async getParentSubCollectionById<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    id: string,
  ): Promise<T | null> {
    const ref = this.firestore.collectionGroup(collection.prototype.constructor.name)

    const querySnapshot = await this._get(
      {
        where: { field: "id", op: "==", value: id },
      },
      ref,
    )
    if (!querySnapshot) {
      return null
    }

    const refs = querySnapshot.docs.map((doc) => doc.ref.parent.parent?.get())
    if (!refs.length) {
      return null
    }
    const snapshots = await Promise.all(refs)
    return snapshots.filter((s) => s).map((s) => new this.doc(s!.data()))[0] ?? null
  }

  public async get(filter?: Filter): Promise<T[]> {
    const querySnapshot = await this._get(filter)
    if (!querySnapshot) {
      return []
    }
    const documents: T[] = []
    querySnapshot.forEach((s) => documents.push(new this.doc(s.data() as Document.Data<T>)))
    return documents
  }

  public async getFirst(filter?: Filter): Promise<T | null> {
    const elements = await this.get(filter)
    return elements.length ? elements[0] : null
  }

  public async countSubCollection<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    documentId: string,
    filter?: Filter,
  ): Promise<number> {
    const ref = this.collectionRef.doc(documentId).collection(collection.prototype.constructor.name)

    return this._count(filter, ref)
  }

  public async countParentSubCollection<S extends Document.Document>(
    collection: new (data?: Document.Data<S>) => S,
    filter?: Filter,
  ): Promise<number> {
    const ref = this.firestore.collectionGroup(collection.prototype.constructor.name)

    return this._count(filter, ref)
  }

  public count(filter?: Filter): Promise<number> {
    return this._count(filter)
  }

  private async _get(
    filter?: Filter,
    q?: firebase.firestore.Query<firebase.firestore.DocumentData>,
  ): Promise<firebase.firestore.QuerySnapshot | null> {
    const where = filter?.where ? (!Array.isArray(filter.where) ? [filter.where] : filter.where) : null
    const order = filter?.order ? (!Array.isArray(filter.order) ? [filter.order] : filter.order) : null
    const pagination = filter?.pagination ?? null

    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = q ?? this.collectionRef

    where?.forEach((wh: Where) => {
      query = query.where(wh.field, wh.op, wh.value)
    })
    order?.forEach((o: Order) => {
      query = query.orderBy(o.by, o.direction)
    })
    if (pagination) {
      query = query.limit(pagination.limit)
      if (pagination.startAfter) {
        query = query.startAfter(pagination.startAfter)
      }
      // if(pagination.start) {
      //   query.offset(pagination.start)
      // }
    }

    const querySnapshot = await query.get()
    if (querySnapshot.empty) {
      return null
    }
    return querySnapshot
  }

  private async _count(
    filter?: Filter,
    q?: firebase.firestore.Query<firebase.firestore.DocumentData>,
  ): Promise<number> {
    return (await this._get(filter, q))?.size ?? 0
  }
}
