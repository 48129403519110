export const createProduct = {
  title: "Crie seu produto",
  button: "Criar produto",
  subtitle:
    "Preencha as informações básicas e defina qual será o tipo de cobrança do seu produto e o preço que será cobrado em minutos.",
  advancedSetting: {
    title: "Configuração avançada",
    tooltip: "Ative essa opção para criar diferentes turmas em um mesmo produto.",
    firstDescription:
      "Crie seu produto e sempre que for ativar as vendas, abriremos uma nova turma com limite de membros pré-definido de acordo com a sua estratégia.",
    lastDescription: "Membros de uma nova turma não terão acesso a grupos das turmas anteriores, apenas aos conteúdos.",
    dialog: {
      title: "Deseja criar seu produto com turmas? Essa decisão é irreversível.",
      description:
        "Ao realizar essa ação, você não poderá gerenciar seu produto sem turmas. Caso queira, será obrigado a criar um novo produto sem ativar essa opção.",
      button: {
        cancel: "Cancelar",
        enable: "Ativar",
      },
    },
  },
}
