import { routes } from "@chatpay/common"
import { Stack, Text, shape, styled, withUtility } from "@hub-la/design-system"
import ArrowRight from "@mui/icons-material/ArrowRight"
import avatar from "assets/images/placeholder-image.svg"
import { useIsMobile } from "hooks/use-is-mobile"
import { SubscriptionStatus } from "modules/user-subscriptions/domain/enums/subscription-status"
import { SubscriptionType } from "modules/user-subscriptions/domain/enums/subscription-type"
import { SubscriptionDateBuilder } from "modules/user-subscriptions/usecases/subscription-date-builder"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { textEllipsis } from "utils/textEllipsis"

/** @todo Use the image optimization from hubla-nx */
const Avatar = styled("img")`
  width: 70px;
  height: auto;
  aspect-ratio: 218 / 142;
  border-radius: 8px;
  object-fit: cover;
  margin: auto;
  border: 1px solid ${withUtility(({ palette }) => palette("surfaceVariant"))};
`

const Card = styled(Stack)`
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: ${shape("medium")};
  background-color: ${withUtility(({ palette }) => palette("surface"))};
  :hover {
    background-color: ${withUtility(({ palette }) => palette("surfaceVariant"))};
  }
`

type Props = {
  id: string
  name: string
  picture?: string
  status: SubscriptionStatus
  type: SubscriptionType
  nextDueAt?: string
  inactivatedAt?: string
  credits?: number
}

export const Subscription: React.FC<Props> = (props) => {
  const { id, name, picture, status, type, nextDueAt, credits, inactivatedAt } = props
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()

  const redirectToSubscriptionDetail = (id: string) => history.push(routes.USER_SUBSCRIPTIONS.LINK + "/" + id)

  const dateBuilder = new SubscriptionDateBuilder(
    status,
    type,
    nextDueAt,
    moment(new Date()).add(credits, "day").toISOString(),
    inactivatedAt,
  )

  return (
    <Card
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={3}
      onClick={() => redirectToSubscriptionDetail(id)}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Avatar src={picture ?? avatar} alt={name} />
        <Stack direction="column" gap={1}>
          <Text variant="body1">{isMobile ? textEllipsis(name, 20) : name}</Text>
          {type !== SubscriptionType.TYPE_ONE_TIME && (
            <Text variant="caption" color="onSurfaceVariant">
              {t(`userSubscriptions.subscription.date.${dateBuilder.getI18nKey()}`)} dia {dateBuilder.execute()}
            </Text>
          )}
        </Stack>
      </Stack>
      <ArrowRight />
    </Card>
  )
}
