import React, { useEffect, useState } from "react"
import { Button, Form, Input } from "semantic-ui-react"
import * as Fields from "components/Fields"
import { useTranslation } from "react-i18next"
import { ISetIntegrations } from "@chatpay/common/src/Interfaces/UserInfo/IFunction"
import { API } from "@chatpay/components"
import confirmPopup from "../../../Dialogs/ConfirmPopup"
import { Spacer } from "components"
type keys = "eNotasApiKey" | "trashButton"

interface IForm {
  eNotasApiKey: string | undefined
}

interface IProps {
  disabled: Partial<Record<keys, boolean>>
  value: Partial<IForm | undefined>
  onChange: (data: Partial<IForm>, valid: boolean) => any
  onError: (error: Error | undefined) => any
  onLoading: (loading: boolean) => any
  setData: (data: Partial<IForm> | undefined) => any
  valid: boolean
  loading: boolean
}

export const Integrations: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled, valid, onError, onLoading, setData } = props

  const [form, setForm] = useState<Partial<IForm>>()
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const setFormValue = (name: string, value: any) => {
    const newData: Partial<IForm> = {
      ...form,
      [name]: value,
    }
    const valid = checkValidity(newData)
    setForm(newData)
    onChange(newData, valid)
  }

  const checkValidity = (data: Partial<IForm>): boolean => {
    return disabled?.eNotasApiKey || data.eNotasApiKey !== null
  }

  const onIntegrate = async () => {
    setLoading(true)
    onLoading(true)
    onError(undefined)

    const newData: ISetIntegrations = {
      eNotas: {
        apiKey: form?.eNotasApiKey,
      },
    }

    try {
      await new API.UserInfo().setIntegrations(newData)
      setData({ eNotasApiKey: newData.eNotas?.apiKey })
    } catch (error: any) {
      onError(error)
    } finally {
      setLoading(false)
      onLoading(false)
    }
  }

  const onRemove = async () => {
    const data: ISetIntegrations = {
      eNotas: {
        apiKey: undefined,
      },
    }

    confirmPopup(
      async () => {
        setLoading(true)
        onLoading(true)

        try {
          await new API.UserInfo().setIntegrations(data)
          setData(undefined)
        } catch (error: any) {
          onError(error)
        } finally {
          setLoading(false)
          onLoading(false)
        }
      },
      t("UserForm.integrations.eNotas.removePopUpTitle"),
      t("UserForm.integrations.eNotas.removePopUpMessage"),
    )
  }

  return (
    <Form>
      <Fields.InputLabel title={t("UserForm.integrations.eNotas.apiKeyLabel")}>
        <Spacer size="small" />
        <div style={{ display: "flex", width: "100%" }}>
          <Input
            style={{ width: "100%" }}
            disabled={disabled?.eNotasApiKey || loading}
            value={form?.eNotasApiKey ?? ""}
            onChange={(_, data) => setFormValue("eNotasApiKey", data.value)}
            fluid={true}
            floated="left"
          />
          {disabled?.eNotasApiKey && (
            <Button
              icon="trash"
              size="small"
              floated="right"
              disabled={disabled?.trashButton}
              onClick={() => onRemove()}
            />
          )}
        </div>
      </Fields.InputLabel>
      {valid && !disabled?.eNotasApiKey && (
        <Button
          loading={loading}
          floated="right"
          size="small"
          color="green"
          fluid={false}
          content={t("UserForm.integrations.eNotas.integrateButton")}
          disabled={!valid || loading}
          onClick={() => onIntegrate()}
        />
      )}
    </Form>
  )
}

export { Integrations as Form }
export type { IProps, IForm }
