import styled from "styled-components"

const BaseText = styled.p`
  font-family: var(--secondary-font);
  font-weight: 400;
  font-style: normal !important;
  color: ${(props) => props.color};
  white-space: break-spaces;
  margin: 0px;
  a {
    color: #3399f7;
    cursor: pointer;
  }
`

const Massive = styled(BaseText)`
  font-size: 28px;
  letter-spacing: -0.01em;
`

const Big = styled(BaseText)`
  font-size: 20px;
  letter-spacing: -0.01em;
`

const Normal = styled(BaseText)`
  font-size: 18px;
  letter-spacing: -0.01em;
`

const Small = styled(BaseText)`
  font-size: 16px;
`

const Tiny = styled(BaseText)`
  font-size: 13px;
`

export const Text = {
  Massive,
  Big,
  Normal,
  Small,
  Tiny,
}
