export const userInvoices = {
  title: "Histórico de faturas",
  error: "Houve um erro ao trazer as faturas, por favor recarregue a página ou entre em contato com o suporte.",
  invoiceStatus: {
    draft: "Agendada",
    pending: "Em aberto",
    paid: "Paga",
    expired: "Não paga",
    refunded: "Reembolsada",
    in_protest: "Contestada",
    chargeback: "Chargeback",
    canceled: "Cancelada",
  },
  invoiceType: {
    sell: "Venda",
    renewal: "Renovação",
    upgrade: "Upgrade",
  },
  table: {
    total: "Valor",
    status: "Status",
    product: "Produto",
    type: "Tipo",
    createdAt: "Data de criação",
  },
  actions: {
    download: "Exibir fatura",
    pay: "Pagar agora",
  },
  description: {
    product: "{{productName}}",
    productInstallment: "1x parcela de {{productName}}",
    discountCents: "Cupom de desconto - {{couponCode}}",
    installmentFeeCents: "Juros de parcelamento",
    totalCents: "Valor total",
  },
  cardBrand: {
    0: "",
    1: "",
    2: "Mastercard",
    3: "Visa",
    4: "Elo",
    5: "Amex",
  },
  header: {
    date: {
      pending: "Vence",
      paid: "Pago",
      expired: "Venceu",
      refunded: "",
      in_protest: "Pago",
      chargeback: "",
      draft: "Vence",
      canceled: "",
    },
  },
  empty: "Nenhum dado encontrado",
  back: "Voltar",
  pix: {
    copyCode: "Copiar código",
    codeCopiedSuccessfully: "Código copiado com sucesso!",
  },
}
