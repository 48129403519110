import { Service } from "@chatpay/components"
import { Container, Link, Logo, Stack } from "@hub-la/design-system"
import { AccountDropdown, AccountDropdownProvider } from "@hub-la/fe-account-dropdown"
import { container } from "@hub-la/fe-container"
import { AppBar, Toolbar } from "@mui/material"
import { useIsMobile } from "hooks/use-is-mobile"
import React, { memo } from "react"
import { useHistory } from "react-router-dom"

const AppHeader: React.FC = memo(() => {
  const history = useHistory()
  const isMobile = useIsMobile()

  const currentUser = Service.Firebase.currentUser

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Container maxWidth="xl">
        <Stack direction="column" gap={4}>
          <Toolbar
            disableGutters
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 0",
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              <Link href="">
                <Logo hierarchy="primary" size={isMobile ? "small" : "medium"} />
              </Link>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              {currentUser && (
                <AccountDropdownProvider container={container} inverted>
                  <AccountDropdown
                    push={history.push}
                    id={currentUser.id}
                    firstName={currentUser.firstName ?? undefined}
                    picture={currentUser.picture ?? null}
                    username={currentUser.username}
                    bankAccount={currentUser.gateways?.carriers?.iugu?.status}
                  />
                </AccountDropdownProvider>
              )}
            </Stack>
          </Toolbar>
        </Stack>
      </Container>
    </AppBar>
  )
})

export default AppHeader
