import { FeatureFlag, FLAG } from "../Service/FeatureFlag"
import Firebase from "../Service/Firebase"

export interface IState {
  isLoading: boolean
  error?: Error | null
}

export const DefaultState = {
  isLoading: false,
}

class API extends Firebase {
  protected async getBillingUrl(): Promise<string> {
    const pattern = /\/(v[0-9]{1})\//gi
    const version = pattern.exec(window?.location.pathname)?.last().toUpperCase()

    const url = process.env[`REACT_APP_BILLING_URL_${version}`] ?? process.env.REACT_APP_BILLING_URL_V2!

    return url
  }

  public static factory<T>(this: new () => T): T {
    return new this()
  }
}

export default API
