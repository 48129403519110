"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = exports.ISubscriberStatus = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["get"] = "get";
  Name["create"] = "create";
  Name["subscribe"] = "subscribe";
  Name["subscribers"] = "subscribers";
  Name["list"] = "list";
})(Name || (exports.Name = Name = {}));

var ISubscriberStatus;
exports.ISubscriberStatus = ISubscriberStatus;

(function (ISubscriberStatus) {
  ISubscriberStatus["AllSubscribers"] = "AllSubscribers";
  ISubscriberStatus["converted"] = "converted";
  ISubscriberStatus["notConverted"] = "notConverted";
})(ISubscriberStatus || (exports.ISubscriberStatus = ISubscriberStatus = {}));