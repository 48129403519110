export const userInvoices = {
  title: "Purchase history",
  error: "There was an error bringing in invoices, please reload the page or contact support.",
  invoiceStatus: {
    draft: "Scheduled",
    pending: "Open",
    paid: "Paid",
    expired: "No payment",
    refunded: "Refunded",
    in_protest: "Disputed",
    chargeback: "Chargeback",
  },
  invoiceType: {
    sell: "Sell",
    renewal: "Renewal",
    upgrade: "Upgrade",
  },
  table: {
    total: "Total",
    status: "Status",
    product: "Product",
    type: "Description",
    paidAt: "Paid at",
  },
  actions: {
    download: "Download invoice",
  },
  empty: "No data found",
  back: "Back",
}
