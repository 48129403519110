const JoinNotificationGroupSection = {
  title: "Seu grupo de notificações foi criado 🚀",
  subtitle: "Caso não tenha sido adicionado automaticamente, clique no botão abaixo para acessá-lo.",
  action: "Entrar",
  enter: "Acessar grupo",
}

const WaitingForGroupCreationSection = {
  title: "Seu grupo será criado em alguns instantes",
  subtitle: "Te adicionaremos no grupo assim que ele for criado",
}

const CreateNotificationGroupFromImid = {
  title: "Crie seu grupo de notificações pelo WhatsApp 🚀",
  subtitle: "Insira seu número de WhatsApp para criarmos seu grupo",
  placeholder: "Insira seu número",
  action: "Criar grupo",
}

const CreateNotificationGroup = {
  title: "Crie seu grupo de notificações pelo WhatsApp 🚀",
  subtitle: "Você reberá todas as notificações importantes para melhor gerenciar seus grupos.",
  action: "Criar grupo",
  loading: "Criando o seu grupo...",
}

const SomethingHappened = {
  title: "Ops! Ocorreu algo errado",
  subtitle: "Não conseguimos pegar o link do seu grupo de notificações. Recarregue a página e tente novamente.",
  action: "Recarregar página",
}

export const NotificationGroup = {
  JoinNotificationGroupSection,
  WaitingForGroupCreationSection,
  CreateNotificationGroupFromImid,
  CreateNotificationGroup,
  SomethingHappened,
}
