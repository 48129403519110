const cpfMask = (value: string) => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
}
const cnpjMask = (value: string) => {
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
}
export const formatCpfCnpj = (value: string) => {
  let formattedValue = value.replace(/[-./]/g, "")
  if (formattedValue.length <= 11) {
    return cpfMask(formattedValue)
  } else {
    return cnpjMask(formattedValue)
  }
}
