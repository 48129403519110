import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { Value } from "firebase/remote-config"
import { API, Service } from "@chatpay/components"

export const FLAG = Service.FLAG

export const FlagsContext = createContext<{
  isLoading: boolean
  flags: Record<string, Value>
}>({
  flags: {},
  isLoading: true,
})

export const FlagsProvider = ({ children }) => {
  const [flags, setFlags] = useState<Record<string, Value>>({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    new API.FeatureFlag()
      .getAll()
      .then((configs) => {
        setFlags(configs)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  return <FlagsContext.Provider value={{ flags, isLoading }}>{children}</FlagsContext.Provider>
}

export const useFlags = () => {
  const { isLoading, flags } = useContext(FlagsContext)

  const isEnabledByFlag = useCallback(
    (flag: string, data?: unknown) => {
      return new Service.FeatureFlag(flags).isEnabled(flag, data)
    },
    [flags],
  )

  return {
    isLoading,
    isEnabledByFlag,
  }
}
