"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
var Status;
exports.Status = Status;

(function (Status) {
  Status["pending"] = "pending";
  Status["acceptedByClient"] = "accepted_by_client";
  Status["acceptedAutomatically"] = "accepted_automatically";
  Status["contestedByClient"] = "contested_by_client";
  Status["waitingResolution"] = "waiting_resolution";
  Status["lost"] = "lost";
  Status["won"] = "won";
  Status["accepted"] = "accepted";
  Status["reverted"] = "reverted";
})(Status || (exports.Status = Status = {}));