export const userPendencies = {
  title: "Pagamentos",
  back: "Voltar",
  overdue: {
    title: "Pendências ({{length}})",
    dueDate: "Venceu",
  },
  unpaid: {
    title: "Renovações via PIX ({{length}})",
    dueDate: "Vence",
  },
  empty: "Você não possui pendências no momento.",
}
