import React from "react"
import { AuthProvider, AuthRedirectProvider, SignInPage as AuthSignInPage } from "@hub-la/fe-auth"
import { Service } from "@chatpay/components"

const SignInPage = () => {
  const getCurrentUser = () => {
    return Service.Firebase.currentUser
  }

  return (
    <AuthProvider>
      <AuthRedirectProvider getCurrentUser={getCurrentUser}>
        <AuthSignInPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}

export default SignInPage
