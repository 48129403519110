import React from "react"
import { Card, Stack, Text } from "@hub-la/design-system"
import { useTranslation } from "react-i18next"
import { InvoiceAmountDetail } from "../../../../domain/dtos/invoice-amount-detail"
import { ContentRow } from "./content-row"
import { formatCurrency } from "modules/user-invoices/domain/vos/format-currency"

type Props = {
  id: string
  detail: InvoiceAmountDetail
}

export const Description: React.FC<Props> = (props) => {
  const { id, detail } = props
  const { t } = useTranslation()

  return (
    <Stack height="100%" width="100%">
      <Card hasHover={false} variant="filled" fullHeight>
        <Stack flexDirection="column" gap={6}>
          <Text variant="body2" color="outline">
            Fatura: {id}
          </Text>
          <Stack direction="column" width="100%" gap={2}>
            {detail.products.map((product, idx) => (
              <ContentRow
                key={idx}
                showDivider
                title={t("userInvoices.description.product", { productName: product.productName })}
                content={<Text variant="body1">{formatCurrency(product.priceCents / 100)}</Text>}
              />
            ))}
            {detail.discountCents ? (
              <ContentRow
                showDivider
                title={t("userInvoices.description.discountCents", { couponCode: detail.couponCode })}
                content={
                  <Text variant="body1" color="error">
                    - {formatCurrency(detail.discountCents / 100)}
                  </Text>
                }
              />
            ) : (
              <></>
            )}
            {detail.installmentFeeCents ? (
              <ContentRow
                showDivider
                title={t("userInvoices.description.installmentFeeCents")}
                content={<Text variant="body1">{formatCurrency(detail.installmentFeeCents / 100)}</Text>}
              />
            ) : (
              <></>
            )}
            {detail.totalCents ? (
              <ContentRow
                title={t("userInvoices.description.totalCents")}
                fontWeight="bold"
                content={
                  <Text variant="body1" fontWeight="bold">
                    {formatCurrency(detail.totalCents / 100)}
                  </Text>
                }
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}
