export const migrationRequest = {
  intro: {
    title: "Are you already a paid group owner?",
    subtitle:
      "If you already have a paid group and do not want to lose your audience, but still gain all the benefits that Hubla can bring to your management, here is the right place!",
    button: "Next",
  },
  form: {
    title: "Tell us your group's access link",
    subtitle:
      "We need you to tell us the link to access your WhatsApp or Telegram group, so that we can put one of our robots in it.",
    button: "Continue",
  },
  done: {
    title: "Migration in progress",
    subtitle: "Soon someone from our team will contact you to help you take the next steps.",
    button: "Close",
  },
  migrationPending: {
    title: "You already have a migration in progress",
    subtitle: "Wait for someone from our team to contact you soon to help you take the next steps.",
    button: "Close",
  },
  invalidLink: "This is not a valid WhatsApp or Telegram group link.",
}
