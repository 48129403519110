import firebase from "firebase/compat/app"
import "firebase/compat/storage"

export default class Database {
  protected storageRoot: firebase.storage.Reference

  constructor() {
    /** Storage root starts in folder called "private"  */
    this.storageRoot = firebase.storage().ref().child("private")
  }
  /**
   * @function uploadFile
   * @description This function will upload a file to firebase storage.
   * The dir to store the file and the respective callback functions can
   * be specified in the parameters.
   *
   * @example
   * uploadFile(elephants.mov, "videos/elephants", progressCallback, errorCallback, completedCallback).
   *
   * @param file represents the file to be uploaded to firebase storage.
   * @param path represents the path to store the file.
   * @param next is the callback to track the upload progress.
   * @param error is the callback to handle an error.
   * @param complete is the callback called when the upload is completed.
   */
  public uploadFile(
    file: File,
    path: string,
    error: (error: Error) => any,
    next?: (progress: number) => any,
    complete?: (url: string) => any,
  ) {
    /** Add a file to firebase */
    const uploadTask = this.storageRoot.child(path).child(file.name).put(file)

    /**
     * @function on handles all the callbacks in firebase.
     */
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        /**
         * When there is progress update, the @callback next function will be called.
         * The upload progress equal (bytes transfered / file size)
         */
        if (next) {
          next((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        }
      },
      /* Handle error */
      error,
      /**
       * On completion we will pass in the URL to the @callback complete
       */
      function () {
        // tslint:disable-next-line: no-floating-promises
        return uploadTask.snapshot.ref.getDownloadURL().then((URL) => {
          if (complete) {
            complete(String(URL))
          }
        })
      },
    )

    return uploadTask.then(async (a: firebase.storage.UploadTaskSnapshot) => {
      return await a.ref.getDownloadURL()
    }, error)
  }
}
