export const userGroups = {
  title: "Minhas compras",
  hello: "Olá, {{name}}",
  userTransactions: "Histórico de faturas",
  userSubscriptions: "Minhas assinaturas",
  error: "Erro ao buscar produtos. Por favor recarregue a página.",
  back: "Voltar",
  empty: "Você não possui acesso a nenhum produto.",
  oldExperience: {
    title: "Você está usando a nova experiência de alunos.",
    cta: "Clique aqui para usar a antiga experiência.",
  },
  newExperience: {
    title: "Há uma nova experiência de alunos disponível na plataforma!",
    cta: "Clique aqui para utilizá-la.",
  },
  overdue: {
    title: "Problema de Cobrança",
    subtitle:
      "Houve um problema ao realizar o pagamento de {{length}} assinaturas. Para evitar interrupções de acesso realize o pagamento das pendências.",
    cta: "Ver pendências ({{length}})",
  },
  unpaid: {
    title: "Pagamento de Renovação via PIX",
    subtitle:
      "Você possui {{length}} renovações de assinaturas via PIX já disponíveis para pagamento. Realize o pagamento antes da data de vencimento.",
    cta: "Ver renovações via PIX ({{length}})",
  },
  pendingInvites: {
    acceptInvite: "Aceitar convite",
    cancelInvite: "Recusar convite",
    inviteDaysTime: "Acesso gratuito por: {{days}} dias",
    inviteLifetime: "Acesso gratuito ilimitado",
    successAccept: "Convite aceito com sucesso!",
  },
}
