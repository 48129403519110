import { Grid, MenuItem, Select, Stack, styled, Text, TextField } from "@hub-la/design-system"
import { useFormikContext } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import amex from "../../../../assets/amex.png"
import diners from "../../../../assets/diners.png"
import elo from "../../../../assets/elo.png"
import hipercard from "../../../../assets/hipercard.png"
import mastercard from "../../../../assets/mastercard.png"
import visa from "../../../../assets/visa.png"
import { formatCurrency } from "modules/user-subscriptions/domain/vos/format-currency"
import { useField } from "../../../../hooks/use-field"
import { useIsCreditCardFieldsValid } from "modules/user-subscriptions/presentation/hooks/use-is-credit-card-valid"
import { CPF } from "./cpf"
import { useGetCreditCardToken } from "modules/user-subscriptions/presentation/hooks/use-get-credit-card-token"
import { Values } from "."
import { StyledTextField } from "./index.styled"
import { Installment } from "modules/user-subscriptions/domain/dtos/installment"
import { CreditCardType } from "@hub-la/fe-tokenizer"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"

const Type = styled("img", {
  shouldForwardProp: (props) => props !== "isActive",
})(({ isActive }: { isActive: boolean }) => ({
  width: 30,
  opacity: isActive ? 1 : 0.2,
}))

const CardInput = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 4px 4px 0 0;
  }
  .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error):not(:hover) .MuiOutlinedInput-notchedOutline {
    border-bottom: none;
  }
`

const ValidInput = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 0 0 0 4px;
  }
  .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error):not(:hover) .MuiOutlinedInput-notchedOutline {
    border-right: none;
  }
`

const CvvInput = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 0 0 4px 0;
  }
`

type Props = {
  offerId: string
  installments: Installment[]
}

export const FillCreditCard: React.FC<Props> = (props) => {
  const { offerId, installments } = props
  const { values, handleBlur, handleChange, setValues, setFieldValue } = useFormikContext<Values>()
  const { hasError, getErrorText } = useField()
  const { mutate: getToken } = useGetCreditCardToken(offerId)

  const { t } = useTranslation()

  const isCreditCardValid = useIsCreditCardFieldsValid()

  const creditCards = {
    amex,
    diners,
    elo,
    hipercard,
    mastercard,
    visa,
  }

  useEffect(() => {
    if (!isCreditCardValid) {
      return
    }

    getToken()
  }, [isCreditCardValid])

  return (
    <Stack>
      <Stack position="relative">
        <NumberFormat
          format="#### #### #### ####"
          placeholder={t("userSubscriptions.changePaymentMethodModal.creditCard.number")}
          data-testid={"credit-card-number"}
          onBlur={handleBlur}
          error={hasError("creditCard.number") || hasError("creditCard.token")}
          id="creditCard.number"
          type="tel"
          size="small"
          value={values.creditCard.number}
          customInput={CardInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value: string = event.target.value

            setValues({
              ...values,
              creditCard: {
                ...values.creditCard,
                number: value,
                type: CreditCardType.build(value).getValue(),
              },
            })
          }}
        />
        <Stack flexDirection="row" position="absolute" top="15px" right="5px">
          {CreditCardType.types.map(({ id, name }) => (
            <Type key={id} isActive={id === values.creditCard.type} src={creditCards[id]} alt={name} />
          ))}
        </Stack>
      </Stack>

      <Grid container marginBottom={2}>
        <Grid item xs={6}>
          <NumberFormat
            id="creditCard.expiration"
            error={hasError("creditCard.expiration")}
            onBlur={handleBlur}
            size="small"
            format="##/##"
            fullWidth
            mask={["M", "M", "Y", "Y"]}
            placeholder="MM/YY"
            onChange={handleChange}
            type="tel"
            value={values.creditCard.expiration}
            customInput={ValidInput}
          />
        </Grid>

        <Grid item xs={6}>
          <CvvInput
            fullWidth
            type="tel"
            error={hasError("creditCard.cvv")}
            size="small"
            placeholder="CVV"
            id="creditCard.cvv"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.creditCard.cvv}
          />
        </Grid>
        <Text id="txt-card-error-j1Qd" color="error" fontSize={12}>
          {t(
            getErrorText("creditCard.number") ||
              getErrorText("creditCard.token") ||
              getErrorText("creditCard.expiration") ||
              getErrorText("creditCard.cvv"),
          )}
        </Text>
      </Grid>

      <StyledTextField
        marginBottom={3}
        label={t("userSubscriptions.changePaymentMethodModal.creditCard.holder")}
        id="creditCard.holder"
        error={hasError("creditCard.holder")}
        onBlur={handleBlur}
        size="small"
        helperText={getErrorText("creditCard.holder")}
        onChange={handleChange}
        value={values.creditCard.holder}
      />

      <CPF />

      {/** Removed temporarily because payments don't allow it */}
      {/* <Select
        fullWidth
        marginTop={3}
        size="small"
        label="Opções de parcelamento"
        value={values.creditCard.installments}
        onChange={(e) => setFieldValue("creditCard.installments", parseInt(String(e.target.value), 10))}
      >
        {installments.map((installment: Installment) => {
          const shouldRenderAdvanceFee = installment.advanceFee === 0

          return (
            <MenuItem key={installment.installmentAmount} value={installment.installmentAmount}>
              {installment.installmentAmount}x de {formatCurrency(installment.installmentPrice)}{" "}
              {shouldRenderAdvanceFee ? "sem juros" : ""}
            </MenuItem>
          )
        })}
      </Select> */}
    </Stack>
  )
}
