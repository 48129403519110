import API from "./API"
import { Document, Interface } from "@chatpay/common"
import { DB, DBError } from "../Service"

class ExportRequest extends API {
  private db = new DB(Document.ExportRequest)

  private async callExport(
    type: Document.ExportTypes,
    data: Document.ExportData,
    param?: string,
  ): Promise<Document.ExportRequest> {
    const params: Interface.ExportCreate = { type, data, param }
    return new Document.ExportRequest((await this.callFunction("exportRequestCreate", params)).data)
  }

  public requestInvoicesExport = (type: Document.ExportTypes, param?: string) =>
    this.callExport(type, Document.ExportData.invoices, param)

  public requestSubscriptionsExport = (type: Document.ExportTypes, param?: string) => {
    throw new Error("NOT IMPLEMENTD")
  }

  public requestAffiliatesExport = (type: Document.ExportTypes) => this.callExport(type, Document.ExportData.affiliates)

  public requestMembersExport = (groupId: string, type: Document.ExportTypes) =>
    this.callExport(type, Document.ExportData.members, groupId)

  public requestRefundsExport = (type: Document.ExportTypes) => this.callExport(type, Document.ExportData.refunds)

  public requestWaitlistSubscribersExport = (type: Document.ExportTypes, param: string) =>
    this.callExport(type, Document.ExportData.waitlist, param)

  public listen = (id: string, callback: (doc: Document.ExportRequest | null, error?: DBError) => void) =>
    this.db.listen(id, callback)
}

export default ExportRequest
