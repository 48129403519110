"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDate = getDate;
exports.getTimestamp = getTimestamp;

var _TimestampFactory = require("./TimestampFactory");

function getDate(dateObj) {
  if (dateObj instanceof Date) {
    return dateObj;
  }

  if (typeof dateObj === "number") {
    return new Date(dateObj * 1000);
  }

  if (typeof dateObj === "string") {
    return new Date(dateObj);
  } // Timestamp


  if (dateObj !== null && dateObj !== void 0 && dateObj._seconds && typeof (dateObj === null || dateObj === void 0 ? void 0 : dateObj._nanoseconds) === "number") {
    return new Date(dateObj._seconds * 1000 + Math.floor(dateObj._nanoseconds / 1000000));
  }

  if (dateObj !== null && dateObj !== void 0 && dateObj.seconds && typeof (dateObj === null || dateObj === void 0 ? void 0 : dateObj.nanoseconds) === "number") {
    return new Date(dateObj.seconds * 1000 + Math.floor(dateObj.nanoseconds / 1000000));
  }

  return null;
}

function getTimestamp(timestampObj) {
  if (timestampObj !== null && timestampObj !== void 0 && timestampObj._seconds && typeof (timestampObj === null || timestampObj === void 0 ? void 0 : timestampObj._nanoseconds) === "number") {
    return _TimestampFactory.TimestampInject.TimestampFactory.createTimestampFromTime(timestampObj._seconds, timestampObj._nanoseconds);
  }

  if (timestampObj !== null && timestampObj !== void 0 && timestampObj.seconds && typeof (timestampObj === null || timestampObj === void 0 ? void 0 : timestampObj.nanoseconds) === "number") {
    return _TimestampFactory.TimestampInject.TimestampFactory.createTimestampFromTime(timestampObj.seconds, timestampObj.nanoseconds);
  }

  var dateObj = getDate(timestampObj);
  return dateObj ? _TimestampFactory.TimestampInject.TimestampFactory.createTimestampFromDate(dateObj) : null;
}