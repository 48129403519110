"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetParticipantsStatus = void 0;
var GetParticipantsStatus;
exports.GetParticipantsStatus = GetParticipantsStatus;

(function (GetParticipantsStatus) {
  GetParticipantsStatus["Completed"] = "Completed";
  GetParticipantsStatus["Late"] = "Late";
  GetParticipantsStatus["ErrorFetchingFile"] = "ErrorFetchingFile";
  GetParticipantsStatus["ErrorParsingFile"] = "ErrorParsingFile";
})(GetParticipantsStatus || (exports.GetParticipantsStatus = GetParticipantsStatus = {}));