const lrCodeMapped = {
  "11": {
    reason: "Transação autorizada com sucesso para cartão emitido no exterior",
  },
  "12": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "13": {
    reason: "Valor inválido",
  },
  "14": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "15": {
    reason: "Banco emissor indisponível ou inexistente",
  },
  "19": {
    reason: "Tente novamente ou tente novamente mais tarde",
  },
  "21": {
    reason: "Não foi possível cancelar a transação",
  },
  "22": {
    reason: "Valor inválido",
  },
  "23": {
    reason: "Valor inválido",
  },
  "24": {
    reason: "Valor inválido",
  },
  "25": {
    reason: "Falha na tentativa de cobrança",
  },
  "28": {
    reason: "Entre com contato com nosso suporte",
  },
  "30": {
    reason: "Falha na tentativa de cobrança",
  },
  "39": {
    reason: "Falha na tentativa de cobrança",
  },
  "41": {
    reason:
      "Não foi possível processar a transação, entre em contato com o banco emissor ou tente novamente com outro cartão",
  },
  "43": {
    reason:
      "Não foi possível processar a transação, entre em contato com o banco emissor ou tente novamente com outro cartão",
  },
  "51": {
    reason: "Falta de limite no cartão",
  },
  "52": {
    reason: "Falha na tentativa de cobrança",
  },
  "53": {
    reason: "Falha na tentativa de cobrança",
  },
  "54": {
    reason: "Falha na tentativa de cobrança",
  },
  "55": {
    reason: "Falha na tentativa de cobrança",
  },
  "56": {
    reason: "Falha na tentativa de cobrança",
  },
  "57": {
    reason: "Falha na tentativa de cobrança",
  },
  "58": {
    reason: "Falha na tentativa de cobrança",
  },
  "59": {
    reason: "Falha na tentativa de cobrança",
  },
  "60": {
    reason: "Falha na tentativa de cobrança",
  },
  "61": {
    reason: "Falha na tentativa de cobrança",
  },
  "62": {
    reason: "Falha na tentativa de cobrança",
  },
  "63": {
    reason: "Falha na tentativa de cobrança",
  },
  "64": {
    reason: "Falha na tentativa de cobrança",
  },
  "65": {
    reason: "Falha na tentativa de cobrança",
  },
  "67": {
    reason: "Falha na tentativa de cobrança",
  },
  "70": {
    reason: "Falta de limite no cartão",
  },
  "72": {
    reason: "Falha na tentativa de cobrança",
  },
  "74": {
    reason: "Falha na tentativa de cobrança",
  },
  "75": {
    reason: "Falha na tentativa de cobrança",
  },
  "76": {
    reason: "Falha na tentativa de cobrança",
  },
  "77": {
    reason: "Falha na tentativa de cobrança",
  },
  "78": {
    reason: "Desbloqueie o seu cartão para utilizá-lo a primeira vez",
  },
  "79": {
    reason: "Falha na tentativa de cobrança",
  },
  "80": {
    reason: "Falha na tentativa de cobrança",
  },
  "82": {
    reason: "Falha na tentativa de cobrança",
  },
  "83": {
    reason: "Falha na tentativa de cobrança",
  },
  "85": {
    reason: "Falha na tentativa de cobrança",
  },
  "86": {
    reason: "Falha na tentativa de cobrança",
  },
  "88": {
    reason: "Falha na tentativa de cobrança",
  },
  "89": {
    reason: "Erro na transação",
  },
  "90": {
    reason: "Transação não permitida",
  },
  "91": {
    reason: "Banco emissor temporariamente indisponível",
  },
  "92": {
    reason: "Tempo de comunicação excedido",
  },
  "93": {
    reason: "Falha na tentativa de cobrança",
  },
  "94": {
    reason: "Falha na tentativa de cobrança",
  },
  "96": {
    reason: "Falha na tentativa de cobrança",
  },
  "97": {
    reason: "Falha na tentativa de cobrança",
  },
  "98": {
    reason: "Falha na tentativa de cobrança",
  },
  "99": {
    reason: "Falha na tentativa de cobrança",
  },
  "475": {
    reason: "Falha na tentativa de cobrança",
  },
  "999": {
    reason: "Falha na tentativa de cobrança",
  },
  "01": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "02": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "03": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "04": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "05": {
    reason: "Reveja os dados informados e tente novamente",
  },
  "06": {
    reason: "Entre em contato com seu banco emissor",
  },
  "07": {
    reason: "Entre em contato com seu banco emissor",
  },
  "08": {
    reason: "Código de segurança inválido",
  },
  "09": {
    reason: "Transação cancelada parcialmente com sucesso",
  },
  A2: {
    reason: "Falha na tentativa de cobrança",
  },
  A3: {
    reason: "Falha na tentativa de cobrança",
  },
  A5: {
    reason: "Falha na tentativa de cobrança",
  },
  A7: {
    reason: "Falha na tentativa de cobrança",
  },
  AA: {
    reason: "Falha na tentativa de cobrança",
  },
  AB: {
    reason: "Cartão de débito sendo utilizado em uma transação de crédito",
  },
  AC: {
    reason: "Cartão de débito sendo utilizado em uma transação de crédito",
  },
  AE: {
    reason: "Falha na tentativa de cobrança",
  },
  AF: {
    reason: "Falha na tentativa de cobrança",
  },
  AG: {
    reason: "Falha na tentativa de cobrança",
  },
  AH: {
    reason: "Cartão de débito sendo utilizado em uma transação de crédito",
  },
  AI: {
    reason: "Falha na tentativa de cobrança",
  },
  AJ: {
    reason: "Falha na tentativa de cobrança",
  },
  AV: {
    reason: "Falha na tentativa de cobrança",
  },
  BD: {
    reason: "Falha na tentativa de cobrança",
  },
  BL: {
    reason: "Limite diário excedido",
  },
  BM: {
    reason: "Falha na tentativa de cobrança",
  },
  BN: {
    reason: "Falha na tentativa de cobrança",
  },
  BO: {
    reason: "Falha na tentativa de cobrança",
  },
  BP: {
    reason: "Falha na tentativa de cobrança",
  },
  BV: {
    reason: "Falha na tentativa de cobrança",
  },
  CF: {
    reason: "Falha na tentativa de cobrança",
  },
  CG: {
    reason: "Falha na tentativa de cobrança",
  },
  DA: {
    reason: "Falha na tentativa de cobrança",
  },
  DF: {
    reason: "Falha na tentativa de cobrança",
  },
  DM: {
    reason: "Falta de limite no cartão",
  },
  DQ: {
    reason: "Falha na tentativa de cobrança",
  },
  DS: {
    reason: "Falha na tentativa de cobrança",
  },
  EB: {
    reason: "Limite diário excedido",
  },
  EE: {
    reason: "Falha na tentativa de cobrança",
  },
  EK: {
    reason: "Falha na tentativa de cobrança",
  },
  FA: {
    reason: "Falha na tentativa de cobrança",
  },
  FC: {
    reason: "Falha na tentativa de cobrança",
  },
  FD: {
    reason: "Falha na tentativa de cobrança",
  },
  FE: {
    reason: "Falha na tentativa de cobrança",
  },
  FG: {
    reason: "Falha na tentativa de cobrança",
  },
  GA: {
    reason: "Falha na tentativa de cobrança",
  },
  GD: {
    reason: "Falha na tentativa de cobrança",
  },
  HJ: {
    reason: "Falha na tentativa de cobrança",
  },
  IA: {
    reason: "Falha na tentativa de cobrança",
  },
  JB: {
    reason: "Falha na tentativa de cobrança",
  },
  KA: {
    reason: "Falha na tentativa de cobrança",
  },
  KB: {
    reason: "Falha na tentativa de cobrança",
  },
  KE: {
    reason: "Falha na tentativa de cobrança",
  },
  N7: {
    reason: "Falha na tentativa de cobrança",
  },
  R1: {
    reason: "Falha na tentativa de cobrança",
  },
  U3: {
    reason: "Falha na tentativa de cobrança",
  },
  default: {
    reason: "Falha na tentativa de cobrança",
  },
}

export class MapLrError {
  public execute(lr: string): string | null {
    const lrCodeDescription = lrCodeMapped[lr]?.reason
    if (!lrCodeDescription) {
      return lrCodeMapped["default"]?.reason
    }
    return lrCodeDescription
  }
}
