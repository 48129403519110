import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { GetPendingInvoiceForSubscriptionOutput } from "../domain/dtos/get-pending-invoice-for-subscription-output"
import { Envs } from "../envs"

export class GetPendingInvoiceForSubscription {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(id: string): Promise<GetPendingInvoiceForSubscriptionOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/pay/get-pending-invoice-for-subscription`,
      params: { subscriptionId: id },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new Error("Erro ao buscar dados da assinatura")
  }
}
