"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = exports.Model = exports.Format = exports.Bank = void 0;

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var Model = {
  "001": {
    name: "Banco do Brasil",
    agency: "9999-D",
    account: "99999999-D"
  },
  "033": {
    name: "Santander",
    agency: "9999",
    account: "99999999-D"
  },
  "104": [{
    name: "Caixa Econômica",
    agency: "9999",
    account: "99999999-D",
    operations: {
      "001": "cc-pf",
      "003": "cc-pj",
      "013": "cp-pf",
      "022": "cp-pj"
    }
  }, {
    account: "999999999-D",
    operations: {
      "001": "cc-pf",
      "003": "cc-pj",
      "1288": "cp-pf",
      "022": "cp-pj"
    }
  }],
  "237": [{
    name: "Bradesco",
    agency: "9999-D",
    account: "9999999-D"
  }, {
    name: "Next"
  }],
  "341": [{
    name: "Itaú",
    agency: "9999",
    account: "99999-D"
  }, {
    name: "Itaú - iti",
    account: "999999999-D"
  }],
  "97": {
    name: "Cooperativa Central de Credito Noroeste Brasileiro",
    agency: "9999",
    account: "9999999-D"
  },
  "121": {
    name: "Agibank",
    agency: "9999",
    account: "9999999999"
  },
  "125": {
    name: "Banco Genial",
    agency: "9999",
    account: "9999-D"
  },
  "133": {
    name: "Cresol",
    agency: "9999",
    account: "99999999-D"
  },
  "136": {
    name: "Unicred",
    agency: "9999",
    account: "99999999-D"
  },
  "173": {
    name: "BRL Trust DTVM",
    agency: "999",
    account: "999999-D"
  },
  "197": {
    name: "Stone",
    agency: "9999",
    account: "9999999-D"
  },
  "208": {
    name: "Banco BTG Pactual",
    agency: "9999",
    account: "999999-D"
  },
  "212": {
    name: "Banco Original",
    agency: "9999",
    account: "9999999-D"
  },
  "218": {
    name: "BS2",
    agency: "9999",
    account: "9999999-D"
  },
  "260": {
    name: "Nubank",
    agency: "9999",
    account: "9999999999-D"
  },
  "273": {
    name: "CCR de São Miguel do Oeste",
    agency: "9999",
    account: "99999"
  },
  "290": {
    name: "Pagseguro",
    agency: "9999",
    account: "99999999-D"
  },
  "301": {
    name: "PJBank",
    agency: "9999",
    account: "9999999999-D"
  },
  "323": {
    name: "Mercado Pago",
    agency: "9999",
    account: "9999999999-D"
  },
  "336": {
    name: "Banco C6",
    agency: "9999",
    account: "99999999-D"
  },
  "355": {
    name: "Ótimo",
    agency: "9999",
    account: "99999-D"
  },
  "364": {
    name: "Gerencianet Pagamentos do Brasil",
    agency: "9999",
    account: "99999999-D"
  },
  "376": {
    name: "JP Morgan",
    agency: "9999",
    account: "99999999999-D"
  },
  "380": {
    name: "Picpay",
    agency: "9999",
    account: "99999999-D"
  },
  "383": {
    name: "Juno",
    agency: "9999",
    account: "9999999999-D"
  },
  "384": {
    name: "Global SCM",
    agency: "9999",
    account: "99999999999"
  },
  "389": {
    name: "Mercantil do Brasil",
    agency: "9999",
    account: "99999999-D"
  },
  "403": {
    name: "Cora",
    agency: "9999",
    account: "9999999-D"
  },
  "422": {
    name: "Safra",
    agency: "9999",
    account: "99999999-D"
  },
  "461": {
    name: "ASAAS IP",
    agency: "9999",
    account: "9999999-D"
  },
  "613": {
    name: "Banco Omni",
    agency: "9999",
    account: "999999-D"
  },
  "623": {
    name: "Banco Pan",
    agency: "9999",
    account: "999999999-D"
  },
  "633": {
    name: "Rendimento",
    agency: "9999-D",
    account: "9999999999"
  },
  "655": {
    name: "Neon/Votorantim",
    agency: "9999",
    account: "9999999999-D"
  },
  "707": {
    name: "Banco Daycoval",
    agency: "9999",
    account: "999999-D"
  },
  "741": {
    name: "Banco Ribeirão Preto",
    agency: "99999",
    account: "9999999999"
  },
  "745": {
    name: "Citibank",
    agency: "9999",
    account: "99999999"
  },
  "746": {
    name: "Modal",
    agency: "9999",
    account: "999999999-D"
  },
  "748": {
    name: "Sicredi",
    agency: "9999",
    account: "99999999D"
  },
  "752": {
    name: "BNP Paribas Brasil",
    agency: "999",
    account: "999999-DDD"
  },
  "756": {
    name: "Sicoob",
    agency: "9999",
    account: "999999999-D"
  },
  "037": {
    name: "Banpará",
    agency: "9999",
    account: "999999999-D"
  },
  "041": {
    name: "Banrisul",
    agency: "9999",
    account: "999999999-D"
  },
  "077": {
    name: "Inter",
    agency: "9999",
    account: "999999999-D"
  },
  "070": {
    name: "BRB",
    agency: "9999",
    account: "999999999-D"
  },
  "085": {
    name: "Via Credi",
    agency: "9999",
    account: "99999999999-D"
  },
  "021": {
    name: "Banestes",
    agency: "9999",
    account: "99999999-D"
  },
  "082": {
    name: "Banco Topazio",
    agency: "9999",
    account: "99999-D"
  },
  "099": {
    name: "Uniprime",
    agency: "9999",
    account: "9999-D"
  },
  "004": {
    name: "Banco do Nordeste",
    agency: "999",
    account: "999999-D"
  },
  "084": {
    name: "Uniprime Norte do Paraná",
    agency: "9999",
    account: "999999-D"
  },
  "003": {
    name: "Banco da Amazonia",
    agency: "9999",
    account: "999999-D"
  },
  "047": {
    name: "Banco Banese",
    agency: "999",
    account: "99999999-D"
  },
  "093": {
    name: "Polocred",
    agency: "9999",
    account: "999999-D"
  },
  "412-x": {
    name: "Banco Capital S.A",
    agency: "9999",
    account: "999999999"
  },
  "274": {
    name: "Money Plus",
    agency: "9",
    account: "99999999-D"
  }
};
exports.Model = Model;
var Name = Object.values(Model).map(function (m) {
  return (Array.isArray(m) ? m : [m]).map(function (m1) {
    return m1.name;
  });
}).flat().filter(function (m) {
  return m;
});
exports.Name = Name;

var getModelData = function getModelData(code, anAccount) {
  var _models$map$flat$find, _sort$find;

  var model = Model[code];

  var parseAccount = function parseAccount(str) {
    var _str$replace;

    return (_str$replace = str === null || str === void 0 ? void 0 : str.replace("-", "")) !== null && _str$replace !== void 0 ? _str$replace : "";
  };

  var account = parseAccount(anAccount);
  var models = (Array.isArray(model) ? model : [model]).filter(function (m) {
    return m.account;
  });
  var op = account ? (_models$map$flat$find = models.map(function (m) {
    var _m$account$length, _m$account;

    return m.operations && account.length > ((_m$account$length = (_m$account = m.account) === null || _m$account === void 0 ? void 0 : _m$account.length) !== null && _m$account$length !== void 0 ? _m$account$length : 0) ? Object.keys(m.operations) : "";
  }).flat().find(function (o) {
    return o && account.length > o.length && o === account.slice(0, o.length);
  })) !== null && _models$map$flat$find !== void 0 ? _models$map$flat$find : "" : "";

  if (op) {
    account = account.replace(op, "");
  }

  return account ? (_sort$find = _toConsumableArray(models).sort(function (m1, m2) {
    var a = parseAccount(m1.account).length;
    var b = parseAccount(m2.account).length;
    return a > b ? 1 : a < b ? -1 : 0;
  }).find(function (m) {
    return account.length <= parseAccount(m.account).length;
  })) !== null && _sort$find !== void 0 ? _sort$find : models.last() : undefined;
};

var Bank = function Bank(code, anAccount) {
  var bank = Model[code];
  var models = Array.isArray(bank) ? bank : [bank];
  var names = models.filter(function (m, idx) {
    return m.name && (!anAccount || idx === 0 || !m.account || models.every(function (m1) {
      var _m1$account, _m$account2;

      return ((_m1$account = m1.account) === null || _m1$account === void 0 ? void 0 : _m1$account.length) !== ((_m$account2 = m.account) === null || _m$account2 === void 0 ? void 0 : _m$account2.length);
    }));
  }).map(function (m) {
    return m.name;
  });
  var withAccount = models.filter(function (m) {
    return m.account;
  }).map(function (m) {
    var _m$name;

    return {
      name: (_m$name = m.name) === null || _m$name === void 0 ? void 0 : _m$name.replace("/", " / ")
    };
  });
  var data = anAccount ? getModelData(code, anAccount) : undefined;
  return _objectSpread(_objectSpread(_objectSpread({}, withAccount.first()), data), names.length > 1 ? {
    name: names.join(" / ")
  } : undefined);
};

exports.Bank = Bank;

var Format = function Format(code, anAccount) {
  var _getModelData, _data$agency, _data$account, _data$operations;

  var model = Model[code];

  var pattern = function pattern(str) {
    var _acc$length;

    var _str$split = str.split("-"),
        _str$split2 = _slicedToArray(_str$split, 2),
        acc = _str$split2[0],
        dgt = _str$split2[1];

    var digit = dgt ? "-[\\w]{".concat(dgt.length, "}") : (acc === null || acc === void 0 ? void 0 : acc[acc.length - 1]) === "D" ? "[\\w]{1}" : "";
    return "^\\d{".concat((_acc$length = acc === null || acc === void 0 ? void 0 : acc.length) !== null && _acc$length !== void 0 ? _acc$length : 0, "}").concat(digit, "$");
  };

  var format = function format(str) {
    return str.replace(/(9)/gi, "#");
  };

  var models = (Array.isArray(model) ? model : [model]).filter(function (m) {
    return m.account;
  });
  var data = (_getModelData = getModelData(code, anAccount)) !== null && _getModelData !== void 0 ? _getModelData : models.first();
  var agency = (_data$agency = data.agency) !== null && _data$agency !== void 0 ? _data$agency : models.first().agency;
  var account = (_data$account = data.account) !== null && _data$account !== void 0 ? _data$account : models.first().account;
  var operations = (_data$operations = data.operations) !== null && _data$operations !== void 0 ? _data$operations : models.first().operations;
  return {
    agency: {
      pattern: pattern(agency),
      format: format(agency)
    },
    account: {
      pattern: pattern(account),
      format: format(account)
    },
    operations: operations
  };
};

exports.Format = Format;