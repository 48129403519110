import PhoneInput from "react-phone-number-input"
import React from "react"
import { useTranslation } from "react-i18next"
import { InputLabel } from "components/Fields/InputLabel"
import HelpPopup from "components/Dialogs/HelpPopup"
import "react-phone-number-input/style.css"
/* import same lib from react-phone-number-input/style.css, because webpack bundle not include css files. */
import "./phone-number-input.scss"

interface IProps {
  label?: string | null
  popupText?: string | null
  disabled?: boolean
  autoFocus?: boolean
  value?: string | undefined
  onChange: (values: string) => void
  "data-testid"?: string
}

export const PhoneField: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation()

  return (
    <InputLabel title={props.label ?? " "}>
      {props.popupText ? (
        <HelpPopup text={props.popupText} style={{ position: "absolute", left: "15em", top: "0" }} />
      ) : null}
      <PhoneInput
        data-testid={props["data-testid"]}
        defaultCountry="BR"
        placeholder={t("phone.phoneHint")}
        disabled={props.disabled ?? false}
        value={props.value}
        autoFocus={props.autoFocus ?? true}
        onChange={props.onChange}
      />
    </InputLabel>
  )
}
