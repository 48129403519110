"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IWhatsAppTemplate = void 0;
var IWhatsAppTemplate;
exports.IWhatsAppTemplate = IWhatsAppTemplate;

(function (IWhatsAppTemplate) {
  IWhatsAppTemplate["IMIDVerificationReminder"] = "verification_reminder";
  IWhatsAppTemplate["groupPurchase"] = "wpp_group_purchase_with_redirect_button";
  IWhatsAppTemplate["purchase"] = "wpp_purchase";
  IWhatsAppTemplate["authCode"] = "wpp_auth_code";
  IWhatsAppTemplate["creditCardPurchase"] = "wpp_cc_purchase";
  IWhatsAppTemplate["bankSlipPurchase"] = "wpp_bs_purchase";
  IWhatsAppTemplate["bankSlipExpired"] = "wpp_bs_expired";
  IWhatsAppTemplate["refundAccepted"] = "wpp_refund_accepted";
  IWhatsAppTemplate["refundRejected"] = "wpp_refund_rejected";
  IWhatsAppTemplate["refundReminder"] = "wpp_24h_refund_reminder";
  IWhatsAppTemplate["subscriptionExpired"] = "wpp_expired_subscription";
  IWhatsAppTemplate["removedByBot"] = "wpp_removed_bot";
  IWhatsAppTemplate["nonMemberJoined"] = "wpp_non_member_joined";
  IWhatsAppTemplate["memberIdentified"] = "wpp_member_identified";
  IWhatsAppTemplate["memberJoined"] = "member_joined_update";
  IWhatsAppTemplate["memberWhitelisted"] = "wpp_member_whitelisted";
  IWhatsAppTemplate["removedFromExpiredSubscription"] = "wpp_removed_from_expired_subscription";
  IWhatsAppTemplate["removedByAdmin"] = "wpp_removed_admin";
  IWhatsAppTemplate["userIssuedBankSlip"] = "wpp_bankslip_issued";
  IWhatsAppTemplate["userPurchasedGroup"] = "admin_invoice_update";
})(IWhatsAppTemplate || (exports.IWhatsAppTemplate = IWhatsAppTemplate = {}));