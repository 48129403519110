"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["getSubscription"] = "getSubscription";
  Name["getSubscriptionsBySellerId"] = "getSubscriptionsBySellerId";
  Name["getSubscriptionsByPayerId"] = "getSubscriptionsByPayerId";
  Name["searchSubscriptions"] = "searchSubscriptions";
  Name["activateSubscription"] = "activateSubscription";
  Name["deactivateSubscription"] = "deactivateSubscription";
  Name["enableSubscriptionAutoRenew"] = "enableSubscriptionAutoRenew";
  Name["disableSubscriptionAutoRenew"] = "disableSubscriptionAutoRenew";
  Name["addSubscriptionDailyCredits"] = "addSubscriptionDailyCredits";
  Name["removeSubscriptionDailyCredits"] = "removeSubscriptionDailyCredits";
  Name["refreshSubscription"] = "refreshSubscription";
  Name["changeSubscriptionFundingSource"] = "changeSubscriptionFundingSource";
  Name["renewSubscription"] = "renewSubscription";
  Name["bulkActivateSubscriptions"] = "bulkActivateSubscriptions";
  Name["bulkDeactivateSubscriptions"] = "bulkDeactivateSubscriptions";
  Name["bulkEnableSubscriptionAutoRenew"] = "bulkEnableSubscriptionAutoRenew";
  Name["bulkDisableSubscriptionAutoRenew"] = "bulkDisableSubscriptionAutoRenew";
  Name["bulkAddSubscriptionDailyCredits"] = "bulkAddSubscriptionDailyCredits";
  Name["bulkRemoveSubscriptionDailyCredits"] = "bulkRemoveSubscriptionDailyCredits";
  Name["bulkRefreshSubscriptions"] = "bulkRefreshSubscriptions";
  Name["voidInvoice"] = "voidInvoice";
  Name["refundInvoice"] = "refundInvoice";
  Name["replaceInvoiceCharge"] = "replaceInvoiceCharge";
  Name["refreshInvoice"] = "refreshInvoice";
})(Name || (exports.Name = Name = {}));