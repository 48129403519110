export const couponForm = {
  code: {
    title: "Code",
    placeholder: "Enter coupon code",
    more: "characters remaining",
    helpPopup: "",
    error:
      "Invalid code. Rules: Between 6-12 characters. We don't accept accent. We don't accept special characters, unless (-)",
  },
  discount: {
    title: "Discount (between 0% and 80%)",
  },
  limit: {
    checkbox: "Limit the number of coupons",
    quantity: "quantity",
    helpPopup: {
      checked: "This coupon will be disabled after the determined number of uses.",
      unchecked: "An unlimited number of customers can use this coupon.",
    },
  },
  expiration: {
    checkbox: "Set an expiration date",
    placeholder: "Select a date",
    error: "Invalid expiration date",
  },
  recurrence: {
    checkbox: "Recurrent for subscriptions renewals",
    helpPopup: {
      checked: "The discount will also be applied for subscription renewal.",
      unchecked: "Subscription renewals will be charged full price.",
    },
  },
  groups: {
    checkbox: "Limit coupon to specific groups",
    placeholder: "Select groups",
    helpPopup: {
      checked: "The coupon can only be used on the purchase of one of the selected groups",
      unchecked: "The coupon can be used on any of your groups",
      plans: " on the selected plan.",
    },
  },
  plan: {
    checkbox: "Limit coupon to specific plans",
    placeholder: "Select plans",
    title: "On which plan can the coupon be applied?",
    helpPopup: {
      checked: "The coupon can only be used on the selected plan",
      unchecked: "The coupon can be used on any plan",
      groups: " of the selected groups.",
    },
    cohort: "Cohort",
  },
  errorTitle: "Failed to save coupon",
  button: {
    cancel: "Cancel",
    generate: "Generate coupon",
  },
}
