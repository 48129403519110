import { Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { ReactComponent as IconTelegramCheck } from "assets/icons/icon-telegram-check.svg"
import { Spacer } from "components"
import { CPLoader } from "components/CPLoader"
import React, { useState } from "react"
import TelegramLoginButton from "react-telegram-login"
import { Headers } from "../Headers"
import { Basic, BasicProps } from "./Basic"

interface IProps extends BasicProps {
  header: { title: string; subtitle: string }
  onFinished?: (imid: string) => any
}

export const TelegramOfficialVerification: React.FunctionComponent<IProps> = (props) => {
  const { header, onFinished, ...rest } = props
  return (
    <Basic {...rest}>
      <TelegramVerificationForm onFinished={onFinished} header={header} />
    </Basic>
  )
}

const TelegramVerificationForm: React.FunctionComponent<IProps> = ({ onFinished, header }) => {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>()

  const handleTelegramAuth = async (response) => {
    console.log(`Response: ${JSON.stringify(response)}`)
    setLoading(true)
    try {
      const user: Document.User = await new API.User().connectTelegram({
        id: `${response.id}`,
        username: response.username,
      })
      onFinished?.(user.ims?.telegram!)
    } catch (e) {
      console.error(e)
      setError(e as Error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <IconTelegramCheck />
      </div>
      {Headers.Basic(header.title, header.subtitle)}
      <Spacer />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <CPLoader />
        ) : (
          <div>
            <TelegramLoginButton dataOnauth={handleTelegramAuth} botName={process.env.REACT_APP_TELEGRAM_BOT} />
          </div>
        )}
      </div>
      <Spacer />
      {error ? <Service.ErrorMessage error={error} /> : null}
    </>
  )
}
