import { Button, Card, MenuItem, Modal, Select, Stack, Text } from "@hub-la/design-system"
import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import { useIsMobile } from "hooks/use-is-mobile"
import { useInitUpgradePlan } from "modules/user-subscriptions/presentation/hooks/use-init-upgrade-plan"
import moment from "moment"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { ContentRow } from "./content-row"
import { formatCurrency, formatCurrencyWithInstallments } from "modules/user-subscriptions/domain/vos/format-currency"
import { useFormik } from "formik"
import { UpgradeSummary } from "modules/user-subscriptions/domain/dtos/init-upgrade-plan-output"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { Loading } from "./loading"
import { ErrorComponent } from "./error-component"
import { useSubmitUpgradePlan } from "modules/user-subscriptions/presentation/hooks/use-submit-upgrade-plan"
import { UpgradeAlreadyInProgressError } from "modules/user-subscriptions/domain/errors/upgrade-already-in-progress"
import { Submitted } from "./submitted"
import { PaymentMethodTextBuilder } from "modules/user-subscriptions/usecases/payment-method-text-builder"
import { useGetUpgradeState } from "modules/user-subscriptions/presentation/hooks/use-get-upgrade-state"
import { UpgradeStatus } from "modules/user-subscriptions/domain/enums/upgrade-status"
import { Installment } from "modules/user-subscriptions/domain/dtos/installment"

type Props = {
  open: boolean
  onClose: () => void
  subscriptionId: string
}

export type Values = {
  plan: string
  installments: number
}

export const UpgradePlanModal: React.FC<Props> = ({ open, onClose, subscriptionId }) => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { data: initUpgrade, isFetching, error } = useInitUpgradePlan(subscriptionId, open)
  const shouldFetchUpgradeState = (error instanceof UpgradeAlreadyInProgressError || submitted) && open
  const { data: upgradeState } = useGetUpgradeState(subscriptionId, shouldFetchUpgradeState)
  const isCreditCardPending =
    upgradeState?.status === UpgradeStatus.PENDING &&
    upgradeState?.paymentMethod.type === UpgradePaymentMethods.PAYMENT_METHOD_CARD
  const { t } = useTranslation()
  const submitUpgradePlan = useSubmitUpgradePlan()
  const isMobile = useIsMobile()

  const { values, submitForm, setFieldValue } = useFormik<Values>({
    initialValues: {
      plan: Object.keys(initUpgrade?.options ?? {})[0] ?? "",
      installments: 1,
    },
    validationSchema: Yup.object().shape({
      plan: Yup.string().required(),
      installments: Yup.number().required().min(1).max(12),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      /** If there's no data, it means that the screen is still loading, so we can't send a submit yet. */
      if (!initUpgrade) return
      const payload = {
        subscriptionId,
        selectedOptionId: Object.values(initUpgrade.options).find((it) => it.billingCycle === values.plan)?.id,
        installments: values.installments,
      }
      await submitUpgradePlan.mutateAsync(payload).then(() => {
        setSubmitted(true)
      })
    },
  })

  if (error instanceof UpgradeAlreadyInProgressError || (submitted && !isCreditCardPending)) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <Submitted
          open={open}
          onClose={onClose}
          onCancel={() => setSubmitted(false)}
          subscriptionId={subscriptionId}
          upgradeState={upgradeState}
        />
      </Modal>
    )
  }

  const selectedPlan = initUpgrade?.options?.[values.plan] as UpgradeSummary
  const selectedInstallment = selectedPlan?.installments.find((it) => it.installmentAmount === values.installments)
  const availableOptions = Object.values(initUpgrade?.options ?? {})

  if (isFetching && !initUpgrade) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <Loading onClose={onClose} />
      </Modal>
    )
  }

  if (error && !initUpgrade) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <ErrorComponent onClose={onClose} error={(error as Error).message} />
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
      <Stack direction="column" gap={8}>
        {/** Header */}
        <Stack direction="column" gap={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Text variant="h4">{t("userSubscriptions.upgradePlanModal.title")}</Text>
            <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Text color="outline">
            <Trans
              i18nKey="userSubscriptions.upgradePlanModal.subtitle"
              values={{
                plan: t(`userSubscriptions.planType.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                price: formatCurrencyWithInstallments(
                  initUpgrade?.subscription.paymentMethod.installments,
                  (initUpgrade?.subscription.priceInCents ?? 0) / 100,
                  initUpgrade?.subscription.paymentMethod.installmentPrice,
                ),
                periodicity: t(
                  `userSubscriptions.billingCycle.${initUpgrade?.subscription.billingCycle.toLowerCase()}`,
                ),
                renewAt: moment(new Date()).add(initUpgrade?.subscription.renewalInDays, "days").format("D MMM. YYYY"),
              }}
              components={[<b />]}
            />
          </Text>
        </Stack>

        {/** Form */}
        <Stack direction="column" gap={4}>
          <Text fontWeight="bold">{t("userSubscriptions.upgradePlanModal.formTitle")}</Text>
          <Select
            fullWidth
            size="normal"
            label="Plano de assinatura"
            value={values.plan}
            onChange={(e) => setFieldValue("plan", e.target.value)}
          >
            {availableOptions.map((option) => (
              <MenuItem key={option.billingCycle} value={option.billingCycle}>
                {t(`userSubscriptions.planType.${option.billingCycle.toLowerCase()}`)}
              </MenuItem>
            ))}
          </Select>

          <Select
            fullWidth
            size="normal"
            label="Opções de parcelamento"
            value={values.installments}
            onChange={(e) => setFieldValue("installments", parseInt(String(e.target.value), 10))}
          >
            {(selectedPlan?.installments ?? []).map((installment: Installment) => {
              const shouldRenderAdvanceFee =
                installment.advanceFee === 0 &&
                initUpgrade?.subscription.paymentMethod.type === UpgradePaymentMethods.PAYMENT_METHOD_CARD

              return (
                <MenuItem key={installment.installmentAmount} value={installment.installmentAmount}>
                  {installment.installmentAmount}x de {formatCurrency(installment.installmentPrice)}{" "}
                  {shouldRenderAdvanceFee ? "sem juros" : ""}
                </MenuItem>
              )
            })}
          </Select>
        </Stack>

        {/** Summary */}
        <Card hasHover={false} variant="filled" selected fullHeight>
          <Stack flexDirection="column" gap={6}>
            <Text variant="body2" color="outline">
              {t("userSubscriptions.upgradePlanModal.summary", {
                from: t(`userSubscriptions.planInterval.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                to: t(`userSubscriptions.planInterval.${values.plan.toLowerCase()}`),
              })}
            </Text>
            <Stack direction="column" width="100%" gap={2}>
              <ContentRow
                title={initUpgrade?.offer.name + " - " + t(`userSubscriptions.planType.${values.plan.toLowerCase()}`)}
                content={
                  <Text variant="body1">
                    {formatCurrencyWithInstallments(
                      values.installments,
                      selectedPlan?.price,
                      ((selectedInstallment?.totalPrice ?? 0) + selectedPlan?.prorata) / values.installments,
                    )}
                  </Text>
                }
              />
              <ContentRow
                showDivider
                title={t("userSubscriptions.upgradePlanModal.credits", {
                  plan: t(`userSubscriptions.planType.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                })}
                content={<Text variant="body1">- {formatCurrency(selectedPlan?.prorata ?? 0)}</Text>}
              />
              <ContentRow
                fontWeight="bold"
                title="Total"
                content={
                  <Text variant="body1">
                    {formatCurrencyWithInstallments(
                      values.installments,
                      selectedInstallment?.installmentPrice,
                      selectedInstallment?.installmentPrice,
                    )}
                  </Text>
                }
              />
            </Stack>
          </Stack>
        </Card>

        {/** Submit Button */}
        <Stack direction="column" gap={3}>
          <Button onClick={submitForm} fullWidth loading={submitUpgradePlan.isLoading || isCreditCardPending}>
            {t("userSubscriptions.upgradePlanModal.button")}
          </Button>
          <Text variant="body2" color="outline">
            {t("userSubscriptions.upgradePlanModal.buttonSubtitle", {
              method: t(
                new PaymentMethodTextBuilder().execute(
                  initUpgrade?.subscription.paymentMethod.type,
                  initUpgrade?.subscription.paymentMethod.last4,
                  initUpgrade?.subscription.paymentMethod.cardBrand,
                ),
              ),
            })}
          </Text>
        </Stack>
      </Stack>
    </Modal>
  )
}
