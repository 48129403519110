import ReactPixel from "react-facebook-pixel"
import TagManager from "react-gtm-module"
import { Document, Type } from "@chatpay/common"
import { Analytics } from "@chatpay/components"
import { useEffect } from "react"

function getPaymentMethod(paymentMethod: Document.PaymentMethod) {
  if (paymentMethod === Document.PaymentMethod.bankSlip) {
    return "Boleto Bancário"
  } else if (paymentMethod === Document.PaymentMethod.creditCard) {
    return "Cartão de Crédito"
  } else if (paymentMethod === Document.PaymentMethod.pix) {
    return "Pix"
  } else {
    return paymentMethod
  }
}

export function tagManagerPixel(group?: Document.Group | null, page?: string) {
  if (!group?.trackers?.tagManager || group?.trackers?.tagManager === "") {
    return
  }

  const dataLayerAttributes = {
    dataLayerName: group.trackers.tagManager.replace("-", ""),
    dataLayer: {
      event: page,
      groupId: group.id,
      groupName: group.name,
    },
  }

  const tagManagerArgs = {
    gtmId: group.trackers.tagManager,
    ...dataLayerAttributes,
  }

  TagManager.initialize(tagManagerArgs)
  TagManager.dataLayer(dataLayerAttributes)
}

export function facebookPixel(
  group?: Document.Group | null,
  transaction?: Document.Transaction | null,
  trackCustom?: string | null,
) {
  if (!group?.trackers?.facebook?.id) {
    return
  }
  const options = {
    autoConfig: false, // set pixel's autoConfig
    debug: false, // enable logs
  }
  const pixelIds = group.trackers.facebook.id.split(",") // Facebook multiple pixels
  pixelIds.map((pixel) => ReactPixel.init(pixel, undefined, options))
  if (trackCustom) {
    ReactPixel.trackCustom(trackCustom, {
      product_id: group.id,
    })
  } else if (transaction) {
    const affiliate = transaction.receiversWithType(Type.Receiver.Type.affiliate).first()

    ReactPixel.track("Purchase", {
      product_id: group.id,
      currency: group.currency,
      value: transaction.total,
      paymentMethod: getPaymentMethod(transaction.paymentMethod!),
      coupon: transaction.coupon ? transaction.coupon?.discount + "%" : undefined,
      affiliate: affiliate ? affiliate.user.name : undefined,
      affiliateCommission: affiliate ? affiliate?.fee.percent + "%" : undefined,
    })
  } else {
    ReactPixel.pageView()
  }
}

// export const withScreenTracking = (name: string) => (component: Session.GComponent) => {
//   Analytics.Tracker().setCurrentScreen(name)
//   return component
// }

export type ScreenTrackProps = {
  screenName: string
  screenParams?: any
  children: any
}

export const ScreenTrack: React.FC<ScreenTrackProps> = (props) => {
  const { screenName, screenParams } = props
  const source = userSource()

  useEffect(() => {
    Analytics.page(screenName, { user_source: source, ...screenParams })
    // eslint-disable-next-line
  }, [])
  return props.children
}

export const userSource = (): string => {
  if (document.referrer.search("https?://(.*)google.([^/?]*)") === 0) {
    return "Google"
  } else if (document.referrer.search("https?://(.*)bing.([^/?]*)") === 0) {
    return "Bing"
  } else if (document.referrer.search("https?://(.*)yahoo.([^/?]*)") === 0) {
    return "Yahoo"
  } else if (document.referrer.search("https?://(.*)facebook.([^/?]*)") === 0) {
    return "Facebook"
  } else if (document.referrer.search("https?://(.*)twitter.([^/?]*)") === 0) {
    return "Twitter"
  } else if (document.referrer.search("https?://(.*)instagram.([^/?]*)") === 0) {
    return "Instagram"
  } else {
    return "Other"
  }
}
