"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitialValue = void 0;
var InitialValue = {
  value: 0,
  percent: 10.9,
  bankSlip: {
    value: 0,
    percent: 0
  },
  creditCard: {
    value: 0,
    percent: 0
  },
  pix: {
    value: 0,
    percent: 0
  }
};
exports.InitialValue = InitialValue;