"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Code = void 0;
var Code;
exports.Code = Code;

(function (Code) {
  Code["ALREADY_TAKEN"] = "ALREADY_TAKEN";
  Code["NOT_FOUND"] = "NOT_FOUND";
  Code["WHITELISTED"] = "WHITELISTED";
})(Code || (exports.Code = Code = {}));