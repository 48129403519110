import { Value } from "firebase/remote-config"
import { get, gt, gte, includes, isEqual, isNil, lt, lte } from "lodash"

type WhereFilterOp = "<" | "<=" | "==" | "!=" | ">=" | ">" | "array-contains" | "in" | "not-in" | "array-contains-any"

type FlagValue = {
  field: string
  op?: WhereFilterOp
  value: unknown
}

type Flag = {
  name: string
  type: "release" | "ops"
  value: FlagValue[] | boolean
  author?: string
  description: string
}

/** @deprecated: Use from @hub-la/fe-feature-flag instead */
export const FLAG = {}

export class FeatureFlag {
  public constructor(private readonly flags: Record<string, Value>) {}

  public isEnabled(flag: string, data?: unknown) {
    const flagsAsJSON = Object.values(this.flags).map((value) => {
      try {
        return JSON.parse(value.asString())
      } catch (error) {
        return {}
      }
    })

    const operators: Record<WhereFilterOp, (value: any, other: any) => boolean> = {
      "==": isEqual,
      "!=": (value, other) => !isEqual(value, other),
      "<=": lte,
      ">": gt,
      ">=": gte,
      "<": lt,
      "array-contains": (value, other) => includes(value, other),

      /**
       * @todo
       */
      "array-contains-any": () => false,
      "not-in": () => false,
      in: (value, other) => includes(other, value),
    }

    const flagByName: Flag | undefined = flagsAsJSON.find(({ name }) => isEqual(flag, name))

    /**
     * @description local fallback to critical flags when remote config does not fetch them
     */
    if (isNil(flagByName) && this.isEnabledByDefault(flag)) {
      return true
    }

    if (isNil(flagByName)) {
      return false
    }

    return typeof flagByName.value === "boolean"
      ? flagByName.value
      : !Array.isArray(flagByName.value)
      ? false
      : flagByName.value.every(({ field, op, value }) => {
          const operator = operators[op ?? "=="]

          return operator(get(data, field), value)
        })
  }

  private isEnabledByDefault(flag: string) {
    return ([] as string[]).includes(flag)
  }
}
