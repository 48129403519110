"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionItemType = void 0;
var SubscriptionItemType;
exports.SubscriptionItemType = SubscriptionItemType;

(function (SubscriptionItemType) {
  SubscriptionItemType["group"] = "group";
})(SubscriptionItemType || (exports.SubscriptionItemType = SubscriptionItemType = {}));