"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.providerIdFixture = exports["default"] = exports.botIdFixture = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _Bot = require("../Bot");

var _ = require("../");

var _BotAction = _interopRequireDefault(require("./BotAction"));

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var botIdFixture = function botIdFixture(type, botNumber) {
  var finalType = type !== null && type !== void 0 ? type : _faker["default"].random.objectElement(_.BotType);
  return "".concat(finalType, "_").concat(botNumber !== null && botNumber !== void 0 ? botNumber : _faker["default"].datatype.number({
    min: 1,
    max: 100
  }));
};

exports.botIdFixture = botIdFixture;

var providerIdFixture = function providerIdFixture() {
  return "".concat(_faker["default"].datatype.number({
    min: 1,
    max: 100
  }));
};

exports.providerIdFixture = providerIdFixture;

var botFixture = function botFixture(args) {
  var _args$type;

  var type = (_args$type = args === null || args === void 0 ? void 0 : args.type) !== null && _args$type !== void 0 ? _args$type : _faker["default"].random.objectElement(_.BotType);
  var botId = botIdFixture(type);
  var bkpBotId = botIdFixture(type, _faker["default"].datatype.number({
    min: 200,
    max: 300
  }));
  var providerId = providerIdFixture();

  var role = _faker["default"].random.objectElement(_.BotRole);

  return new _.Bot(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    id: providerId,
    auth: {
      url: "https://".concat(botId),
      token: "token_".concat(botId),
      fetchUrl: "https://".concat(botId)
    },
    type: type,
    name: botId.replace("_", " ").capitalize(),
    phoneNumber: _faker["default"].phone.phoneNumber("##########"),
    role: role,
    note: _faker["default"].company.catchPhrase(),
    backup: type !== _.BotType.whatsapp && role !== _.BotRole.manager ? null : {
      id: bkpBotId,
      imId: _faker["default"].phone.phoneNumber("##########")
    },
    canCreateGroups: _faker["default"].datatype["boolean"](),
    botApi: _faker["default"].random.objectElement(_.BotAPI),
    health: {
      healthy: _faker["default"].datatype["boolean"](),
      healthCheckResponse: "Checked!",
      lastHealthCheck: _utils.FirebaseInjections.timestampFromDate(new Date()),
      healthCount: _faker["default"].datatype.number(5000)
    },
    skills: _Bot.defaultBotSkills,
    lastAction: (0, _BotAction["default"])().toReference()
  }, args));
};

var _default = botFixture;
exports["default"] = _default;