import * as React from "react"
import { Message } from "semantic-ui-react"
import Errors from "./Errors"

export type ErrorData = {
  title?: string | null
  message?: string | null
}

export interface IProps {
  style?: React.CSSProperties
  error?: Error | Errors | null
}

class ErrorMessage extends React.Component<IProps> {
  private get data(): ErrorData | null | undefined {
    const { error } = this.props

    if (!error) {
      return null
    }

    return {
      title: (error as Errors)?.title ?? "OPS! Algo deu errado :(",
      message: !this.isInternal()
        ? error.message
        : "Tente atualizar a página, caso o erro persista entre em contato com o suporte.",
    }
  }

  private isInternal = (): boolean => {
    const { error } = this.props

    return !!error?.message && error.message.toUpperCase() === "INTERNAL"
  }

  public shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps)
  }

  public render() {
    return (
      <Message
        className="ErrorMessage"
        style={{
          whiteSpace: "pre-line",
          marginBottom: "1em",
        }}
        error={true}
        header={this.data?.title}
        content={this.data?.message}
        hidden={!this.data}
      />
    )
  }
}

export default ErrorMessage
