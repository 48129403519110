export const editGroup = {
  mobilePageTitle: "Produto",
  tabs: {
    introduction: "Introdução",
    groups: "Grupos",
    basic: "Básico",
    description: "Página do produto",
    partner: "Co-produtor",
    tools: "Ferramentas",
    partners: "Co-produtor",
    content: "Conteúdos",
    waitlist: "Lista de espera",
    settings: "CONFIGURAÇÕES",
    offers: "Precificação e ofertas",
    customMember: "Área de membros",
    customCheckout: "Checkout",
    members: "Membros",
    custom: "PERSONALIZAÇÃO",
    materials: "Kit de boas-vindas",
    externalLinks: "Links",
    new_externalLinks: "Links",
  },
  introduction: {
    header: {
      title: "Por onde você quer começar?",
      description: "Na Hubla você pode criar uma comunidade que tenha grupos, cursos ou os dois...",
    },
    features: {
      memberArea: {
        title: "Área de membros com conteúdos exclusivos",
        cta: "Adicionar conteúdos",
        imageAlt:
          "Representando a página de produto da Hubla, com as thumbnail dos conteúdos, materiais das aulas, posts e vídeos.",
      },
      group: {
        title: "Grupo para interagir com os meus membros",
        cta: "Criar grupo",
        imageAlt:
          "Mockup de um iphone com o Whatsapp aberto no grupo 'Cartões, Milhas e Viagens', com a mensagem 'Mais um obrigado a todos' e uma imagem de cartão preto.",
      },
      migration: {
        title: "Grupo para interagir com os meus membros",
        cta: "Migrar",
        imageAlt: "Algumas logos da Hubla em forma de bolhas quadradas.",
      },
    },
  },
  affiliates: {
    title: "Afiliados",
    subtitle: "Ofereça uma comissão para aqueles que promovem seu produto",
    about: "Entenda como funciona o nosso",
    link: "programa de afiliados.",
  },
  entryFee: {
    title: "Avançado: Taxa de inscrição",
    subtitle: "Configure se deseja cobrar uma taxa diferenciada para o primeiro mês de acesso ao seu grupo.",
    about: "Deseja entender melhor sobre a taxa de adesão?",
    link: "Clique aqui.",
  },
  assets: {
    title: "Mídias",
    subtitle:
      "Adicione mídias que ficarão em destaque na sua página. Elas podem servir para complementar visualmente o seu produto, quebrar a objeção do cliente ou até mesmo mostrar uma prova social.",
    youtube: {
      notValid: "Não é uma URL válida.",
      mustBeYoutube: "Precisa ser um video do Youtube.",
      title: "Insira o url do video",
      done: "Pronto",
    },
  },
  description: {
    title: "Descrição do grupo",
    subtitle: "Escreva um documento explicando detalhes sobre o conteúdo de seu grupo.",
    about: "Para conferir nossas melhores práticas ao montar sua página de vendas",
    link: "clique aqui.",
    warning:
      "Modificamos o lugar onde você cria a descrição do seu grupo. Clique no botão abaixo para criar ou editar sua descrição",
  },
  aboutDescription: {
    title: "Descrição",
    subtitle:
      "A descrição do seu produto pode aumentar significativamente a conversão de compra na sua página de vendas. Seja claro, breve e conte um pouco sobre o seu produto.",
  },
  advantages: {
    title: "Vantagens",
    subtitle:
      "Adicione uma lista de vantagens para esclarecer ao cliente, qual tipo de acesso, conteúdo e outros benefícios ele irá adquirir ao fazer parte do seu produto.",
  },
  faq: {
    title: "Perguntas Frequentes",
    subtitle:
      "Ao criar uma lista de perguntas frequentes, você reduz a quantidade de clientes que acionam o seu time de suporte, ou até mesmo deixam de participar do seu produto por conta de uma dúvida.",
  },
  info: {
    title: "Página do produto",
    subtitle:
      "As informações básicas são uma parte importante do seu produto. Adicione um título, subtítulo e foto para mostrar mais credibilidade.",
    about: "Você pode saber mais sobre como montar a introdução do seu produto",
    link: "clicando aqui.",
    deprecated: 'Esta aba está sendo desativada. O conteúdo foi migrado para as abas laterais "Ofertas" e "Materiais".',
  },
  linkedGroup: {
    title: "Grupos vinculados",
    subtitle:
      "Seu cliente terá acesso automaticamente aos grupos vinculados de um produto assim que adquirir este produto.",
    about: "Se quiser entender direitinho como funciona os grupos vinculados",
    link: "clique aqui.",
    disabledByCohortType:
      "Por enquanto a experiência de grupos vinculados está disponível apenas para grupos pertencentes a produtos do tipo permanente.",
  },
  trackers: {
    title: "Pixel de rastreamento",
    subtitle: "Conecte sua página com pixels externos",
    about: "Está com dúvidas para configurar o Meta Pixel? ",
    link: "Clique aqui.",
    facebook: {
      pixel: "Meta Pixel",
      conversionApi: "Api de conversão",
      domain: "Domínio",
      notSetled: "Não informado",
      verified: "Verificado",
      notVerified: "Não Verificado",
      remove: "Remover",
      configureDomain: "Configurar domínio",
      configurationDetail: "Detalhes da configuração",
      ie: "Ex:",
      cancel: "Cancelar",
      undo: "Desfazer",
      invalidDomain: "Domínio inválido",
      yourdomain: "seudominio.com.br",
      domainNotFound: "Domínio {{domain}} não encontrado no registro CNAME",
      verify: "Verificar",
      texts: {
        apple: `Com as atualizações do Facebook, para atender os requisitos da Apple para o iOS 14, você só poderá rodar campanhas que são otimizadas com eventos de conversão com um domínio verificado.`,
        1: {
          1: "1. Digite o domínio que você usa no",
          2: "Facebook Business Manager",
        },
        2: {
          1: "O domínio deve estar verificado no",
          2: "Business Manager",
          3: "Caso tenha qualquer dúvida. Por favor, nos chame no suporte.",
        },
        3: {
          1: "2. Siga os passos para configurar seu CNAME com",
          2: "seu registro de domínio",
        },
        4: "Um CNAME é usado para apostar um subdomínio que será usado no fluxo do evento de conversão.",
        5: "2.1. Vá até a configuração de DNS do seu registro de domínio.",
        6: {
          1: "2.2. Adicione um CNAME",
          2: "apontando para",
        },
        7: "2.3. Salve o DNS.",
        8: "Se você estiver inseguro quanto a este processo. Por favor, contate o seu registro de domínio.",
        9: 'Clique em "Verificar" para validar se a configuração está correta',
        10: "Um certificado será gerado automaticamente após a verificação. A propagação do DNS pode levar até 72 horas.",
      },
    },
  },
  price: {
    title: "Preço",
    subtitle: "Defina qual será o tipo de cobrança do seu produto principal e o preço que será cobrado.",
  },
  welcome: {
    title: "Kit de boas-vindas",
    subtitle: "Crie uma mensagem de boas vindas, e adicione materiais como pdfs, entre outros.",
    about: "Saiba mais sobre o nosso material de boas vindas",
    link: "aqui.",
  },
  publishButton: {
    activateButton: "Ativar",
    activateModalTitle: "Você está a um passo de lançar o seu produto",
    activateModalSubtitle: "Agora você pode escolher a melhor forma de lançar seu produto",
    deleteModal: {
      title: "Deletar produto",
      subTitle:
        "Ao deletar o seu produto, ele ficará com as vendas desativadas e não será mais exibido para você. Além disso, a página de vendas dele será desativada.\nEssa ação não pode ser revertida, mas, sempre que você quiser, basta criar um novo produto.\nCaso você tenha deletado seu produto por engano, entre em contato com o nosso suporte.",
      warningMessage: "Você não conseguirá reverter esta ação.",
      btnNext: "Deletar",
      confirmDelete: "Seu produto foi deletado",
      confirmDeleteDescription: "",
      confimButton: "Fechar",
      tooltipButtonDescription: "Deletar produto",
    },
    hideModal: {
      title: "Esconder o produto do perfil",
      subTitle: "Ao esconder o produto do seu perfil ele não será mais exibido na sua página do criador aqui na Hubla.",
      description:
        "Mas fique tranquilo, a página de vendas dele continua ativa e quem acessá-la através do link poderá comprar o seu produto.\nE você poderá, sempre que quiser, exibir novamente o produto no seu perfil.",
      confirmDelete: "Seu produto foi escondido do seu perfil",
      confirmDeleteDescription: "Você poderá exibi-lo novamente em seu perfil a qualquer momento",
      descriptionDelete: "Você poderá exibi-lo novamente em seu perfil a qualquer momento",
      btnNext: "Esconder do perfil",
      confimButton: "Fechar",
      tooltipButtonDescription: "Esconder produto do perfil",
    },
    showModal: {
      title: "Exibir produto no perfil",
      subTitle:
        "Ao exibir o produto no seu perfil ele poderá ser acessado através da sua página de criador aqui na Hubla",
      description: "",
      confirmDelete: "Seu produto está sendo exibido no seu perfil",
      confirmDeleteDescription: "Agora poderão encontrar a página de vendas de forma mais fácil",
      descriptionDelete: "Agora poderão encontrar a página de vendas de forma mais fácil",
      btnNext: "Exibir do perfil",
      confimButton: "Fechar",
      tooltipButtonDescription: "Exibir produto no perfil",
    },
    maybeLater: "Talvez mais tarde",
    activateSales: "Ativando vendas",
    activateSalesResourceType: "Criando seu {{resourceType}} no {{resourceName}}",
    activateSalesResourceTypeLabel: {
      group: "grupo",
      channel: "canal",
    },
    activateWaitList: "Ativando lista de espera",
    activeSales: "Seu produto está pronto",
    activeWaitlist: "A lista de espera foi ativada ;)",
    disableSales: "As vendas da sua comunidade foram desativadas",
    doneSales: "Você já pode compartilhar a sua página do produto e começar a vender",
    doneWaitlist: "Seu produto está pronto para receber novos contatos",
    buttonAccessProductPage: "Acessar página do produto",
    buttonWaitlist: "Fechar",
    salesOptionTitle: "Ativar vendas",
    salesOptionSubtitle: "Clientes podem encontrar e comprar seu produto",
    waitlistOptionTitle: "Ativar lista de espera",
    waitlistOptionSibtitle: "Clientes podem encontrar sua comunidade e deixar seus contatos na lista de espera",
    activateMyProduct: "Ativar meu produto",
    verifyAccount: "Você precisa verificar sua conta.",

    activateModalCohortSettingsTitle: "Ativar vendas para uma nova turma",
    activateModalCohortSettingsSubtitle:
      "Ao ativar as vendas do seu produto você abrirá uma nova turma para ele, criando novos grupos. Membros de uma turma não terão acesso aos grupos da turma anterior, apenas aos conteúdos. Sempre que você ativar as vendas, você poderá definir novas configurações de limite de membros por turma.",
    activateCohortSettingsTotalSalesLimitTitle: "Limite de vendas",
    activateCohortSettingsTotalSalesLimitOptions: {
      withoutTotalSalesLimit: "Sem limite de vendas",
      withTotalSalesLimit: "Com limite de vendas",
    },
    defineTotalLimitSales: {
      label: "Defina o limite de vendas do seu produto",
    },
    activateCohortSales: "Ativando nova turma",
    doneCohortSales: "Sua turma foi criada ;)",
    activeCohortSales: "Agora você já pode entrar no primeiro produto da turma",
    activeteCohortSettingsButton: "Próximo",
    activateCohortSettingsTotalMembersLimitTitle: "Limite de membros",
    activateCohortSettingsTotalSalesLimitInfo: "Quando um grupo lotar, nós criamos outro automaticamente para você.",
  },
  groups: {
    noGroupsTitle: "Comece a criar a sua comunidade agora mesmo",
    title: "Grupos",
    subtitle: "Crie um grupo ou canal de transmissão exclusivo para os seus membros",
    createAGroup: "Criar um grupo",
    table: {
      row: {
        status: {
          idle: "Inativo",
          ready: "Ativo",
          full: "Lotado",
        },
        join: "Entrar",
        see: "Ver",
        copyToClipboard: "Copiar link de acesso",
        freeMembers: "Membros gratuitos",
        quantityMembers: "Qtd. de membros",
        quantity: "Quantidade",
        actions: "Ações",
        resourceStatus: "Status",
      },
    },
    Empty: "Você ainda não possui comunidades",
    accordion: {
      title: "Turma",
    },
    getInMessage: {
      youHave: "Você tem ",
      newGroups: "novos grupos.",
      newGroup: "novo grupo.",
      clickOnBotton: "Clique no botão ",
      getInButton: "Entrar",
      toAccess: " para acessá-los",
    },
  },
  partner: {
    title: "Co-produtor",
    subtitle: "Há outras pessoas produzindo com você? Adicione aqui...",
    invite: "Convidar parceiro",
    edit: "Editar",
    cancel: "Cancelar solicitação",
    status: {
      invited: "Aguardando aceite",
      cancellation_requested: "Cancelamento solicitado",
      rejected: "Recusada",
      accepted: "Ativa",
      canceled: "Cancelada",
      finished: "Finalizada",
    },
  },
  funnelStrategy: {
    title: "Estratégia de vendas",
    subtitle: "Configure diversas opções de estratégias de vendas para o seu produto principal.",
    about: "",
    link: "Saiba mais",
  },
  offer: {
    title: "Precificação e ofertas",
    subtitle: "Configure uma oferta para este produto de acordo com sua estratégia de vendas.",
  },
  buyCta: {
    title: "Botão de vendas",
    subtitle: "Configure qual será o valor exibido no botão de acordo com o seu pitch de vendas",
  },
}
