import { Text, Stack, styled, spacing, shape, withUtility } from "@hub-la/design-system"
import React from "react"
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined"
import { useTranslation } from "react-i18next"
import svgIconClasses from "@mui/material/SvgIcon/svgIconClasses"

const EmptyIcon = styled(Stack)`
  padding: ${spacing(2)};
  border-radius: ${shape("medium")};
  border: 1px solid ${withUtility(({ palette }) => palette("surfaceVariant"))};
  background: ${withUtility(({ palette }) => palette("surfaceAt1"))};
  .${svgIconClasses.root} {
    color: ${withUtility(({ palette }) => palette("onSurfaceVariant"))};
  }
`

export const UserDoesNotHaveGroup = () => {
  const { t } = useTranslation()
  return (
    <Stack
      data-testid="empty-state"
      width="100%"
      justifyContent="center"
      alignItems="center"
      paddingY={30}
      height="100%"
    >
      <EmptyIcon>
        <ManageSearchOutlinedIcon />
      </EmptyIcon>

      <Text variant="h4" marginTop={6}>
        {t("userGroups.empty")}
      </Text>
    </Stack>
  )
}
