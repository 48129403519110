import { Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

export enum GroupsOrderActionTypes {
  SORT = "GROUPS_ORDER_SORT",
  LOAD = "GROUPS_ORDER_LOAD",
  LOAD_SUCCESS = "GROUPS_ORDER_LOAD_SUCCESS",
  LOAD_FAILURE = "GROUPS_ORDER_LOAD_FAILURE",
  SAVE = "GROUPS_ORDER_SAVE",
  SAVE_SUCCESS = "GROUPS_ORDER_SAVE_SUCCESS",
  SAVE_FAILURE = "GROUPS_ORDER_SAVE_FAILURE",
  DISMISS_BUTTON = "GROUPS_ORDER_DISMISS_BUTTON",
}

export interface ILoadAction {
  type: GroupsOrderActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: GroupsOrderActionTypes.LOAD_SUCCESS
  groups: Document.Group[]
}

export interface ILoadFailureAction {
  type: GroupsOrderActionTypes.LOAD_FAILURE
  reason: string
}

export interface ISaveAction {
  type: GroupsOrderActionTypes.SAVE
}

export interface ISaveSuccessAction {
  type: GroupsOrderActionTypes.SAVE_SUCCESS
}

export interface ISaveFailureAction {
  type: GroupsOrderActionTypes.SAVE_FAILURE
  reason: string
}

export interface ISortAction {
  type: GroupsOrderActionTypes.SORT
  oldIndex: number
  newIndex: number
}

export interface IDismissButtonAction {
  type: GroupsOrderActionTypes.DISMISS_BUTTON
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: GroupsOrderActionTypes.LOAD,
  })

  new API.Product()
    .getAllAvailableInCreatorsPage()
    .then((results) => {
      dispatch(loadSuccess(results.map((result) => new Document.Group(result.currentOffer))))
    })
    .catch((error) => dispatch(loadFailure(error)))
}

export const loadSuccess = (groups: Document.Group[]): ILoadSuccessAction => {
  return {
    type: GroupsOrderActionTypes.LOAD_SUCCESS,
    groups,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: GroupsOrderActionTypes.LOAD_FAILURE,
    reason,
  }
}

export const sort = (oldIndex: number, newIndex: number): ISortAction => {
  return {
    type: GroupsOrderActionTypes.SORT,
    oldIndex,
    newIndex,
  }
}

export const save =
  (groups: Document.Group[]): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: GroupsOrderActionTypes.SAVE,
    })

    const data = groups.map((group, index) => {
      return { id: group.productId!, order: index }
    })

    new API.Product()
      .saveOrder(data)
      .then((_success) => dispatch(saveSuccess()))
      .catch((error) => dispatch(saveFailure(error)))
  }

export const saveSuccess = (): ISaveSuccessAction => {
  window.location.href = `/${Service.Firebase.currentUser?.id}`
  return {
    type: GroupsOrderActionTypes.SAVE_SUCCESS,
  }
}

export const saveFailure = (reason: string): ISaveFailureAction => {
  return {
    type: GroupsOrderActionTypes.SAVE_FAILURE,
    reason,
  }
}

export const dismissButton = (): IDismissButtonAction => {
  return {
    type: GroupsOrderActionTypes.DISMISS_BUTTON,
  }
}

export type GroupsOrderAction =
  | ILoadAction
  | ILoadSuccessAction
  | ILoadFailureAction
  | ISaveAction
  | ISaveSuccessAction
  | ISaveFailureAction
  | ISortAction
  | IDismissButtonAction
