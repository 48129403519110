import { FileAssetType, IFileAsset } from "components/Interfaces/IFileAsset"
import React, { useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Table, Icon, Button } from "semantic-ui-react"

interface IProps {
  files?: IFileAsset[]
  disabled?: boolean
  onChange?: (files?: IFileAsset[]) => any
}

export const InputFiles: React.FunctionComponent<IProps> = (props) => {
  const { files, onChange, disabled } = props

  const [selectedFiles, setSelectedFiles] = useState<Array<IFileAsset>>()

  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const formats = process.env.REACT_APP_FILE_MIMETYPE_ALLOWED

  const handleNewFiles = (list: FileList | null) => {
    if (list) {
      const newFiles: IFileAsset[] = []

      Object.keys(list).forEach((key) => {
        const f = list[key] as File

        newFiles.push({
          type: FileAssetType.local,
          file: f,
          name: f.name,
        })
      })
      const result = newFiles.concat(selectedFiles ?? [])
      if (onChange) {
        onChange(result)
      }
      setSelectedFiles(result)
    }
  }

  const handleRemoveFile = (file: IFileAsset) => {
    const result = selectedFiles?.filter((other) => other !== file)
    if (onChange) {
      onChange(result)
    }
    setSelectedFiles(result)
  }

  useEffect(() => {
    if (JSON.stringify(files) !== JSON.stringify(selectedFiles)) {
      setSelectedFiles(files)
    }
  }, [files, selectedFiles])

  return (
    <Table celled={true} fixed={true} sortable={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={10}> {t("InputFiles.files")}</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            <Button
              disabled={disabled}
              style={{ float: "right" }}
              size="tiny"
              basic={true}
              content={t("InputFiles.add")}
              onClick={() => inputRef.current?.click()}
            />
            <input
              disabled={disabled}
              ref={inputRef}
              hidden={true}
              type="file"
              multiple={true}
              max-size="2000"
              accept={formats}
              onChange={(event) => handleNewFiles(event.target.files)}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {selectedFiles?.map((file, key) => {
          return (
            <Table.Row key={key}>
              <Table.Cell>{decodeURIComponent(file.name)}</Table.Cell>
              <Table.Cell textAlign="right">
                <Icon
                  style={{ cursor: "pointer", opacity: "0.3" }}
                  name="trash alternate outline"
                  onClick={() => handleRemoveFile(file)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}
