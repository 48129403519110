import { Box, Button, Card, Divider, Grid, Pagination, Stack, Table, Text, useUtility } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import EventRepeat from "@mui/icons-material/EventRepeat"
import { Skeleton } from "@mui/material"
import times from "lodash/times"
import React from "react"
import { useHistory } from "react-router-dom"
import { InvoiceTableLoading } from "./components/invoice-table-loading"
import { useTranslation } from "react-i18next"

const Loading: React.FC = () => {
  const { t } = useTranslation()
  const { palette } = useUtility()
  const history = useHistory()

  const getHeadCells = () => {
    return [
      { key: "total", label: t("userSubscriptions.table.total"), sortable: false, width: 90 },
      { key: "status", label: t("userSubscriptions.table.status"), sortable: false, width: 70 },
      { key: "paidAt", label: t("userSubscriptions.table.paidAt"), sortable: false, width: 110 },
    ]
  }

  return (
    <Box pb={40} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userSubscriptions.back")}
        </Button>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Card variant="filled" hasHover={false}>
            <Stack>
              <Stack direction="row" alignItems="center" gap={2} mb={4}>
                <Skeleton width={90} height="auto" style={{ aspectRatio: "218 / 142" }} />
                <Stack direction="column">
                  <Stack direction="row" gap={2} alignItems="center">
                    <Text variant="h4">
                      <Skeleton width={130} />
                    </Text>
                    <Skeleton width={59} height={32} />
                  </Stack>
                  <Text variant="caption" color="onSurfaceVariant">
                    <Skeleton width={130} />
                  </Text>
                </Stack>
              </Stack>
              <Divider />
              <Stack direction="row" mt={4}>
                <Stack direction="column" gap={2} width="100%">
                  <Text variant="body1">Data de inicio</Text>
                  <Stack direction="row" gap={2}>
                    <CalendarTodayIcon style={{ color: palette("onSurfaceVariant") }} />
                    <Text variant="body1" color="onSurfaceVariant">
                      <Skeleton width={110} />
                    </Text>
                  </Stack>
                </Stack>
                <Stack direction="column" gap={2} width="100%">
                  <Text variant="body1">Data de renovação</Text>
                  <Stack direction="row" gap={2}>
                    <EventRepeat style={{ color: palette("onSurfaceVariant") }} />
                    <Text variant="body1" color="onSurfaceVariant">
                      <Skeleton width={110} />
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="filled" hasHover={false} fullHeight>
            <Stack direction="column" gap={4}>
              <Stack direction="column" width="100%">
                <Text variant="body1">Plano de assinatura</Text>
                <Text variant="body1" color="onSurfaceVariant">
                  <Skeleton width={110} />
                </Text>
              </Stack>
              <Stack direction="column" width="100%">
                <Text variant="body1">Preço</Text>
                <Text variant="body1" color="onSurfaceVariant">
                  <Skeleton width={110} />
                </Text>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="filled" hasHover={false} fullHeight>
            <Stack direction="column" justifyContent="space-between" height="100%">
              <Stack direction="column" width="100%">
                <Text variant="body1">Método de pagamento</Text>
                <Text variant="body1" color="onSurfaceVariant">
                  <Skeleton width={110} />
                </Text>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <Stack height="100%" width="100%" mt={8}>
        <Text variant="h4" mb={3}>
          {t("userSubscriptions.invoiceHistory")}
        </Text>
        <Stack flexDirection="column" gap={4}>
          <Table id="table" maxHeight="100%" headCells={getHeadCells()} bgHeadCell="#121212" minWidth={700}>
            {times(3, (idx) => (
              <InvoiceTableLoading key={`skeleton-row-${idx}`} />
            ))}
          </Table>

          <Pagination
            total={10}
            pageSize={25}
            page={1}
            lastPage={1}
            onChangePage={() => {
              //
            }}
            onChangePageSize={() => {
              //
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Loading
