export const GetStarted = {
  Info: {
    title: "Em que categoria seu produto se encaixa?",
    placeholder: "Escolha a categoria que você melhor se encaixa",
    bets: "Apostas Esportivas",
    crypto: "Cripto",
    education: "Educação Profissional",
    investments: "Investimentos e Trading",
    travel: "Milhas e Viagens",
    wellness: "Saúde e Fitness",
    other: "Outro",

    /**@deprecated */
    languages: "Idiomas",
    literature: "Literatura",
    entertainment: "Entretenimento",
    personalGrowth: "Crescimento Pessoal",
    gastronomy: "Culinária e Gastronomia",
  },
  Profile: {
    title: "Crie o seu perfil",
    subtitle: "Suas informações aparecerão na sua página de perfil pessoal.",
    label1: "Foto",
    label2: "Nome",
    label3: "Sobrenome",
    label4: "Email",
    label5: "Escreva a bio do seu perfil",
    label6: "Crie o link de acesso para sua página",
    label7: "Escolha uma cor tema para sua página",
    label8: "Compartilhe suas redes sociais",
    warning: "💡 Você sempre pode alterar essas informações depois",
    placeholder1: "ex.: Joao",
    placeholder2: "ex.: Silva",
    placeholder3: "ex.: joao.silva@gmail.com.br",
    placeholder4: "ex.: Ensino pessoas a tocar violão do amador ao profissional",
    placeholder5: "ex.: joaosilva",
    placeholder6: "ex.: vemdehubla",
    placeholder7: "ex.: hubla",
    loader: "Estamos processando suas informações. Aguarde alguns segundos.",
  },
  Skip: "Pular",
  Back: "Voltar",
  Next: "Próximo",
  Step: "Passo",
  To: "de",
  errors: {
    required: "Campo obrigatório",
    email: "Email inválido",
    minChar: "Mínimo de {{count}} caracteres",
    maxChar: "Máximo de {{count}} caracteres",
    reservedPath: "Esse link é usado pela própria Hubla",
    social: "Esse não é um username do {{media}} válido.",
    instagram: "Não precisa colocar o @ antes.",
  },
}
