import React from "react"
import {
  AuthProvider,
  RecoverPasswordToken as AuthRecoverPasswordTokenPage,
  AuthRedirectProvider,
} from "@hub-la/fe-auth"
import { Service } from "@chatpay/components"

const RecoverPasswordTokenPage = () => {
  const getCurrentUser = () => {
    return Service.Firebase.currentUser
  }

  return (
    <AuthProvider>
      <AuthRedirectProvider getCurrentUser={getCurrentUser}>
        <AuthRecoverPasswordTokenPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}

export default RecoverPasswordTokenPage
