export const GroupStatusButton = {
  offerWaitlistWarning: {
    modal: {
      title: "Ativar lista de espera também fecha a turma aberta",
      description:
        "Ao desativar o seu produto, você está encerrando as vendas para sua turma atual. Esta ação é <b>irreversível</b>.<br>Ou seja, se você desejar abrir para vendas novamente, estará abrindo uma nova turma.",
      buttons: {
        maybeLater: "Talvez mais tarde",
        createWaitlist: "Abrir lista de espera e fechar turma aberta",
      },
    },
  },
  accountVerificationInput: {
    modal: {
      title: "Precisamos verificar sua conta ☑️",
      description: "Para começar a vender você precisa verificar a sua conta bancária.",
      buttons: {
        maybeLater: "Talvez mais tarde",
        checkAccount: "Verificar conta",
      },
    },
  },
  accountVerificationPending: {
    modal: {
      title: "Estamos processando as informações da sua conta",
      description: "Assim que tudo estiver certo, você vai conseguir vender seu produto",
      buttons: {
        close: "Fechar",
      },
    },
  },
  offerNotSellingWarning: {
    modal: {
      title: "Desativando vendas do produto",
      description: "Estamos desativando as vendas do seu produto",
      confirm: {
        title: "As vendas do seu produto foram desativadas",
        description: "Você poderá reativar a qualquer momento novamente",
      },
    },
  },
  resourceDisabled: {
    modal: {
      title: "Instabilidade no {{resource_name}}",
      description:
        "Estamos com instabilidade no {{resource_name}}, portanto não é possível ativar as vendas nesse momento. Aguarde e tente novamente mais tarde.",
      buttons: {
        close: "Fechar",
      },
    },
  },
}
