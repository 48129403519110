import React from "react"
import { onlyNumbers } from "@brazilian-utils/brazilian-utils"
import { Stack } from "@hub-la/design-system"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { Values } from "."
import { StyledTextField } from "./index.styled"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"

/** CPF is brazilian unique person identifier number. */
export function CPF() {
  const { t } = useTranslation()
  const { values, handleBlur, handleChange } = useFormikContext<Values>()
  const { paymentMethod } = values

  const isCreditCard = paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD

  const isCNPJ = onlyNumbers(values.document).length > 11

  const getLabel = () => {
    if (isCreditCard) {
      return isCNPJ ? "CNPJ" : "userSubscriptions.changePaymentMethodModal.documentLabelCreditCard"
    }
    return "userSubscriptions.changePaymentMethodModal.documentLabel"
  }

  return (
    <Stack>
      <NumberFormat
        label={t(getLabel())}
        id="document"
        data-testid="document"
        onBlur={handleBlur}
        /** We need to add those # on cpf as well to allow 14 characters */
        format={isCNPJ ? "##.###.###/####-##" : "###.###.###-#####"}
        onChange={handleChange}
        type="tel" // numbers only
        value={values.document}
        customInput={StyledTextField}
        fullWidth
        size="small"
      />
    </Stack>
  )
}
