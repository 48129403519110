import { Stack, shape, spacing, styled, withUtility, Text } from "@hub-la/design-system"
import { IconButton, svgIconClasses } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined"
import React from "react"
import { useTranslation } from "react-i18next"

export const ErrorComponent: React.FC<{ onClose: () => void; error?: string }> = ({ onClose, error }) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column">
      <Stack alignSelf="end">
        <IconButton data-testid="close-button" sx={{ marginLeft: "auto" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack
        data-testid="empty-state"
        width="100%"
        justifyContent="center"
        alignItems="center"
        paddingY={30}
        paddingX={8}
        height="100%"
      >
        <EmptyIcon>
          <ManageSearchOutlinedIcon />
        </EmptyIcon>

        <Text variant="h4" marginTop={6} textAlign="center">
          {t(`userSubscriptions.upgradePlanModal.errors.${error}`)}
        </Text>
      </Stack>
    </Stack>
  )
}

const EmptyIcon = styled(Stack)`
  padding: ${spacing(2)};
  border-radius: ${shape("medium")};
  border: 1px solid ${withUtility(({ palette }) => palette("surfaceVariant"))};
  background: ${withUtility(({ palette }) => palette("surfaceAt1"))};
  .${svgIconClasses.root} {
    color: ${withUtility(({ palette }) => palette("onSurfaceVariant"))};
  }
`
