import { routes } from "@chatpay/common"
import { Stack, Text, shape, styled, useIsMobile, withUtility } from "@hub-la/design-system"
import ArrowRight from "@mui/icons-material/ArrowRight"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { textEllipsis } from "utils/textEllipsis"
import { OrderIcon } from "./order-icon"

const Card = styled(Stack)`
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: ${shape("medium")};
  background-color: ${withUtility(({ palette }) => palette("surface"))};
  :hover {
    background-color: ${withUtility(({ palette }) => palette("surfaceVariant"))};
  }
`

type Props = {
  id: string
  type: "unpaid" | "overdue"
  dueDate: string
  name: string
  price: string
}

export const PendingCard: React.FC<Props> = (props) => {
  const { id, type, dueDate, name, price } = props
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()

  const redirectToInvoiceDetail = (id: string) => history.push(routes.USER_INVOICES.LINK + "/" + id)

  return (
    <Card
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={3}
      onClick={() => redirectToInvoiceDetail(id)}
    >
      <Stack direction="row" alignItems="center" gap={4} mr={[2, 4]} width="100%">
        <OrderIcon color={type === "unpaid" ? "#444840" : "#80230D"} />
        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between" width="100%">
          <Stack direction="column" gap={1}>
            <Text variant="body1">{isMobile ? textEllipsis(name, 20) : name}</Text>
            <Text variant="caption" color="onSurfaceVariant">
              {t(`userPendencies.${type}.dueDate`)} {moment(dueDate).format("D MMM. YYYY")}
            </Text>
          </Stack>
          <Text variant="body1">{price}</Text>
        </Stack>
      </Stack>
      <ArrowRight />
    </Card>
  )
}
