"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeSpecialCharactersExceptHyphen = exports.removeEmojiCharacters = exports.removeBlankSpaces = exports.formatMembersCount = exports.DocumentType = void 0;

var _Currency = require("../Models/Currency");

var _Locale = require("../Models/Locale");

var DocumentType;
exports.DocumentType = DocumentType;

(function (DocumentType) {
  DocumentType["cpf"] = "^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$";
  DocumentType["cnpj"] = "^\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}\\-\\d{2}$";
  DocumentType["cpfOrCnpj"] = "(^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$)|(^\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}-\\d{2}$)";
})(DocumentType || (exports.DocumentType = DocumentType = {}));

// NUMBER
Number.prototype.toCurrencyString = function () {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$language = _ref.language,
      language = _ref$language === void 0 ? _Locale.Locale.pt_BR : _ref$language,
      _ref$code = _ref.code,
      code = _ref$code === void 0 ? _Currency.Currency.brl : _ref$code,
      _ref$stripSymbols = _ref.stripSymbols,
      stripSymbols = _ref$stripSymbols === void 0 ? false : _ref$stripSymbols;

  var str = new Intl.NumberFormat(language, {
    style: "currency",
    currency: code
  }).format(this);

  if (stripSymbols) {
    str = str.replace(/([^0-9.,]+)/g, "").trim();
  }

  return str;
};

Number.prototype.withPrecision = function () {
  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return parseFloat(this.toFixed(fractionDigits));
};

Number.prototype.truncatePrecision = function () {
  var _ref2;

  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var matched = String(this).match(new RegExp("^[0-9]+.?[0-9]{0,".concat(fractionDigits, "}")));
  return (_ref2 = matched && parseFloat(matched[0])) !== null && _ref2 !== void 0 ? _ref2 : this;
}; // STRING


String.prototype.isNumber = function () {
  return /^-?[0-9]+(\.)?[0-9]*$/.test(this);
};

String.prototype.numbers = function () {
  return this.replace(/\D/g, "");
};

String.prototype.isDocument = function (type) {
  return new RegExp(type).test(this);
};

String.prototype.toFloat = function () {
  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;

  var _float = !this.includes(",") ? parseFloat(this) : NaN;

  return (isNaN(_float) ? parseInt(this.replace(/[^\d-]/g, ""), 10) / 100 : _float).withPrecision(fractionDigits);
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.uncapitalize = function () {
  return this.charAt(0).toLowerCase() + this.slice(1);
};

var removeBlankSpaces = function removeBlankSpaces(value) {
  return value ? value.replace(/\s+/g, "") : "";
};

exports.removeBlankSpaces = removeBlankSpaces;

var removeEmojiCharacters = function removeEmojiCharacters(value) {
  return value ? value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "") : "";
};

exports.removeEmojiCharacters = removeEmojiCharacters;

var removeSpecialCharactersExceptHyphen = function removeSpecialCharactersExceptHyphen(value) {
  return value ? value.replace(/[^A-Z0-9-]/g, "") : "";
}; // ARRAY


exports.removeSpecialCharactersExceptHyphen = removeSpecialCharactersExceptHyphen;

Array.prototype.first = function () {
  return this[0];
};

Object.defineProperty(Array.prototype, "first", {
  enumerable: false
});

Array.prototype.last = function () {
  return this[this.length - 1];
};

Object.defineProperty(Array.prototype, "last", {
  enumerable: false
});

Array.prototype.at = function (index, def) {
  var _this$index;

  return (_this$index = this[index]) !== null && _this$index !== void 0 ? _this$index : def;
};

Object.defineProperty(Array.prototype, "at", {
  enumerable: false
});

var formatMembersCount = function formatMembersCount(value) {
  var lang = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "pt-BR";
  return new Intl.NumberFormat(lang).format(value);
};

exports.formatMembersCount = formatMembersCount;