export enum CountryCode {
  AR = "AR", // Argentina
  AU = "AU", // Australia
  AT = "AT", // Austria

  BE = "BE", // Belgium
  BO = "BO", // Bolivia
  BR = "BR", // Brazil
  BG = "BG", // Bulgaria

  CA = "CA", // Canada
  CL = "CL", // Chile
  CO = "CO", // Colombia
  CY = "CY", // Cyprus
  CZ = "CZ", // Czech Republic
  CH = "CH", // Switzerland

  DK = "DK", // Denmark
  DE = "DE", // Germany

  EC = "EC", // Ecuador
  EG = "EG", // Egypt
  EE = "EE", // Estonia
  ES = "ES", // Spain

  FI = "FI", // Finland
  FR = "FR", // France

  GR = "GR", // Greece
  GB = "GB", // The United Kingdom

  HK = "HK", // Hong Kong

  ID = "ID", // Indonesia
  IE = "IE", // Ireland
  IT = "IT", // Italy

  JP = "JP", // Japan

  LV = "LV", // Latvia
  LT = "LT", // Lithuania
  LU = "LU", // Luxembourg

  MT = "MT", // Malta
  MX = "MX", // Mexico

  NL = "NL", // Netherlands
  NZ = "NZ", // New Zealand
  NO = "NO", // Norway

  PY = "PY", // Paraguay
  PL = "PL", // Poland
  PT = "PT", // Portugal

  RO = "RO", // Romania

  SG = "SG", // Singapore
  SK = "SK", // Slovakia
  SI = "SI", // Slovenia
  SE = "SE", // Sweden

  TH = "TH", // Thailand

  US = "US", // The United States

  ZA = "ZA", // South Africa
}
