import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "modules/user-subscriptions/domain/enums/query-key"
import { GetPendingInvoiceForSubscription } from "modules/user-subscriptions/usecases/get-pending-invoice-for-subscription"

export const useGetPendingInvoiceForSubscription = (id?: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getPendingInvoiceForSubscription, id],
    () => new GetPendingInvoiceForSubscription(container.get(HttpClient)).execute(id!),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
    },
  )
}
