import axios from "axios"
import { Document, Interface } from "@chatpay/common"
import { DB, Firebase } from "../Service"
import API from "./API"

export enum FetchWho {
  user = "to",
  owner = "from",
}

class Refund extends API implements Interface.Refund.Function.ITemplate {
  private db = new DB(Document.Refund.Document)

  public async request(data: Interface.Refund.Function.IRequest): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.requestBffWeb("request", "POST", data))?.data
  }
  public async accept(data: Interface.Refund.Function.IAccept): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.requestBffWeb(`${data.id}/accept`, "PATCH"))?.data
  }
  public async reject(data: Interface.Refund.Function.IReject): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.requestBffWeb(`${data.id}/reject`, "PATCH"))?.data
  }
  public async cancel(data: Interface.Refund.Function.ICancel): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.requestBffWeb(`${data.id}/cancel`, "PATCH"))?.data
  }
  public async reactivate(
    data: Interface.Refund.Function.IReactivate,
  ): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.requestBffWeb(`${data.id}/reactivate`, "PATCH"))?.data
  }

  private async requestBffWeb(path: string, method: "POST" | "PATCH", params?: Interface.Refund.Function.Params) {
    return await axios.request({
      url: `${process.env.REACT_APP_HUB_API}/refunds/${path}`,
      method,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${Firebase.accessToken}` },
      data: params,
    })
  }

  // QUERIES
  public async fetch(who: FetchWho): Promise<Document.Refund.Document[]> {
    const currentUserId = API.currentRoleplayUserId ?? API.currentUser?.id
    if (!currentUserId) {
      return Promise.resolve([])
    }

    try {
      return await this.db.get({
        where: [
          { field: `${who}.id`, op: "==", value: currentUserId },
          { field: "isDeleted", op: "==", value: false },
        ],
      })
    } catch (e) {
      console.error(e)
      return Promise.resolve([])
    }
  }

  public async retrieve(id: string): Promise<Document.Refund.Document | null> {
    try {
      return await this.db.getById(id)
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export default Refund
