import React from "react"
import { Segment } from "semantic-ui-react"
import "./DotsLoading.scss"

export const DotsLoading: React.FunctionComponent = () => {
  return (
    <Segment basic={true} data-testid="dots-loading">
      <div className="dot-wrapper">
        <div className="dot-pulse" />
      </div>
    </Segment>
  )
}
