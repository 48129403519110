import React, { useEffect, useRef } from "react"

import "./InputTitle.scss"

interface IProps {
  placeholder: string
  value?: string
  disabled?: boolean
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
}

const InputTitle: React.FunctionComponent<IProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  const { placeholder, value, onChange, disabled } = props

  return (
    <div className="cp text bold huge black compact">
      <input
        disabled={disabled}
        ref={inputRef as React.RefObject<HTMLInputElement>}
        style={{
          fontSize: "2.25rem",
          lineHeight: "1.5em",
          color: "#373737",
          letterSpacing: "-0.75px",
          fontWeight: "bold",
          border: "none",
          padding: 0,
        }}
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          if (onChange) {
            onChange(event)
          }
        }}
      />
    </div>
  )
}

export { InputTitle }
