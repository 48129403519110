import API from "./API"
import * as UpChunk from "@mux/upchunk"
import { Document, Interface } from "@chatpay/common"

class Video extends API implements Interface.Video.Function.ITemplate {
  public upChunk: UpChunk.UpChunk | undefined

  private async call(func: Interface.Video.Function.Name, params?: Interface.Video.Function.Params) {
    return await this.callFunction(`video/${func}`, params)
  }

  public async createUpload(): Promise<Document.VideoUpload> {
    return (await this.call(Interface.Video.Function.Name.createUpload)).data
  }

  public async getUpload(params: Interface.Video.Function.IGetUpload): Promise<Document.VideoUpload> {
    return (await this.call(Interface.Video.Function.Name.getUpload, params)).data
  }

  public async getAsset(params: Interface.Video.Function.IGetAsset): Promise<Document.VideoAsset> {
    return (await this.call(Interface.Video.Function.Name.getAsset, params)).data
  }

  public async getLatestAssetStateFromUpload(
    params: Interface.Video.Function.IGetLatestAssetStateFromUpload,
  ): Promise<Document.VideoAsset | null> {
    const result = (await this.call(Interface.Video.Function.Name.getLatestAssetStateFromUpload, params)).data
    return result ? result : null
  }

  public async upload(
    file: File,
    onStart: (upload: Document.VideoUpload) => any,
    onProgress: (number: number) => any,
    onError: (error: Error) => any,
    onFinish: () => any,
  ): Promise<any> {
    try {
      const uploadChunk = UpChunk.createUpload({
        endpoint: async () => {
          const upload = await this.createUpload()
          onStart(upload)
          return upload.url!
        },
        file,
      })

      uploadChunk.on("error", (err) => onError(new Error(err.detail)))
      uploadChunk.on("progress", (progress) => onProgress(Number(progress.detail)))
      uploadChunk.on("success", () => onFinish())

      this.upChunk = uploadChunk
    } catch (error: any) {
      onError(error)
    }
  }

  public async updateAssetMP4Support(params: Interface.Video.Function.IGetAsset): Promise<Document.VideoAsset> {
    return (await this.call(Interface.Video.Function.Name.updateAssetMP4Support, params)).data
  }
}

export default Video
