"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "sellableItemFixture", {
  enumerable: true,
  get: function get() {
    return _ISellableItem.sellableItemFixture;
  }
});

var _ISellableItem = require("./ISellableItem");