import * as React from "react"
import { Document } from "@chatpay/common"
import { API } from "@chatpay/components"
import ItemInfo from "components/ItemInfo"
import { Container, Icon } from "semantic-ui-react"
import moment from "moment"
import { SortableHandle } from "react-sortable-hoc"

import "./GroupSortItem.scss"

interface IProps {
  user?: Document.User
  group?: Document.Group
  groupId?: string
  isLoading?: boolean
}

interface IState {
  group?: Document.Group | null
  isLoading: boolean
  error?: Error | null
}

const DragHandle = SortableHandle(() => (
  <div style={{ position: "relative" }}>
    <Icon name="bars" className="absolute" />
  </div>
))

export class GroupSortItem extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    group: this.props.group,
    isLoading: true,
    error: undefined,
  }

  private api = new API.Group()

  public componentDidMount() {
    this.loadGroup()
  }

  public shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
    const shouldUpdate =
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      JSON.stringify(this.props) !== JSON.stringify(nextProps)

    if (nextProps.group && !this.props.group) {
      this.setState({ group: this.props.group }, () => {
        this.loadGroup()
      })
    }
    return shouldUpdate
  }

  private async loadGroup() {
    try {
      if (this.props.group) {
        this.setState({
          error: undefined,
          group: this.props.group,
          isLoading: false,
        })
        return
      }

      if (!this.props.groupId && this.state.isLoading) {
        return
      }

      const group = await this.api.fetch(this.props.groupId!, ["owner"])
      if (!group) {
        this.setState({
          group,
          isLoading: false,
        })
        return
      }

      this.setState({
        group,
        isLoading: false,
      })
    } catch (e: any) {
      console.error(e)
      this.setState({
        error: e,
        isLoading: false,
      })
    }
  }

  public render() {
    const { group, isLoading } = this.state
    return (
      <Container className="GroupSortItem">
        <ItemInfo
          isLoading={isLoading}
          picture={group?.picture}
          name={group?.name}
          size="medium"
          detail={`Criado: ${String(moment(group?.createdAt?.toDate()).format("ll"))}`}
          rightDetail={<DragHandle />}
        />
      </Container>
    )
  }
}
