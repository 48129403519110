"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["fetchGroupPageProps"] = "fetchGroupPageProps";
  Name["fetchProductPageProps"] = "fetchProductPageProps";
  Name["fetchUserPageProps"] = "fetchUserPageProps";
  Name["fetchReferralUserPageProps"] = "fetchReferralUserPageProps";
  Name["fetchLastActivatedGroups"] = "fetchLastActivatedGroups";
  Name["fetchGroupReviews"] = "fetchGroupReviews";
  Name["fetchCurrentUser"] = "fetchCurrentUser";
})(Name || (exports.Name = Name = {}));