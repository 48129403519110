import { Interface, Document } from "@chatpay/common"
import { DB } from "../Service"
import API from "./API"

export interface ICreditCard extends Interface.Gateway.Common.ICreditCard {
  gateway: Document.GatewayCarrier
}

export interface IValidation extends Interface.Gateway.Common.Validation.IValidation {
  gateway: Document.GatewayCarrier
}

class UserGateway extends API implements Interface.Gateway.Function.ITemplate {
  public async accountRequestVerification(
    data: Interface.Gateway.Function.IAccountRequestVerification,
  ): Promise<Interface.Gateway.Common.IAccount | void> {
    return (await this.call(Interface.Gateway.Function.Name.accountRequestVerification, data))?.data
  }
  public async updateBankAccount(
    data: Interface.Gateway.Function.IUpdateBankAccount,
  ): Promise<Interface.Gateway.Common.BankAccount.IBankAccount> {
    return (await this.call(Interface.Gateway.Function.Name.updateBankAccount, data))?.data
  }
  public async updateCreditCard(
    data: Interface.Gateway.Function.IUpdateCreditCard,
  ): Promise<Interface.Gateway.Common.CreditCard.ICreditCard> {
    return (await this.call(Interface.Gateway.Function.Name.updateCreditCard, data))?.data
  }
  public async retrieveBankAccount({
    gateway,
  }: Interface.Gateway.Function.IRetrieveBankAccount = {}): Promise<Interface.Gateway.Common.BankAccount.IBankAccount | null> {
    try {
      return (await this.call(Interface.Gateway.Function.Name.retrieveBankAccount, { gateway }))
        ?.data as Interface.Gateway.Common.BankAccount.IBankAccount
    } catch {
      return null
    }
  }
  public async listCreditCards({ gateway }: Interface.Gateway.Function.IListCreditCards = {}): Promise<ICreditCard[]> {
    return (await this.call(Interface.Gateway.Function.Name.listCreditCards, { gateway }))?.data
  }
  public async accountValidations({ gateway }: Interface.Gateway.Function.IAccountValidations = {}): Promise<
    IValidation[] | null
  > {
    return (await this.call(Interface.Gateway.Function.Name.accountValidations, { gateway }))?.data
  }
  public async accountInfo({
    gateway,
  }: Interface.Gateway.Function.IAccountInfo = {}): Promise<Interface.Gateway.Function.IAccountInfoResponse | null> {
    return (await this.call(Interface.Gateway.Function.Name.accountInfo, { gateway }))?.data
  }

  private async call(func: Interface.Gateway.Function.Name, params?: Interface.Gateway.Function.Params) {
    return await this.callFunction(`userGateway/${func}`, params)
  }
}

export default UserGateway
