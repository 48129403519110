"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionToIItemFixture = exports["default"] = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _ISellableItem = require("../../Interfaces/__fixtures__/ISellableItem");

var _Models = require("../../Models");

var _index = require("../index");

var _Transaction = _interopRequireWildcard(require("../Transaction"));

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var transactionFixture = function transactionFixture(args) {
  var _args$status, _args$paymentMethod, _args$lr, _args$installments, _args$item, _coupon$discount, _statusAt;

  var isSubscription = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : faker.datatype["boolean"]();
  var isMembershipFee = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : faker.datatype["boolean"]();
  var status = (_args$status = args === null || args === void 0 ? void 0 : args.status) !== null && _args$status !== void 0 ? _args$status : (0, _utils.randomEnumValue)(_index.TransactionStatus);
  var isPaid = status === _index.TransactionStatus.paid;
  var paidAt = isPaid ? _utils.FirebaseInjections.timestampFromDate(faker.date.recent()) : undefined;
  var paymentMethod = (_args$paymentMethod = args === null || args === void 0 ? void 0 : args.paymentMethod) !== null && _args$paymentMethod !== void 0 ? _args$paymentMethod : (0, _utils.randomEnumValue)(_index.PaymentMethod);
  var isCreditCard = paymentMethod === _index.PaymentMethod.creditCard;
  var lr = ((_args$lr = args === null || args === void 0 ? void 0 : args.lr) !== null && _args$lr !== void 0 ? _args$lr : isCreditCard && status === _index.TransactionStatus.canceled) ? faker.random.arrayElement(["51", "DM"]) : null;
  var amount = faker.datatype.number();
  var coupon = (args === null || args === void 0 ? void 0 : args.coupon) === undefined ? {
    code: faker.random.word().toUpperCase(),
    discount: faker.datatype.number(89),
    id: (0, _utils.firebaseIdFixture)(),
    isRecurrent: faker.datatype["boolean"]()
  } : args === null || args === void 0 ? void 0 : args.coupon;
  var installments = (_args$installments = args === null || args === void 0 ? void 0 : args.installments) !== null && _args$installments !== void 0 ? _args$installments : faker.datatype.number({
    min: 1,
    max: 12
  });
  var item = (_args$item = args === null || args === void 0 ? void 0 : args.item) !== null && _args$item !== void 0 ? _args$item : (0, _ISellableItem.sellableItemFixture)({}, isMembershipFee, isSubscription);
  return new _Transaction["default"](_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    invoiceId: (0, _utils.iuguIdFixture)(),
    currency: _Models.Currency.brl,
    isRenewing: faker.datatype["boolean"](),
    lr: lr,
    amount: amount,
    paymentMethod: paymentMethod,
    identification: !isCreditCard ? faker.random.alphaNumeric(40) : undefined,
    accountId: (0, _utils.iuguIdFixture)(),
    createdById: (0, _utils.firebaseIdFixture)(),
    customerId: (0, _utils.iuguIdFixture)(),
    // Fixture financial data not guaranteed to be correct
    financial: {
      amount: amount,
      discounts: amount * ((_coupon$discount = coupon === null || coupon === void 0 ? void 0 : coupon.discount) !== null && _coupon$discount !== void 0 ? _coupon$discount : 0) / 10,
      total: amount,
      net: faker.datatype.number(amount),
      share: faker.datatype.number(amount),
      advanced: faker.datatype.number(amount),
      paid: isPaid ? amount : 0,
      commission: faker.datatype.number(amount)
    },
    installments: installments,
    installment: faker.datatype.number({
      min: 1,
      max: installments
    }),
    item: item,
    paidAt: paidAt,
    url: faker.internet.url(),
    sellerId: (0, _utils.firebaseIdFixture)(),
    statusAt: (_statusAt = {}, _defineProperty(_statusAt, status, faker.date.past()), _defineProperty(_statusAt, "paid", paidAt), _statusAt),
    type: _Transaction.Type.debit,
    userId: (0, _utils.firebaseIdFixture)()
  }, args));
};

var transactionToIItemFixture = function transactionToIItemFixture(transaction) {
  var _transaction$identifi;

  return {
    id: transaction.invoiceId,
    expiresAt: transaction.expiresAt,
    identification: (_transaction$identifi = transaction.identification) !== null && _transaction$identifi !== void 0 ? _transaction$identifi : null,
    currency: transaction.currency,
    gateway: transaction.gateway,
    isRenewing: transaction.isRenewing,
    paymentMethod: transaction.paymentMethod,
    status: transaction.status,
    total: transaction.total,
    isMembershipFee: !!transaction.item.membershipFee
  };
};

exports.transactionToIItemFixture = transactionToIItemFixture;
var _default = transactionFixture;
exports["default"] = _default;