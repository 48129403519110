"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ITaxInvoiceGroupEvent = require("./ITaxInvoiceGroupEvent");

Object.keys(_ITaxInvoiceGroupEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITaxInvoiceGroupEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITaxInvoiceGroupEvent[key];
    }
  });
});

var _ITaxInvoiceUserEvent = require("./ITaxInvoiceUserEvent");

Object.keys(_ITaxInvoiceUserEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITaxInvoiceUserEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITaxInvoiceUserEvent[key];
    }
  });
});

var _ITaxInvoiceTransactionCancelEvent = require("./ITaxInvoiceTransactionCancelEvent");

Object.keys(_ITaxInvoiceTransactionCancelEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITaxInvoiceTransactionCancelEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITaxInvoiceTransactionCancelEvent[key];
    }
  });
});

var _ITaxInvoiceTransactionEvent = require("./ITaxInvoiceTransactionEvent");

Object.keys(_ITaxInvoiceTransactionEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITaxInvoiceTransactionEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITaxInvoiceTransactionEvent[key];
    }
  });
});

var _ITaxInvoiceWebhookEvent = require("./ITaxInvoiceWebhookEvent");

Object.keys(_ITaxInvoiceWebhookEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITaxInvoiceWebhookEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITaxInvoiceWebhookEvent[key];
    }
  });
});

var _ITaxInvoiceEvent = require("./ITaxInvoiceEvent");

Object.keys(_ITaxInvoiceEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITaxInvoiceEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITaxInvoiceEvent[key];
    }
  });
});