import * as React from "react"
import { Image } from "semantic-ui-react"

import "./Avatar.scss"

interface IProps {
  name: string
  picture?: string | null
  detail?: string | JSX.Element
  size?: "medium" | "small"
}

interface IState {
  isLoaded: boolean
  isError: boolean
}

class Avatar extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    isLoaded: false,
    isError: false,
  }

  public render() {
    const defaultImage = require("assets/images/default-avatar.svg")
    const { isLoaded, isError } = this.state
    const { size, name, detail, picture } = this.props

    return (
      <div className="Avatar">
        {!isLoaded && picture ? (
          <Image
            alt="Avatar"
            src={picture}
            hidden={true}
            onLoad={() => this.setState({ isLoaded: true })}
            onError={() => this.setState({ isError: true })}
          />
        ) : null}
        <Image
          alt="Avatar"
          wrapped={true}
          avatar={true}
          size={size}
          verticalAlign="middle"
          spaced="right"
          src={isLoaded && !isError && picture ? picture : defaultImage}
        />
        <div className="avatar-content">
          <div className="text">{name}</div>
          {detail ? <div className="text tiny">{detail}</div> : null}
        </div>
      </div>
    )
  }
}

export default Avatar
