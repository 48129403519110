import React, { CSSProperties } from "react"
import CloseIconSVG from "assets/icons/close-icon.svg"

export const CloseIcon: React.FC<{ style?: CSSProperties; onClick?: () => void; className?: string }> = ({
  style,
  onClick,
  className,
}) => {
  return (
    <img
      alt="close icon"
      className={"close-icon " + className ?? ""}
      style={{
        ...style,
        cursor: "pointer",
        width: "24px",
        height: "24px",
      }}
      src={CloseIconSVG}
      onClick={onClick}
    />
  )
}
