export const couponForm = {
  code: {
    title: "Código",
    placeholder: "Digite o código do seu cupom",
    more: "dígitos restantes",
    helpPopup: "",
    error:
      "Código inválido. Regras: Entre 6-12 caracteres. Não aceitamos acento. Não aceitamos caracteres especiais, a não ser (-)",
  },
  discount: {
    title: "Desconto (entre 0% e 80%)",
  },
  limit: {
    checkbox: "Limitar o número de cupons",
    quantity: "quantidade",
    helpPopup: {
      checked: "O cupom será automaticamente desabilitado após o número de usos determinado.",
      unchecked: "Um número ilimitado de clientes poderá usar este cupom.",
    },
  },
  expiration: {
    checkbox: "Configurar uma data de expiração",
    placeholder: "Selecione uma data",
    error: "Data de expiração inválida",
  },
  recurrence: {
    checkbox: "Recorrente para renovação assinaturas",
    helpPopup: {
      checked: "O desconto também será aplicado nas renovações da assinatura.",
      unchecked: "Será cobrado o preço integral na renovação da assinatura.",
    },
  },
  groups: {
    checkbox: "Permitir o cupom apenas para grupos específicos",
    placeholder: "Selecionar grupos",
    helpPopup: {
      checked: "O cupom só poderá ser usado na compra dos grupos selecionados",
      unchecked: "O cupom poderá ser usado na compra de qualquer um dos seus grupos",
      plans: " no plano selecionado.",
    },
    cohort: "Turma",
  },
  plan: {
    checkbox: "Permitir o cupom apenas em planos específicos",
    placeholder: "selecionar planos",
    title: "Em qual plano o cupom poderá ser usado?",
    helpPopup: {
      checked: "O cupom só poderá ser usado no plano selecionado",
      unchecked: "O cupom poderá ser usado para qualquer plano",
      groups: " dos grupos selecionados.",
    },
  },
  errorTitle: "Não foi possível salvar o cupom",
  button: {
    cancel: "Cancelar",
    generate: "Gerar cupom",
  },
}
