import { Box, Button, Stack, Text, shape, styled, withUtility } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import ArrowRight from "@mui/icons-material/ArrowRight"
import { Skeleton } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const Card = styled(Stack)`
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: ${shape("medium")};
  background-color: ${withUtility(({ palette }) => palette("surface"))};
  :hover {
    background-color: ${withUtility(({ palette }) => palette("surfaceVariant"))};
  }
`

export const Loading: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userPendencies.back")}
        </Button>
      </Stack>
      <Stack marginBottom={4}>
        <Text variant="h1">{t("userPendencies.title")}</Text>
      </Stack>

      <Stack direction="column" my={6} gap={4}>
        <Stack>
          <Text variant="body1">
            <Skeleton animation="wave" width={120} />
          </Text>
        </Stack>
        <Stack direction="column" gap={4}>
          <Card direction="row" alignItems="center" justifyContent="space-between" p={3}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Skeleton animation="wave" variant="rounded" width={20} height={20} />
              <Stack direction="column" gap={1}>
                <Text variant="body1">
                  <Skeleton animation="wave" width={140} />
                </Text>
                <Text variant="caption" color="onSurfaceVariant">
                  <Skeleton animation="wave" width={80} />
                </Text>
              </Stack>
            </Stack>
            <ArrowRight />
          </Card>
        </Stack>
      </Stack>
    </Box>
  )
}
