export const GetStarted = {
  Info: {
    title: "In which category does your content fit?",
    placeholder: "Choose the category that best fits you",
    bets: "Bets",
    crypto: "Crypto",
    education: "Professional Education",
    investments: "Investments and Trading",
    travel: "Miles and Trips",
    wellness: "Health and Fitness",
    other: "Other",

    /**@deprecated */
    languages: "Languages",
    literature: "Literature",
    entertainment: "Entertainment",
    personalGrowth: "Personal Growth",
    gastronomy: "Culinary and Gastronomy",
  },
  Profile: {
    title: "Create your profile",
    subtitle: "Your information will show up on your profile",
    label1: "Picture",
    label2: "Name",
    label3: "Lastname",
    label4: "Email",
    label5: "Write a bio for your profile",
    label6: "Create your profile access link",
    label8: "Share your social medias",
    warning: "💡 You can always change these informations later on",
    placeholder1: "e.g Oliver",
    placeholder2: "e.g Smith",
    placeholder3: "e.g oliver.smith@gmail.com",
    placeholder4: "e.g I teach people to play the guitar from beginner to pro.",
    placeholder5: "e.g oliversmith",
    placeholder6: "e.g vemdehubla",
    placeholder7: "e.g hubla",
    loader: "We are processing your information. Wait a few seconds.",
  },
  GroupCreation: {
    title: "Create your product at Hubla",
    subtitle: "Create a paid product and charge for your members",
    warning: "💡 You can change these informations later on, before publishing your product",
  },
  Skip: "Skip",
  Back: "Back",
  Next: "Next",
  Step: "Step",
  To: "of",
  errors: {
    required: "Required field",
    email: "Invalid email",
    minChar: "Min {{count}} characters",
    maxChar: "Max {{count}} characters",
    reservedPath: "This path is already used by Hubla.",
    social: "This is not a valid {{media}} username.",
    instagram: "No need to put @ before.",
  },
}
