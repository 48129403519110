import { Document } from "@chatpay/common"
import { AnalyticsEventParams, AnalyticsProviderType, IAnalyticsProvider, IAnalyticsTrackableItem } from "./Tracker"

export class ClarityProvider implements IAnalyticsProvider {
  identify = (user: Document.User) => {
    if (!user.id) {
      return
    }

    try {
      ;(window as any).clarity("identify", user.id)
    } catch (e) {
      console.error("Clarity is not setup up correctly")
    }
  }

  identifyFB(user: firebase.default.User, providers?: AnalyticsProviderType[]): void {}

  init(): void {}

  page(name: string, params?: AnalyticsEventParams, providers?: AnalyticsProviderType[]): void {}

  reset(providers?: AnalyticsProviderType[]): void {}

  track(
    event: string,
    params?: AnalyticsEventParams,
    entities?: IAnalyticsTrackableItem[],
    providers?: AnalyticsProviderType[],
  ): void {}

  type(): AnalyticsProviderType {
    return AnalyticsProviderType.CLARITY
  }
}
