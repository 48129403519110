"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReminderType = void 0;
var ReminderType;
exports.ReminderType = ReminderType;

(function (ReminderType) {
  ReminderType["requestChatVerification"] = "requestChatVerification";
})(ReminderType || (exports.ReminderType = ReminderType = {}));