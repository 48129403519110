import { Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import { routes } from "@chatpay/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Button, Form, Input } from "semantic-ui-react"
import { Headers } from "../Headers"
import { Basic, BasicProps } from "./Basic"

enum Step {
  intro = "intro",
  form = "form",
  done = "done",
  migrationPending = "migrationPending",
}

export const MigrationRequest: React.FunctionComponent<BasicProps> = (props) => {
  return (
    <Basic {...props}>
      <MigrationRequestForm />
    </Basic>
  )
}

const MigrationRequestForm: React.FunctionComponent = () => {
  const [state, setState] = useState<{ chatUrl?: string; step: Step }>({ step: Step.intro })
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()

  const history = useHistory()
  const { t } = useTranslation()

  const userApi = new API.User()
  const groupApi = new API.Group()

  useEffect(() => {
    const currentUserStep = Service.Firebase.currentUser?.steps?.gettingStarted

    if (currentUserStep === Document.UserGettingStartedStep.migrationPending) {
      setState({ step: Step.migrationPending })
    }
  }, [])

  const onNext = () => {
    setState({ step: Step.form })
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      await Promise.all([
        groupApi.sendMigrationRequestToSlack({ chatUrl: state.chatUrl?.trim()! }),
        userApi.setCurrentStep({
          gettingStarted: Document.UserGettingStartedStep.migrationPending,
        }),
      ])
      setState({ step: Step.done })
    } catch (e: any) {
      console.error(e)
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = () => {
    history.push(routes.USER_GROUPS.PATH)
  }

  const title = Headers.Basic(t(`migrationRequest.${state.step}.title`), t(`migrationRequest.${state.step}.subtitle`))

  const invalidLink =
    state.chatUrl !== undefined &&
    state.chatUrl !== "" &&
    !(state.chatUrl.includes("chat.whatsapp.com") || state.chatUrl.includes("t.me"))

  switch (state.step) {
    case Step.migrationPending:
      return (
        <>
          {title}
          <Spacer />
          <Button
            color="green"
            fluid={true}
            loading={loading}
            onClick={onFinish}
            content={t(`migrationRequest.${state.step}.button`)}
            disabled={loading}
          />
          <Spacer />
        </>
      )
    case Step.intro:
      return (
        <>
          {title}
          <Spacer />
          <Button
            color="green"
            fluid={true}
            loading={loading}
            onClick={onNext}
            content={t(`migrationRequest.${state.step}.button`)}
            disabled={loading}
          />
          <Spacer />
        </>
      )
    case Step.form:
      return (
        <>
          {title}
          <Form>
            <Fields.InputLabel title="" errorText={t(`migrationRequest.invalidLink`)}>
              <Input
                value={state.chatUrl}
                disabled={loading}
                onChange={(_, data) => setState({ ...state, chatUrl: data.value })}
                fluid={true}
                placeholder="e.g. https://chat.whatsapp.com/EZobip8Dfw3"
                error={invalidLink}
              />
            </Fields.InputLabel>
          </Form>
          <Spacer />
          <Button
            color="green"
            fluid={true}
            loading={loading}
            onClick={onSubmit}
            content={t(`migrationRequest.${state.step}.button`)}
            disabled={!state.chatUrl || invalidLink || loading}
          />
          <Spacer />
          {error ? <Service.ErrorMessage error={error} /> : null}
        </>
      )
    case Step.done:
      return (
        <>
          {title}
          <Spacer />
          <Button
            color="green"
            fluid={true}
            loading={loading}
            onClick={onFinish}
            content={t(`migrationRequest.${state.step}.button`)}
            disabled={loading}
          />
          <Spacer />
        </>
      )
    default:
      return <></>
  }
}
