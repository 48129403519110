import React from "react"
import { Stack, Tag, Text, palette, styled } from "@hub-la/design-system"
import avatar from "assets/images/placeholder-image.svg"
import { useIsMobile } from "hooks/use-is-mobile"
import { memo } from "react"
import { getGroupUrl } from "utils/SessionUtils"
import { textEllipsis } from "utils/textEllipsis"
import { useHistory } from "react-router-dom"
import { FormatDate } from "../../../../domain/vos/format-date"
import { CalendarToday } from "@mui/icons-material"

/** @todo Use the image optimization from hubla-nx */
const Avatar = styled("img")`
  width: 100%;
  height: auto;
  aspect-ratio: 218 / 142;
  border-radius: 8px;
  object-fit: cover;
  margin: auto;
  border: 1px solid ${palette("surfaceVariant")};
`

const Card = styled(Stack)`
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
  :hover {
    border-radius: 7.115px;
    background: ${palette("surfaceAt1")};

    /* Overlay (Small) */
    box-shadow: 0px 4px 8px 0px rgba(26, 28, 24, 0.16);
    transform: scale(1.2);
  }
`

export interface GroupProps {
  id: string
  name: string
  picture?: string
  type: "digital" | "event"
  eventData?: {
    startDate: string
    endDate: string
  }
}

export const Group = memo((data: GroupProps) => {
  const startDate = data?.eventData?.endDate ? new FormatDate(data?.eventData?.startDate).getValue() : null
  const endDate = data?.eventData?.endDate ? new FormatDate(data?.eventData?.endDate).getValue() : null

  const history = useHistory()
  const redirectToGroupFeed = () => {
    if (data.type === "event") {
      history.push(`/user_events/${data.id}`)
      return
    }

    window.location.assign(getGroupUrl(data.id))
  }

  const isMobile = useIsMobile()

  return (
    <Card direction="column" gap={2} p={1} onClick={() => redirectToGroupFeed()} textAlign="center" position="relative">
      {Boolean(startDate && endDate) && (
        <Tag
          size="tiny"
          icon={
            <CalendarToday
              style={{
                width: 14,
                height: 14,
              }}
              sx={{
                width: 14,
                height: 14,
                mr: 3,
              }}
            />
          }
          label={`${startDate} ${startDate === endDate ? "" : "- " + endDate}`}
          style={{
            position: "absolute",
            top: 10,
            left: 10,
          }}
          variant="neutral"
        />
      )}
      <Avatar src={data.picture ?? avatar} alt={data.name} />

      <Text variant="body2">{isMobile ? textEllipsis(data.name, 20) : data.name}</Text>
    </Card>
  )
})
