"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _ = require("../");

var _Member = require("../Member");

var _Subscription = require("../Subscription/Subscription");

var _Transaction = require("../Transaction");

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var memberFixture = function memberFixture(args) {
  var itemType = faker.random.arrayElement(["Transaction", "Subscription"]);
  var itemStatus = itemType === "Transaction" ? faker.random.objectElement(_Transaction.Status) : faker.random.objectElement(_Subscription.Status);
  return new _.Member(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    status: faker.random.objectElement(_Member.Status),
    // number Enums must be casted to "any" or "unknown" first
    statusAt: {
      off: _utils.FirebaseInjections.timestampFromDate(faker.date.past()),
      waiting: _utils.FirebaseInjections.timestampFromDate(faker.date.recent()),
      elegible: _utils.FirebaseInjections.timestampFromDate(new Date())
    },
    emailStatus: faker.random.objectElement(Object.values(_Member.EmailStatus)),
    isSuspended: faker.datatype["boolean"](),
    isExpired: faker.datatype["boolean"](),
    isParticipant: faker.datatype["boolean"](),
    isPersistent: faker.datatype["boolean"](),
    renewCount: faker.datatype.number(3),
    parentId: (0, _utils.firebaseIdFixture)(),
    items: _defineProperty({}, (0, _utils.firebaseIdFixture)(), {
      type: itemType,
      status: itemStatus,
      renewCount: itemType === "Transaction" ? faker.datatype.number(10) : undefined,
      expiresAt: _utils.FirebaseInjections.timestampFromDate(faker.date.soon()),
      createdAt: _utils.FirebaseInjections.timestampFromDate(faker.date.recent()),
      updatedAt: _utils.FirebaseInjections.timestampFromDate(faker.date.recent())
    }),
    action: faker.random.objectElement(_Member.Action),
    actor: faker.random.objectElement(_Member.Actor)
  }, args));
};

var _default = memberFixture;
exports["default"] = _default;