import React from "react"
import { Grid, Container } from "semantic-ui-react"
import { Service } from "@chatpay/components"

export interface ISectionProps {
  content: React.ReactElement
  form: React.ReactElement
  action?: React.ReactElement
  error?: Error
  advanced?: boolean
}

export const Section: React.FunctionComponent<ISectionProps> = (props) => {
  const { form, content, action, advanced, error } = props

  return (
    <React.Fragment>
      <Grid.Row
        columns={2}
        style={advanced ? { backgroundColor: "#F7F7F7", padding: "1.5em 0em", borderRadius: "4px" } : { padding: "0" }}
      >
        <Grid.Column>{content}</Grid.Column>
        <Grid.Column>{form}</Grid.Column>
      </Grid.Row>
      {error ? (
        <Container>
          <Service.ErrorMessage error={error} />
        </Container>
      ) : null}
      {action && <Grid.Row>{action}</Grid.Row>}
    </React.Fragment>
  )
}
