export const purchaseDetail = {
  afterFirstMonthWillCharge: "After the first month, you'll be charged",
  afterFirst: "After the first",
  afterTheFirsts: "After the firsts",
  willBeCharged: ", you'll be charged",
  daysWillBeCharged: "days, you'll be charged",
  theValueOf: "the value of",
  subscription: "Subscription:",
  membership: "1x Membership:",
  membershipFee: "Membership fee:",
  interest: "Interest:",
  discount: "Discount:",
  otherDiscount: "Others discounts:",
  total: "Total:",
  startsIn: "Starts in",
  plansAdditionalInformation:
    "* The amount previously paid was keeped due to the grace period, the amount for other users is",
  membershipFeeAdditionalInformation: "* There will be no charge due to the grace period",
  couponOnePlanInterval: "* Valid for 1 ",
  couponByRecurrent: "* Coupon recurrent",
}
