import { Document, Interface } from "@chatpay/common"
import { DB } from "../Service"
import API from "./API"

class GroupWhitelist extends API implements Interface.Group.Whitelist.Function.ITemplate {
  private db = new DB(Document.Group)

  public async remove(
    data: Interface.Group.Whitelist.Function.Params.Remove,
  ): Interface.Group.Whitelist.Function.Result.Remove {
    await this.call("remove", data)
  }

  public async generateLink(
    data: Interface.Group.Whitelist.Function.Params.GenerateLink,
  ): Interface.Group.Whitelist.Function.Result.GenerateLink {
    const response = await this.call("generateLink", data)
    return new Document.WhiteListRequest(response.data)
  }

  private async call(
    func: Interface.Group.Whitelist.Function.Name,
    params?: Interface.Group.Whitelist.Function.Params.All,
  ) {
    return await this.callFunction(`groupWhitelist/${func}`, params)
  }

  public async listMembersByGroupResourceId(
    data: Interface.Group.Whitelist.Function.Params.ListMembersByGroupResourceId,
  ): Interface.Group.Whitelist.Function.Result.ListMembersByGroupResourceId {
    return (await this.call("listMembersByGroupResourceId", data)).data
  }

  // QUERIES
  public async retrieve(groupId: string): Promise<Document.WhiteList[]> {
    return this.db.getSubCollection(Document.WhiteList, groupId, {
      where: [{ field: "isDeleted", op: "==", value: false }],
    })
  }
}

export default GroupWhitelist
