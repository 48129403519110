import { Document, Interface, BuyCta } from "@chatpay/common"
import { DB, Firebase } from "../Service"
import API from "./API"

class Group extends API implements Interface.Group.Function.ITemplate {
  private db = new DB(Document.Group)

  public async fetch(id: string, include: string[] = []) {
    const response = await this.get({ id })
    if (!response) {
      return null
    }

    const group = response.group as Document.Group
    const owner = response.owner as Document.User

    if (group && owner && include.includes("owner")) {
      group.owner = owner
    }

    return group?.isDeleted ? null : group
  }

  public async create(data: Interface.Group.Function.ICreate): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.create, data)
    return new Document.Group(response.data)
  }

  public async update(data: Interface.Group.Function.IUpdate): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.update, data)
    return new Document.Group(response.data)
  }

  public async get(data: Interface.Group.Function.IGet): Promise<Interface.Group.Function.IGetResponse> {
    const response = await this.call(Interface.Group.Function.Name.get, data)

    if (!response.data || !response.data.group) {
      return null
    }
    return {
      group: response.data.group,
      owner: response.data.owner,
      prices: response.data.prices,
    }
  }

  public async getGroupDetail(data: Interface.IIdentifier): Promise<Interface.Group.Function.DetailResponse> {
    const htmlData = this.getPrefetchedData(data.id)
    const response = htmlData ? { data: htmlData } : await this.call(Interface.Group.Function.Name.getGroupDetail, data)
    const group = response.data?.group ? new Document.Group(response.data.group) : null
    const resources = response.data?.resources ?? []
    const owner = response.data?.owner ? new Document.User(response.data.owner) : null
    const member = response.data?.member ? new Document.Member(response.data.member) : null
    const plans = (response.data?.plans ?? []).map((it: Partial<Document.Plan>) => new Document.Plan(it))
    const plansForThisUser = (response.data?.plansForThisUser ?? []).map(
      (it: Partial<Document.Plan>) => new Document.Plan(it),
    )
    const prices = response.data?.prices
    const pricesVersion = response.data?.pricesVersion

    return { group, resources, owner, member, plans, plansForThisUser, prices, pricesVersion }
  }

  private getPrefetchedData(groupId: string) {
    return Firebase.currentUser ? null : (window as any)?.htmlData?.[groupId]
  }

  public async setInfo(data: Interface.Group.Function.ISetInfo): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setInfo, data)
    return new Document.Group(response.data)
  }

  public async setResourceTypesIds(data: Interface.Group.Function.ISetResourceTypesIds): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setResourceTypesIds, data)
    return new Document.Group(response.data)
  }

  public async setDescription(data: Interface.Group.Function.ISetDescription): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setDescription, data)
    return new Document.Group(response.data)
  }

  public async setAboutDescription(data: Interface.Group.Function.ISetAboutDescription): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setAboutDescription, data)
    return new Document.Group(response.data)
  }

  public async setAboutAdvantages(data: Interface.Group.Function.ISetAboutAdvantages): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setAboutAdvantages, data)
    return new Document.Group(response.data)
  }

  public async setAboutFaq(data: Interface.Group.Function.ISetAboutFaq): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setAboutFaq, data)
    return new Document.Group(response.data)
  }

  public async setAboutAssets(data: Interface.Group.Function.ISetAboutAssets): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setAboutAssets, data)
    return new Document.Group(response.data)
  }

  public async setEntryFee(data: Interface.Group.Function.ISetEntryFee): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setEntryFee, data)
    return new Document.Group(response.data)
  }

  public async setLinkedGroups(data: Interface.Group.Function.ISetLinkedGroups): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setLinkedGroups, data)
    return new Document.Group(response.data)
  }

  public async getLinkedGroups(data: Interface.Group.Function.IGetLinkedGroups): Promise<string[]> {
    const response = await this.call(Interface.Group.Function.Name.getLinkedGroups, data)
    return response.data as string[]
  }

  public async setPixel(data: Interface.Group.Function.ISetPixel): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setPixel, data)
    return new Document.Group(response.data)
  }

  public async setAffiliate(data: Interface.Group.Function.ISetAffiliate): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setAffiliate, data)
    return new Document.Group(response.data)
  }

  public async setWelcome(data: Interface.Group.Function.ISetWelcome): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setWelcome, data)
    return new Document.Group(response.data)
  }

  public async sendMigrationRequestToSlack(data: Interface.Group.Function.ISendMigrationRequestToSlack): Promise<void> {
    await this.call(Interface.Group.Function.Name.sendMigrationRequestToSlack, data)
  }

  public async setTaxInvoiceChoice(data: Interface.Group.Function.ISetTaxInvoiceChoice): Promise<void> {
    await this.call(Interface.Group.Function.Name.setTaxInvoiceChoice, data)
  }

  public async getMemberships(
    data: Interface.Group.Function.IGetMemberships,
  ): Promise<Interface.Group.Function.Memberships> {
    const response = await this.call(Interface.Group.Function.Name.getMemberships, data)
    return response.data as Interface.Group.Function.Memberships
  }

  public async publish(data: Interface.Group.Function.IPublish): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.publish, data)
    return new Document.Group(response.data)
  }

  public async saveOrder(data: Interface.Group.Function.SaveOrder): Promise<boolean> {
    return (await this.call(Interface.Group.Function.Name.saveOrder, data)).data
  }

  public async setFlags(data: Interface.Group.Function.SetFlags): Promise<Document.Group> {
    const response = await this.call(Interface.Group.Function.Name.setFlags, data)
    return new Document.Group(response.data)
  }

  public async show(id: string): Promise<Document.Group> {
    return await this.setFlags({ id, isVisible: true })
  }

  public async hide(id: string): Promise<Document.Group> {
    return await this.setFlags({ id, isVisible: false })
  }

  public async delete(id: string): Promise<Document.Group> {
    return await this.setFlags({ id, isDeleted: true })
  }

  public async stopSelling(id: string): Promise<Document.Group> {
    return await this.setFlags({ id, isSelling: false })
  }

  public async startSelling(id: string): Promise<Document.Group> {
    return await this.setFlags({ id, isSelling: true })
  }

  public async verifyDomain(data: Interface.Group.Function.IVerifyDomain): Promise<boolean> {
    return (await this.call(Interface.Group.Function.Name.verifyDomain, data)).data
  }

  public async changeStatus(data: Interface.Group.Function.IChangeStatus): Promise<Document.Group> {
    return (await this.call(Interface.Group.Function.Name.changeStatus, data)).data
  }

  public async getGroupResourceAndResource(
    data: Interface.Group.Function.IGetGroupResourceAndResource,
  ): Promise<Interface.Group.Function.GroupResourceAndResource[]> {
    const response = await this.call(Interface.Group.Function.Name.getGroupResourceAndResource, data)
    return response.data
  }

  public async getShortLinks(data: Interface.Group.Function.IGetShortLinks): Promise<Document.ShortLink[]> {
    return (await this.call(Interface.Group.Function.Name.getShortLinks, data)).data
  }

  public async createShortLink(data: Interface.Group.Function.ICreateShortLink): Promise<Document.ShortLink> {
    return (await this.call(Interface.Group.Function.Name.createShortLink, data)).data
  }

  public async updateShortLink(data: Interface.Group.Function.IUpdateShortLink): Promise<Document.ShortLink> {
    return (await this.call(Interface.Group.Function.Name.updateShortLink, data)).data
  }

  public async deleteShortLink(data: Interface.Group.Function.IDeleteShortLink): Promise<Document.ShortLink> {
    return (await this.call(Interface.Group.Function.Name.deleteShortLink, data)).data
  }

  public async setCheckoutSettings(data: Interface.Group.Function.ISetCheckoutSettings): Promise<Document.Group> {
    return (await this.call(Interface.Group.Function.Name.setCheckoutSettings, data)).data
  }

  public async setSmartInstallmentsSettings(
    data: Interface.Group.Function.ISetSmartInstallmentsSettings,
  ): Promise<Document.Group> {
    return (await this.call(Interface.Group.Function.Name.setSmartInstallmentsSettings, data)).data
  }

  private async call(func: Interface.Group.Function.Name, params?: Interface.Group.Function.Params) {
    return await this.callFunction(`group/${func}`, params)
  }

  // QUERIES
  public async fetchPlan(planId: string, groupId: string): Promise<Document.Plan | null> {
    return this.db.getSubCollectionById(Document.Plan, planId, groupId)
  }

  public async plans(groupId: string): Promise<Document.Plan[]> {
    return this.db.getSubCollection(Document.Plan, groupId, {
      where: [
        { field: "isDeleted", op: "==", value: false },
        { field: "isEnabled", op: "==", value: true },
      ],
    })
  }

  public async myGroups(): Promise<Document.Group[] | null> {
    if (!API.currentUser?.id) {
      return null
    }
    const response = await this.call(Interface.Group.Function.Name.myGroups)
    return response.data?.map((it: any) => new Document.Group(it) ?? null)
  }

  public async myGroupsAsOwnerOrCocreator(): Promise<Document.Group[] | null> {
    if (!API.currentUser?.id) {
      return null
    }
    const response = await this.call(Interface.Group.Function.Name.myGroupsAsOwnerOrCocreator)
    return response.data?.map((it: any) => new Document.Group(it) ?? null)
  }

  public async myPurchasedGroups(): Promise<Document.Group[] | null> {
    if (!API.currentUser?.id) {
      return null
    }

    return (await this.call(Interface.Group.Function.Name.myPurchasedGroups)).data
  }

  public async getById(id: string): Promise<Document.Group | null> {
    if (!API.currentUser?.id) {
      return null
    }

    return await this.db.getById(id)
  }

  public async getByPlan(planId: string): Promise<Document.Group | null> {
    if (!API.currentUser?.id) {
      return null
    }

    return await this.db.getParentSubCollectionById(Document.Plan, planId)
  }

  public async myVisiblyPublishedGroups(): Promise<Document.Group[] | null> {
    if (!API.currentUser?.id) {
      return null
    }
    return this.db.get({
      where: [
        { field: "owner", op: "==", value: API.currentUser.id },
        { field: "isEnabled", op: "==", value: true },
        { field: "isVisible", op: "==", value: true },
        { field: "isDeleted", op: "==", value: false },
        { field: "isDraft", op: "==", value: false },
      ],
      order: [
        { by: "order", direction: "asc" },
        { by: "createdAt", direction: "desc" },
      ],
    })
  }

  public async userAllGroups(): Promise<Document.Group[] | null> {
    const currentUserId = API.currentRoleplayUserId ?? API.currentUser?.id

    if (!currentUserId) {
      return null
    }

    return this.db.get({
      where: [
        { field: "owner", op: "==", value: currentUserId },
        { field: "isEnabled", op: "==", value: true },
        { field: "isDeleted", op: "==", value: false },
        { field: "isDraft", op: "==", value: false },
      ],
      order: [
        { by: "order", direction: "asc" },
        { by: "createdAt", direction: "desc" },
      ],
    })
  }

  public async getCheckLog(groupId: string): Promise<Document.CheckLog[]> {
    const result = await this.db.getSubCollection(Document.CheckLog, groupId, {
      where: [
        { field: "owner", op: "==", value: API.currentUser?.id },
        { field: "skippedActions", op: "==", value: false },
      ],
    })

    return result.filter((it) => it.removedUsers.length > 0)
  }

  public async setBuyCta(groupId: string, buyCta: BuyCta): Promise<Document.Group> {
    const result = await this.call(Interface.Group.Function.Name.setBuyCta, {
      groupId,
      buyCta,
    })
    const group = result.data as Document.Group

    return group
  }
}

export default Group
