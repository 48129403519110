import React from "react"
import { Stack } from "@hub-la/design-system"
import { SelectOffers } from "../../../components/select-offers"

export const Filters = ({ setFilters, filters, offers }) => {
  return (
    <Stack
      flexDirection={["column", "row"]}
      gap={[3, 2]}
      justifyContent="flex-start"
      alignItems={["flex-start", "center"]}
    >
      <Stack width="100%" maxWidth={["100%", 170]}>
        <SelectOffers offers={offers} filters={filters} setFilters={setFilters} />
      </Stack>
    </Stack>
  )
}
