export enum QueryKey {
  getSubscription = "getSubscription",
  getSubscriptionValue = "getSubscriptionValue",
  getSubscriptionInvoices = "getSubscriptionInvoices",
  getPendingInvoiceForSubscription = "getPendingInvoiceForSubscription",
  listMemberSubscriptions = "listMemberSubscriptions",
  initUpgradePlan = "initUpgradePlan",
  submitUpgradePlan = "submitUpgradePlan",
  cancelUpgradePlan = "cancelUpgradePlan",
  initChangePaymentMethod = "initChangePaymentMethod",
  submitChangePaymentMethod = "submitChangePaymentMethod",
  getUpgradeState = "getUpgradeState",
  enableSubscriptionAutorenew = "enableSubscriptionAutorenew",
  disableSubscriptionAutorenew = "disableSubscriptionAutorenew",
}
