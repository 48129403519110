export const refundPage = {
  title: "Refund requests",
  subtitle: "Enter the information below to request a refund for a purchase",
  policy1: "First, read our",
  policy2: "chargeback policy",
  product: "Product",
  cohort: "Cohort",
  chooseProduct: "Choose a product",
  transaction: "Transaction",
  chooseTransaction: "Choose the invoice you want to refund",
  transactionDisclaimer:
    "Important: Renewals do not fall within the 7-day withdrawal period. Thus, the administrator has the right to refuse your order.",
  noTransactions: "You have no refundable transactions for this product.",
  reasonTitle: "Refund request",
  reason: "Explain the reason for the chargeback. The more details, the more likely your chargeback will be granted.",
  button: "Make a chargeback request",
  refundDisclaimer1: "The administrator has up to",
  refundDisclaimerBold: "14 business days",
  refundDisclaimer2: "to give you an answer. Wait for an email with the approval or rejection of your order.",
  email: "Email",
  purchase: "Purchase",
  request: "Order",
  cancel: "Cancel",
  reactivate: "Reactivate",
  autoWithdraw: "Withdraw my refund automatically.",
  //TODO: handle hardcoded currency
  autoWithdrawDisclaimer: "* A fee of R$ 3,00 will be charged",
}
