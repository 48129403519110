import * as React from "react"
import { Popup, Icon } from "semantic-ui-react"

interface IProps {
  text: string
  style?: object | null
  children?: React.ReactElement
}

class HelpPopup extends React.Component<IProps> {
  public render() {
    if (!this.props.text || this.props.text === "") {
      return <>{this.props.children}</>
    }
    return (
      <Popup
        trigger={
          <Icon
            name="question circle outline"
            color="green"
            style={this.props.style ? this.props.style : { marginLeft: "0.5em", position: "absolute" }}
          />
        }
        content={<div className="cp text compact black">{this.props.text}</div>}
        on="hover"
        basic={true}
        style={{ backgroundColor: "white" }}
      />
    )
  }
}

export default HelpPopup
