import axios from "axios"
import { Firebase } from "../Service"
import API from "./API"
import { Interface } from "@chatpay/common"

export default class Invoice extends API implements Interface.Invoice.Function.ITemplate {
  private getUrl(type: Interface.Invoice.Function.RequesterType = Interface.Invoice.Function.RequesterType.creator) {
    const prefix = process.env.REACT_APP_HUB_API
    return `${prefix}/dashboard/${type}`
  }

  public async getDetail(
    data: Interface.Invoice.Function.GetInvoiceDetailRequestDto,
    type: Interface.Invoice.Function.RequesterType = Interface.Invoice.Function.RequesterType.creator,
  ): Promise<
    | Interface.Invoice.Function.GetInvoiceDetailResponseDto
    | Interface.Invoice.Function.GetAffiliatedInvoiceDetailsResponseDto
  > {
    const { invoiceId } = data

    const response = await axios.request({
      url: `${this.getUrl(type)}/invoices/${invoiceId}`,
      headers: this.getHeader(),
    })
    const responseData:
      | Interface.Invoice.Function.GetInvoiceDetailResponseDto
      | Interface.Invoice.Function.GetAffiliatedInvoiceDetailsResponseDto = response.data
    return responseData
  }

  public async list(
    data: Interface.Invoice.Function.ListInvoicesRequestDto,
    type: Interface.Invoice.Function.RequesterType = Interface.Invoice.Function.RequesterType.creator,
  ): Promise<Interface.Invoice.Function.ListCreatorInvoicesResponseDto> {
    const response = await axios.request({
      url: `${this.getUrl(type)}/invoices`,
      params: data,
      headers: this.getHeader(),
    })

    let responseData: Interface.Invoice.Function.ListCreatorInvoicesResponseDto = response.data
    return responseData
  }

  public async getChart(
    data: Interface.Invoice.Function.GetChartRequestDto,
    type: Interface.Invoice.Function.RequesterType = Interface.Invoice.Function.RequesterType.creator,
  ): Promise<Interface.Invoice.Function.GetChartResponseDto> {
    const response = await axios.request({
      url: `${this.getUrl(type)}/chart`,
      params: data,
      headers: this.getHeader(),
    })
    const responseData: Interface.Invoice.Function.GetChartResponseDto = response.data
    return responseData
  }

  public async export(
    data: Interface.Invoice.Function.CreatorExportInvoicesRequestDto,
    type: Interface.Invoice.Function.RequesterType = Interface.Invoice.Function.RequesterType.creator,
  ): Promise<Buffer> {
    const response = await axios.request({
      url: `${this.getUrl(type)}/invoices/export`,
      responseType: "arraybuffer",
      params: data,
      headers: this.getHeader(),
    })
    const responseData: Buffer = Buffer.from(response.data, "binary")
    return responseData
  }

  private getHeader() {
    const AuthToken = Firebase.accessToken
    return { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` }
  }
}
