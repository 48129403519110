import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { Envs } from "../envs"
import { SubmitChangePaymentMethodInput } from "../domain/dtos/submit-change-payment-method-input"
import { Values } from "../presentation/pages/user-subscription-detail/components/change-payment-method-modal"
import merge from "lodash/merge"
import { UpgradePaymentMethods } from "../domain/dtos/payment-method"
import { GenerateCardsOrTokens } from "@hub-la/fe-tokenizer"

export class SubmitChangePaymentMethod {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: Values): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/pay/submit-change-payment-method`,
      body: this.makeBody(input),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return
    }

    throw new Error(response.data?.code ?? "GENERAL")
  }

  private makeBody(input: Values): SubmitChangePaymentMethodInput {
    const body = {
      subscriptionId: input.subscriptionId,
      sessionId: input.sessionId,
      customerId: input.customerId,
      gateway: input.gateway,
      paymentMethod: {
        cardDetails: input.creditCard.cardDetails,
        type: input.paymentMethod,
        installments: input.creditCard.installments,
      },
    }

    if ([UpgradePaymentMethods.PAYMENT_METHOD_PIX].includes(input.paymentMethod)) {
      return body
    }

    return merge(body, {
      paymentMethod: {
        cardsOrTokens: new GenerateCardsOrTokens(input.gateway).execute(input.creditCard.token, input.customerId),
      },
    })
  }
}
