import { Box, Button, Card, Divider, Grid, Stack, Text, useSnackbar, useUtility } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { useGetPayerProduct } from "../../hooks/use-get-payer-product"
import { FormatDate } from "../../../domain/vos/format-date"
import DefaultGroupImage from "../../../../../assets/images/default-group-avatar.png"
import "moment/locale/pt-br"
import { Ticket, TicketForm } from "@hub-la/fe-event"
import { FormatAddress } from "modules/user-events/domain/vos/format-address"
import { PayerTicket } from "modules/user-events/domain/dtos/group"
import { Loading } from "./loading"

const EventDetails: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { addSnackbar } = useSnackbar()
  const { productId } = useParams<{ productId: string }>()
  const { data, isLoading, isFetched } = useGetPayerProduct({
    productId,
    onError: (e: any) => {
      addSnackbar({
        message: t("userEvents.error", {
          code: e?.code,
        }),
        variant: "negative",
        onClose: () => history.push("/user_groups"),
        autoHideDuration: null,
      })
    },
  })

  const eventData = data?.payerTickets && data?.payerTickets[0] ? data?.payerTickets[0].eventData : null

  const startDate = eventData?.endDate ? new FormatDate(eventData?.startDate).getValue() : null
  const startHours =
    eventData?.endDate && eventData?.endDate[0] ? new FormatDate(eventData?.startDate).getHours() : null
  const endDate = eventData?.endDate ? new FormatDate(eventData?.endDate).getValue() : null
  const endHours = eventData?.endDate ? new FormatDate(eventData?.endDate).getHours() : null
  const isSameDate = startDate === endDate

  if (isLoading) {
    return <Loading />
  }

  // Redirect to members area if the product is not an event
  if ((data?.type && data?.type !== "event") || (!data && isFetched)) {
    return <Redirect to={`/g/${productId}`} />
  }

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userEvents.back")}
        </Button>
      </Stack>
      <Stack direction="column" gap={4}>
        <Card variant="filled" hasHover={false} fullHeight>
          <>
            <Stack direction="row" alignItems="center" gap={2}>
              <img
                src={data?.pictureUrl ?? DefaultGroupImage}
                alt="Event"
                width={87}
                height={58}
                style={{
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
              <Stack direction="column">
                <Text variant="h4">{data?.name}</Text>
                <Text variant="body2" color="onSurfaceVariant">
                  {data?.id}
                </Text>
              </Stack>
            </Stack>
            <Divider my={4} />
            <Grid container spacing={4}>
              <Grid item xs={6}>
                {startDate && endDate && (
                  <Stack direction="column">
                    <Text variant="body1">
                      {startDate}
                      {isSameDate ? "" : `– ${endDate}`}
                    </Text>
                    <Text variant="body2" color="onSurfaceVariant">
                      {startHours} – {endHours} GMT-3
                    </Text>
                  </Stack>
                )}
              </Grid>
              {eventData?.location && (
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Text variant="body1">{eventData?.location?.name}</Text>
                    <Text variant="body2" color="onSurfaceVariant">
                      {new FormatAddress(eventData?.location).format()}
                    </Text>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </>
        </Card>
        <Card variant="filled" hasHover={false} fullHeight>
          <Stack alignItems="center" gap={14}>
            {(data?.payerTickets ?? []).map((ticket: PayerTicket) => (
              <Ticket
                key={ticket.userDocument}
                eventName={ticket.eventName}
                name={ticket.ticketName}
                userName={ticket.userName}
                variant="big"
                bgColor={ticket.primaryColor}
                secondaryColor={ticket?.secondaryColor ?? "#0000001A"}
                startDate={new Date(ticket.eventData.startDate)}
                endDate={new Date(ticket.eventData.endDate)}
                eventLogo={data?.pictureUrl}
                textColor={ticket.textColor}
                location={ticket.eventData.location ? new FormatAddress(ticket.eventData.location).format() : undefined}
                code={ticket?.code}
              />
            ))}
          </Stack>
        </Card>
      </Stack>
    </Box>
  )
}

export default EventDetails
