export const groupDetail = {
  Available: "Available",
  CopyLink: "Copy Link",
  Share: "Share",
  Affiliate: "Affiliate",
  About: "About",
  Material: "Material",
  GroupAdmin: "Group Admin",
  ClickHere: "Click here",
  ToDownload: "to download",
  DownloadPermission: "You need to buy the group to access the material.",
  CancelSubscription: "Cancel Subscription",
  alreadySubscribed: "You are a subscriber",
  alreadyPurchased: "You already bought",
  copyCheckoutLink: "Copy checkout link",
  groupsCreated: "groups created",
  expiresIn: "Your subscription expires in ",
  days: "days.",
  seeBankSlip: "See Bankslip",
  subscriptionDetail: "Subscription details",
  expiredAt: "Your subscription has expired",
  expiredStatusSubtitle: "Renew your subscription to return to the group.",
  pendingStatusTitle: "Pay your bankslip to access the group",
  pendingStatusSubtitle: "Once your bankslip is accepted, the button below will be available to access the group.",
  pixPendingStatusTitle: "We haven't identified the payment yet",
  pixPendingStatusSubtitle: "If you have already made the payment, refresh the page. If not, make the payment",
  allowJoinStatusTitle: "You can now access the group, but remember to pay the bankslip to not be removed.",
  allowJoinStatusSubtitle:
    "If the bankslip is not paid today, it will not be accepted tomorrow and you will be removed from the group.",
  downloadBankSlip: "Download Bankslip",
  copyCode: "Copy Code",
  codeCopied: "Code copied!",
  copyPix: "Copy pix code",
  pixCopied: "Code copied, paste on your bank app!",
  pixli3: `3. After payment, click on the button "Reload page."`,
  seeAs: "Preview",
  client: "Client",
  admin: "Administrator",
  editGroup: "Edit Group",
  bankslipSent: "Bankslip sent to:",
  reloadPage: "Reload page",
  Feed: "Feed",
  ChatpaySection: {
    title: "Join a Hubla community",
    subtitle: "A new way to learn from experts",
    li1: {
      title: "Active, not passive learning",
      subtitle:
        "Unlike a simple online course, you will have direct contact with the creator! {{name}} will be there to create a community together with all the other participants.",
    },
    li2: {
      title: "Engagement with other members",
      subtitle:
        "The exclusive group you will have access to is an ideal environment to interact and learn from people who are participating along with you.",
    },
    li3: {
      title: "Interaction and direct feedback from the expert",
      subtitle:
        "Learning in community allows you to receive feedback directly from the creator, who in turn will be there to follow the results of all members.",
    },
  },
}
