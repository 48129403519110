export const landingPage = {
  LPHeaderContent: "Lucre criando grupos pagos de WhatsApp e Telegram.",
  LPHeaderSubcontent:
    "Transforme suas experiências e seu talento em um negócio online. Engaje sua audiência com conteúdos de qualidade através de grupos pagos exclusivos dentro dos principais aplicativos de mensagens.",
  StartNow: "Vamos começar!",
  LPHeaderButtonContact: "contatar vendas",
  TabTitle1: "Automação de Membros",
  TabTitle2: "Página de Vendas",
  TabTitle3: "Feed & Área de Membros",
  TabTitle4: "Painel de Vendas",
  LpWhoUser: "Os melhores coaches, mentores e experts",
  ExploreMore: "Explorar grupos",
  controlFinancesTitle: "Você no controle de sua vida",
  controlFinancesSubtitle:
    "Queremos construir um futuro onde você tenha liberdade para criar e faturar com o seu talento. Na Hubla é possível compartilhar seu conhecimento através da geração de conteúdos e construir comunidades poderosas.",
  LPCard1Title: "Sistema de pagamentos",
  LPCard1Body: "Um sistema completo e flexível que aceita diferentes formas de pagamento.",
  LPCard2Title: "Automação para membros",
  LPCard2Body: "Nosso robô automatiza o processo de adição e remoção de membros de acordo com seu status de pagamento.",
  LPCard3Title: "Dashboard de vendas",
  LPCard3Body: "Acompanhe em tempo real todas as suas vendas, lucros e assinaturas.",
  LPCard4Title: "Programa de afiliados",
  LPCard4Body: "Amplie sua rede. Você pode comissionar seus clientes para que eles também promovam o seu produto.",
  LPHowItWorks: "Como Funciona.",
  LPHowItWorks1Title: "Se você tem um dom, crie um grupo",
  LPHowItWorks1Subtitle:
    "Todos nós temos um talento que pode, e deve, ser compartilhado. Seja você um gamer, especialista na bolsa de valores ou conhecedor de um método incrível para perder peso, a Hubla é a solução ideal para você começar a lucrar.",
  LPHowItWorks1Link1: "Como precificar meu grupo",
  LPHowItWorks1Link2: "Nichos para grupos",
  LPHowItWorks2Title: "Todo mundo tem que ficar sabendo",
  LPHowItWorks2Subtitle:
    "Comece convidando seus amigos, colegas de trabalho e família. Se uma pessoa se apaixonar pelo seu conteúdo, ela vai divulgar para mais 5. Aos poucos sua rede vai se expandindo.",
  LPHowItWorks2Link1: "Como divulgar e vender mais",
  LPHowItWorks2Link2: "Como conseguir meus primeiros 100 clientes",
  LPHowItWorks3Title: "Ser único é uma feature, não um bug",
  LPHowItWorks3Subtitle:
    "Aqui ser diferente é um benefício. Quanto mais original for o seu conteúdo, maior o número de pessoas que você vai atingir.",
  LPHowItWorks3Link1: "Como criar um grupo de sucesso",
  LPHowItWorks3Link2: "Confira nossos grupos em destaque",
  LpFooterTitle: "O melhor lugar para compartilhar seu conteúdo",
  LpFooterCtaTitle: "Pronto para começar?",
  LPHeaderButtonCreate: "Criar Grupo",
}
