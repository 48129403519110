import API from "./API"

class Referral extends API {
  public async sendEmail(emails: string[]) {
    try {
      return await this.callFunction("referralSendInviteEmails", { emails })
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export default Referral
