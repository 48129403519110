import React, { memo } from "react"
import { Service } from "@chatpay/components"
import { useHistory } from "react-router-dom"
import { AccountDropdown, AccountDropdownProvider } from "@hub-la/fe-account-dropdown"
import { Container, ThemeProvider, theme, Stack, Logo, Link } from "@hub-la/design-system"
import { useContainer } from "hooks/use-container"
import { AppBar, Toolbar } from "@mui/material"

import { useIsMobile } from "hooks/use-is-mobile"
import { useIsDashboard } from "hooks/use-is-dashboard"
import { MenuBurger } from "./MenuBurger"
import { useRoleplay, useGetRoleplayReference } from "@hub-la/fe-roleplay"

const AppHeader: React.FC = memo(() => {
  const { isRoleplay } = useRoleplay()

  const currentUser = Service.Firebase.currentUser
  const history = useHistory()
  const container = useContainer()
  const isMobile = useIsMobile()
  const isAtDashboard = useIsDashboard()

  const { data: roleplayUser } = useGetRoleplayReference({ enabled: isRoleplay })

  return (
    <ThemeProvider theme={theme.light}>
      <AppBar position="static" elevation={0} color="transparent">
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              {isMobile && isAtDashboard && <MenuBurger />}
              <Link href="">
                <Logo hierarchy="primary" size={isMobile ? "small" : "medium"} />
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              {currentUser && (
                <AccountDropdownProvider container={container}>
                  {currentUser && !isRoleplay && (
                    <AccountDropdown
                      push={history.push}
                      id={currentUser.id}
                      firstName={currentUser.firstName ?? undefined}
                      picture={currentUser.picture ?? null}
                      username={currentUser.username}
                      bankAccount={currentUser.gateways?.carriers?.iugu?.status}
                    />
                  )}
                  {currentUser && isRoleplay && roleplayUser && (
                    <AccountDropdown
                      push={history.push}
                      id={roleplayUser.userId}
                      firstName={roleplayUser.name ?? undefined}
                      picture={roleplayUser.picture ?? null}
                      username={roleplayUser.username}
                      bankAccount={currentUser.gateways?.carriers?.iugu?.status}
                    />
                  )}
                </AccountDropdownProvider>
              )}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  )
})

export default AppHeader
