import { Box, Button, Snackbar, Stack, Text } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import isEmpty from "lodash/isEmpty"
import { SubscriptionStatus } from "modules/user-subscriptions/domain/enums/subscription-status"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useListMemberSubscriptions } from "../../hooks/use-list-member-subscriptions"
import { Subscription, UserDoesNotHaveSubscription } from "./components"
import Autocomplete from "./components/autocomplete"
import { Loading } from "./loading"

const UserSubscriptions: React.FC = () => {
  const { data = [], error, isFetching } = useListMemberSubscriptions()
  const { t } = useTranslation()
  const history = useHistory()
  const isDataEmpty = isEmpty(data)
  const canRenderEmptyState = !isFetching && isDataEmpty
  const activeSubscriptions = data.filter((subscription) =>
    [SubscriptionStatus.ACTIVE, SubscriptionStatus.CANCELED].includes(subscription.status),
  )
  const inactiveSubscriptions = data.filter((subscription) =>
    [SubscriptionStatus.INACTIVE, SubscriptionStatus.INCOMPLETE].includes(subscription.status),
  )

  if (isFetching && isDataEmpty) {
    return <Loading />
  }

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userSubscriptions.back")}
        </Button>
      </Stack>
      <Stack marginBottom={4}>
        <Text variant="h1">{t("userSubscriptions.title")}</Text>
      </Stack>
      <Stack marginBottom={4}>
        <Autocomplete data={data} loading={isFetching} />
      </Stack>

      {canRenderEmptyState && <UserDoesNotHaveSubscription />}

      {!canRenderEmptyState && (
        <>
          {activeSubscriptions.length > 0 && (
            <Stack direction="column" my={6} gap={4}>
              <Stack>
                <Text variant="body1">{t("userSubscriptions.activeSubscriptions")}</Text>
              </Stack>
              <Stack direction="column" gap={4}>
                {activeSubscriptions.map(
                  (data) =>
                    data && (
                      <Subscription
                        key={data.id}
                        id={data.id}
                        name={data.items[0]?.productName ?? ""}
                        picture={data.items[0]?.picture}
                        status={data.status}
                        type={data.type}
                        credits={data.credits}
                        nextDueAt={data.nextDueAt}
                        inactivatedAt={data.inactivatedAt}
                      />
                    ),
                )}
              </Stack>
            </Stack>
          )}

          {inactiveSubscriptions.length > 0 && (
            <Stack direction="column" my={6} gap={4}>
              <Stack>
                <Text variant="body1">{t("userSubscriptions.inactiveSubscriptions")}</Text>
              </Stack>
              <Stack direction="column" gap={4}>
                {inactiveSubscriptions.map(
                  (data) =>
                    data && (
                      <Subscription
                        key={data.id}
                        id={data.id}
                        name={data.items[0]?.productName ?? ""}
                        picture={data.items[0]?.picture}
                        status={data.status}
                        type={data.type}
                        credits={data.credits}
                        nextDueAt={data.nextDueAt}
                        inactivatedAt={data.inactivatedAt}
                      />
                    ),
                )}
              </Stack>
            </Stack>
          )}
        </>
      )}
      <Snackbar open={!!error} variant="negative" closeable={false}>
        {t((error as Error)?.message) ?? ""}
      </Snackbar>
    </Box>
  )
}

export default UserSubscriptions
