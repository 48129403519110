import { Document, Interface } from "@chatpay/common"
import API from "./API"

class Waitlist extends API {
  private async call(func: Interface.Waitlist.Function.Name, params?: Interface.Waitlist.Function.Params) {
    return await this.callFunction(`waitlist/${func}`, params)
  }

  public async get(params: Interface.Waitlist.Function.IGet): Promise<Document.Waitlist | null> {
    return (await this.call(Interface.Waitlist.Function.Name.get, params)).data
  }

  public async list(params: Interface.Waitlist.Function.IList): Promise<Interface.Waitlist.Function.IListResult[]> {
    return (await this.call(Interface.Waitlist.Function.Name.list, params)).data
  }

  public async subscribers(
    params: Interface.Waitlist.Function.ISubscribers,
  ): Promise<Interface.Waitlist.Function.ISubscribersResult> {
    return (await this.call(Interface.Waitlist.Function.Name.subscribers, params)).data
  }
}

export default Waitlist
