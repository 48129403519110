export const EmptyStates = {
  DashboardProducts: {
    title: "Sua jornada como criador começa aqui",
    subtitle: "Adicione o seu primeiro produto e comece a criar conexões",
    button: "Criar um novo produto",
    advantages: {
      content: "Desenvolva conteúdos exclusivos para a sua comunidade",
      groups: "Crie grupos pagos e tenha gestão de membros automatizada",
      lives: "Faça lives e tenha disparo de convites automáticos",
      branding: "Personalize sua área de membros com o seu branding",
    },
  },
  EditPartner: {
    title: "Ainda não há nenhum",
    subtitle1: "Aqui você pode adicionar co-produtores para dividir o faturamento deste produto.",
    subtitle2: "Só um detalhe, o co-produtor já precisa ter conta na Hubla.",
    button: "Adicionar",
  },
}
