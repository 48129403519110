export const groupDetail = {
  Available: "Disponível",
  CopyLink: "Copiar Link",
  Affiliate: "Afiliar",
  About: "Sobre",
  Share: "Compartilhar",
  Material: "Material",
  GroupAdmin: "Admin do grupo",
  ClickHere: "Clique aqui",
  ToDownload: "para baixar o arquivo",
  DownloadPermission: "Você precisa comprar o grupo para acessar seu material.",
  CancelSubscription: "Cancelar assinatura",
  alreadySubscribed: "Você é um assinante",
  alreadyPurchased: "Você ja comprou",
  copyCheckoutLink: "Copiar link do checkout",
  groupsCreated: "grupos criados",
  expiresIn: "Sua assinatura expira em ",
  days: "dias.",
  seeBankSlip: "Ver Boleto",
  subscriptionDetail: "Detalhes da assinatura",
  expiredAt: "Sua assinatura expirou há ",
  expiredStatusSubtitle: "Renove sua assinatura para voltar ao grupo.",
  pendingStatusTitle: "Pague seu boleto para acessar o grupo",
  pendingStatusSubtitle: "Assim que seu boleto for compensado, o botão abaixo ficará disponível para acessar o grupo.",
  pixPendingStatusTitle: "Ainda não identificamos o pagamento",
  pixPendingStatusSubtitle: "Caso você já tenha realizado o pagamento, atualize a página. Se não, realize o pagamento",
  allowJoinStatusTitle: "Você ja pode acessar o grupo, porém lembre de pagar o boleto para não ser removido.",
  allowJoinStatusSubtitle:
    "Caso o boleto não seja pago hoje, ele não irá compensar amanhã e você será removido do grupo.",
  downloadBankSlip: "Baixar Boleto",
  copyCode: "Copiar Código",
  codeCopied: "Código copiado!",
  copyPix: "Copiar código pix",
  pixCopied: "Código copiado, cole no aplicativo do seu banco!",
  pixli3: `3. Após o pagamento, clique no botão ”Atualizar página”.`,
  seeAs: "Ver página de vendas",
  client: "Cliente",
  admin: "Administrador",
  editGroup: "Editar Grupo",
  bankslipSent: "Boleto enviado para:",
  reloadPage: "Atualizar página",
  Feed: "Feed",
  ChatpaySection: {
    title: "Entre em uma comunidade Hubla",
    subtitle: "Uma nova maneira de aprender com experts",
    li1: {
      title: "Conhecimento ativo, não passivo",
      subtitle:
        "Diferente de um simples curso online, você terá contato direto com o criador! {{name}} estará lá para criar uma comunidade junto com todos os outros participantes.",
    },
    li2: {
      title: "Engajamento com outros membros",
      subtitle:
        "O grupo exclusivo que você terá acesso é um ambiente ideal para interagir e aprender com pessoas que estão participando junto com você.",
    },
    li3: {
      title: "Interação e feedback direto do expert",
      subtitle:
        "Aprender em comunidade te possibilita receber feedbacks diretamente do criador, que por sua vez estará la para acompanhar os resultados de todos os membros.",
    },
  },
}
