export const intervalDescription = {
  name: {
    monthly: "mês",
    quarterly: "trimestre",
    quaterly: "trimestre",
    semiannually: "semestre",
    annually: "ano",
  },
  interval: {
    monthly: "mensalmente",
    quarterly: "trimestralmente",
    quaterly: "trimestralmente",
    semiannually: "semestralmente",
    annually: "anualmente",
  },
}
