import { Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { IAppState } from "base/AppState"
import { Spacer } from "components"
import { CloseIcon } from "components/CloseIcon"
import { CopyButton } from "components/Group"
import FuzzySearch from "fuzzy-search"
import { isEmpty } from "lodash"
import React from "react"
import { connect } from "react-redux"
import { Button, Container, Image, Input, Modal, ModalContent, Pagination, Table } from "semantic-ui-react"
import { formatPhoneNumber } from "utils/formatPhoneNumber"
import { load, removeUser } from "./WhitelistActions"

interface IState {
  input: string
  activePage: number
  searchBy: string[]
  documentIdToDelete?: string
  comfirmPopup: boolean
  isLoading: boolean
  generatedLinks?: (string | null | undefined)[]
}

interface IProps {
  removeUser: (groupId: string, userId: string) => null
  load: (groupId: string) => null
  whitelist: Document.WhiteList[]
  groupId: string
  group: Document.Group | null
  isLoading: boolean
  error: Error | undefined
}

class WhitelistContainer extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    input: "",
    activePage: 1,
    searchBy: ["name", "ims.telegram", "ims.telegramUsername", "ims.whatsapp"],
    comfirmPopup: false,
    isLoading: false,
  }

  public componentDidMount() {
    /** Load whitelist from db. */
    this.props.load(this.props.groupId)
  }

  /**
   * @description
   * When the user click on the button remove, open the confirmation popup and
   * set the current user represented from @param documentId to be deleted.
   */
  public handleRemoveClick = (documentId: string) => {
    this.setState({
      documentIdToDelete: documentId,
      comfirmPopup: true,
    })
  }

  /**
   * @description
   * If the user comfirm the removal we will remove it from the DB.
   */
  public handleComfirmRemoveClick = () => {
    this.props.removeUser(this.props.groupId, this.state.documentIdToDelete ?? "")
    this.setState({
      documentIdToDelete: undefined,
      comfirmPopup: false,
    })
  }

  /**
   * @description
   * Get the correct ims based on group type
   */
  public getIms = (whitelistItem: Document.WhiteList) => {
    switch (whitelistItem.resourceType) {
      case Document.ResourceType.whatsapp:
        return formatPhoneNumber("+" + whitelistItem.ims?.whatsapp!)
      case Document.ResourceType.telegram:
        return whitelistItem.ims?.telegram
      default:
        return "-"
    }
  }

  /**
   * @description
   * Get the correct title
   */
  public getTitle = () => {
    return "Contato"
  }

  public getWhitelistLink = async () => {
    this.setState({ isLoading: true })
    const { group } = this.props
    try {
      const links = await Promise.all(
        (group?.groupResourcesType || []).map(async (resourceType) => {
          const { link } = await new API.GroupWhitelist().generateLink({
            groupId: this.props.groupId,
            resourceType: resourceType,
          })
          return link
        }),
      )
      this.setState({ isLoading: false, generatedLinks: links })
    } catch (e) {
      console.error(e)
      this.setState({ isLoading: false })
    }
  }

  public render() {
    const { searchBy, comfirmPopup, generatedLinks } = this.state
    const { isLoading, error, group } = this.props

    /** create search object populated by the full whitelist. */
    const searcher = new FuzzySearch(this.props.whitelist, searchBy)
    const result = searcher.search(this.state.input)

    return (
      <Container>
        <Modal open={isLoading} dimmer="inverted" basic={true} fluid={true}>
          <div style={{ textAlign: "center" }}>
            <Image
              wrapped={true}
              avatar={true}
              verticalAlign="middle"
              size="huge"
              spaced="right"
              src={require("assets/images/loading.gif")}
            />
          </div>
        </Modal>
        <Modal
          open={comfirmPopup}
          dimmer="inverted"
          basic={true}
          size="small"
          closeIcon={<CloseIcon onClick={() => this.setState({ comfirmPopup: false })} />}
        >
          <ModalContent>
            <h2>Remover o usuário da whitelist?</h2>
            <p style={{ opacity: "0.54" }}>Essa ação é irreversível. Não será possível recuperar o usuário.</p>
            <Spacer />
            <Button
              content="CANCELAR"
              basic={true}
              color="black"
              size="small"
              onClick={() => this.setState({ comfirmPopup: false, documentIdToDelete: undefined })}
            />
            <Button content="REMOVER" color="red" size="small" onClick={() => this.handleComfirmRemoveClick()} />
          </ModalContent>
        </Modal>
        <Spacer />
        {group ? (
          <Image
            src={group?.picture}
            style={{
              maxWidth: "157px",
              maxHeight: "90px",
              borderRadius: "0.5em",
              margin: "auto",
              marginBottom: "1em",
            }}
          />
        ) : null}
        <div className="cp text bold huge black center" style={{ margin: "auto" }}>
          {group?.name}
        </div>
        <Spacer size="big" />
        <div style={{ display: "flex" }}>
          <div className="cp text huge black" style={{ flex: "1" }}>
            Whitelist
          </div>
          <Modal
            basic={true}
            trigger={
              <Button basic={true} size="small" content="gerar link de convite" onClick={this.getWhitelistLink} />
            }
            size="small"
            dimmer={"inverted"}
            closeIcon={<CloseIcon />}
            content={
              this.state.isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <Image
                    wrapped={true}
                    avatar={true}
                    verticalAlign="middle"
                    size="huge"
                    spaced="right"
                    src={require("assets/images/loading.gif")}
                  />
                </div>
              ) : (
                <div style={{ maxWidth: "386px", margin: "auto" }}>
                  <div className="cp text big black bold">Compartilhe o link de convite para sua Whitelist</div>
                  <Spacer size="small" />
                  <div className="cp text">
                    Este link só pode ser usado uma vez. Para dar acesso a mais pessoas você precisa gerar vários links.
                  </div>
                  <Spacer />
                  {(generatedLinks || []).map((generatedLink, index) => (
                    <div key={index} style={{ marginBottom: "1em" }}>
                      <CopyButton link={generatedLink ?? ""} />
                    </div>
                  ))}
                </div>
              )
            }
          />
        </div>
        <Spacer />
        <Input
          icon="search"
          iconPosition="left"
          name="search"
          fluid={true}
          placeholder="Procure um nome ou usuário"
          onChange={(_, data) => this.setState({ input: data.value })}
        />
        <Spacer />
        {!error ? (
          <Table sortable={true} celled={true} fixed={true}>
            <Table.Header>
              <Table.Row>
                <HeaderCell rowSpan={5} title={"Nome"} />
                <HeaderCell title={this.getTitle()} />
                <HeaderCell title="" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isEmpty(result) ? (
                <Table.Row>
                  <TextCell text="Sem resultado" />
                </Table.Row>
              ) : (
                result
                  .slice((this.state.activePage - 1) * 20, this.state.activePage * 20)
                  .map((whitelistItem: Document.WhiteList, idx: number) => {
                    return (
                      <Table.Row key={idx}>
                        <TextCell text={whitelistItem.name ?? "-"} />
                        <TextCell text={this.getIms(whitelistItem)!} />
                        <Table.Cell textAlign="right">
                          {whitelistItem.ims?.telegramUsername ? (
                            <ActionButton
                              title="CONTATO"
                              color="blue"
                              onClick={() =>
                                window.open(`https://t.me/${whitelistItem.ims?.telegramUsername}`, "_blank")
                              }
                            />
                          ) : null}
                          {whitelistItem.ims?.whatsapp ? (
                            <ActionButton
                              title="CONTATO"
                              color="blue"
                              onClick={() =>
                                window.open(
                                  `https://api.whatsapp.com/send?phone=${whitelistItem.ims?.whatsapp}`,
                                  "_blank",
                                )
                              }
                            />
                          ) : null}
                          <ActionButton
                            href=""
                            title="REMOVER"
                            color="red"
                            onClick={() => this.handleRemoveClick(whitelistItem.id ?? "")}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
              )}
            </Table.Body>
          </Table>
        ) : (
          <Service.ErrorMessage error={error} />
        )}
        <div style={{ textAlign: "right" }}>
          <Pagination
            activePage={this.state.activePage}
            onPageChange={(event, data) => this.setState({ activePage: Number(data.activePage) })}
            totalPages={Math.ceil(result.length / 20)}
            prevItem={null}
            nextItem={null}
            boundaryRange={0}
          />
        </div>
      </Container>
    )
  }
}

const HeaderCell: React.FunctionComponent<{ title: string; rowSpan?: number }> = (props) => {
  return (
    <Table.HeaderCell rowSpan={props.rowSpan}>
      <div className="cp text small">{props.title}</div>
    </Table.HeaderCell>
  )
}

const TextCell: React.FunctionComponent<{ text: string }> = (props) => {
  return (
    <Table.Cell>
      <div className="cp text compact black">{props.text}</div>
    </Table.Cell>
  )
}

interface IActionButtonProps {
  href?: string
  title: string
  color: "red" | "blue"
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined
}

const ActionButton: React.FunctionComponent<IActionButtonProps> = (props) => {
  const color = { red: "#DD4B3C", blue: "#3399F7" }
  return (
    // eslint-disable-next-line
    <a
      style={{ cursor: "pointer", color: color[props.color], fontSize: "13px", padding: "1em" }}
      onClick={props.onClick}
    >
      {props.title}
    </a>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    whitelist: store.WhitelistState.whitelist,
    group: store.WhitelistState.group,
    isLoading: store.WhitelistState.isLoading,
    error: store.WhitelistState.error,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    load: (groupId: string) => dispatch(load(groupId)),
    removeUser: (groudId: string, userId: string) => dispatch(removeUser(groudId, userId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistContainer)
