import { routes } from "@chatpay/common"
import { Button, Divider, Grid, Stack, Text, useUtility } from "@hub-la/design-system"
import CancelIcon from "@mui/icons-material/Cancel"
import InfoIcon from "@mui/icons-material/Info"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { formatCurrencyWithInstallments } from "modules/user-subscriptions/domain/vos/format-currency"
import { useGetPendingInvoiceForSubscription } from "modules/user-subscriptions/presentation/hooks/use-get-pending-invoice-for-subscription"
import moment from "moment"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { getInvoicePaymentUrl } from "utils/SessionUtils"
import inactive_order from "../../../assets/inactive_order.svg"
import { Card, StatusIcon } from "./pending-invoice-card.styled"
import { MapLrError } from "modules/user-subscriptions/usecases/map-lr-error"
import PostAddIcon from "@mui/icons-material/PostAdd"
import { SubscriptionStatus } from "modules/user-subscriptions/domain/enums/subscription-status"

type Props = {
  subscriptionId?: string
  cancelEta?: string
  status?: SubscriptionStatus
}

const Header = ({ icon, content }) => {
  return (
    <Stack direction="column" gap={4}>
      <Stack direction="row" gap={2} alignItems="center">
        {icon}
        <Text variant="body2" display="contents">
          {content}
        </Text>
      </Stack>
      <Divider />
    </Stack>
  )
}

export const PendingInvoiceCard: React.FC<Props> = (props) => {
  const { subscriptionId, cancelEta, status } = props
  const { data: pendingInvoice } = useGetPendingInvoiceForSubscription(subscriptionId)
  const { palette } = useUtility()
  const { t } = useTranslation()
  const history = useHistory()

  const renders = {
    shouldRenderLrCode: (
      <Header
        icon={<CancelIcon style={{ color: palette("onError") }} />}
        content={
          <Trans
            i18nKey="userSubscriptions.pendingInvoiceCard.overdueCard"
            components={[<b />, <Text variant="body2" color="outline" />]}
            values={{ reason: new MapLrError().execute(pendingInvoice?.lrCode ?? "default") }}
          />
        }
      />
    ),
    unpaidPixLabel: (
      <Header
        icon={<PostAddIcon style={{ color: palette("primary") }} />}
        content={
          <Trans
            i18nKey="userSubscriptions.pendingInvoiceCard.unpaidPix"
            components={[<b />, <Text variant="body2" color="outline" />]}
          />
        }
      />
    ),
    overduePixLabel: (
      <Header
        icon={<InfoIcon style={{ color: palette("onError") }} />}
        content={
          <Trans
            i18nKey="userSubscriptions.pendingInvoiceCard.overduePix"
            components={[<b />, <Text variant="body2" color="outline" />]}
            values={{ cancelEta: moment(cancelEta).format("D [de] MMM. YYYY") }}
          />
        }
      />
    ),
    inactivePixSubscription: (
      <Header
        icon={<CancelIcon style={{ color: palette("onError") }} />}
        content={
          <Trans
            i18nKey="userSubscriptions.pendingInvoiceCard.inactivePixSubscription"
            components={[<b />, <Text variant="body2" color="outline" />]}
          />
        }
      />
    ),
    default: <></>,
  }

  /**
   * This function determines which header to display based on the payment method, invoice type,
   * and subscription status of a pending invoice.
   */
  const getHeader = () => {
    switch (true) {
      // If payment method is card and invoice type is overdue
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD &&
        pendingInvoice.type === "overdue":
        return renders.shouldRenderLrCode // Render shouldRenderLrCode header
      // If payment method is PIX and invoice type is unpaid
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX &&
        pendingInvoice.type === "unpaid":
        return renders.unpaidPixLabel // Render unpaidPixLabel header
      // If payment method is PIX, invoice type is overdue, and status is active
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX &&
        pendingInvoice.type === "overdue" &&
        status === SubscriptionStatus.ACTIVE:
        return renders.overduePixLabel // Render overduePixLabel header
      // If payment method is PIX, invoice type is overdue, and status is inactive
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX &&
        pendingInvoice.type === "overdue" &&
        status === SubscriptionStatus.INACTIVE:
        return renders.inactivePixSubscription // Render inactivePixSubscription header
      // Default case: Render nothing if none of the above conditions are met
      default:
        return renders.default
    }
  }

  return pendingInvoice ? (
    <Grid item xs={12} md={12}>
      <Card>
        <Stack direction="column" gap={4}>
          {getHeader()}

          <Stack direction="row" alignItems="start" justifyContent="space-between">
            <Stack direction="column" gap={[2, 0.5]}>
              <Text color="onSurfaceVariant">
                {t("userSubscriptions.pendingInvoiceCard.product", {
                  productName: pendingInvoice.items[0].description,
                })}
              </Text>
              <Stack direction={["column-reverse", "row"]} gap={[0, 2]} alignItems={["start", "center"]}>
                <Text variant="h2">
                  {formatCurrencyWithInstallments(
                    pendingInvoice.installmentsAmount,
                    pendingInvoice.priceInCents / 100,
                    pendingInvoice.installmentsPriceInCents / 100,
                  )}
                </Text>
              </Stack>
              <Text color="onSurfaceVariant">
                {t(`userSubscriptions.pendingInvoiceCard.date.${pendingInvoice.type}`)}{" "}
                {moment(pendingInvoice.dueDate).format("D MMM. YYYY HH:mm")}
              </Text>
            </Stack>
            <StatusIcon src={inactive_order} aria-label="inactive_order" />
          </Stack>
          <Divider />
          <Stack direction="column" gap={2}>
            <Button
              fullWidth
              onClick={() => window.open(`${getInvoicePaymentUrl()}/${pendingInvoice?.invoiceId}`, "_blank")}
            >
              {t("userSubscriptions.pendingInvoiceCard.actions.pay")}
            </Button>
            <Button
              variant="text"
              hierarchy="secondary"
              fullWidth
              onClick={() => history.push(`${routes.USER_DOWNLOAD_INVOICE.LINK}/${pendingInvoice?.invoiceId}`)}
            >
              {t("userSubscriptions.pendingInvoiceCard.actions.download")}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  ) : (
    <></>
  )
}
