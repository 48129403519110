import API from "./API"
import { DB, Firebase } from "../Service"
import { Document, Interface, BillingAPI } from "@chatpay/common"
import axios from "axios"
import moment from "moment-timezone"
import { chunk, orderBy } from "lodash"

class Subscription extends API implements Interface.Subscription.Function.ITemplate {
  private readonly db = new DB(Document.Subscription)

  private async getUrl() {
    return `${await this.getBillingUrl()}/api/v1/subscription/dashboard`
  }

  public async changePaymentMethod(
    data: Interface.Subscription.Function.ChangePaymentMethod,
  ): Promise<Interface.Subscription.Function.ResponseData> {
    return (await this.call(Interface.Subscription.Function.Name.changePaymentMethod, data)).data
  }

  public async changePlan(
    data: Interface.Subscription.Function.ChangePlan,
  ): Promise<Interface.Subscription.Function.ChangePlanResponse> {
    return (await this.call(Interface.Subscription.Function.Name.changePlan, data)).data
  }
  public async changePlanSimulation(
    data: Interface.Subscription.Function.ChangePlanSimulation,
  ): Promise<Interface.Subscription.Function.ChangePlanSimulationResponse> {
    return (await this.call(Interface.Subscription.Function.Name.changePlanSimulation, data)).data
  }

  public async setRenewal(
    data: Interface.Subscription.Function.SetRenewal,
  ): Promise<Interface.Subscription.Function.ResponseData> {
    return (await this.call(Interface.Subscription.Function.Name.setRenewal, data)).data
  }

  public async getDetail(id: string): Promise<BillingAPI.ISubscription.SubscriptionDetail> {
    const AuthToken = Firebase.accessToken
    const url = await this.getUrl()
    const response = await axios.request({
      url: `${url}/subscription/${id}`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` },
    })
    const data: BillingAPI.ISubscription.SubscriptionDetail = response.data
    return data
  }

  public async list(
    start: number,
    limit: number,
    status: "active" | "all",
    createdAt: [Date, Date],
    query?: string,
    orderBy?: "ASC" | "DESC",
    groupId?: string,
  ): Promise<BillingAPI.ISubscription.ListSubscriptionsOutput> {
    const params: BillingAPI.ISubscription.ListSubscriptionsInput = {
      page: start > 0 ? start : 1,
      perPage: limit,
      textSearch: query,
      status: status,
      groupId: groupId,
      byDate: {
        from: createdAt[0].toISOString(),
        to: createdAt[1].toISOString(),
        timezone: moment.tz.guess() ?? "America/Sao_Paulo",
      },
    }

    const AuthToken = Firebase.accessToken
    const url = await this.getUrl()
    const response = await axios.request({
      url: `${url}/subscription`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` },
      params,
    })
    const data: BillingAPI.ISubscription.ListSubscriptionsOutput = response.data
    return data
  }

  public async chart(from: Date, to: Date, groupId?: string): Promise<BillingAPI.ISubscription.ChartOutput> {
    const params: BillingAPI.ISubscription.ChartInput = {
      from: from.toISOString(),
      to: to.toISOString(),
      timezone: moment.tz.guess() ?? "America/Sao_Paulo",
      groupId: groupId,
    }

    const AuthToken = Firebase.accessToken
    const url = await this.getUrl()
    const response = await axios.request({
      url: `${url}/chart`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` },
      params,
    })

    const data: BillingAPI.ISubscription.ChartOutput = response.data
    return data
  }

  // HELPERS
  private async call(func: Interface.Subscription.Function.Name, params?: Interface.Subscription.Function.Params) {
    return await this.callFunction(`subscription/${func}`, params)
  }

  // QUERIES
  public async fetch(id: string, include: string[] = []): Promise<Document.Subscription | null> {
    return this.db.getById(id, include)
  }

  public async mySubscribers(): Promise<Document.Subscription[] | null> {
    if (!API.currentUser?.id) {
      return null
    }

    return this.db.get({
      where: {
        field: "item.userId",
        op: "==",
        value: API.currentUser.id,
      },
      order: {
        by: "createdAt",
        direction: "desc",
      },
    })
  }

  public async membershipFeeForGroup(
    group: Document.Group,
    planId: string,
  ): Promise<Interface.Sellable.IMembershipFee | undefined | null> {
    const { membershipFee } = group
    const renewalToleranceDays = membershipFee?.renewalToleranceDays

    if (!membershipFee?.price) {
      return
    }
    if (!renewalToleranceDays) {
      return membershipFee
    }

    const subscription = (await this.retrieveLastSubscriptionsPaid(group.id, planId))[0]

    if (
      subscription?.expiresAt &&
      Math.round((subscription.expiresAt.toMillis() - Date.now()) / (1000 * 60 * 60 * 24)) >= renewalToleranceDays
    ) {
      return
    }

    return membershipFee
  }

  public async retrieveLastSubscriptionsPaid(groupId: string, planId: string): Promise<Document.Subscription[]> {
    return [
      ...(await this.db.get({
        where: [
          { field: "item.id", op: "==", value: groupId },
          { field: "user.id", op: "==", value: API.currentUser?.id },
          { field: "item.plan.id", op: "==", value: planId },
        ],
      })),
      ...(await this.db.get({
        where: [
          { field: "item.id", op: "==", value: groupId },
          { field: "user.id", op: "==", value: API.currentUser?.id },
          { field: "item.subId", op: "==", value: planId },
        ],
      })),
    ]
      .filter((subscription) => subscription.statusAt.activated)
      .sort((a, b) => {
        if (!a.updatedAt || !b.updatedAt) {
          return 0
        }
        if (a.updatedAt.toMillis() > b.updatedAt.toMillis()) {
          return 1
        }
        if (a.updatedAt.toMillis() === b.updatedAt.toMillis()) {
          return 0
        }
        return -1
      })
  }

  public async getByGroupId(groupId: string): Promise<Document.Subscription[] | null> {
    try {
      return await this.db.get({
        where: [
          { field: "item.id", op: "==", value: groupId },
          { field: "user.id", op: "==", value: API.currentUser?.id },
        ],
      })
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public async getByGroupsIds(groupsId: string[]): Promise<Document.Subscription[] | null> {
    const chunks = chunk(groupsId, 10)

    const getQueries = async () =>
      await chunks.map((ids) =>
        this.db.get({
          where: [
            { field: "item.id", op: "in", value: ids },
            { field: "user.id", op: "==", value: API.currentUser?.id },
          ],
        }),
      )
    try {
      const subscriptions = (await Promise.all(await getQueries())).flat() ?? []

      return orderBy(subscriptions, ["updatedAt"], ["desc"])
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export default Subscription
