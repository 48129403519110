import i18n from "i18next";

export const customLocale = {
  months: [
    i18n.t("InputDate.months.January"),
    i18n.t("InputDate.months.February"),
    i18n.t("InputDate.months.March"),
    i18n.t("InputDate.months.April"),
    i18n.t("InputDate.months.May"),
    i18n.t("InputDate.months.June"),
    i18n.t("InputDate.months.July"),
    i18n.t("InputDate.months.August"),
    i18n.t("InputDate.months.September"),
    i18n.t("InputDate.months.October"),
    i18n.t("InputDate.months.November"),
    i18n.t("InputDate.months.December"),
  ],
  weekDays: [
    {
      name: i18n.t("InputDate.weekDays.Sunday"), // used for accessibility
      short: i18n.t("InputDate.weekDays.shortSunday"), // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: i18n.t("InputDate.weekDays.Monday"),
      short: i18n.t("InputDate.weekDays.shortMonday"),
    },
    {
      name: i18n.t("InputDate.weekDays.Tuesday"),
      short: i18n.t("InputDate.weekDays.shortTuesday"),
    },
    {
      name: i18n.t("InputDate.weekDays.Wednesday"),
      short: i18n.t("InputDate.weekDays.shortWednesday"),
    },
    {
      name: i18n.t("InputDate.weekDays.Thursday"),
      short: i18n.t("InputDate.weekDays.shortThursday"),
    },
    {
      name: i18n.t("InputDate.weekDays.Friday"),
      short: i18n.t("InputDate.weekDays.shortFriday"),
    },
    {
      name: i18n.t("InputDate.weekDays.Saturday"),
      short: i18n.t("InputDate.weekDays.shortSaturday"),
      isWeekend: true,
    },
  ],
  weekStartingIndex: 0,
  getToday(gregorainTodayObject) {
    return gregorainTodayObject
  },
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day)
  },
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate()
  },
  transformDigit(digit) {
    return digit
  },
  nextMonth: i18n.t("InputDate.nextMonth"),
  previousMonth: i18n.t("InputDate.previousMonth"),
  openMonthSelector: i18n.t("InputDate.openMonthSelector"),
  openYearSelector: i18n.t("InputDate.openYearSelector"),
  closeMonthSelector: i18n.t("InputDate.closeMonthSelector"),
  closeYearSelector: i18n.t("InputDate.closeYearSelector"),
  defaultPlaceholder: i18n.t("InputDate.defaultPlaceholder"),
  from: "from",
  to: "to",
  digitSeparator: ",",
  yearLetterSkip: 0,
  isRtl: false,
}