import * as React from "react"
import { Button, TextArea } from "semantic-ui-react"
import { copyToClipboard } from "components/Helpers/CopyToClipboard"
import { stopPropagation } from "../../../utils/Clicks"
import ActionPopup from "../../Dialogs/ActionPopup"
import i18n from "i18next"

import "./CopyButton.scss"
import { Analytics } from "@chatpay/components"
import { Service } from "@chatpay/components"
import { Events } from "@chatpay/common"

interface IProps {
  link: string
  isLoading?: boolean
}

export class CopyButton extends React.Component<IProps> {
  public shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    const shouldUpdate = JSON.stringify(this.props) !== JSON.stringify(nextProps)
    return shouldUpdate
  }

  private onCopyClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    Analytics.track(Events.GROUP.COPY_CLICKED, {
      byId: Service.Firebase.currentUser?.id,
      byName: Service.Firebase.currentUser?.name,
      page: window.location.pathname,
    })
    stopPropagation(event)
    copyToClipboard(this.props.link ?? "")
  }

  public render() {
    const { link, isLoading } = this.props
    return (
      <div className="CopyButton">
        <TextArea readyonly="true" rows={1} wrap="off" value={link} onClick={(event: any) => event.target.select()} />
        <ActionPopup text={i18n.t("LinkCopied")}>
          <Button content={`${i18n.t("Copy")}`} size="small" loading={isLoading} onClick={this.onCopyClick} />
        </ActionPopup>
      </div>
    )
  }
}
