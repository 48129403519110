import styled from "styled-components"

const BaseTitle = styled.h3`
  font-family: var(--primary-font);
  font-weight: 600 !important;
  font-style: normal !important;
  margin: 0;
  color: ${(props) => props.color};
  margin: 0px;
`

const Big = styled(BaseTitle)`
  font-size: 40px;
  letter-spacing: -0.02em;
`

const Normal = styled(BaseTitle)`
  font-size: 30px;
  letter-spacing: -0.02em;
`

const Small = styled(BaseTitle)`
  font-size: 16px;
`

const Tiny = styled(BaseTitle)`
  font-size: 13px;
`

export const Title = {
  Big,
  Normal,
  Small,
  Tiny,
}
