import { Spacer } from "components"
import * as Fields from "components/Fields"
import { Settings } from "components/Settings"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Button, Form } from "semantic-ui-react"
import "./WebhookEventsIntegration.scss"

export const WebhookEventsIntegration: React.FunctionComponent = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRedirectWebhook = () => {
    history.push({
      pathname: "/dashboard",
      hash: "integrations",
      search: "integration=webhook",
    })
  }

  return (
    <Settings.Section
      content={
        <div>
          <div className="cp text big black bold compact">
            <a href="#webhookeventsapi-integration" id="webhookeventsapi-integration" target="_blank"></a>
            {t("UserForm.integrations.webhookEvents.title")}
          </div>
          <div className="cp text large">{t("UserForm.integrations.webhookEvents.description")}</div>
          <Spacer />
          <a
            className="cp text blue large"
            href="https://app.hub.la/webhook-events-integration"
            target="_blank"
            rel="noreferrer"
          >
            {t("UserForm.integrations.webhookEvents.documentation")}
          </a>
        </div>
      }
      form={
        <Form>
          <Fields.InputLabel title={t("UserForm.integrations.webhookEvents.apiKeyTitle")}>
            <Spacer size="small" />
            <div className="buttonContainer">
              <Button
                disabled={false}
                size="small"
                basic={true}
                className="rounded"
                onClick={handleRedirectWebhook}
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{t("UserForm.integrations.webhookEvents.buttonConfigAPIToken")}</div>
                  </div>
                }
              />
            </div>
            <Spacer size="small" />
          </Fields.InputLabel>
        </Form>
      }
    />
  )
}
export { WebhookEventsIntegration as Form }
