import { Box, Button, Grid, Snackbar, Stack } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { InvoiceStatus } from "../../../domain/enums/invoice-status"
import { useGetInvoice } from "../../hooks/use-get-invoice"
import { Description } from "./components/description"
import { Header } from "./components/header"
import { PaymentDetails } from "./components/payment-details"
import Loading from "./loading"

const UserInvoiceDetail: React.FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { data: invoice, error, isFetching } = useGetInvoice(invoiceId)
  const { t } = useTranslation()
  const history = useHistory()

  if (isFetching && !invoice) {
    return <Loading />
  }

  return (
    <Box pb={40} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userInvoices.back")}
        </Button>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Header
            invoice={{
              id: invoice?.id ?? "",
              status: invoice?.status ?? InvoiceStatus.PAID,
              dueDate: invoice?.dueDate ?? "",
              paidAt: invoice?.paidAt ?? "",
              createdAt: invoice?.createdAt ?? "",
              total: invoice?.totalCents ?? 0,
              installment: invoice?.installment ?? 1,
              installments: invoice?.installments ?? 1,
              smartInstallmentRef: invoice?.smartInstallmentRef,
            }}
            product={{
              name: invoice?.items[0]?.productName ?? "",
            }}
            payer={{
              fullName: invoice?.payer.identity?.fullName ?? "",
            }}
            seller={{
              fullName: invoice?.seller.identity?.fullName ?? "",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <PaymentDetails
            paymentMethod={invoice?.paymentMethod}
            cardDetails={invoice?.cardDetails}
            softDescriptor={invoice?.softDescriptor}
            qrcodeData={invoice?.qrcodeData}
            qrcodeText={invoice?.qrcodeText}
            status={invoice?.status ?? InvoiceStatus.PAID}
          />
        </Grid>

        <Grid item xs={12}>
          <Description
            id={invoice?.id ?? ""}
            detail={{
              products: invoice?.amountDetail?.products ?? [],
              totalCents: invoice?.amountDetail?.totalCents ?? 0,
              couponCode: invoice?.amountDetail?.couponCode,
              discountCents: invoice?.amountDetail?.discountCents,
              installmentFeeCents: invoice?.amountDetail?.installmentFeeCents,
            }}
          />
        </Grid>
      </Grid>

      <Snackbar open={!!error} variant="negative" closeable={false}>
        {t((error as Error)?.message) ?? ""}
      </Snackbar>
    </Box>
  )
}

export default UserInvoiceDetail
