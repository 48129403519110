import * as React from "react"
import { Grid } from "semantic-ui-react"

import { Helper } from "@chatpay/common"

import * as Fields from "components/Fields"
import * as Base from "components/Forms/Base"
import * as Forms from "components/Forms"

interface IData {
  value: string
  type?: Helper.DocumentType
}

export type Data = IData

export const EmptyData: IData = {
  value: "",
  type: Helper.DocumentType.cpf,
}

interface IProps<I extends Data> extends Base.Props<I> {
  title?: string
  type?: Helper.DocumentType
}
interface IState<T extends Data> extends Base.State<T> {}

export class DocumentForm extends Base.Form<IProps<Data>, IState<Data>> {
  protected formGridRef = React.createRef<Forms.Grid>()
  protected documentFieldRef = React.createRef<Fields.InputDocument>()

  public get field(): Fields.InputDocument | null {
    return this.documentFieldRef.current
  }

  protected validateField(name: string): { name: string; isValid: boolean } {
    return { name, isValid: this.documentFieldRef.current?.isValid ?? true }
  }

  private onDocumentFieldChange = (component: Fields.InputDocument, input: HTMLInputElement) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          type: component.type,
        },
      },
      () => {
        this.onFieldChange({
          target: input,
          isValid: component.isValid,
        })
      },
    )
  }

  private onDocumentFieldBlur = (component: Fields.InputDocument, input: HTMLInputElement) => {
    this.onFieldBlur({ target: input, isValid: component.isValid })
  }

  public render() {
    const { data, isDisabled } = this.state

    return (
      <div className="DocumentForm">
        <Forms.Grid ref={this.formGridRef}>
          <Grid.Row>
            <Grid.Column>
              <Fields.InputDocument
                ref={this.documentFieldRef}
                name="value"
                type={this.props.type}
                title={this.props.title}
                value={data?.value ?? ""}
                disabled={isDisabled || this.props.isDisabled}
                onChange={this.onDocumentFieldChange}
                onBlur={this.onDocumentFieldBlur}
                className="cohere-block"
              />
            </Grid.Column>
          </Grid.Row>
        </Forms.Grid>
      </div>
    )
  }
}
