import { Document, Interface } from "@chatpay/common"
import API from "./API"

class GroupResource extends API implements Interface.GroupResource.Function.ITemplate {
  public async get(data: Interface.GroupResource.Function.IGet): Promise<Document.GroupResource> {
    const response = await this.call(Interface.GroupResource.Function.Name.get, data)
    return response.data
  }

  public async getByGroup(
    data: Interface.GroupResource.Function.IGetGroupResourcesByGroup,
  ): Promise<Document.GroupResource[]> {
    const response = await this.call(Interface.GroupResource.Function.Name.getByGroup, data)
    return response.data
  }

  private async call(func: Interface.GroupResource.Function.Name, params?: Interface.GroupResource.Function.Params) {
    return await this.callFunction(`groupResource/${func}`, params)
  }
}

export default GroupResource
