import { Service } from "@chatpay/components"
import { Button, Snackbar, Stack, Text, useIsMobile, useUtility } from "@hub-la/design-system"
import { useGetPendingInvites } from "modules/user-groups/presentation/hooks/use-get-pending-invites"
import { usePostAcceptInvite } from "modules/user-groups/presentation/hooks/use-post-accept-invite"
import { usePostRejectInvite } from "modules/user-groups/presentation/hooks/use-post-reject-invite"
import React from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "@tanstack/react-query"
import { useHistory } from "react-router-dom"
import DefaultGroupImage from "../../../../../../assets/images/default-group-avatar.png"
import { Invite } from "../../../../domain/dtos/get-pending-invites-output"
import { QueryKey } from "../../../../domain/enums/query-key"

const InviteCard = ({
  productName,
  picture,
  days = 0,
  onAccept,
  onReject,
  isLoading,
}: {
  picture: string
  productName: string
  days: number | null
  needRequestUserInfo: boolean
  onAccept: () => void
  onReject: () => void
  isLoading?: boolean
}) => {
  const { t } = useTranslation()
  const { palette } = useUtility()
  const isMobile = useIsMobile()

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      gap={3}
      bgcolor={palette("surface")}
      p={4}
      borderRadius={3}
      justifyContent="space-between"
    >
      <Stack direction="row" gap={3} alignItems="center">
        <img
          src={picture ?? DefaultGroupImage}
          width={90}
          height={59}
          alt={`${productName} logo`}
          style={{
            borderRadius: 4,
            objectFit: "cover",
          }}
        />
        <Stack>
          <Text variant="body1" fontWeight="500" mb={1}>
            {productName}
          </Text>
          <Text variant="caption" color="onSurfaceVariant">
            {!days
              ? t("userGroups.pendingInvites.inviteLifetime")
              : t("userGroups.pendingInvites.inviteDaysTime", {
                  days: days,
                })}
          </Text>
        </Stack>
      </Stack>
      <Stack gap={3} direction="row" alignItems="center">
        <Button variant="text" hierarchy="secondary" onClick={onReject} loading={isLoading}>
          {t("userGroups.pendingInvites.cancelInvite")}
        </Button>
        <Button
          variant="filled"
          hierarchy="secondary"
          size="small"
          fullWidth={isMobile}
          onClick={onAccept}
          loading={isLoading}
        >
          {t("userGroups.pendingInvites.acceptInvite")}
        </Button>
      </Stack>
    </Stack>
  )
}

export const PendingInvites: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useGetPendingInvites()
  const history = useHistory()
  const queryClient = useQueryClient()
  const currentUser = Service.Firebase.currentUser

  const {
    mutate: acceptInvite,
    isSuccess,
    isError: isAcceptError,
    error,
    reset,
    variables: acceptVariables,
    isLoading: isAcceptLoading,
  } = usePostAcceptInvite({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.listMemberGroups])
    },
  })

  const { mutate: rejectInvite, isLoading: isRejectLoading, variables: rejectInviteId } = usePostRejectInvite()

  const handleAccept = (needRequestUserInfo: boolean, invite: Invite) => {
    if (needRequestUserInfo) {
      history.push(`/invites/${invite?.id}/accept`)
      return
    }

    // TODO: wait backend endpoint to accept without request user info
    acceptInvite({
      inviteId: invite?.id,
      email: currentUser?.email ?? "",
      fullName: currentUser?.name ?? "",
      phoneNumber: currentUser?.phoneNumber ?? "",
    })
  }

  if ((data?.items ?? []).length === 0) {
    return null
  }

  const isLoading = isAcceptLoading || isRejectLoading

  return (
    <Stack direction="column" gap={4} mb={4}>
      {data?.items.map((invite) => (
        <InviteCard
          key={invite.id}
          productName={invite?.details?.product?.name}
          picture={invite?.details?.product?.picture}
          days={invite?.details?.days}
          needRequestUserInfo={data?.needRequestUserInfo}
          isLoading={(acceptVariables?.inviteId === invite.id || rejectInviteId === invite.id) && isLoading}
          onAccept={() => handleAccept(data?.needRequestUserInfo, invite)}
          onReject={() => rejectInvite(invite.id)}
        />
      ))}

      <Snackbar open={isSuccess} variant="positive" closeable={false} autoHideDuration={3000} onClose={() => reset()}>
        {t("userGroups.pendingInvites.successAccept")}
      </Snackbar>

      <Snackbar
        open={isAcceptError}
        variant="negative"
        closeable={false}
        autoHideDuration={3000}
        onClose={() => reset()}
      >
        {error?.message ?? ""}
      </Snackbar>
    </Stack>
  )
}
