import { Button, Card, Divider, Stack, Text } from "@hub-la/design-system"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton, Skeleton } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

export const Loading: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" gap={8}>
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text variant="h4">{t("userSubscriptions.upgradePlanModal.title")}</Text>
          <IconButton data-testid="close-button" sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Text color="outline">
          <Skeleton width="100%" />
        </Text>
      </Stack>

      <Stack direction="column" gap={4}>
        <Text fontWeight="bold">Novo plano</Text>
        <Skeleton width="100%" height={56} sx={{ transform: "scale(1, 1)" }} />
        <Skeleton width="100%" height={56} sx={{ transform: "scale(1, 1)" }} />
      </Stack>

      <Card hasHover={false} variant="filled" selected fullHeight>
        <Stack flexDirection="column" gap={6}>
          <Skeleton width={165} height={44} />
          <Stack direction="column" width="100%" gap={2}>
            <Stack direction="column" width="100%" gap={2}>
              <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={2}>
                  <Text variant="body1">
                    <Skeleton width={165} />
                  </Text>
                </Stack>
                <Skeleton width={65} />
              </Stack>
            </Stack>
            <Stack direction="column" width="100%" gap={2}>
              <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={2}>
                  <Text variant="body1">
                    <Skeleton width={165} />
                  </Text>
                </Stack>
                <Skeleton width={65} />
              </Stack>
              <Divider />
            </Stack>
            <Stack direction="column" width="100%" gap={2}>
              <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={2}>
                  <Text variant="body1">
                    <Skeleton width={165} />
                  </Text>
                </Stack>
                <Skeleton width={65} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>

      <Button loading fullWidth>
        {t("userSubscriptions.upgradePlanModal.button")}
      </Button>
    </Stack>
  )
}
