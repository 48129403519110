import React from "react"
import { Spacer } from "components"
import * as Fields from "components/Fields"
import { Settings } from "components/Settings"
import { useTranslation } from "react-i18next"
import { Form } from "semantic-ui-react"
import { Service } from "@chatpay/components"
import { UserSettingsNavigationTarget } from "@hub-la/fe-sign-in"

export const NavigationTarget: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { currentUser } = Service.Firebase

  return (
    <Settings.Section
      content={
        <div>
          <div className="cp text big black bold compact">
            {t("UserForm.integrations.loginPreferences.navigationTarget.title")}
          </div>
          <div className="cp text large"> {t("UserForm.integrations.loginPreferences.navigationTarget.subtitle")}</div>
        </div>
      }
      form={
        <Form>
          <Fields.InputLabel>
            <UserSettingsNavigationTarget currentUser={currentUser} />
            <Spacer size="small" />
          </Fields.InputLabel>
        </Form>
      }
    />
  )
}
export { NavigationTarget as Form }
