export const EditNotification = {
  SectionTitle: "Notifications",
  SectionSubtitle: "Receive notifications about activities in your Hubla app, payment updates, and others.",
  SectionText: "Receive notifications about",
  DataTitle: "Information on notifications",
  SoundTitle: "Notifications sound",
  adminJoined: "Admin joined group",
  memberJoined: "A group member joined your group",
  nonMemberJoined: "A non-identified user joined your group",
  purchased: "User purchased your group",
  removed: "User has been removed from your group",
  whitelisted: "User has been added to the group's whitelist",
  paymentMethod: "Payment method",
  productName: "Product name",
  userName: "User name",
  userEmail: "User email",
  userPhone: "User phone",
  sound: "Habilitar?",
  DownloadApp: "Download the app",
  postOwnerCommentAlert: "A user commented on content that I produced",
  threadParticipationReplyAlert: "A user replied to a thread in which I am participating or own",
}
