import * as Document from "./Document"
import * as Base from "./Base"
import * as UserInfo from "./UserInfo"
import * as UserForm from "./UserForm"
import { Section } from "./Section/Section"

import { FormGrid as Grid } from "./FormGrid"

const Forms = {
  Base,
  Document,
  Grid,
  UserInfo,
}

export { Forms, UserForm, Base, Document, Grid, UserInfo, Section }
