"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Funding = exports.Brand = void 0;
var Brand;
exports.Brand = Brand;

(function (Brand) {
  Brand["amex"] = "amex";
  Brand["visa"] = "visa";
  Brand["master"] = "master";
  Brand["diners"] = "diners";
  Brand["elo"] = "elo";
  Brand["hiper"] = "hiper";
  Brand["hipercard"] = "hipercard";
  Brand["jcb"] = "jcb";
  Brand["discover"] = "discover";
  Brand["unionPay"] = "union_pay";
  Brand["unknown"] = "unknown";
})(Brand || (exports.Brand = Brand = {}));

var Funding;
exports.Funding = Funding;

(function (Funding) {
  Funding["credit"] = "credit";
  Funding["debit"] = "debit";
  Funding["prepaid"] = "prepaid";
  Funding["unknown"] = "unknown";
})(Funding || (exports.Funding = Funding = {}));