import { Document, Events } from "@chatpay/common"
import firebase from "firebase/compat"
import { AnalyticsEventParams, AnalyticsProviderType, IAnalyticsProvider } from "./Tracker"
import { isDebug } from "./Utils"

/**@deprecated: Use the analytics container from hubla-nx instead */
export class KondutoProvider implements IAnalyticsProvider {
  private call = (func: () => void) => {
    var period = 300
    var limit = 20 * 1e3
    var nTry = 0
    var intervalID = setInterval(function () {
      // loop to retry sending if the script is still not loaded
      var clear = limit / period <= ++nTry
      if (typeof (window as any).Konduto !== "undefined") {
        func()
        clear = true
      }
      if (clear) {
        clearInterval(intervalID)
      }
    }, period)
  }

  init = () => {}

  identify = (user?: Document.User | null) =>
    user &&
    this.call(() => {
      LOG("identify", user.id)
      ;(window as any).Konduto?.setCustomerID(user.id)
    })

  type = (): AnalyticsProviderType => {
    return AnalyticsProviderType.KONDUTO
  }

  track = (event: string, params?: any) => {}
  alias = (id: string) => {}
  setCurrentScreen = (screen: string, params?: AnalyticsEventParams) => {}
  identifyFB(user: firebase.User): void {}

  private handlePageChange = (page: string) =>
    this.call(() => {
      LOG("page", page)
      ;(window as any).Konduto?.sendEvent("page", page)
    })

  page(name: string): void {
    switch (name) {
      case Events.MEMBER_DASHBOARD.PAGE_VIEWED: {
        this.handlePageChange("account")
        break
      }
      case Events.CREATOR.ONBOARDING_PROFILE_VIEWED: {
        this.handlePageChange("account_creation")
        break
      }
      case Events.CHECKOUT_PAYMENT_VIEWED: {
        this.handlePageChange("checkout")
        break
      }
    }
  }
  reset(): void {}
}
function LOG(message?: any, ...optionalParams: any[]) {
  if (isDebug()) console.log("KondutoProvider", message, ...optionalParams)
}
