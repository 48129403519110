import { Document } from "@chatpay/common"
import { DB } from "../Service"
import API from "./API"

class NotificationGroup extends API {
  public async create(type: Document.NotificationChannel): Promise<string> {
    const response = await this.callFunction("createNotificationGroup", { type })
    console.log(`Response ${JSON.stringify(response)}`)
    const link = response.data?.link
    console.log(`Link is ${JSON.stringify(link)}`)
    if (!link) {
      throw new Error("Could not retrieve group notification link.")
    }
    return link
  }

  public async getLink(notificationGroupId: string): Promise<string> {
    const notificationDB = new DB(Document.NotificationGroup)
    const notificationGroup = await notificationDB.getById(notificationGroupId)
    if (!notificationGroup) {
      throw new Error("Group notification not found.")
    }
    if (!notificationGroup.link) {
      throw new Error("Group notification does not have a link.")
    }
    return notificationGroup.link
  }
}

export default NotificationGroup
