"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["send"] = "send";
})(Name || (exports.Name = Name = {}));