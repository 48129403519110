export const purchaseDetail = {
  afterFirstMonthWillCharge: "Após o primeiro mês, será cobrado",
  afterFirst: "Após o primeiro",
  afterTheFirsts: "Após os primeiros",
  willBeCharged: ", será cobrado",
  daysWillBeCharged: "dias, será cobrado",
  theValueOf: "o valor de",
  subscription: "Assinatura:",
  membership: "1x Vaga no grupo:",
  membershipFee: "Taxa de adesão:",
  interest: "Taxa de parcelamento:",
  discount: "Desconto:",
  otherDiscount: "Outros descontos:",
  total: "Total:",
  startsIn: "Começa",
  plansAdditionalInformation:
    "* O valor pago anteriormente foi mantido devido ao período de carência, o valor para os demais usuários é de",
  membershipFeeAdditionalInformation: "* não haverá cobrança devido ao periodo de carência",
  couponOnePlanInterval: "* Válido para 1 ",
  couponByRecurrent: "* Cupom recorrente",
}
