import { Text, Box, Grid, Stack } from "@hub-la/design-system"
import React from "react"
import { useParams } from "react-router-dom"
import { InvoiceStatus } from "../../../domain/enums/invoice-status"
import { useGetInvoice } from "../../hooks/use-get-invoice"
import { ChargeDetail } from "./components/charge-detail"
import { Description } from "./components/description"
import { Header } from "./components/header"
import { PaymentDetails } from "./components/payment-details"
import Loading from "./loading"

const UserDownloadInvoice: React.FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { data: invoice, isFetching } = useGetInvoice(invoiceId)

  if (isFetching && !invoice) {
    return <Loading />
  }

  return (
    <Box py={12} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={12}>
        <Text variant="h2" color="outline">
          Detalhes da fatura
        </Text>
        <img style={{ width: "136px" }} src={require("../../assets/hubla-logo.png")} alt={"hubla logo avatar"} />
      </Stack>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Header
            invoice={{
              id: invoice?.id ?? "",
              status: invoice?.status ?? InvoiceStatus.PAID,
              dueDate: invoice?.dueDate ?? "",
              paidAt: invoice?.paidAt ?? "",
              createdAt: invoice?.createdAt ?? "",
              total: invoice?.totalCents ?? 0,
              installment: invoice?.installment ?? 1,
              installments: invoice?.installments ?? 1,
              smartInstallmentRef: invoice?.smartInstallmentRef,
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <ChargeDetail
            payer={{
              fullName: invoice?.payer.identity?.fullName ?? "",
              email: invoice?.payer.identity?.email ?? "",
              phone: invoice?.payer.identity?.phone ?? "",
            }}
            seller={{
              fullName: invoice?.seller.identity?.fullName ?? "",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <PaymentDetails
            paymentMethod={invoice?.paymentMethod}
            cardDetails={invoice?.cardDetails}
            softDescriptor={invoice?.softDescriptor}
          />
        </Grid>

        <Grid item xs={12}>
          <Description
            detail={{
              products: invoice?.amountDetail?.products ?? [],
              totalCents: invoice?.amountDetail?.totalCents ?? 0,
              couponCode: invoice?.amountDetail?.couponCode,
              discountCents: invoice?.amountDetail?.discountCents,
              installmentFeeCents: invoice?.amountDetail?.installmentFeeCents,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserDownloadInvoice
