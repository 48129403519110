import { Session } from "@chatpay/components"
import { ThemeProvider, theme } from "@hub-la/design-system"
import { CssBaseline } from "@mui/material"
import * as React from "react"
import { withUserAuthenticated } from "utils/SessionUtils"
import { ContainerProvider } from "../../../container"
import UserDownloadInvoice from "./user-download-invoice"

const UserDownloadInvoiceComponent: React.FC = () => (
  <ContainerProvider>
    <ThemeProvider theme={theme.light}>
      <CssBaseline />
      <Session.Context.Consumer>{(state) => state.authUser && <UserDownloadInvoice />}</Session.Context.Consumer>
    </ThemeProvider>
  </ContainerProvider>
)

export default withUserAuthenticated(UserDownloadInvoiceComponent)
