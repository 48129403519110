"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateType = exports.State = exports.Operation = void 0;
var Operation;
exports.Operation = Operation;

(function (Operation) {
  Operation["release"] = "release";
  Operation["revert"] = "revert";
  Operation["reverse"] = "reverse";
  Operation["receive"] = "receive";
  Operation["transfer"] = "transfer";
  Operation["escrow"] = "escrow";
  Operation["reinstate"] = "reinstate";
  Operation["cancel"] = "cancel";
})(Operation || (exports.Operation = Operation = {}));

var UpdateType;
exports.UpdateType = UpdateType;

(function (UpdateType) {
  UpdateType["sell"] = "sell";
  UpdateType["commission"] = "commission";
})(UpdateType || (exports.UpdateType = UpdateType = {}));

var State;
exports.State = State;

(function (State) {
  State["received"] = "received";
  State["released"] = "released";
  State["escrowed"] = "escrowed";
  State["escrowedOut"] = "escrowed_out";
  State["protested"] = "protested";
  State["reverted"] = "reverted";
  State["reversed"] = "reversed";
  State["reinstated"] = "reinstated";
  State["chargebacked"] = "chargebacked";
  State["errored"] = "errored";
  State["created"] = "created";
  State["canceled"] = "canceled";
})(State || (exports.State = State = {}));