"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _index = require("../index");

var _index2 = require("./index");

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var couponFixture = function couponFixture(args) {
  var uses = faker.datatype.number(50);
  var limit = faker.datatype.number({
    min: uses + 1,
    max: 200
  });
  var coupon = new _index.Coupon.Document(_objectSpread(_objectSpread({}, (0, _index2.documentFixture)()), {}, {
    userId: (0, _utils.firebaseIdFixture)(),
    name: faker.commerce.productName(),
    code: faker.random.word().toUpperCase(),
    discount: faker.datatype.number(80),
    isRecurrent: faker.datatype["boolean"](),
    limit: {
      total: limit,
      user: faker.datatype.number(100),
      recurrency: faker.datatype.number(12)
    },
    description: faker.commerce.productDescription(),
    usageCount: {
      success: uses,
      failed: faker.datatype.number(10)
    },
    expiresAt: _utils.FirebaseInjections.timestampFromDate(faker.date.future()),
    items: _defineProperty({}, (0, _utils.firebaseIdFixture)(), {
      name: faker.commerce.productName(),
      type: "Group"
    }),
    users: null,
    gateways: _defineProperty({}, faker.random.arrayElement(Object.keys(_index.GatewayCarrier)), faker.internet.password()),
    type: faker.random.arrayElement(Object.keys(_index.Coupon.Type))
  }, args));
  return coupon;
};

var _default = couponFixture;
exports["default"] = _default;