export const Sales = {
  balanceInvoices: {
    total: "Total invoicing",
    personal: "Your invoicing",
    partnership: "Partnership invoicing",
    chargeback: "Refunds and Disputes",
  },
  chart: {
    checkbox: {
      sales: "Sale(s)",
      renews: "Renew(s)",
      refunds: "Refund(s)",
    },
    tooltip: {
      sales: "Sale(s)",
      renews: "Renew(s)",
      refunds: "Refund(s)",
    },
  },
  searchField: {
    placeholder: "Search...",
  },
  invoices: {
    title: "Invoices",
    loading: "Loading...",
    export: {
      button: "Export",
      modal: {
        title: "Export invoices",
        cancel: "Cancel",
        confirm: "Export",
        status: {
          all: "All",
          paid: "Paid",
          pending: "Pending",
          expired: "Expired",
        },
        fileType: "File type",
      },
    },
  },
  invoiceDetails: {
    customer: {
      name: "Customer",
      email: "Email",
      cellphone: "Cellphone",
    },
    paymentLabel: "Payment data",
    commissionTotalValueInCents: "Total commission value",
    commissionTotalValuePercent: "Total commission percentage",
    payment: {
      createdAt: "Created at",
      paidAt: "Paid at",
      methodLabel: "Payment method",
      type: "Payment type",
      recurrence: "Recurrence",
      affiliateCommission: "Affiliate commission",
      productPrice: "Product price",
      couponDiscount: "Coupon discount",
      companyFee: "Hubla fee",
      companyFeeTooltipText:
        "The Hubla service fee and transaction and group costs are included in this purchase. Any questions?",
      companyFeeTooltipLink: "find out more here.",
      otherFee: "Other fee",
      total: "Liquid value",
      hasMembershipFee: "Has membership fee",
      hasMembershipFeeTrue: "Yes",
      hasMembershipFeeFalse: "No",
      couponName: "Coupon name",
      netValueCents: "Net value",
      totalValueCents: "Total value",
    },
    product: "Product",
    group: "Group",
    logsLabel: "History",
    logs: {
      title: "Title",
      description: "Description",
      createdAt: "Created at",
      loading: "Searching invoice history...",
      notFound: "No invoice history found...",
    },
  },
}
