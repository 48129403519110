import { styled, TextField } from "@hub-la/design-system"

/**
 * Due to the usage of TextField in dark mode, this disables the background color
 * when the browser autofills it. Ideally, this issue should be addressed within the
 * design system by utilizing a transparent background. However, as a temporary
 * workaround, this solution is implemented.
 */
export const StyledTextField = styled(TextField)`
  & :-webkit-autofill,
  & :-webkit-autofill:hover,
  & :-webkit-autofill:focus,
  & :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset;
  }
`
