export const landingPage = {
  LPHeaderContent: "Profit by creating paid WhatsApp and Telegram groups.",
  LPHeaderSubcontent:
    "Transform your experiences and talent into an online business. Engage your audience with quality content through exclusive paid groups within the main messaging apps.",
  StartNow: "Let's start!",
  LPHeaderButtonContact: "contact sales",
  TabTitle1: "Member Automation",
  TabTitle2: "Sales Page",
  TabTitle3: "Feed & Members Area",
  TabTitle4: "Sales Dashboard",
  LpWhoUser: "The best coaches, mentors and experts",
  ExploreMore: "Explore groups",
  controlFinancesTitle: "You are in control of your life",
  controlFinancesSubtitle:
    "We want to build a future where you have the freedom to create and profit from your talent. At Hubla it is possible to share your knowledge through the generation of content and build powerful communities.",
  LPCard1Title: "Payment system",
  LPCard1Body: "A complete and flexible system that accepts different forms of payment.",
  LPCard2Title: "Automation for members",
  LPCard2Body: "Our bot automates the process of adding and removing members according to their payment status.",
  LPCard3Title: "Sales dashboard",
  LPCard3Body: "Track in real time all your sales, profits and subscriptions.",
  LPCard4Title: "Affiliate program",
  LPCard4Body: "Extend your network. You can commission your customers so that they also promote your product.",
  LPHowItWorks: "How It Works.",
  LPHowItWorks1Title: "If you have a gift, create a group",
  LPHowItWorks1Subtitle:
    "We all have a talent that can, and should, be shared. Whether you are a gamer, a stock exchange expert or knowledgeable about an incredible method to lose weight, Hubla is the ideal solution for you to start making a profit.",
  LPHowItWorks1Link1: "How to price my group",
  LPHowItWorks1Link2: "Niches for groups",
  LPHowItWorks2Title: "Everyone has to know",
  LPHowItWorks2Subtitle:
    "Start by inviting your friends, co-workers and family. If a person falls in love with your content, they will spread the word to five more. Gradually your network will expand.",
  LPHowItWorks2Link1: "How to advertise and sell more",
  LPHowItWorks2Link2: "How to get my first 100 customers",
  LPHowItWorks3Title: "Being unique is a feature, not a bug",
  LPHowItWorks3Subtitle:
    "Being different here is a benefit. The more original your content, the more people you will reach.",
  LPHowItWorks3Link1: "How to create a successful group",
  LPHowItWorks3Link2: "Check out our featured groups",
  LpFooterTitle: "The best place to share your content",
  LpFooterCtaTitle: "Ready to start?",
  LPHeaderButtonCreate: "Create Group",
}
