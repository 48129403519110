import * as Groups from "./Groups"
import * as User from "./User"

export { Groups, User }

const Store = {
  Groups,
  User,
}

export { Store }
