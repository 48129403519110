import React from "react"
import { AuthProvider, AuthRedirectProvider, SignUpPage as AuthSignUpPage } from "@hub-la/fe-auth"
import { Service } from "@chatpay/components"

const SignUpPage = () => {
  const getCurrentUser = () => {
    return Service.Firebase.currentUser
  }

  return (
    <AuthProvider>
      <AuthRedirectProvider getCurrentUser={getCurrentUser}>
        {/* No need to redirect because the user needs to select to type of account and redirect  */}
        <AuthSignUpPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}

export default SignUpPage
