import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API, Service } from "@chatpay/components"
import { Document } from "@chatpay/common"

interface ILoadStartAction {
  type: "LOAD_START"
}

interface ILoadSuccessAction {
  type: "LOAD_SUCCESS"
  whitelist: Document.WhiteList[]
  group: Document.Group
}

interface ILoadFailureAction {
  type: "LOAD_FAILURE"
  error: Error
}

interface IRemoveStartAction {
  type: "REMOVE_START"
}

interface IRemoveSuccessAction {
  type: "REMOVE_SUCCESS"
  groupId: string
  documentId: string
}

interface IRemoveFailureAction {
  type: "REMOVE_FAILURE"
  error: Error
}

export const removeUser =
  (groupId: string, documentId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch(removeUserStart())

    new API.GroupWhitelist()
      .remove({ groupId, id: documentId })
      .then((value: any) => dispatch(removeUserSuccess(groupId, documentId)))
      .catch((error: Error) => dispatch(removeUserFailure(error)))
  }

export const load =
  (groupId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch(loadStart())

    try {
      const whitelist = await new API.GroupWhitelist().retrieve(groupId)
      const group = await new API.Group().fetch(groupId)

      const currentUserId = Service.Firebase.currentRoleplayUserId ?? Service.Firebase.currentUser?.id

      if (group?.owner?.id !== currentUserId) {
        dispatch(loadFailure(new Error("Você não é o dono deste grupo.")))
      } else {
        dispatch(loadSuccess(whitelist, group!))
      }
    } catch (error: any) {
      dispatch(loadFailure(error))
    }
  }

export const removeUserStart = () => {
  return {
    type: "REMOVE_START",
  }
}

export const removeUserSuccess = (groupId: string, documentId: string) => {
  return {
    type: "REMOVE_SUCCESS",
    groupId,
    documentId,
  }
}

export const removeUserFailure = (error: Error) => {
  return {
    type: "REMOVE_FAILURE",
    error,
  }
}

export const loadStart = () => {
  return {
    type: "LOAD_START",
  }
}

export const loadSuccess = (whitelist: Document.WhiteList[], group: Document.Group) => {
  return {
    type: "LOAD_SUCCESS",
    whitelist,
    group,
  }
}

export const loadFailure = (error: Error) => {
  return {
    type: "LOAD_FAILURE",
    error,
  }
}

export type IActions =
  | ILoadStartAction
  | ILoadSuccessAction
  | ILoadFailureAction
  | IRemoveSuccessAction
  | IRemoveStartAction
  | IRemoveFailureAction
