import * as React from "react"
import { isString } from "util"

import "./InputOption.scss"

interface IProps {
  label?: string
  name?: string
  value?: any
  icon?: string | React.ReactElement
  selected?: boolean
  disabled?: boolean
  fluid?: boolean
  onChange?: (input: InputOption, selected: boolean) => void
}

interface IState {
  selected: boolean
}

class InputOption extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    selected: false,
  }

  public get name(): string | undefined | null {
    return this.props.name
  }

  public get value(): string | undefined | null {
    return this.props.value
  }

  public get isSelected(): boolean {
    return this.state.selected
  }

  public componentDidMount() {
    this.setState({ selected: this.props.selected ?? false })
  }

  private onClick = () => {
    if (this.props.disabled) {
      return
    }
    this.setState({ selected: true }, () => {
      if (this.props.onChange) {
        this.props.onChange(this, this.state.selected)
      }
    })
  }

  public render() {
    const { selected } = this.state
    const { icon, label, fluid, disabled } = this.props

    return (
      <div
        className={`InputOption${selected ? " selected" : ""}${disabled ? " disabled" : ""}${
          fluid ? " fluid" : ""
        } ui cp input`}
        onClick={this.onClick}
      >
        <div className="bullet">
          <div className="bulletCenter" />
        </div>
        {isString(icon) ? <img src={icon} alt={label ?? ""} /> : icon}
        {label ? <div className="label text">{label}</div> : null}
      </div>
    )
  }
}

export default InputOption
