import { Interface } from "@chatpay/common"
import API from "./API"

class Typeform extends API implements Interface.Typeform.Function.ITemplate {
  public async getData(data: Interface.Typeform.Function.IGetForm): Promise<any> {
    try {
      const params = {
        responseId: data.responseId,
      }
      return await this.call(params)
    } catch (error) {
      console.log("Erro ao pegar forms: ", error)
    }
  }

  private async call(params: Interface.Typeform.Function.IGetForm) {
    return await this.callFunction(`typeform/getData`, params)
  }
}

export default Typeform
