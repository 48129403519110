export const EditAbout = {
  infoHeading:
    "🎉 Let's start by creating a community from scratch. Describe your group's basic information, such as name, price, platform, etc...",
  descriptionHeading: "⚡ Well written descriptions can boost organic traffic to your page",
  advantagesHeading: "🎯 Your written content is an excellent tool to optimize conversion",
  faqHeading: "💎 Don't leave your client hanging. Answer his questions before he makes a purchase.",
  assetsHeading: "📚 Add a gallery of videos or images to show a little more of your group",
  back: "Back",
  next: "Next",
  save: "Save",
  description: {
    title: "Describe your community",
    subtitle:
      "Your group's description can significantly increase the purchase conversion on your sales page. Be clear, brief and tell us a little about your community.",
    tip: {
      title: "Look at this example of a great description 😎",
      text1:
        "In this mentoring you will be guided in your challenges, mistakes and successes to achieve an exponential growth in your client portfolio and really live from traffic management for local businesses.",
      text2: "Every week, there will be live mentoring, with exclusive content directly from the battlefield.",
    },
  },
  advantages: {
    title: "Describe some benefits of your community",
    subtitle:
      "Add a list of benefits to clarify to the customer, what type of access, content and other benefits they will gain from being part of your community.",
    tip: {
      title: "See a great example of a list of benefits 🤠",
      text1: "Access to a Zoom webinar per week",
      text2: "Daily tips within the group",
      text3: "+3 exclusive Ebooks",
    },
  },
  faq: {
    title: "Frequently asked questions",
    subtitle:
      "By creating a list of frequently asked questions, you reduce the amount of customers who call your support team, or even stop participating by your group because of a question.",
    tip: {
      title: "Example of a great FAQ 😎",
      1: {
        question: "What is the group's reversal policy?",
        answer:
          "Refund requests are automatically accepted within 7 days of the first purchase, after this period, each case is analyzed individually.",
      },
      two: {
        question: "How can I join?",
        answer:
          "If the group has the affiliate program enabled, you can join it. That is, if you sell through the link generated on the affiliate platform, you will receive commissions for every sale you make.",
      },
      3: {
        question: "I changed my mobile number, now what?",
        answer:
          "Don't worry, you won't lose access to the group. Contact Hubla support and the attendants will be able to change the number for you.",
      },
      4: {
        question: "Can I buy with someone else's card?",
        answer: "Yes, you can buy with someone else's card. Your personal data is not linked to the cardholder's data.",
      },
    },
  },
  assets: {
    title: "Add media",
    subtitle:
      "Videos can explain better how your group will work, images to create a social proof or results that your community will provide.",
    tip: {
      title: "Show in more detail what your group will provide 📸",
      text1:
        "Bringing a video explaining your content will help your customers answer their questions about your product.",
      text2:
        "In addition, you can add multiple before and after images or even some screenshots of your customers' testimonials.",
    },
  },
  points: "points",
}
