export const myGroups = {
  verifyAccountWarning: "Conecte sua conta bancária para publicá seus produtos e começar a divulgar",
  verifyAccountButton: "Conectar conta bancária",
  link1: "Adicione uma produto.",
  m2: "Publique o seu produto",
  m3: "Estamos verificando sua conta. Aguarde alguns minutos.",
  publishWarning: "Seu produto ainda está como rascunho! Publique-o no botão acima para começar a vender.",
  Title: "Minhas compras",
  Error: "Erro ao buscar produtos. Por favor recarregue a página.",
  Drafts: "Rascunhos",
  Publisheds: "Publicados",
  Actives: "Ativos",
  Purchases: "Compras",
  Subscriptions: "Assinaturas",
  Inactive: "Inativos",
  Ready: "pronto",
  Sale: "venda",
  InviteTitle: "Convide um criador e ganhe até R$ 500",
  InviteText:
    "Conhece alguém que criaria um ótimo produto? Convide-o para a Hubla e ganhe até 5% do faturamento dele(a) no primeiro mês.",
  InviteButton: "Convidar",
  ClosedSales: "Vendas Encerradas",
  expirationDate: "DD [de] MMMM",
  product: "produto",
  productOwnership: "<0>Por {{ownerName}}</0>",
  renewalDate: "<0>Data de Renovação: {{renewalDate}}</0>",
  seeMore: "Ver mais",
  labels: {
    active: "Publicado",
    draft: "Rascunho",
    disabled: "Vendas Encerradas",
  },
  EmptyState: {
    title: "Seja bem vindo à Hubla!",
    subtitle:
      "Nós ajudamos todos os tipos de pessoas a criarem uma comunidade paga onde podem entregar para o seu público um conteúdo de forma rápida, fácil e sem burocracias.",
    creatorTitle: "Se você for um criador de conteúdo.",
    creatorSubtitle: "Comece criando um produto com a gente!",
    buyerTitle: "Podemos também te indicar vários produtos de sucesso!",
    buyerSubtitle: "Confira abaixo alguns dos produtos que separamos especialmente para você",
    purchaseTitle: "Não tem nada aqui :)",
  },
  AccountVerificationSteps: {
    0: {
      title: "Verifique sua conta para começar a vender",
      subtitle: "Precisamos de uma conta bancária para você receber pagamentos de seus membros",
    },
    1: {
      title: "Estamos processando suas informações",
      subtitle: "Assim que tudo estiver certo, você vai conseguir vender seu produto",
    },
    2: {
      title: "Houve um erro nos seus dados",
      subtitle: "Verifique se seus dados estão corretos ou entre em contato com o suporte",
    },
    3: {
      title: "Oi, ",
      subtitle: "Ative seu produto e comece a vender!",
    },
    cantVerifyYet: "Antes, precisamos de algumas informações",
    button: "Verificar conta",
    modalTitle: "Verifique sua conta bancária, para conseguirmos te pagar",
    modalSubtitle:
      "Antes de começar a vender, precisamos saber seus dados bancários. Todo seu faturamento será transferido para essa conta bancária.",
  },
  JoinGroupSteps: {
    title: "Tudo pronto!",
    subtitle: "Agora você já pode acessar ao grupo do seu produto. ",
  },
  CompletionStepsTitle: "Sua página do produto ainda está fraca. Termine o seu produto e começe a vender:",
  CompletionStepsAltTitle1: "Agora você pode ter uma página de vendas otimizada!",
  CompletionStepsAltTitle2: "Clique aqui",
  CompletionStepsAltTitle3: "para editá-la!",
  CompletionStepsInfo: "Informações básicas",
  CompletionStepsAssets: "Mídias da página de vendas",
  CompletionStepsDescription: "Coloque uma descrição",
  CompletionStepsAdvantages: "Adicione uma lista de vantagens",
  CompletionStepsFAQ: "Adicione perguntas frequentes",
  active: "Ativos",
  previousPurchases: "Inativos",
  Button: {
    access: "Acessar",
    join: "Entrar",
    pay: "Pagar",
    pending: "Pendente",
    failed: "Falhou",
    unavailable: "Indisponível",
    month: "por mês",
    renew: "Assinar novamente",
  },
  Dropdown: {
    subscription: "Detalhes da assinatura",
    changePayment: "Trocar método de pagamento",
    changePlan: "Alterar plano de assinatura",
  },
  Feedback: {
    problemWithPaymentMethod:
      "<0>Atualize o método de pagamento da sua assinatura!</0> Identificamos um problema com o método de pagamento da sua assinatura. Troque o método de pagamento até <1>hoje às 23:59</1> para evitar a suspensão da sua assinatura.",
    suspendedSubscription:
      "<0>Sua assinatura foi suspensa!</0> Você perdeu seu acesso ao produto. Para retornar, renove sua assinatura.",
    pixExpiring:
      "<0>Renove sua assinatura!</0> Sua assinatura vencerá <1>{{ days }}</1>. Para evitar que sua assinatura seja suspensa, realize o pagamento via Pix.",
    actions: {
      changePaymentMethod: "Trocar método de pagamento",
      renew: "Renovar assinatura",
      payViaPix: "Pagar via Pix",
      stopCommunications: "Parar comunicações",
    },
    Snackbar: {
      stopCommunications: "Comunicações canceladas com sucesso",
    },
    format: {
      inXDays: "em {{ days }} dia(s)",
      atMidnight: "em breve",
    },
  },
  GroupStatus: {
    isDraft: {
      current: "Rascunho",
      action: "Colocar em rascunho",
      next: "Editar",
    },
    isPublished: {
      current: "Publicado",
      action: "Publicar",
      next: "Ativar lista de espera",
    },
    isSelling: {
      current: "Vendendo",
      action: "Iniciar vendas",
      next: "Ativar lista de espera",
    },
    isWaitlisted: {
      current: "Em lista de espera",
      action: "Abrir lista de espera",
      next: "Ativar vendas",
    },
    isNotEnabled: {
      current: "Desativado",
      action: "Desativar",
      next: "Ativar vendas",
    },
    isNotSelling: {
      current: "Vendas encerradas",
      action: "Encerrar vendas",
      next: "Ativar",
    },
  },
}
