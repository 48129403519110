import React, { useContext } from "react"
import { MenuBurgerContext, MenuBurgerProvider } from "./Context"
import { IconButton } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/CloseOutlined"

export const MenuBurger = () => {
  const { isOpen, toggleMenu } = useContext(MenuBurgerContext)

  return (
    <IconButton
      data-testid="burger-button"
      size="medium"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: [0, 2] }}
      onClick={toggleMenu}
    >
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  )
}

export { MenuBurgerContext, MenuBurgerProvider }
