"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Type = exports.DisabledReason = exports.CoproductionErrorKeys = void 0;
var Type;
exports.Type = Type;

(function (Type) {
  Type["affiliate"] = "affiliate";
  Type["partner"] = "partner";
})(Type || (exports.Type = Type = {}));

var DisabledReason;
exports.DisabledReason = DisabledReason;

(function (DisabledReason) {
  DisabledReason["err100"] = "user not exists or igual to this receiver";
  DisabledReason["err101"] = "receiver not exists or disabled";
  DisabledReason["err102"] = "owner and group not exists";
  DisabledReason["err103"] = "group exists but commissioning is disabled or different of item id";
  DisabledReason["err104"] = "owner exists but is disabled or commissioning disabled or equal to user";
  DisabledReason["err105"] = "exists another transaction for the user with this receiver";
  DisabledReason["err106"] = "commission is not available for {mode}";
  DisabledReason["err107"] = "commission is not settled for {mode}";
  DisabledReason["err108"] = "group owner is not equal to the seller id or owner is not equal to the item user id";
})(DisabledReason || (exports.DisabledReason = DisabledReason = {}));

var CoproductionErrorKeys;
exports.CoproductionErrorKeys = CoproductionErrorKeys;

(function (CoproductionErrorKeys) {
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverUserNotFound"] = 0] = "ReceiverUserNotFound";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInviteEmptyEmailOrUserid"] = 1] = "ReceiverInviteEmptyEmailOrUserid";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInviteSameEmailThatCreator"] = 2] = "ReceiverInviteSameEmailThatCreator";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverGroupNotFound"] = 3] = "ReceiverGroupNotFound";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverGroupHasAnotherOwner"] = 4] = "ReceiverGroupHasAnotherOwner";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInviteExpiresAtInvalid"] = 5] = "ReceiverInviteExpiresAtInvalid";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverCommissionGreaterThanZero"] = 6] = "ReceiverCommissionGreaterThanZero";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverRenewalCommissionNeedGreaterZero"] = 7] = "ReceiverRenewalCommissionNeedGreaterZero";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverRenewalCommissionGreater100"] = 8] = "ReceiverRenewalCommissionGreater100";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverSellCommissionNeedGreaterZero"] = 9] = "ReceiverSellCommissionNeedGreaterZero";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverSellCommissionGreater100"] = 10] = "ReceiverSellCommissionGreater100";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverNotExists"] = 11] = "ReceiverNotExists";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverHasNotGroup"] = 12] = "ReceiverHasNotGroup";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverForbiddenUser"] = 13] = "ReceiverForbiddenUser";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverOtherUser"] = 14] = "ReceiverOtherUser";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverEditGroupRequired"] = 15] = "ReceiverEditGroupRequired";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInvalidStatus"] = 16] = "ReceiverInvalidStatus";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInvalidType"] = 17] = "ReceiverInvalidType";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInviteUserAlreadyInvited"] = 18] = "ReceiverInviteUserAlreadyInvited";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverAcceptInviteUserAlreadyCoproduction"] = 19] = "ReceiverAcceptInviteUserAlreadyCoproduction";
  CoproductionErrorKeys[CoproductionErrorKeys["ReceiverInviteAccountIdInvalid"] = 20] = "ReceiverInviteAccountIdInvalid";
})(CoproductionErrorKeys || (exports.CoproductionErrorKeys = CoproductionErrorKeys = {}));