import styled from "styled-components"

const size = {
  tiny: "4px",
  small: "8px",
  medium: "16px",
  big: "32px",
  huge: "64px",
  massive: "128px",
}

interface SpacerProps {
  size?: "tiny" | "small" | "medium" | "big" | "huge" | "massive"
}

export const Spacer = styled.div<SpacerProps>`
  width: 100%;
  padding: ${(props) => size[props.size ?? "medium"]};
`
