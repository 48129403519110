"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _BotAction = require("../BotAction");

var _ = require("../");

var _Bot = require("./Bot");

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var botActionFixture = function botActionFixture(args) {
  var _args$status;

  var status = (_args$status = args === null || args === void 0 ? void 0 : args.status) !== null && _args$status !== void 0 ? _args$status : _faker["default"].random.objectElement(_BotAction.Status);
  return new _.BotAction(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    targetId: (0, _utils.firebaseIdFixture)(),
    targetType: _faker["default"].random.objectElement(_BotAction.TargetType),
    action: _faker["default"].random.objectElement(_.BotActionAction),
    actionParams: null,
    actor: status === _BotAction.Status.completed ? (0, _Bot.botIdFixture)() : undefined,
    status: status,
    logs: undefined,
    result: null,
    retryCount: status === _BotAction.Status.errored ? _faker["default"].datatype.number(9) : 0
  }, args));
};

var _default = botActionFixture;
exports["default"] = _default;