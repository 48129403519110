import { Spacer } from "components"
import i18n from "i18next"
import React from "react"
import { Title } from "typography"

export const StepHeader = (currentStep: number, totalSteps: number, title: string) => {
  return (
    <React.Fragment>
      <Spacer size="small" />
      <Title.Tiny>{`${i18n.t("GetStarted.Step").toUpperCase()} ${currentStep} ${i18n
        .t("GetStarted.To")
        .toUpperCase()} ${totalSteps}`}</Title.Tiny>
      <Spacer size="small" />
      <Title.Normal>{title}</Title.Normal>
    </React.Fragment>
  )
}
