"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Type = exports.Status = void 0;
var Type;
exports.Type = Type;

(function (Type) {
  Type["trn"] = "trn";
  Type["abn"] = "abn";
  Type["cnpj"] = "cnpj";
  Type["cpf"] = "cpf";
  Type["bn"] = "bn";
  Type["qst"] = "qst";
  Type["tin"] = "tin";
  Type["cif"] = "cif";
  Type["br"] = "br";
  Type["npwp"] = "npwp";
  Type["cn"] = "cn";
  Type["brn"] = "brn";
  Type["uid"] = "uid";
  Type["rfc"] = "rfc";
  Type["frp"] = "frp";
  Type["itn"] = "itn";
  Type["sst"] = "sst";
  Type["at"] = "at";
  Type["inn"] = "inn";
  Type["uen"] = "uen";
  Type["ein"] = "ein";
  Type["unknown"] = "unknown";
})(Type || (exports.Type = Type = {}));

var Status;
exports.Status = Status;

(function (Status) {
  Status["pending"] = "pending";
  Status["verified"] = "verified";
  Status["unverified"] = "unverified";
  Status["unavailabl"] = "unavailable";
})(Status || (exports.Status = Status = {}));