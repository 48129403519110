export const refundPage = {
  title: "Pedidos de reembolso",
  subtitle: "Insira as informações abaixo para realizar o pedido de reembolso de uma compra",
  policy1: "Antes, leia nossa",
  policy2: "política de estorno",
  product: "Produto",
  cohort: "Turma",
  chooseProduct: "Escolha um produto",
  transaction: "Transação",
  chooseTransaction: "Escolha a fatura que deseja reembolsar",
  transactionDisclaimer:
    "Importante: Renovações não se enquadram no período de 7 dias de desistência. Assim, o administrador tem o direito de recusar seu pedido." +
    " O reembolso vai ser efetuado para todos os produtos comprados juntos nesta mesma transação, o reembolso vai ser integral e o acesso será revogado para todos os produtos.",
  noTransactions: "Você não possui transações passíveis de reembolso para este produto.",
  reasonTitle: "Pedido de estorno",
  reason: "Explique o motivo do estorno. Quanto mais detalhes, maior a chance de seu estorno ser concedido.",
  button: "Fazer pedido de estorno",
  refundDisclaimer1: "O administrador tem até",
  refundDisclaimerBold: "14 dias úteis",
  refundDisclaimer2:
    "para te dar uma resposta. Aguarde que receberá um email com a aprovação ou rejeição de seu pedido.",
  email: "Email",
  purchase: "Compra",
  request: "Pedido",
  cancel: "Cancelar",
  reactivate: "Reativar",
  autoWithdraw: "Transferir meu reembolso automaticamente.",
  //TODO: handle hardcoded currency
  autoWithdrawDisclaimer: "* É cobrada uma taxa de transferência no valor de R$ 3,00",
}
