import React from "react"
import { Stack, Stats, StatsVariant } from "@hub-la/design-system"
import StreamIcon from "@mui/icons-material/Stream"
import { InvoiceStatus } from "modules/user-invoices/domain/enums/invoice-status"

type SmartInstallmentChipProps = {
  status: InvoiceStatus
  installment: number
  installments: number
  size: "large" | "small" | "tiny"
}

export const SmartInstallmentChip = ({ status, size, installment, installments }: SmartInstallmentChipProps) => {
  const getVariant = () => {
    switch (status) {
      case InvoiceStatus.DISPUTED:
      case InvoiceStatus.PAID:
        return StatsVariant.positive
      case InvoiceStatus.UNPAID:
      case InvoiceStatus.DRAFT:
        return StatsVariant.informative
      case InvoiceStatus.EXPIRED:
      case InvoiceStatus.CANCELED:
        return StatsVariant.negative

      case InvoiceStatus.RETURNED:
      case InvoiceStatus.CHARGEBACK:
        return StatsVariant.attention

      default:
        return StatsVariant.positive
    }
  }
  return (
    <Stats variant={getVariant()} size={size}>
      <Stack direction="row" gap={1}>
        <StreamIcon style={{ fontSize: "16px" }} />
        {installment}/{installments}
      </Stack>
    </Stats>
  )
}
