"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WithReason = exports.True = exports.False = void 0;
var True = {
  isOn: true
};
exports.True = True;
var False = {
  isOn: false
};
exports.False = False;

var WithReason = function WithReason(isOn, reason) {
  return {
    isOn: isOn,
    reason: reason
  };
};

exports.WithReason = WithReason;