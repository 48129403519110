import { CopyClipboard } from "@hub-la/fe-copy-clipboard"
import { Button, shape, spacing, Stack, styled, Text } from "@hub-la/design-system"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Alert, Skeleton, Snackbar } from "@mui/material"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"

const QrCode = styled("img")(() => ({
  maxWidth: 160,
  maxHeight: 160,
  padding: spacing(3),
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: shape("small"),
  alignSelf: "center",
}))

type Props = {
  qrcode: string
  code: string
}

export const Pix = ({ code, qrcode }: Props) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const handleCopyClipboard = (code: string) => new CopyClipboard().execute(code).then(() => setIsOpen(true))

  return (
    <Stack spacing={2}>
      <Stack direction="column" spacing={6} textAlign="center">
        <Text alignSelf="center">
          <Trans i18nKey="userSubscriptions.upgradePlanModal.pix.title" components={[<strong />]} />
        </Text>

        {!qrcode ? (
          <Skeleton variant="rectangular" width={160} height={160} animation="wave" sx={{ alignSelf: "center" }} />
        ) : (
          <QrCode src={qrcode} alt="QR code pix" />
        )}

        <Button
          hierarchy="secondary"
          fullWidth
          onClick={() => handleCopyClipboard(code)}
          loading={!code}
          startIcon={<ContentCopyIcon />}
        >
          {t("userSubscriptions.upgradePlanModal.pix.copyCode")}
        </Button>
      </Stack>
      <Text textAlign="left" variant="caption" color="outline">
        {t("userSubscriptions.upgradePlanModal.pix.footer")}
      </Text>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        autoHideDuration={3000}
      >
        <Alert severity="success" icon={<CheckCircleIcon />} onClose={() => setIsOpen(false)}>
          {t("userSubscriptions.upgradePlanModal.pix.codeCopiedSuccessfully")}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
