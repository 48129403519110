import { User } from "@chatpay/common"
import { AnalyticsProviderType, IAnalyticsProvider } from "./Tracker"
import { Firebase } from "../Service"

export class AppcuesProvider implements IAnalyticsProvider {
  init(): void {}

  identify(user?: User | null): void {
    if (user?.id && user?.isSeller) {
      try {
        ;(window as any).Appcues.identify(user.id)
      } catch (e) {
        console.error("Appcues is not setup up correctly")
      }
    }
  }

  identifyFB(user: firebase.default.User, providers?: AnalyticsProviderType[]): void {}

  page(name: string, params?: any, providers?: AnalyticsProviderType[]): void {
    this.identify(Firebase.currentUser)
  }

  reset(providers?: AnalyticsProviderType[]): void {}

  track(event: string, params?: any, entities?: any, providers?: AnalyticsProviderType[]): void {}

  type(): AnalyticsProviderType {
    return AnalyticsProviderType.APPCUES
  }
}
