export const activity = {
  new: "Novos",
  removed: "Removidos",
  all: "Todos",
  product: "Produto",
  contact: "Entrar em contato",
  client: "Cliente",
  analysis: "Análise",
  status: "Status",
  date: "Data",
  removeIn: "Remove em",
  noData: "Sem dados",
  Analysis: {
    unknown: "Usuário não identificado pela Hubla.",
    member: "Membro pagante em dia.",
    paymentIdentified: "Pagamento identificado.",
    notAMember: "Usuário não fez a compra do grupo.",
    whitelisted: "Adicionado à whitelist.",
    admin: "Entrada de Administrador.",
    expired: "Assinatura expirada.",
    removedByBot: "Removido pelo bot.",
    removedByAdmin: "Removido pelo Administrador.",
  },
  Status: {
    unknown: "Não identificado",
    member: "Pago",
    paymentIdentified: "Pago",
    notAMember: "Não identificado",
    whitelisted: "Membro gratuito",
    admin: "Administrador",
    removedByBot: "Removido",
    removedByAdmin: "Removido",
  },
  StatusFilter: {
    removed: "Removidos",
    paid: "Pagos",
    admin: "Administradores",
    whitelist: "Membros gratuitos",
    unknown: "Não identificados",
  },
  Subtitle: {
    enterGreen: "Entrada no grupo com autorização.",
    enterRed: "Entrada no grupo sem autorização.",
    leaveRed: "Usuário removido do grupo.",
  },
  SubHeader: "Saiba quem entrou ou saiu dos seus grupos.",
  disclaimer: "* Essa lista representa os membros que entraram ou foram removidos do seu grupo após dia 8 de Jan.",
}
