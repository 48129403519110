import { routes } from "@chatpay/common"
import { Service } from "@chatpay/components"
import { Card as DSCard, Stack, Text } from "@hub-la/design-system"
import ArrowRight from "@mui/icons-material/ArrowRight"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

type Props = {
  href: string
  icon: JSX.Element
  text: string
}

const Card: React.FC<Props> = (props) => {
  const { href, icon, text } = props
  const history = useHistory()
  return (
    <DSCard variant="filled" size="small" onClick={() => history.push(href)}>
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Stack direction="row" gap={2} width="max-content">
          {icon}
          <Text variant="body2">{text}</Text>
        </Stack>
        <ArrowRight />
      </Stack>
    </DSCard>
  )
}

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const currentUser = Service.Firebase.currentUser

  return (
    <Stack
      marginBottom={8}
      direction={["column", "row"]}
      justifyContent="space-between"
      alignItems={["start", "center"]}
      gap={2}
      width="100%"
    >
      <Text variant="h1">
        {currentUser?.firstName ? t("userGroups.hello", { name: currentUser.firstName }) : t("userGroups.title")}
      </Text>
      <Stack direction={["column", "row"]} gap={2} width={["100%", "fit-content"]}>
        <Card href={routes.USER_INVOICES.LINK} icon={<ManageSearchIcon />} text={t("userGroups.userTransactions")} />
        <Card
          href={routes.USER_SUBSCRIPTIONS.LINK}
          icon={<CurrencyExchangeIcon />}
          text={t("userGroups.userSubscriptions")}
        />
      </Stack>
    </Stack>
  )
}
