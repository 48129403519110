import React, { useState } from "react"
import { Button, Container, Link, Modal, Stack, styled, Tag, Text, useUtility } from "@hub-la/design-system"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useIsMobile } from "../../hooks/use-is-mobile"
import Cookies from "js-cookie"

const StackBgWhite = styled(Stack)`
  &::before {
    position: absolute;
    inset: 0;
    background: #fff;
    width: 100%;
    height: calc(100%);
    content: "";
    z-index: -1;
    display: block;
  }
`

const getRedirectUrl = () => {
  const url = new URL(window.location.href)
  const hostnameParts = url.hostname.split(".")

  // Check if the current subdomain is `app`
  if (hostnameParts[0] === "app") {
    // Change the subdomain to `app2`
    hostnameParts[0] = "app2"
  } else {
    // Add `app2` as the subdomain
    hostnameParts.unshift("app2")
  }
  url.hostname = hostnameParts.join(".")

  // Check if the current path contains "dashboard"
  if (url.pathname.includes("dashboard")) {
    url.pathname = "/dashboard"
  }

  return url.toString()
}

const redirectToNewExperience = () => {
  Cookies.set("new_experience", "true", {
    expires: 365,
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    sameSite: "Lax",
  })
  // Redirect to the new URL
  window.location.href = getRedirectUrl()
}

const NewExperienceAlert = () => {
  const { palette } = useUtility()
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const modalImage = require("assets/images/analytics.png")
  if (typeof window === "undefined" || typeof window.navigator === "undefined") {
    return <></>
  }

  // If there is a cookie new_experience, redirect instantly
  if (Cookies.get("new_experience") === "true") {
    redirectToNewExperience()
    return <></>
  }

  // Return empty if url does not have a query param "new=true"
  // const url = new URL(window.location.href)
  // if (!url.searchParams.get("new")) {
  //   return <></>
  // }

  const onClose = () => setOpen(false)
  return (
    <StackBgWhite width="100vw" bgcolor={palette("success")} py={2} position="relative">
      <Container maxWidth="xl">
        <Stack alignItems="center" flexDirection="row" gap={2} width={"100%"} justifyContent={"center"}>
          <Tag label={"Novo"} variant="positive" size="small" />
          <Text color={"onSuccess"}>{t("newExperienceAlert.title")}</Text>
          <Link variant="body1" color="#fff !important" onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
            {t("newExperienceAlert.buttonText")}
          </Link>
        </Stack>
      </Container>
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <Stack direction="column" gap={8}>
          {/** Header */}
          <Stack direction="column" gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text variant="h4">{t("newExperienceAlert.modal.title")}</Text>
              <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Text color="outline">{t("newExperienceAlert.modal.text")}</Text>
          </Stack>
          <Stack direction="column" gap={4}>
            <img src={modalImage} alt="Nova experiência" />
          </Stack>

          {/** Submit Button */}
          <Stack direction={["column", "row"]} gap={3}>
            <Button variant="outlined" hierarchy="secondary" onClick={onClose} fullWidth>
              {t("newExperienceAlert.modal.cancel")}
            </Button>
            <Button onClick={redirectToNewExperience} fullWidth>
              {t("newExperienceAlert.modal.confirm")}
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </StackBgWhite>
  )
}

export { NewExperienceAlert }
