import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import merge from "deepmerge"
import { inject } from "inversify"
import { PartialDeep } from "type-fest"
import { GetSessionInput, GetSessionOutput } from "../domain/dtos/get-session"
import { Envs } from "../envs"

export class GetSession {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(data: Partial<GetSessionInput>): Promise<GetSessionOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(),
      body: this.makeBody(data),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new Error("Não foi possível criar a sessão do cliente")
    }
  }

  private makeBody(data: Partial<GetSessionInput>): GetSessionInput {
    return merge<GetSessionInput, PartialDeep<GetSessionInput>>(data, {
      country: "BR",
    })
  }

  private makeUrl() {
    return `${Envs.BFF_WEB_URL}/pay/session`
  }
}
