import { Button, Stack, Text } from "@hub-la/design-system"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton, Skeleton } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

export const Loading: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" gap={8}>
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text variant="h4">{t("userSubscriptions.changePaymentMethodModal.title")}</Text>
          <IconButton data-testid="close-button" sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Text color="outline">{t("userSubscriptions.changePaymentMethodModal.subtitle")}</Text>
      </Stack>

      <Stack direction="row" gap={1}>
        <Skeleton width={159} height={56} />
        <Skeleton width={69} height={56} />
      </Stack>

      <Stack direction={["column", "row"]} gap={3}>
        <Button onClick={onClose} hierarchy="secondary" variant="outlined" fullWidth>
          {t("userSubscriptions.changePaymentMethodModal.cancel")}
        </Button>
        <Button loading fullWidth>
          {t("userSubscriptions.changePaymentMethodModal.save")}
        </Button>
      </Stack>
    </Stack>
  )
}
