import axios from "axios"
import { Firebase } from "../Service"
import API from "./API"
import { Interface } from "@chatpay/common"

export default class CommunicationScheduler extends API {
  public async cancelCommunications(subscriptionId: string): Promise<void> {
    const response = await axios.request({
      url: `${this.getUrl()}/communication-Scheduler/cancel-communications?subscriptionId=${subscriptionId}`,
      headers: this.getHeader(),
    })
    const responseData: void = response.data
    return responseData
  }

  private getUrl() {
    const prefix = process.env.REACT_APP_BFF_URL
    return prefix
  }

  private getHeader() {
    const AuthToken = Firebase.accessToken
    return { "Content-Type": "application/json", Authorization: `Bearer ${AuthToken}` }
  }
}
