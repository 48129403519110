"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DashboardStatus = void 0;
var DashboardStatus;
exports.DashboardStatus = DashboardStatus;

(function (DashboardStatus) {
  DashboardStatus["paid"] = "paid";
  DashboardStatus["pending"] = "pending";
  DashboardStatus["canceled"] = "canceled";
  DashboardStatus["expired"] = "expired";
  DashboardStatus["refunded"] = "refunded";
  DashboardStatus["chargeback"] = "chargeback";
})(DashboardStatus || (exports.DashboardStatus = DashboardStatus = {}));