import moment from "moment"
import "moment/locale/pt-br"
import { SubscriptionStatus } from "../domain/enums/subscription-status"
import { SubscriptionType } from "../domain/enums/subscription-type"

export class SubscriptionDateBuilder {
  private status: SubscriptionStatus
  private type: SubscriptionType
  private nextDueAt?: string
  private cancelEta?: string
  private inactivatedAt?: string

  constructor(
    status: SubscriptionStatus,
    type: SubscriptionType,
    nextDueAt?: string,
    cancelEta?: string,
    inactivatedAt?: string,
  ) {
    this.status = status
    this.type = type
    this.nextDueAt = nextDueAt
    this.cancelEta = cancelEta
    this.inactivatedAt = inactivatedAt
  }

  public execute(): string {
    moment.locale("pt-br")
    const format = (date?: string) => moment(date).format("D MMM. YYYY")
    if (this.type === SubscriptionType.TYPE_FREE) return format(this.cancelEta)
    switch (this.status) {
      case SubscriptionStatus.ACTIVE:
        return format(this.nextDueAt)
      case SubscriptionStatus.CANCELED:
        return format(this.cancelEta)
      case SubscriptionStatus.INACTIVE:
        return format(this.inactivatedAt)
      default:
        return ""
    }
  }

  public getI18nKey(): string {
    if (this.type === SubscriptionType.TYPE_FREE) return "free"
    switch (this.status) {
      case SubscriptionStatus.ACTIVE:
        return "active"
      case SubscriptionStatus.CANCELED:
        return "canceled"
      case SubscriptionStatus.INACTIVE:
        return "inactive"
      default:
        return ""
    }
  }
}
