import { Service } from "@chatpay/components"
import { Model } from "@chatpay/common"
import i18n from "i18n"
import * as React from "react"
import ReactDOM from "react-dom"
import { Input } from "semantic-ui-react"
import { InputLabel } from "../InputLabel"

interface IProps {
  title?: string
  currency?: string
  name?: string
  value?: number
  required?: boolean
  disabled?: boolean
  opaque?: boolean
  fluid?: boolean
  forceRange?: boolean
  range?: { min?: number; max?: number } | null
  onChange?: (component: InputPrice, input: HTMLInputElement) => void
  onBlur?: (component: InputPrice, input: HTMLInputElement) => void
}

interface IState {
  error: boolean
}

class InputPrice extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    error: false,
  }

  private inputRef = React.createRef<Input>()

  private get max(): number {
    const { forceRange, range } = this.props
    if (forceRange) {
      return range?.max ?? 0
    }
    return Math.min(
      range?.max ?? parseInt(process.env.REACT_APP_IUGU_MAX_PRICE ?? "0", 10),
      parseInt(process.env.REACT_APP_IUGU_MAX_PRICE ?? "0", 10),
    )
  }

  public get input(): HTMLInputElement | undefined | null {
    const element = this.inputRef.current ? (ReactDOM.findDOMNode(this.inputRef.current) as HTMLElement) : null
    return element?.querySelector("input") as HTMLInputElement
  }

  public get name(): string | undefined | null {
    return this.inputRef.current?.props.name
  }

  public get value(): number {
    return this.inputValue
  }

  public get isValid(): boolean {
    return !this.state.error && this.isValueValid(this.value) && (this.validity?.valid ?? true)
  }

  public get validity(): ValidityState | null | undefined {
    return this.input?.validity
  }

  private get inputValue(): number {
    return parseInt(this.input?.value.numbers() ?? "0", 10) / 100
  }

  public componentDidMount() {
    if (!this.props.value) {
      return
    }
    this.setState({
      error: !this.isValueValid(this.props.value ?? 0),
    })
  }

  private isValueValid(value: number): boolean {
    const { range } = this.props
    return value >= (range?.min ?? 0) && value <= this.max
  }

  private onChange = ({ target }: { target: HTMLInputElement }) => {
    const { value } = target
    const price = Number(value.replace(/\D/g, "")) / 100
    target.value = Service.Global.Price.currencyFormat(
      price,
      Service.Firebase.currentUser?.currency ?? Model.Currency.brl,
      { symbol: false },
    )

    this.setState(
      {
        error: false,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this, target)
        }
      },
    )
  }

  private onBlur = ({ target }: { target: HTMLInputElement }) => {
    const { value } = target

    const price = parseInt(String(value).numbers(), 10) / 100

    this.setState(
      {
        error: !this.isValueValid(price),
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(this, target)
        }
      },
    )
  }

  public render() {
    const userCurrency = Service.Global.Price.userCurrency()

    const {
      value,
      fluid = true,
      disabled,
      required = true,
      name = "price",
      range,
      title = i18n.t("Price"),
      currency = userCurrency,
      opaque,
    } = this.props

    const { error } = this.state
    return (
      <InputLabel
        title={title}
        errorText={`O preço deve ser entre ${Service.Global.Price.currencyFormat(
          range?.min ?? 0,
          currency ?? Model.Currency.brl,
        )} e ${Service.Global.Price.currencyFormat(this.max, currency ?? Model.Currency.brl)}`}
      >
        <Input
          ref={this.inputRef}
          error={error}
          fluid={fluid}
          name={name}
          input={{ opaque: opaque ? "true" : "false" }}
          maxLength={10}
          label={{
            basic: true,
            content: Service.Global.Price.currencySymbol(currency ?? Model.Currency.brl),
          }}
          required={required}
          disabled={disabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          placeholder="0,00"
          defaultValue={Service.Global.Price.currencyFormat(value ?? 0, currency ?? Model.Currency.brl, {
            symbol: false,
          })}
        />
      </InputLabel>
    )
  }
}

export default InputPrice
