import React from "react"
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom"
import { compose } from "recompose"
import AuthUserContext from "./Context"
import { withFirebase } from "../Service/withFirebase"
import Firebase from "../Service/Firebase"

export interface IProps extends RouteComponentProps {
  firebase: Firebase
}

interface IAuthRoute {
  path: string
  state?: any
}

export type GComponent = React.ComponentClass<any, any> | React.FC<any>

export const withAuthorization =
  (condition: (arg: any) => boolean, authRoute?: IAuthRoute) => (InnerComponent: GComponent) => {
    class WithAuthorization extends React.Component<IProps, {}> {
      public static contextType = AuthUserContext
      public context!: React.ContextType<typeof AuthUserContext>

      public render() {
        if (authRoute?.state) {
          authRoute.state.redirectTo = window.location.pathname
        }
        return (
          <AuthUserContext.Consumer>
            {(state) =>
              condition(state.authUser) ? (
                <InnerComponent {...this.props} />
              ) : (
                <Redirect
                  to={{
                    pathname: authRoute?.path ?? `/auth`,
                    state: authRoute?.state,
                  }}
                />
              )
            }
          </AuthUserContext.Consumer>
        )
      }
    }

    return compose<IProps, any>(withRouter, withFirebase)(WithAuthorization)
  }

export default withAuthorization
