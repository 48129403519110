"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
var Status;
exports.Status = Status;

(function (Status) {
  Status[Status["created"] = 0] = "created";
  Status[Status["pending"] = 1] = "pending";
  Status[Status["rejected"] = 2] = "rejected";
  Status[Status["accepted"] = 3] = "accepted";
})(Status || (exports.Status = Status = {}));