import { routes } from "@chatpay/common"
import { Stack, Text, TextField, palette, styled } from "@hub-la/design-system"
import ArrowRight from "@mui/icons-material/ArrowRight"
import { Autocomplete as MuiAutocomplete } from "@mui/material"
import avatar from "assets/images/placeholder-image.svg"
import { useIsMobile } from "hooks/use-is-mobile"
import React from "react"
import { useHistory } from "react-router-dom"
import { textEllipsis } from "utils/textEllipsis"
import { Subscription } from "../../../../domain/dtos/subscription"

/** @todo Use the image optimization from hubla-nx */
const Avatar = styled("img")`
  width: 70px;
  height: 46px;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid ${palette("surfaceVariant")};
`

const Option = styled(Stack)`
  cursor: pointer;
  transition: background-color 0.2s;
  :hover {
    background-color: ${palette("surfaceVariant")};
  }
`

type Props = {
  data: Subscription[]
  loading?: boolean
}

const Autocomplete: React.FC<Props> = (props) => {
  const { data, loading } = props
  const isMobile = useIsMobile()
  const history = useHistory()

  const redirectToSubscriptionDetail = (id?: string) => history.push(routes.USER_SUBSCRIPTIONS.LINK + "/" + id)

  return (
    <MuiAutocomplete
      disablePortal
      noOptionsText={"Nenhuma assinatura encontrada."}
      loadingText={"Carregando..."}
      loading={loading}
      disableCloseOnSelect
      sx={{ width: isMobile ? "auto" : 356 }}
      options={data.map((option) => {
        return { id: option?.id, label: option.items[0]?.productName, picture: option.items[0]?.picture }
      })}
      renderOption={(_, option) => (
        <Option
          key={option.id}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={3}
          onClick={() => redirectToSubscriptionDetail(option.id)}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Avatar src={option.picture ?? avatar} alt={option.label} />
            <Text variant="body1">{isMobile ? textEllipsis(option.label, 20) : option.label}</Text>
          </Stack>
          <ArrowRight />
        </Option>
      )}
      renderInput={(params) => <TextField {...params} label="Buscar por produto" size="small" />}
    />
  )
}

export default Autocomplete
