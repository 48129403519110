import React from "react"
import { Document } from "@chatpay/common"

export interface IState {
  authUser?: Document.User
  accessToken?: string
}

const AuthUserContext = React.createContext<IState>({})

export default AuthUserContext
