import * as React from "react"
import { Text, Title } from "typography"

import "./InputLabel.scss"

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: "small" | "medium"
  title?: string
  errorText?: string | null
}

interface IChildrenProps {
  error?: boolean
  disabled?: boolean
}

const InputLabel = class extends React.Component<IProps, {}> {
  public childrenProps: IChildrenProps = {}

  public findField(children: React.ReactNode) {
    React.Children.forEach(children, (element) => {
      if (!element || !React.isValidElement(element)) {
        return
      }
      const props = (element.props as IChildrenProps) ?? null
      if (props?.error !== undefined) {
        this.childrenProps = props
        return
      }
      if (!(element.type instanceof Function) && element.props?.children) {
        this.findField(element.props.children)
        return
      }
    })
  }

  public render() {
    const { title, errorText, children, style, size } = this.props

    this.findField(children)
    const { error, disabled } = this.childrenProps

    return (
      <div className={`InputLabel cp${error ? " error" : ""}${disabled ? " disabled" : ""}`} style={style}>
        <span style={{ width: "100%" }}>
          {size === "small" ? <Title.Tiny>{title || ` `}</Title.Tiny> : <Title.Small>{title || ` `}</Title.Small>}
        </span>
        <div className="input-label-content">{children}</div>
        {error && errorText ? (
          <Text.Tiny color="#9f3a38" style={{ marginTop: "4px" }}>
            {errorText}
          </Text.Tiny>
        ) : null}
      </div>
    )
  }
}

export default InputLabel
