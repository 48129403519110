"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["get"] = "get";
  Name["getByGroup"] = "getByGroup";
  Name["create"] = "create";
  Name["install"] = "install";
  Name["getGroupResourceByProvider"] = "getGroupResourceByProvider";
})(Name || (exports.Name = Name = {}));