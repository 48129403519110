import React, { useRef } from "react"
import * as tinymce from "@tinymce/tinymce-react"
import { API } from "@chatpay/components"
import * as uuid from "uuid"
import { Editor as TinyMCEEditor } from "tinymce"

interface IProps extends Partial<tinymce.IAllProps> {
  initialValue?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onEditorChange?: (data: string, editor: TinyMCEEditor) => void
}

const Editor: React.FunctionComponent<IProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { initialValue, value, placeholder, onEditorChange, disabled, init } = props

  return (
    <>
      <tinymce.Editor
        disabled={disabled}
        value={initialValue || value}
        apiKey={process.env.REACT_APP_TINYMCE}
        init={{
          min_height: 400,
          branding: false,
          skin: "snow",
          placeholder: placeholder ?? "Descreva sobre o seu produto...",
          menubar: false,
          content_style: "body { font-size: 18px; }",
          fontsize_formats: "18px 22px 28px 30px",
          toolbar1: "undo redo | fontsizeselect | bold italic | link image ",
          toolbar2: "alignleft aligncenter alignright media",
          plugins: "autoresize image media table link",
          allow_script_urls: true,
          elementpath: false,
          icons: "thin",
          max_height: 1000,
          paste_data_images: true,
          automatic_uploads: true,
          images_upload_handler: async (blobInfo, success, failure, progress) => {
            await new API.File()
              .upload(blobInfo.blob() as File, `/public/description/${uuid.v4()}`, (percent) => {
                console.log({ percent, progress })
                progress?.(percent)
              })
              .then((fc) => (fc?.URL ? success(fc.URL) : failure("Fc is null")))
              .catch((e) => failure(e))
          },
          file_picker_types: "image",
          file_picker_callback: (cb: any, value: any, meta: any) => {
            inputRef.current?.click()
            inputRef.current!.onchange = async (ev) => {
              const event = ev as any
              const f = event.target.files[0]
              const fc = await new API.File().upload(f, `/public/description/${uuid.v4()}`)
              cb(fc.URL, { title: fc.name, width: "100%", height: "auto" })
            }
          },
          ...init,
        }}
        onEditorChange={(data, editor) => onEditorChange!(data, editor)}
      />
      <input
        data-testid="editor-upload"
        disabled={false}
        ref={inputRef}
        hidden={true}
        type="file"
        multiple={false}
        max-size="2000"
        accept={process.env.REACT_APP_IMAGE_MIMETYPE_ALLOWED}
      />
    </>
  )
}

export default Editor
