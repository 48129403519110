export const AccountVerification = {
  rejected: {
    title: "Your account account has been rejected",
    subtitle: "Contact support so we can see what happened.",
    button: "Back to home page",
  },
  pending: {
    title: "Your account is being verified",
    subtitle:
      "Keep an eye out. We'll email you as soon as your account is verified or if we need some extra information. ",
    button: "Access your account",
  },
  done: {
    title: "Your account has been successfully verified",
    subtitle: "Now you are ready to sell and receive from Hubla.",
    button: "Continue",
  },
  info: {
    title: "You are almost there",
    subtitle: "We need some extra information so that you can receive money from your groups",
    altSubtitle: "We need some extra information so that you can receive money",
    country: "Country and Currency",
    countryPlaceholder: "Choose your country",
    continue: "continue",
    verifyAccount: "verify account",
    fullname: "Full name or Company name",
    description: "Description of your business",
    tip: "The registered CPF or CNPJ must be the same as that of the cardholder.",
    error: "Invalid information",
  },
  cta: "Verify bank account",
}
