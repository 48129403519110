import React, { useState } from "react"
import { Input, InputProps, InputOnChangeData } from "semantic-ui-react"
import { InputLabel } from "../InputLabel"
import { cpf, cnpj } from "cpf-cnpj-validator/dist/cpf-cnpj-validator.cjs"
import { Helper } from "@chatpay/common"

interface IProps extends InputProps {
  value?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => any
}

const InputDocumentControlled: React.FunctionComponent<IProps> = (props) => {
  const [value, setValue] = useState<string>()
  const [type, setType] = useState<Helper.DocumentType>(Helper.DocumentType.cpfOrCnpj)

  const newProps: InputProps = {
    value: props.value,
  }

  const getText = (): string => {
    switch (type) {
      case Helper.DocumentType.cpf:
        return "CPF"
      case Helper.DocumentType.cnpj:
        return "CNPJ"
      case Helper.DocumentType.cpfOrCnpj:
        return "CPF ou CNPJ"
      default:
        return type
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { onChange } = props

    let newValue = data.value.replace(/[^\d]/g, "")

    if (newValue.length === 11) {
      newValue = newValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      setType(Helper.DocumentType.cpf)
    } else if (newValue.length === 14) {
      newValue = newValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
      setType(Helper.DocumentType.cnpj)
    } else {
      setType(Helper.DocumentType.cpfOrCnpj)
    }

    setValue(newValue)

    const newData: InputOnChangeData = {
      value: newValue,
    }

    if (onChange) {
      onChange(event, newData)
    }
  }

  const checkValidity = (): boolean => {
    if (type === Helper.DocumentType.cpf) {
      return cpf.isValid(value)
    } else if (type === Helper.DocumentType.cnpj) {
      return cnpj.isValid(value)
    }
    return true
  }

  return (
    <InputLabel title={getText()} errorText={`${getText()} Inválido`}>
      <Input {...newProps} onChange={handleChange} fluid={true} type="tel" error={!checkValidity()} />
    </InputLabel>
  )
}

export { InputDocumentControlled }
