export const EditAbout = {
  infoHeading:
    "🎉 Vamos começar criando uma comunidade do zero. Descreva as informações básicas do seu grupo, como nome, preço, plataforma, etc...",
  descriptionHeading: "⚡ Descrições bem escritas podem trazer tráfego orgânico para sua página",
  advantagesHeading: "🎯 O conteúdo da sua página é uma ótima ferramenta para aumentar a conversão de compra",
  faqHeading: "💎 Não deixe seu cliente esperando por uma resposta antes de efetuar o pagamento",
  assetsHeading: "📚 Adicione uma galeria de vídeos ou imagens para mostrar um pouco mais do seu grupo",
  back: "Voltar",
  next: "Próximo",
  save: "Salvar",
  description: {
    title: "Descreva sua comunidade",
    subtitle:
      "A descrição do seu grupo pode aumentar significativamente a conversão de compra na sua página de vendas. Seja claro, breve e conte um pouco sobre a sua comunidade.",
    tip: {
      title: "Olha só esse exemplo de uma ótima descrição 😎",
      text1:
        "Nessa mentoria você será direcionado em seus desafios, erros e acertos para atingir um crescimento exponencial em sua carteira de clientes e realmente viver de gestão de tráfego para negócios locais.",
      text2: "Toda semana, acontecerá uma mentoria ao vivo, com conteúdos exclusivos diretamente do campo de batalha.",
    },
  },
  advantages: {
    title: "Descreva algumas vantagens da sua comunidade",
    subtitle:
      "Adicione uma lista de vantagens para esclarecer ao cliente, qual tipo de acesso, conteúdo e outros benefícios ele irá adquirir ao fazer parte da sua comunidade.",
    tip: {
      title: "Veja um ótimo exemplo de uma lista de vantagens 🤠",
      text1: "Acesso a um webinar no Zoom por semana",
      text2: "Tips diárias dentro do grupo",
      text3: "+3 Ebooks exclusivos",
    },
  },
  faq: {
    title: "Perguntas frequentes",
    subtitle:
      "Ao criar uma lista de perguntas frequentes, você reduz a quantidade de clientes que acionam o seu time de suporte, ou até mesmo deixam de participar por seu grupo por conta de uma dúvida.",
    tip: {
      title: "Exemplo de uma ótima sessão de perguntas frequentes 😎",
      1: {
        question: "Qual a política de estorno do grupo?",
        answer:
          "Pedidos de reembolso são aceitos automaticamente dentro do prazo de 7 dias a partir da primeira compra, após esse período, cada caso é analisado individualmente.",
      },
      2: {
        question: "Como posso me afiliar?",
        answer:
          "Caso o grupo tenha o programa de afiliados habilitado, você pode se afiliar a ele. Isto é, se você vender pelo link gerado na plataforma de afiliados, você receberá comissões para cada venda que fizer.",
      },
      3: {
        question: "Troquei o número de celular, e agora?",
        answer:
          "Não se preocupe, você não perderá acesso ao grupo. Entre em contato com o suporte da Hubla que os atendentes vão conseguir trocar o número para você.",
      },
      4: {
        question: "Posso comprar com cartão de outra pessoa?",
        answer:
          "Sim, você pode comprar com o cartão de outra pessoa. Seus dados pessoais não são vinculados aos dados do titular do cartão.",
      },
    },
  },
  assets: {
    title: "Adicione mídias",
    subtitle:
      "Vídeos podem explicar melhor como irá funcionar o seu grupo, imagens parar criar uma prova social ou resultados que a sua comunidade irá proporcionar.",
    tip: {
      title: "Mostre com mais detalhes o que o seu grupo irá proporcionar 📸",
      text1:
        "Trazer um vídeo explicando o seu conteúdo irá ajudar os seus clientes a sanarem suas dúvidas quanto ao seu produto.",
      text2:
        "Além disso, você pode adicionar várias imagens de antes e depois ou até mesmo alguns prints de depoimentos dos seus clientes.",
    },
  },
  points: "pontos",
}
