import { Events } from "@chatpay/common"
import { Session } from "@chatpay/components"
import { ThemeProvider, theme, Stack } from "@hub-la/design-system"
import { UserRefundProvider, UserRefundPage } from "@hub-la/fe-user-refund"
import { ContainerProvider, container } from "@hub-la/fe-container"
import { CssBaseline } from "@mui/material"
import * as React from "react"
import { ScreenTrack } from "utils/AnalyticsTags"
import { withUserAuthenticated } from "utils/SessionUtils"
import AppHeader from "./app-header"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const UserRefundsComponent: React.FC = () => (
  <ContainerProvider container={container}>
    <ThemeProvider theme={theme.dark}>
      <CssBaseline />
      <QueryClientProvider client={new QueryClient()}>
        <ScreenTrack screenName={Events.USER.REFUND_VIEWED}>
          <Session.Context.Consumer>
            {(state) =>
              state.authUser && (
                <Stack direction="column" gap={8}>
                  <AppHeader />

                  <UserRefundProvider container={container}>
                    <UserRefundPage />
                  </UserRefundProvider>
                </Stack>
              )
            }
          </Session.Context.Consumer>
        </ScreenTrack>
      </QueryClientProvider>
    </ThemeProvider>
  </ContainerProvider>
)

export default withUserAuthenticated(UserRefundsComponent)
