export const adminDashboard = {
  Groups: "Groups",
  Sales: "Sales",
  Renew: "Renewings",
  Volume: "Gross Volume",
  Subscriptions: "Subscriptions",
  Affiliates: "Affiliates",
  Refunds: "Refunds",
  Last7Days: "Last 7 Days",
  "15Days": "15 Days",
  "30Days": "30 Days",
  Total: "Total",
  Invoices: "Invoices",
  Situation: "Status",
  CreationDate: "Creation Date",
  PaidAt: "Paid At",
  PaymentMethod: "Payment Method",
  Name: "Buyer",
  Product: "Product",
  Plan: "Plan",
  DueDate: "Due date",
  CreatedAt: "Start",
  Status: "Status",
  PhoneNumber: "Phone Number",
  last: "Last",
  days: "Days",
  Active: "Active",
  Drafts: "Drafts",
  Inactive: "Inactive",
  noPublishes: "You have no published groups yet.",
  noDrafts: "You have no draft groups yet.",
  noInactive: "You have no inactive groups yet.",
  anyGroups: "You hasn't published any groups yet.",
  pending: "Pending",
  paid: "Paid",
  canceled: "Canceled",
  expired: "Expired",
  refunded: "Refunded",
  chargeback: "Chargeback",
  all: "All",
  export: "Export",
  betweenDates: "Between dates",
  textFilter: `Use text filter: "{{query}}"?`,
  fileType: "File type",
  cancel: "Cancel",
  invoice: "invoice",
  subscription: "subscription",
  search: "Search...",
  pendings: "Pendings",
  paids: "Paids",
  expireds: "Expireds",
  Details: "Details",
  see: "More",
  Picture: "Picture",
  name: "Name",
  activated: "Active",
  active: "Active",
  suspended: "Suspended",
  subscriptionExpired: "Expired",
  subscriptionActives: "Active",
  dues: "Dues",
  suspendeds: "Suspendeds",
  value: "Value",
  refunds: "Refund Requests",
  purchase: "Purchase",
  request: "Request",
  refused: "Refused",
  failed: "Failed",
  subscriptionActivated: "New Subscriptions",
  subscriptionCanceled: "Churn",
  Members: "Members",
  MembersSubheader: "Manage the entry and exit of members in your groups.",
  accessTokenError: "We had a problem loading your data, please reload the page.",
  results: "results found.",
  exportProduct: "Export from product",
  sales: "new sales",
  renews: "renewals",
  RefundTitle: "Refunds and Chargebacks",
  Waitlist: "Waitlist",
  Wallet: "Wallet",
  Extras: "Extras",
  tips: "Check out the tips we separate to help you with your group",
}
