import React, { createContext } from "react"
import { useEffect, useState } from "react"

export const MenuBurgerContext = createContext<{
  isOpen: boolean
  toggleMenu: () => void
  openMenu: () => void
  closeMenu: () => void
}>({
  isOpen: false,
  openMenu: () => {},
  toggleMenu: () => {},
  closeMenu: () => {},
})

export const MenuBurgerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.body.classList.toggle("dashboard-menu-open")
  }, [isOpen])

  const openMenu = () => {
    setIsOpen(true)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const toggleMenu = () => {
    setIsOpen((b) => !b)
  }

  return (
    <MenuBurgerContext.Provider value={{ closeMenu, isOpen, openMenu, toggleMenu }}>
      {children}
    </MenuBurgerContext.Provider>
  )
}
