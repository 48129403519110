export const createProduct = {
  title: "Create your product",
  button: "Create product",
  subtitle:
    "Fill in the basic information and define the type of billing for your product and the price that will be charged in minutes.",
  advancedSetting: {
    title: "Advanced setting",
    tooltip: "Activate this option to create different classes in the same product.",
    firstDescription:
      "Create your product and whenever you go to activate sales, we will open a new class with a predefined limit of members according to your strategy.",
    lastDescription:
      "Members of a new class will not have access to groups from previous classes, only to the contents.",
    dialog: {
      title: "Want to build your product with classes? This decision is irreversible.",
      description:
        "By taking this action, you will not be able to manage your product without classes. If you want, you will be forced to create a new product without activating this option.",
      button: {
        cancel: "Cancel",
        enable: "Activate",
      },
    },
  },
}
