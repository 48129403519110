import { Service } from "@chatpay/components"
import { Model } from "@chatpay/common"
import React from "react"
import { Input, InputProps, InputOnChangeData } from "semantic-ui-react"

interface IProps extends InputProps {
  currency?: string
  value?: number
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>, data: IData) => any
}

interface IData extends InputOnChangeData {
  price: number
}

const InputPrice: React.FunctionComponent<IProps> = (props) => {
  const { onFieldChange, ...inputProps } = props

  const newProps: InputProps = {
    ...inputProps,
    label: props.label ?? {
      basic: true,
      content: Service.Global.Price.currencySymbol(props.currency ?? Model.Currency.brl),
    },
    value:
      props.value === 0
        ? ""
        : Service.Global.Price.currencyFormat(props.value ?? 0, props.currency ?? Model.Currency.brl, {
            symbol: false,
          }),
    onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const p = Number(data.value.replace(/\D/g, "")) / 100
      const value = Service.Global.Price.currencyFormat(p, props.currency ?? Model.Currency.brl)
      onFieldChange(event, { ...data, price: value.toFloat() })
    },
  }

  return <Input {...newProps} />
}

export { InputPrice }
