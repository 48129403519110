"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _User = _interopRequireDefault(require("./User"));

var _Document = _interopRequireDefault(require("./Document"));

var _Receiver = _interopRequireDefault(require("../../Documents/Receiver"));

var _Receiver2 = require("../../Types/Receiver");

var _Group = _interopRequireDefault(require("./Group"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var receiverFixture = function receiverFixture(args) {
  return new _Receiver["default"](_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    type: _faker["default"].random.objectElement(_Receiver2.Type),
    user: (0, _User["default"])().toReference(),
    owner: null,
    totalSold: _faker["default"].datatype.number(),
    link: _faker["default"].random.image(),
    commission: {
      isFeeIncluded: _faker["default"].datatype["boolean"](),
      renewal: {
        value: _faker["default"].datatype.number(),
        percent: _faker["default"].datatype.number(),
        isEnabled: _faker["default"].datatype["boolean"]()
      },
      sell: {
        value: _faker["default"].datatype.number(),
        percent: _faker["default"].datatype.number(),
        isEnabled: true
      }
    },
    group: (0, _Group["default"])().toReference()
  }, args));
};

var _default = receiverFixture;
exports["default"] = _default;