import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { GetPendingInvitesOutput } from "modules/user-groups/domain/dtos/get-pending-invites-output"
import { PostRejectInvite } from "modules/user-groups/usecases/post-reject-invite"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { QueryKey } from "../../domain/enums/query-key"

export const usePostRejectInvite = () => {
  const queryClient = useQueryClient()
  const container = useContainer()

  return useMutation<null, Error, string, unknown>(
    [QueryKey.postRejectInvite],
    (inviteId: string) => new PostRejectInvite(container.get(HttpClient)).execute(inviteId),
    {
      retry: false,
      onSuccess: (_, inviteId) => {
        queryClient.setQueryData<GetPendingInvitesOutput | undefined>([QueryKey.getPendingInvites], (data) => {
          if (!data) {
            return data
          }

          return {
            ...data,
            items: data.items.filter((invite) => invite.id !== inviteId),
          }
        })
      },
    },
  )
}
