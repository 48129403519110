"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Method = exports.IUserForEmailStatus = void 0;
var Method;
exports.Method = Method;

(function (Method) {
  Method["whatsapp"] = "whatsapp";
  Method["sms"] = "sms";
  Method["email"] = "email";
})(Method || (exports.Method = Method = {}));

var IUserForEmailStatus;
exports.IUserForEmailStatus = IUserForEmailStatus;

(function (IUserForEmailStatus) {
  IUserForEmailStatus["EXISTS"] = "exists";
  IUserForEmailStatus["CREATED"] = "created";
  IUserForEmailStatus["LOGGED_IN"] = "logged_in";
})(IUserForEmailStatus || (exports.IUserForEmailStatus = IUserForEmailStatus = {}));