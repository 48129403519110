import { IState } from "./GroupsState"
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit"
import { Document } from "@chatpay/common"

export const pendingReducer: CaseReducer<IState, PayloadAction> = (state) => {
  state.status = "loading"
}

export const rejectedReducer: CaseReducer<IState, PayloadAction<Error>> = (state, action) => {
  state.error = action.payload
  state.status = "failed"
}

type Payload = {
  currentUser: Document.User
  groups: Document.Group[]
}

export const fetchGroupsReducer: CaseReducer<IState, PayloadAction<Payload>> = (state, action) => {
  const { groups, currentUser } = action.payload

  const newState: IState = {
    ...state,
    currentUser,
    asAdminGroups: [],
    allGroups: {},
    all: [],
    status: "succeeded",
  }

  groups?.forEach((group: Document.Group) => {
    if (group?.owner?.id === currentUser.id) {
      newState.allGroups = Object.assign({ [group.id]: { group, status: "admin" } }, newState.allGroups)

      newState.asAdminGroups.push(group.id)
    } else {
      newState.allGroups = Object.assign({ [group.id]: { group, status: "member" } }, newState.allGroups)
    }
  })

  newState.all = groups

  return newState
}
