import { createSlice, Slice, PayloadAction } from "@reduxjs/toolkit"
import { Document } from "@chatpay/common"

export interface IState {
  user?: Document.User
}

const initialState: IState = {
  user: undefined,
}

const UserSlice: Slice<IState> = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Document.User>) => {
      return {
        user: action.payload,
      }
    },
  },
})

const { reducer, actions } = UserSlice

export { reducer, actions }
