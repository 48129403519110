export const createGroup = {
  draft: {
    Edit: "Edit",
    BasicTitle: "Basic Information",
    BasicSubtitle: "Cover photo, name and pricing",
    BasicButton: "Add info",
    DescriptionTitle: "Description",
    DescriptionSubtitle: "Tell your audience what is your group about",
    DescriptionButton: "Add a description",
    MaterialTitle: "Material",
    MaterialSubtitle: "Attach e-books, rules or instructions.",
    MaterialButton: "Add material",
    AffiliatesTitle: "Affiliates",
    AffiliatesSubtitle: "Offer a commission to people that spread the word about your group.",
    AffiliatesButton: "Enable affiliates",
    MembersAreaTitle: "Members Area",
    MembersAreaSubtitle: "Add videos, classes and content that will be made available only to your customers",
    MembersAreaButton: "Enable members area",
    Publish: "Publish",
    Preview: "preview your page",
  },
  basic: {
    Title: "CreateGroup",
    Subtitle: "1. Basic information.",
    GroupImage: "Image",
    GroupName: "Group Name",
    GroupSubtitle: "Subtitle",
    Pricing: "Pricing",
    Price: "Price",
    Plan: "Plan:",
    EnableInstallment: "Enable Installment",
    OneInstallment: "Only full payment",
    UpTo: "Up to",
    OneTime: "One time payment",
    Subscription: "Subscription",
    MonthlyPrice: "Monthly Price",
    TotalPrice: "Total Price",
    Platform: "Platform",
    Footer:
      "This value will be charged to anyone in order to get access to the group. Our Smart-bot will keep anyone who did not paid successfully to enter the group. A 10.9% convenience fee will be charged for every transaction.",
  },
  info: {
    Title: "CreateGroup",
    Subtitle: "1. Basic information.",
  },
  description: {
    Title: "Description",
    Subtitle:
      "2. Explain to your audience what kind of content will be deliver in your group. Your description should explain everything your client needs to know to convince them to buy a spot. It can include images, videos, etc.",
  },
  material: {
    Title: "Material",
    Subtitle:
      "3. Write a thank you message to all the new members that just purchased a spot in your group. It is important to help them with instructions and/or group rules.",
    NewMemberMsg: "Message to new members",
    NewMemberMsgHint: "Thank you for the interest in this group. Here are some instructions and rules of the group…",
    RulesFile: "Include PDF attachment",
    InsertRulesFile: "Insert or drag a pdf file",
  },
  affiliates: {
    Title: "Affiliates",
    Subtitle: "4. Sell more by offering a commission to people who promote your group.",
    Affiliates: "Affiliates",
    DisableAffiliates: "Disable affiliates",
    AllowAffiliates: "Allow affiliates",
    CommissionPerPurchase: "Commission per purchase",
    CommissionPerPlanPurchase: "Commission per plan purchase",
    GroupCost: "Your group costs",
    AffiliatesCommission: "Your affiliates will get",
    CommissionPerRenewal: "Commission per subscription renewal ",
    Sale: "sale",
  },
  membersArea: {
    Title: "Members Area",
    Subtitle: "5. Here you can add videos, classes and content that will be made available only to your customers.",
  },
  done: {
    GroupCreated: "Your group has been published!",
    NextSteps: "Next Steps",
    Step1Title: `Everything is ready!`,
    Step1Text:
      "You just created your group but hasn’t entered it yet. Click the button on the bottom of the page to enter.",
    Step2Title: "Share your group link",
    Step2Text: "Choose the channel that you have the biggest audience in.",
    Step3Title: "Know that the ones who purchase a spot will receive the link instantly.",
    Step3Text: "We give access to the group right after the payment is confirmed.",
    Step4Title: "Pro-tip: leave your group to admins-only",
    Step4Text: "So that only group admins can send messages until everyone is in the group and your content starts.",
    Step5Title: "Receive, check and withdraw your balance here in Hubla.",
    Step5Text: "Everything you earn is received here in Hubla.",
    Step6Title: `Track your sales in real time.`,
    Step6Text: `We have a panel where you can track your sales, subscriptions and everything else that happens in your group.`,
    Step7Title: `You can count on us for whatever you need!`,
    Step7Text: `We have a guide to help you have a very successful product.`,
  },
}
