import { Document, Interface } from "@chatpay/common"
import firebase from "firebase/compat/app"
import Cookies from "js-cookie"
import { getSiteHost } from "../Helpers/Url"
import { DB } from "../Service"
import API from "./API"

class Auth extends API {
  public async requestCode(
    phoneNumber: string,
    token: string,
    whatsapp: boolean = false,
  ): Promise<Interface.Auth.IRequestResponse> {
    return (await this.callFunction("authRequestCode", { phoneNumber, token, whatsapp }))
      .data as Interface.Auth.IRequestResponse
  }

  public async validateCode(verificationId: string, code: number): Promise<boolean> {
    return (await this.callFunction("authValidateCode", { verificationId, code })).data as boolean
  }

  public async logInWithLink(id: string): Promise<Interface.Auth.ILogInWithLinkResponse> {
    const { sessionToken, redirectUrl } = (await this.callFunction("authLoginWithLink", { id }))
      .data as Interface.Auth.IAuthLoginWithLinkResponse
    console.log("Retrived session token successfully")
    let user: Document.User | null = null
    try {
      user = await this.logInWithSessionToken(sessionToken)
    } catch (error) {
      console.log(`Could not login user`)
    }
    return { user, redirectUrl }
  }

  private async logInWithSessionToken(sessionToken: string): Promise<Document.User> {
    const userId = (await this.doSignInWithCustomToken(sessionToken)).user?.uid
    if (!userId) {
      throw new Error("User not found")
    }
    const user = await new DB(Document.User).getById(userId)
    if (!user) {
      throw new Error("User not found")
    }
    return user
  }

  public async logInWithCode(
    verificationId: string,
    code: number,
    method?: Interface.Auth.Method,
  ): Promise<firebase.auth.UserCredential> {
    const referral = Cookies.get("referral")
    const response = await this.callFunction("authLogInWithCode", {
      verificationId,
      code,
      referral,
      method,
    })
    return this.doSignInWithCustomToken(response.data)
  }
  public async userForEmail(name: string, email: string, token: string): Promise<Interface.Auth.IUserForEmailResponse> {
    const referral = Cookies.get("referral")
    const params: Interface.Auth.IUserForEmail = {
      name,
      email: email.trim().toLowerCase(),
      token,
      referral,
    }
    return (await this.callFunction("authUserForEmail", params)).data as Interface.Auth.IUserForEmailResponse
  }
  public async recoverPassword(email: string, token: string) {
    const params: Interface.Auth.IRecoverPassword = {
      email: email.trim().toLowerCase(),
      token,
    }
    await this.callFunction("authRecoverPassword", params)
  }

  public async updateCredentials(email: string, password: string, code?: string): Promise<boolean> {
    const currentUser = API.currentUser
    const referral = Cookies.get("referral")
    const params: Interface.Auth.IUpdateCredentials = {
      email: email.trim().toLowerCase(),
      password,
      code,
      referral: referral ?? undefined,
    }
    const token = (await this.callFunction("authUpdateCredentials", params)).data as string
    if (currentUser) {
      return false
    }
    await this.doSignInWithCustomToken(token)
    return true
  }

  public async getToken(): Promise<string | null | void> {
    return fetch(`${getSiteHost()}/api/auth/get`, {
      method: "post",
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 403) {
          return null
        }

        return (await response.json()).token
      })
      .catch((error) => console.error(`getToken`, error))
  }

  public async setToken(idToken: string): Promise<Response | void> {
    Cookies.set("__session", idToken, {
      expires: 15,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
      sameSite: "strict",
    })
  }

  public async signOut(): Promise<Response | void> {
    Cookies.remove("__session")
  }

  public async logInWithToken(token: string): Promise<boolean> {
    if (API.currentUser) {
      return false
    }
    await this.doSignInWithCustomToken(token)
    return true
  }
}

export default Auth
