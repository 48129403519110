import { Interface, Document } from "@chatpay/common"
import { IListResponse } from "@chatpay/common/lib/Interfaces/Withdraw/IFunction"
import API from "./API"

class Withdraw extends API implements Interface.Withdraw.Function.ITemplate {
  public async count(data: Interface.Withdraw.Function.ICount): Promise<number> {
    return (await this.call(Interface.Withdraw.Function.Name.count, data))?.data
  }

  public async get(data: Interface.Withdraw.Function.IGet): Promise<Document.Withdraw> {
    const withdraw = (await this.call(Interface.Withdraw.Function.Name.get, data))?.data
    return new Document.Withdraw(withdraw)
  }

  public async list(data: Interface.Withdraw.Function.IList): Promise<IListResponse | Document.Withdraw[]> {
    const result = (await this.call(Interface.Withdraw.Function.Name.list, data))?.data
    if (result.itens) {
      return result
    }
    return result.map((it: any) => new Document.Withdraw(it))
  }

  public async create(data: Interface.Withdraw.Function.ICreate): Promise<Interface.Withdraw.Function.ICreateResponse> {
    return (await this.call(Interface.Withdraw.Function.Name.create, data))?.data
  }

  private async call(func: Interface.Withdraw.Function.Name, params?: Interface.Withdraw.Function.Params) {
    return await this.callFunction(`withdraw/${func}`, params)
  }
}

export default Withdraw
