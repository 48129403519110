export default class Errors extends Error {
  public readonly title?: string | null
  constructor(message?: string, title?: string | null) {
    super(message)

    this.title = title
    const actualProto = new.target.prototype
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto)
    } else {
      ;(this as any).__proto__ = actualProto
    }
  }
}
