import { Interface } from "@chatpay/common"
import API from "./API"

class ReferralFinancial extends API {
  public async get(): Promise<Interface.ReferralFinancial.Function.IResponse> {
    const userId = API.currentUser?.id
    const response = await this.call(Interface.ReferralFinancial.Function.Name.get, { referrerId: userId ?? "" })
    return response.data
  }

  private async call(
    func: Interface.ReferralFinancial.Function.Name,
    params?: Interface.ReferralFinancial.Function.Params,
  ) {
    return await this.callFunction(`referralFinancial/${func}`, params)
  }
}

export default ReferralFinancial
