import { Stats, StatsVariant } from "@hub-la/design-system"
import { useTranslation } from "react-i18next"
import { SubscriptionStatus } from "../../../../domain/enums/subscription-status"
import React from "react"

type SubscriptionStatusStatsProps = {
  status: SubscriptionStatus
  size: "large" | "small" | "tiny"
}

export const SubscriptionStatusStats = ({ status, size }: SubscriptionStatusStatsProps) => {
  const { t } = useTranslation()

  const getVariant = () => {
    switch (status) {
      case SubscriptionStatus.ACTIVE:
        return StatsVariant.positive
      case SubscriptionStatus.INACTIVE:
        return StatsVariant.neutralVariant
      case SubscriptionStatus.CANCELED:
        return StatsVariant.negative
      case SubscriptionStatus.INCOMPLETE:
        return StatsVariant.attention

      default:
        return StatsVariant.positive
    }
  }
  return (
    <Stats variant={getVariant()} size={size}>
      {t(`userSubscriptions.subscriptionStatus.${status}`)}
    </Stats>
  )
}
