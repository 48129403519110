"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = exports.ProductType = exports.ProductCategory = exports.Function = exports.CommunityType = void 0;

var _Function = _interopRequireWildcard(require("./IFunction"));

exports.Function = _Function;

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var AssetType;

(function (AssetType) {
  AssetType["video"] = "video";
  AssetType["image"] = "image";
  AssetType["youtube"] = "youtube";
})(AssetType || (AssetType = {}));

var GroupNotificationAction;

(function (GroupNotificationAction) {
  GroupNotificationAction["postCreated"] = "postCreated";
})(GroupNotificationAction || (GroupNotificationAction = {}));

var ProductCategory;
exports.ProductCategory = ProductCategory;

(function (ProductCategory) {
  ProductCategory["bets"] = "bets";
  ProductCategory["crypto"] = "crypto";
  ProductCategory["education"] = "education";
  ProductCategory["investments"] = "investments";
  ProductCategory["travel"] = "travel";
  ProductCategory["wellness"] = "wellness";
  ProductCategory["other"] = "other";
})(ProductCategory || (exports.ProductCategory = ProductCategory = {}));

var CommunityType;
exports.CommunityType = CommunityType;

(function (CommunityType) {
  CommunityType["permanent"] = "permanent";
  CommunityType["cohort"] = "cohort";
  CommunityType["channel"] = "channel";
})(CommunityType || (exports.CommunityType = CommunityType = {}));

var ProductType;
exports.ProductType = ProductType;

(function (ProductType) {
  ProductType["digital"] = "digital";
  ProductType["event"] = "event";
})(ProductType || (exports.ProductType = ProductType = {}));

var Status;
/**
 * Represents the settings for customizing the checkout page for an Offer.
 * While conceptually the setting should live at offer level the Product class also needs it to serve as a "template".
 */

exports.Status = Status;

(function (Status) {
  Status["draft"] = "draft";
  Status["ready"] = "ready";
})(Status || (exports.Status = Status = {}));