import { Spacer } from "components"
import React from "react"

export interface IProps {
  header: string
  subheader: string
  center?: boolean
}

export const FormHeader: React.FunctionComponent<IProps> = (props) => {
  return (
    <div style={props.center ? { textAlign: "center" } : {}}>
      <div className="cp text compact bold huge black center">{props.header}</div>
      <div className="cp text big">{props.subheader}</div>
      <Spacer />
    </div>
  )
}
