"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimestampInject = void 0;
exports.replaceTimestampsToDate = replaceTimestampsToDate;

var _ObjectUtils = _interopRequireDefault(require("../Utils/ObjectUtils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function replaceTimestampsToDate(obj) {
  var isTimestamp = function isTimestamp(key, value) {
    return ((value === null || value === void 0 ? void 0 : value._seconds) && (value === null || value === void 0 ? void 0 : value._nanoseconds) || (value === null || value === void 0 ? void 0 : value.seconds) && (value === null || value === void 0 ? void 0 : value.nanoseconds)) !== undefined;
  };

  var mapToTimestamp = function mapToTimestamp(key, value) {
    var _ref, _value$_seconds, _ref2, _value$_nanoseconds;

    var seconds = (_ref = (_value$_seconds = value._seconds) !== null && _value$_seconds !== void 0 ? _value$_seconds : value.seconds) !== null && _ref !== void 0 ? _ref : 0;
    var nanoseconds = (_ref2 = (_value$_nanoseconds = value._nanoseconds) !== null && _value$_nanoseconds !== void 0 ? _value$_nanoseconds : value.nanoseconds) !== null && _ref2 !== void 0 ? _ref2 : 0;
    return TimestampInject.TimestampFactory.createTimestampFromTime(seconds, nanoseconds).toDate();
  };

  return _ObjectUtils["default"].replaceNested(obj, isTimestamp, mapToTimestamp);
}

var TimestampInject = {
  TimestampFactory: null // To be injected

};
exports.TimestampInject = TimestampInject;