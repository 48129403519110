import { Model } from "@chatpay/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form } from "semantic-ui-react"
import * as Fields from "components/Fields"

export interface IForm {
  country: Model.Country
}

export interface IProps {
  disabled?: boolean
  value?: Partial<IForm>
  onChange?: (data: Partial<IForm>, valid: boolean) => any
}

const Locale: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, disabled } = props

  const [form, setForm] = useState<Partial<IForm>>()

  const { t } = useTranslation()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const checkValidity = (newData: Partial<IForm>) => {
    return newData?.country !== undefined
  }

  const updateForm = (field: string, value: any) => {
    const newData = {
      ...form,
      [field]: value,
    }
    const valid = checkValidity(newData)

    onChange?.(newData, valid)
    setForm(newData)
  }

  return (
    <Form>
      <Fields.InputLabel title={t("AccountVerification.info.country")}>
        <Form.Select
          disabled={disabled ?? false}
          upward={true}
          search={true}
          type="hidden"
          id="Form"
          value={form?.country ?? ""}
          placeholder={t("AccountVerification.info.countryPlaceholder")}
          name="country"
          fluid={true}
          options={Object.values(Model.Countries).map((country) => ({
            key: country.id,
            text: `${country.name} - (${
              Model.CountryCurrency[String(country.id).toLowerCase() as keyof Model.CountryCurrency]
            })`,
            value: country.id,
          }))}
          closeOnBlur={true}
          onChange={(_, data) => updateForm(data.name, data.value)}
        />
      </Fields.InputLabel>
    </Form>
  )
}

export { Locale as Form }
