"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChatApp = void 0;
var ChatApp;
exports.ChatApp = ChatApp;

(function (ChatApp) {
  ChatApp["whatsapp"] = "whatsapp";
  ChatApp["telegram"] = "telegram";
})(ChatApp || (exports.ChatApp = ChatApp = {}));