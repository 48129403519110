import React, { useContext } from "react"
import {
  AuthProvider,
  AuthRedirectProvider,
  SignInSmsPage as AuthSignInSmsPage,
  AuthRedirectContext,
} from "@hub-la/fe-auth"
import { Service } from "@chatpay/components"
import { useEffect } from "react"
import { TimestampInject } from "@chatpay/common"

const AuthSignInSmsPageWithRedirect = () => {
  const { redirectAutoIfAuthenticated } = useContext(AuthRedirectContext)
  const firebaseUser = Service.Firebase.currentUser

  // Redirect if the user is already authenticated or authenticate now and update the firebaseUser
  useEffect(() => {
    // When signing in with SMS and the user is not using a password, no need to redirect because the user needs to complete the registration
    // This code is necessary here because chatpay-firebase is the parent of firebaseAuth
    if (!firebaseUser || !firebaseUser.isUsingPassword) return

    const passworSetupAtDate = firebaseUser?.passwordSetupAt
      ? TimestampInject.TimestampFactory.createTimestampFromTime(
          firebaseUser?.passwordSetupAt["seconds"],
          firebaseUser?.passwordSetupAt["nanoseconds"],
        ).toDate()
      : null

    // If the user has been updated in the last 30 seconds, don't redirect
    // because if the user is not using a password and completes registration, the user will be updated (isUsingPassword prop) and redirected without a sign-in token by completing registration.
    // @hub-la/fe-auth will redirect to the correct page after the user completes registration.
    const updatedRecent = passworSetupAtDate && passworSetupAtDate > new Date(Date.now() - 30000)
    if (updatedRecent) {
      return
    }

    redirectAutoIfAuthenticated()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser])

  return <AuthSignInSmsPage />
}

const SignInSmsPage = () => {
  const getCurrentUser = () => {
    return Service.Firebase.currentUser
  }

  return (
    <AuthProvider>
      <AuthRedirectProvider getCurrentUser={getCurrentUser}>
        <AuthSignInSmsPageWithRedirect />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}

export default SignInSmsPage
