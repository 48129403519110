import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "semantic-ui-react"

interface IFileButtonProps {
  type: "video" | "image"
  disabled?: boolean
  onFileChange?: (file: File) => any
}
export const FileButton: React.FunctionComponent<IFileButtonProps> = (props: IFileButtonProps) => {
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>()
  const { t } = useTranslation()
  const handleFileChange = (file: File) => props.onFileChange?.(file)

  const icon = {
    video: "file video outline",
    image: "file image outline",
  }

  const files = {
    video: process.env.REACT_APP_VIDEO_MIMETYPE_ALLOWED,
    image: process.env.REACT_APP_IMAGE_MIMETYPE_ALLOWED,
  }

  const title = {
    video: "Video",
    image: t("ImageFile"),
  }

  return (
    <React.Fragment>
      <Button
        disabled={props.disabled}
        className="file-button borderless"
        content={title[props.type]}
        size="small"
        onClick={() => inputRef.current?.click()}
        basic={true}
        icon={icon[props.type]}
      />
      <input
        disabled={props.disabled}
        ref={inputRef}
        hidden={true}
        type="file"
        accept={files[props.type]}
        onChange={(event) => handleFileChange(event.target.files![0])}
      />
    </React.Fragment>
  )
}
