import API from "./API"
import { DB } from "../Service"
import { Document } from "@chatpay/common"

class GatewayTransfer extends API {
  private db = new DB(Document.GatewayTransfer)

  public async getGatewayTransfers(): Promise<Document.GatewayTransfer[]> {
    const userId = API.currentUser?.id
    if (!userId) {
      return []
    }

    const accountId = (
      await new DB(Document.User).getSubCollectionById(Document.Gateway, Document.GatewayCarrier.iugu, userId)
    )?.account?.id

    let transfers: Document.GatewayTransfer[] = []
    transfers = transfers.concat(
      await this.db.get({
        where: [{ field: "to", op: "in", value: accountId ? [accountId, userId] : [userId] }],
      }),
    )
    transfers = transfers.concat(
      await this.db.get({
        where: [{ field: "from", op: "==", value: userId }],
      }),
    )
    return transfers
  }
}

export default GatewayTransfer
