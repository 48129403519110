import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { Envs } from "../envs"
import { Group } from "../domain/dtos/group"

export class GetPayerProduct {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(productId: string): Promise<Group> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/payer/product/${productId}`,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new Error("Não foi possível obter os produtos.")
  }
}
