import { Product, Resource, GroupResource, GroupResourceState } from "@chatpay/common"
import React, { useState } from "react"
import { Modal, Placeholder, Image } from "semantic-ui-react"
import { routes } from "@chatpay/common"
import { Link } from "react-router-dom"
import {
  BackArrow,
  FreeMembersHeadContainer,
  InfoBox,
  InfoBoxPicture,
  InfoBoxTextContainer,
  InfoBoxTextSubtitle,
  InfoBoxTextTitle,
  ButtonGenerateInviteLink,
} from "./FreeMembersStyledComponents"
import { useTranslation } from "react-i18next"
import { API } from "@chatpay/components"
import { CloseIcon } from "components/CloseIcon"
import { Spacer } from "components"
import { CopyButton } from "components/Group"

interface IProps {
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  product?: Product
  resource?: Resource
  groupId: string
  groupResource?: GroupResource
}

export const FreeMembersHead: React.FunctionComponent<IProps> = (props) => {
  const { isLoading, product, resource, setIsLoading, groupId, groupResource } = props
  const [generatedLink, setGeneratedLink] = useState<string>()
  const { t } = useTranslation()
  const getWhitelistLink = async () => {
    if (groupResource && resource) {
      setIsLoading(true)
      try {
        const { link } = await new API.GroupWhitelist().generateLink({
          groupId: groupId,
          groupResourceId: groupResource.id,
          resourceType: resource.type,
        })
        if (link) {
          setGeneratedLink(link)
        }

        setIsLoading(false)
      } catch (e) {
        console.error(e)
        setIsLoading(false)
      }
    }
  }
  return (
    <React.Fragment>
      <FreeMembersHeadContainer id="FreeMembersHeadContainer" data-testid="FreeMembersHeadContainer">
        <InfoBox>
          {isLoading ? (
            <Placeholder className="BackArrow">
              <Placeholder.Image rectangular={true} />
            </Placeholder>
          ) : (
            resource?.picture &&
            product && (
              <Link to={`${routes.EDIT_PRODUCT.LINK}/${product.id}/#groups`}>
                <BackArrow src={require(`assets/icons/left-arrow.svg`)} />
              </Link>
            )
          )}
          {isLoading ? (
            <Placeholder className="InfoBoxPicture">
              <Placeholder.Image rectangular={true} />
            </Placeholder>
          ) : (
            resource?.picture && <InfoBoxPicture src={resource?.picture} />
          )}

          <InfoBoxTextContainer>
            {isLoading ? (
              <Placeholder className="InfoBoxTextTitle">
                <Placeholder.Line length="full" />
              </Placeholder>
            ) : (
              product && <InfoBoxTextTitle> {product.name}</InfoBoxTextTitle>
            )}
            {isLoading ? (
              <Placeholder className="InfoBoxTextSubtitle">
                <Placeholder.Line length="full" />
              </Placeholder>
            ) : (
              product && <InfoBoxTextSubtitle> {product.name}</InfoBoxTextSubtitle>
            )}
          </InfoBoxTextContainer>
        </InfoBox>

        <Modal
          basic={true}
          trigger={
            <ButtonGenerateInviteLink
              data-testid="button-generate"
              size="small"
              content={
                groupResource?.state === GroupResourceState.full
                  ? t("FreeMembers.resourceFull")
                  : t("FreeMembers.generateInviteLink")
              }
              color={"green"}
              disabled={isLoading || groupResource?.state === GroupResourceState.full}
              onClick={getWhitelistLink}
            />
          }
          size="small"
          dimmer={"inverted"}
          closeIcon={<CloseIcon />}
          content={
            isLoading ? (
              <div style={{ textAlign: "center" }}>
                <Image
                  wrapped={true}
                  avatar={true}
                  verticalAlign="middle"
                  size="huge"
                  spaced="right"
                  src={require("assets/images/loading.gif")}
                />
              </div>
            ) : (
              <div style={{ maxWidth: "386px", margin: "auto" }}>
                <div className="cp text big black bold">{t("FreeMembers.generateLink.title")}</div>
                <Spacer size="small" />
                <div className="cp text">{t("FreeMembers.generateLink.description")}</div>
                <Spacer />

                <div style={{ marginBottom: "1em" }}>
                  <CopyButton link={generatedLink ?? ""} />
                </div>
              </div>
            )
          }
        />
      </FreeMembersHeadContainer>
    </React.Fragment>
  )
}

export type { IProps as IPropsFreeMembersHead }
