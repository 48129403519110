import * as React from "react"
import { Input } from "semantic-ui-react"
import { Helper } from "@chatpay/common"
import { InputLabel } from "../InputLabel"
import ReactDOM from "react-dom"

import * as CurrencyFormat from "react-currency-format"
import { cpf, cnpj } from "cpf-cnpj-validator/dist/cpf-cnpj-validator.cjs"
import { formatCpfCnpj } from "utils/formatCpfCnpj"

export type Type = Helper.DocumentType

interface IProps {
  type?: Helper.DocumentType
  title?: string
  name?: string
  value?: string
  required?: boolean
  disabled?: boolean
  fluid?: boolean
  className?: string
  onChange?: (component: InputDocument, input: HTMLInputElement) => void
  onBlur?: (component: InputDocument, input: HTMLInputElement) => void
}

interface IState {
  error: boolean
  value?: string
  currentType?: Helper.DocumentType
}

class InputDocument extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    error: false,
  }

  private inputRef = React.createRef<Input>()

  public get input(): HTMLInputElement | undefined | null {
    const element = this.inputRef.current ? (ReactDOM.findDOMNode(this.inputRef.current) as HTMLElement) : null
    return element?.querySelector("input") as HTMLInputElement
  }

  public get name(): string | undefined | null {
    return this.inputRef.current?.props.name
  }

  public get value(): string {
    return this.state.value ?? ""
  }

  public get type(): Helper.DocumentType {
    const { type } = this.props
    const { currentType } = this.state

    return currentType ?? type ?? Helper.DocumentType.cpf
  }

  public get typeString(): string {
    switch (this.type) {
      case Helper.DocumentType.cnpj:
        return "CNPJ"
      case Helper.DocumentType.cpfOrCnpj:
        return "CPF ou CNPJ"
      default:
        return "CPF"
    }
  }

  public get isValid(): boolean {
    return !this.state.error && this.isValueValid(this.value)
  }

  public get validity(): ValidityState | null | undefined {
    return { valid: this.isValid } as any
  }

  private get title(): string {
    const { title = "{type}" } = this.props

    switch (this.type) {
      case Helper.DocumentType.cnpj:
        return title.replace("{type}", "CNPJ")
      case Helper.DocumentType.cpfOrCnpj:
        return title.replace("{type}", "CPF ou CNPJ")
      default:
        return title.replace("{type}", "CPF")
    }
  }

  private get format(): string | undefined {
    if (!this.state.value?.trim().length) {
      return undefined
    }

    const cpf = "###.###.###-##"
    const cnpj = "##.###.###/####-##"

    if (this.type === Helper.DocumentType.cnpj) {
      return cnpj
    }
    return cpf
  }

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.value !== state.value) {
      return {
        value: state.value ?? props.value,
      }
    }
    return null
  }

  public componentDidMount() {
    if (!this.props.value) {
      return
    }
    const isValidValue = this.isValueValid(this.props.value ?? "")
    this.setState({
      error: !isValidValue,
    })
  }

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return (
      nextProps.value !== this.state.value ||
      nextState.error !== this.state.error ||
      nextProps.disabled !== this.props.disabled
    )
  }

  private isValueValid(value: string): boolean {
    if (!value || !value.trim().length) {
      return !(this.props.required ?? true)
    }
    const numbers = value.numbers()

    switch (this.type) {
      case Helper.DocumentType.cnpj:
        return cnpj.isValid(numbers) && value.isDocument(Helper.DocumentType.cnpj)
      case Helper.DocumentType.cpfOrCnpj:
        if (value.trim().length >= 14 && !cpf.isValid(numbers)) {
          return cnpj.isValid(numbers) && value.isDocument(Helper.DocumentType.cnpj)
        }
        break
    }

    return cpf.isValid(numbers) && value.isDocument(Helper.DocumentType.cpf)
  }

  private typeForValue(value: string): Helper.DocumentType | undefined {
    if (!value || !value.trim().length || this.props.type !== Helper.DocumentType.cpfOrCnpj) {
      return this.props.type
    }
    if (value.trim().length >= 14 && !cpf.isValid(value.numbers())) {
      return Helper.DocumentType.cnpj
    }
    return Helper.DocumentType.cpf
  }

  private onChange = ({ target }: { target: HTMLInputElement }) => {
    const { value } = target

    this.setState(
      {
        currentType: this.typeForValue(value),
        error: false,
        value: formatCpfCnpj(value),
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this, target)
        }
      },
    )
  }

  private onBlur = ({ target }: { target: HTMLInputElement }) => {
    const { value } = target
    this.setState(
      {
        error: !this.isValueValid(value),
        currentType: this.typeForValue(value),
        value: formatCpfCnpj(value),
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this, target)
        }
        if (this.props.onBlur) {
          this.props.onBlur(this, target)
        }
      },
    )
  }

  public render() {
    const {
      fluid = true,
      disabled,
      required = true,
      name = "document",
      type = Helper.DocumentType.cpf,
      className,
    } = this.props

    const { error, value } = this.state

    return (
      <InputLabel title={this.title} errorText={`${this.typeString} inválido`}>
        <CurrencyFormat
          data-testid={"input-document"}
          ref={this.inputRef}
          fluid={fluid}
          error={error}
          required={required}
          name={name}
          type="tel"
          pattern={type}
          disabled={disabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          customInput={Input}
          format={this.format}
          value={value}
          className={className}
        />
      </InputLabel>
    )
  }
}

export default InputDocument
