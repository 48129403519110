"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userTaxInvoiceFixture = exports.transactionTaxInvoiceFixture = exports.taxInvoiceFixture = exports.groupTaxInvoiceFixture = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _ = require("../");

var _Document = _interopRequireDefault(require("./Document"));

var _utils = require("./utils");

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var groupTaxInvoiceFixture = function groupTaxInvoiceFixture(args, keys) {
  return taxInvoiceFixture(_objectSpread({
    type: _.TaxInvoiceType.group
  }, args), keys);
};

exports.groupTaxInvoiceFixture = groupTaxInvoiceFixture;

var transactionTaxInvoiceFixture = function transactionTaxInvoiceFixture(args, keys) {
  return taxInvoiceFixture(_objectSpread({
    type: _.TaxInvoiceType.transaction
  }, args), keys);
};

exports.transactionTaxInvoiceFixture = transactionTaxInvoiceFixture;

var userTaxInvoiceFixture = function userTaxInvoiceFixture(args, keys) {
  return taxInvoiceFixture(_objectSpread({
    type: _.TaxInvoiceType.user
  }, args), keys);
};

exports.userTaxInvoiceFixture = userTaxInvoiceFixture;

var taxInvoiceFixture = function taxInvoiceFixture(args, keys) {
  var _ref, _args$type;

  var apiKeys = (_ref = keys !== null && keys !== void 0 ? keys : args !== null && args !== void 0 && args.integratorIds ? Object.keys(args.integratorIds) : undefined) !== null && _ref !== void 0 ? _ref : [(0, _utils.firebaseIdFixture)()];
  var type = (_args$type = args === null || args === void 0 ? void 0 : args.type) !== null && _args$type !== void 0 ? _args$type : (0, _utils.randomEnumValue)(_.TaxInvoiceType);
  var isTransaction = type === _.TaxInvoiceType.transaction;
  var taxInvoice = new _index.Document.TaxInvoice(_objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    type: type,
    integratorId: faker.datatype.uuid(),
    transactionStatus: isTransaction ? (0, _utils.randomEnumValue)(_.TaxInvoiceTransactionStatus) : undefined,
    transactionsStatus: {},
    taxInvoiceStatus: isTransaction ? (0, _utils.randomEnumValue)(_.TaxInvoiceStatus) : undefined,
    taxInvoicesStatus: {},
    printLink: isTransaction ? faker.internet.url() : undefined,
    printLinks: {}
  }, args));

  if (Object.keys(taxInvoice.integratorIds).length === 0) {
    apiKeys.forEach(function (key) {
      taxInvoice.integratorIds[key] = faker.datatype.uuid();
    });
    taxInvoice.integratorIds[apiKeys.first()] = taxInvoice.integratorId;
  }

  if (isTransaction) {
    Object.values(taxInvoice.integratorIds).forEach(function (integratorId) {
      if (!taxInvoice.taxInvoicesStatus[integratorId]) {
        taxInvoice.transactionsStatus[integratorId] = (0, _utils.randomEnumValue)(_.TaxInvoiceTransactionStatus);
        taxInvoice.taxInvoicesStatus[integratorId] = (0, _utils.randomEnumValue)(_.TaxInvoiceStatus);
        taxInvoice.printLinks[integratorId] = faker.internet.url();
      }
    });
    taxInvoice.transactionsStatus[taxInvoice.integratorId] = taxInvoice.transactionStatus;
    taxInvoice.taxInvoicesStatus[taxInvoice.integratorId] = taxInvoice.taxInvoiceStatus;
    taxInvoice.printLinks[taxInvoice.integratorId] = taxInvoice.printLink;
  }

  return taxInvoice;
};

exports.taxInvoiceFixture = taxInvoiceFixture;