"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _ = require("../");

var _Models = require("../../Models");

var _Document = _interopRequireDefault(require("./Document"));

var _User = _interopRequireDefault(require("./User"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var sellableFixture = function sellableFixture(args) {
  var name = _faker["default"].commerce.productName();

  return _objectSpread(_objectSpread({}, (0, _Document["default"])()), {}, {
    name: name,
    price: _faker["default"].datatype.number(2000),
    membershipFee: _faker["default"].random.arrayElement([null, {
      price: _faker["default"].datatype.number(2000),
      paymentsMethods: _faker["default"].random.arrayElements(Object.values(_.PaymentMethod), 3),
      description: "Membership Fee",
      days: _faker["default"].random.arrayElement([30, "plan"]),
      installments: _faker["default"].datatype.number(12)
    }]),
    owner: (0, _User["default"])(),
    commissioning: {
      isEnabled: _faker["default"].datatype["boolean"](),
      isSuspended: _faker["default"].datatype["boolean"](),
      isAutoAdmitReceiverEnabled: _faker["default"].datatype["boolean"](),
      isCycleProtectionEnabled: _faker["default"].datatype["boolean"](),
      isRestricted: _faker["default"].datatype["boolean"](),
      commission: {
        sell: _faker["default"].datatype.number({
          min: 0,
          max: 89
        }),
        renewal: _faker["default"].datatype.number({
          min: 0,
          max: 89
        })
      }
    },
    paymentsMethods: _faker["default"].random.arrayElements(Object.values(_.PaymentMethod), 3),
    invoiceDescription: name,
    installments: _faker["default"].datatype.number(12),
    gateway: _.GatewayCarrier.iugu,
    currency: _faker["default"].random.objectElement(_Models.Currency)
  }, args);
};

var _default = sellableFixture;
exports["default"] = _default;