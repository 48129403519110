export const adminDashboard = {
  Products: "Produtos",
  Sales: "Vendas",
  Renew: "Renovação",
  Volume: "Volume Bruto",
  Subscriptions: "Assinaturas",
  Affiliates: "Afiliados",
  Refunds: "Reembolsos",
  Last7Days: "Ultimos 7 Dias",
  "15Days": "15 Dias",
  "30Days": "30 Dias",
  Invoices: "Faturas",
  Total: "Valor",
  Situation: "Situação",
  DueDate: "Vencimento",
  CreatedAt: "Início",
  CreationDate: "Data de Criação",
  PaidAt: "Data de pagamento",
  PaymentMethod: "Paga com",
  Name: "Comprador",
  PhoneNumber: "Telefone",
  Product: "Produto",
  Plan: "Plano",
  Status: "Status",
  last: "Últimos",
  days: "Dias",
  Active: "Ativos",
  Drafts: "Rascunhos",
  Inactive: "Inativos",
  noPublishes: "Você não tem produtos publicados.",
  noDrafts: "Você não tem produtos em rascunho.",
  noInactive: "Você não tem produtos em inativo.",
  anyGroups: "Você ainda não publicou nenhum produto.",
  pending: "Pendente",
  paid: "Paga",
  canceled: "Cancelada",
  expired: "Expirada",
  refunded: "Reembolsada",
  chargeback: "Contestada",
  all: "Todas",
  export: "Exportar",
  betweenDates: "Entre as datas",
  textFilter: `Utilizar filtro de texto: "{{query}}"?`,
  fileType: "Tipo de arquivo",
  cancel: "Cancelar",
  invoice: "fatura",
  subscription: "assinatura",
  search: "Buscar...",
  pendings: "Pendentes",
  paids: "Confirmadas",
  expireds: "Vencidas",
  Details: "Detalhes",
  see: "Ver",
  Picture: "Foto",
  name: "Nome",
  activated: "Ativa",
  active: "Ativa",
  suspended: "Inativa",
  subscriptionExpired: "Vencida",
  subscriptionActives: "Ativas",
  dues: "Vencidas",
  suspendeds: "Inativas",
  value: "Valor",
  refunds: "Pedidos de Reembolso",
  purchase: "Compra",
  request: "Pedido",
  refused: "Recusado",
  failed: "Falha",
  subscriptionActivated: "Novos Assinantes",
  subscriptionCanceled: "Cancelamentos",
  Members: "Membros",
  MembersSubheader: "Gerencie a entrada e saída dos membros em seus produtos.",
  accessTokenError: "Tivemos um problema para carregar seus dados, por favor recarregue a página.",
  results: "resultados encontrados.",
  exportProduct: "Exportar do produto",
  sales: "vendas",
  renews: "renovações",
  RefundTitle: "Reembolsos e Contestações",
  Waitlist: "Lista de espera",
  Wallet: "Carteira",
  Extras: "Extras",
  tips: "Confira as dicas que separamos para te ajudar com seu produto",
}
