import i18n from "i18next"
import React from "react"
import DatePicker, { DayRange } from "react-modern-calendar-datepicker"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import { Button, Icon, SemanticSIZES } from "semantic-ui-react"
import "./InputDate.scss"
import { customLocale } from "./customLocale"

interface IProps {
  onDateChange: (dayRange: DayRange) => any
  onClickInput?: () => void
  initialState?: [Date, Date]
  defaultValue?: "lastMonth" | "lastWeek" | "yesterday" | "today" | "all"
  size?: SemanticSIZES
  clearable?: boolean
  disabled?: boolean
}

interface IState {
  dayRange: DayRange
  buttonText: string
}

class InputDate extends React.Component<IProps, IState> {
  public state: Readonly<IState> = {
    dayRange: { from: undefined, to: undefined },
    buttonText: i18n.t("InputDate.filterDate"),
  }

  public componentDidMount() {
    if (this.props.defaultValue) {
      this.defaultValues(this.props.defaultValue)
    }
  }

  private defaultValues(value: string) {
    if (value === "lastMonth") {
      this.setState({
        dayRange: { from: this.subtractDate(30), to: this.getToday() },
        buttonText: i18n.t("InputDate.lastMonth"),
      })
      this.props.onDateChange({ from: this.subtractDate(30), to: this.getToday() })
    } else if (value === "lastWeek") {
      this.setState({
        dayRange: { from: this.subtractDate(7), to: this.getToday() },
        buttonText: i18n.t("InputDate.lastWeek"),
      })
      this.props.onDateChange({ from: this.subtractDate(7), to: this.getToday() })
    } else if (value === "today") {
      this.setState({
        dayRange: { from: this.getToday(), to: this.getToday() },
        // This concatenation is necessary for rendering the correct size on input
        buttonText: i18n.t("InputDate.today") + "  ",
      })
      this.props.onDateChange({ from: this.getToday(), to: this.getToday() })
    } else if (value === "yesterday") {
      this.setState({
        dayRange: { from: this.subtractDate(1), to: this.subtractDate(1) },
        // This concatenation is necessary for rendering the correct size on input
        buttonText: i18n.t("InputDate.yesterday") + "   ",
      })
      this.props.onDateChange({ from: this.subtractDate(1), to: this.subtractDate(1) })
    } else if (value === "all") {
      this.setState({
        dayRange: { from: null, to: null },
        // This concatenation is necessary for rendering the correct size on input
        buttonText: i18n.t("InputDate.all") + "  ",
      })
      this.props.onDateChange({ from: null, to: null })
    }
  }

  private subtractDate(days: number) {
    var date = new Date()
    var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
    var day = last.getDate()
    var month = last.getMonth() + 1
    var year = last.getFullYear()

    return { year, month, day }
  }

  private getToday() {
    var date = new Date()
    var day = date.getDate()
    var month = date.getMonth() + 1
    var year = date.getFullYear()

    return { year, month, day }
  }

  private getInitialState() {
    if (this.props.initialState) {
      var start = this.props.initialState[0]
      var startDay = start.getDate()
      var startMonth = start.getMonth() + 1
      var startYear = start.getFullYear()
      var end = this.props.initialState[1]
      var endDay = end.getDate()
      var endMonth = end.getMonth() + 1
      var endYear = end.getFullYear()
      return {
        from: { year: startYear, month: startMonth, day: startDay },
        to: { year: endYear, month: endMonth, day: endDay },
      }
    } else return { from: null, to: null }
  }
  public render() {
    const renderCustomInput = ({ ref }) => (
      <div className={`input-icons ${this.props.size ?? ""}`}>
        {this.state.buttonText !== i18n.t("InputDate.filterDate") && (this.props.clearable ?? true) ? (
          <Icon
            name="close"
            style={{ opacity: "0.68" }}
            onClick={() => {
              this.setState({
                dayRange: this.getInitialState(),
                buttonText: i18n.t("InputDate.filterDate"),
              })
              this.props.onDateChange(this.getInitialState())
            }}
          />
        ) : (
          <Icon name="dropdown" style={{ pointerEvents: "none", opacity: "0.68" }} />
        )}
        <input
          ref={ref}
          readOnly={true}
          onClick={this.props.onClickInput}
          className={`ui basic ${this.props.size ?? "tiny"} button`}
          style={{
            marginTop: "0.36em",
            textAlign: "left",
            boxShadow: "rgba(34, 36, 38, 0.18) 0px 0px 0px 1px inset",
          }}
          value={this.state.buttonText}
          size={this.state.buttonText.length - 1}
          disabled={this.props.disabled}
        />
      </div>
    )

    return (
      <div>
        <DatePicker
          value={this.state.dayRange}
          onChange={(value) => {
            this.setState({
              dayRange: value,
              buttonText:
                i18n.languages[0] === "en"
                  ? `${value.from?.month ?? "--"}/${value.from?.day ?? "--"} > ${value.to?.month ?? "--"}/${
                      value.to?.day ?? "--"
                    }`
                  : `${value.from?.day ?? "--"}/${value.from?.month ?? "--"} > ${value.to?.day ?? "--"}/${
                      value.to?.month ?? "--"
                    }`,
            })
            this.props.onDateChange(value)
          }}
          calendarPopperPosition="bottom"
          renderInput={renderCustomInput}
          locale={customLocale}
          renderFooter={() => (
            <div style={{ padding: "0 3.5em 1em 3.5em" }}>
              <div className="datepicker-footer">
                <Button
                  onClick={() => this.defaultValues("today")}
                  basic={true}
                  className="datepicker-footer-button"
                  size="tiny"
                  style={{ margin: "5px" }}
                  content={`${i18n.t("InputDate.today")}`}
                />
                <Button
                  onClick={() => this.defaultValues("yesterday")}
                  basic={true}
                  className="datepicker-footer-button"
                  size="tiny"
                  style={{ margin: "5px" }}
                  content={`${i18n.t("InputDate.yesterday")}`}
                />
              </div>

              <div className="datepicker-footer">
                <Button
                  onClick={() => this.defaultValues("lastWeek")}
                  basic={true}
                  className="datepicker-footer-button"
                  size="tiny"
                  style={{ margin: "5px" }}
                  content={`${i18n.t("InputDate.lastWeek")}`}
                />
                <Button
                  onClick={() => this.defaultValues("lastMonth")}
                  basic={true}
                  className="datepicker-footer-button"
                  size="tiny"
                  style={{ margin: "5px" }}
                  content={`${i18n.t("InputDate.lastMonth")}`}
                />
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

export default InputDate
