import * as React from "react"
import { Avatar, GroupAvatar } from "components/Avatar"
import { Placeholder } from "semantic-ui-react"

import "./ItemInfo.scss"

export interface IProps {
  picture?: string | null
  name?: string
  createdAt?: string
  detail?: string | JSX.Element
  rightDetail?: string | React.ReactElement
  rightButton?: React.ReactElement
  isLoading?: boolean
  className?: string | null
  size?: "medium" | "small"
  isGroup?: boolean
}

export class ItemInfo extends React.Component<IProps, {}> {
  public render() {
    const {
      picture,
      name,
      detail,
      rightDetail,
      rightButton,
      isLoading,
      size,
      className,
      isGroup,
      createdAt,
    } = this.props

    return (
      <div
        className={`${isGroup ? "GroupInfo" : "ItemInfo"}${className ? " " + className : ""}`}
        style={isLoading ? { display: "block" } : undefined}
      >
        {isLoading ? (
          <Placeholder>
            <Placeholder.Header image={true}>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        ) : (
          <>
            <div className="item-content">
              {isGroup ? (
                <GroupAvatar
                  picture={picture}
                  name={name ?? ""}
                  createdAt={createdAt ?? ""}
                  detail={detail}
                  size={size}
                />
              ) : (
                <Avatar picture={picture} name={name ?? ""} detail={detail} size={size} />
              )}
            </div>
            {rightDetail ? <div className="text small item-right-content">{rightDetail}</div> : null}
            {rightButton ? <>{rightButton}</> : null}
          </>
        )}
      </div>
    )
  }
}
