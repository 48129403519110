import _ from "lodash"
import React from "react"
import { Placeholder, Table } from "semantic-ui-react"

export const TableLoader: React.FunctionComponent<{ columns: number; rows: number }> = (props) => {
  const PlaceholderCell = () => (
    <Table.Cell>
      <Placeholder>
        <Placeholder.Line length="full" />
      </Placeholder>
    </Table.Cell>
  )

  const PlaceholderRow = () => (
    <Table.Row>
      {_.range(props.columns).map((_, idx) => (
        <PlaceholderCell key={idx} />
      ))}
    </Table.Row>
  )

  return (
    <>
      {_.range(props.rows).map((_, idx) => (
        <PlaceholderRow key={idx} />
      ))}
    </>
  )
}
