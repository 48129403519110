export const AccountVerification = {
  rejected: {
    title: "Sua conta conta foi rejeitada",
    subtitle: "Entre em contato com o supporte para podermos ver o que aconteceu. ",
    button: "Voltar a página inicial",
  },
  pending: {
    title: "Sua conta está sendo verificada",
    subtitle:
      "Fique de olho. Vamos te enviar um email assim que sua conta for verificada ou se precisarmos de algumas informações extras.",
    button: "Acessar sua conta",
  },
  done: {
    title: "Sua conta foi verificada com sucesso",
    subtitle: "Agora você está preparado para vender e receber na Hubla.",
    button: "Continuar",
  },
  info: {
    title: "Você está quase lá",
    subtitle: "Precisamos de mais algumas informações para você poder receber pelo seu grupo.",
    altSubtitle: "Precisamos de mais algumas informações para continuar.",
    country: "País e moeda",
    countryPlaceholder: "Escolha seu país",
    continue: "continuar",
    verifyAccount: "verificar conta",
    fullname: "Nome Completo ou Razão Social",
    description: "Descrição do seu negócio",
    tip: "O CPF ou CNPJ deve ser igual ao do titular da conta bancária.",
    error: "Informação inválida",
  },
  cta: "Verificar conta bancária",
}
