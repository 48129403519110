"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ITelegramUpdateEvent = require("./ITelegramUpdateEvent");

Object.keys(_ITelegramUpdateEvent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ITelegramUpdateEvent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ITelegramUpdateEvent[key];
    }
  });
});

var _IChatMemberUpdate = require("./IChatMemberUpdate");

Object.keys(_IChatMemberUpdate).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IChatMemberUpdate[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IChatMemberUpdate[key];
    }
  });
});

var _IInviteLink = require("./IInviteLink");

Object.keys(_IInviteLink).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IInviteLink[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IInviteLink[key];
    }
  });
});

var _IUser = require("./IUser");

Object.keys(_IUser).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IUser[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IUser[key];
    }
  });
});

var _IChat = require("./IChat");

Object.keys(_IChat).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IChat[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IChat[key];
    }
  });
});

var _IChatMember = require("./IChatMember");

Object.keys(_IChatMember).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IChatMember[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IChatMember[key];
    }
  });
});