import { ITimestampFactory } from "@chatpay/common"
import firebase from "firebase/compat/app"

export class TimestampFactoryImpl implements ITimestampFactory {
  createTimestampFromDate(date: Date): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(date)
  }

  createTimestampFromTime(seconds: number, nanoseconds: number): firebase.firestore.Timestamp {
    return new firebase.firestore.Timestamp(seconds, nanoseconds)
  }
}
