export const myGroups = {
  verifyAccountWarning: "Verify your account so that you can publish your product.",
  verifyAccountButton: "Verify account",
  link1: "Verify your account",
  m1: "in order to publish this product.",
  m2: "Publish your product",
  m3: "We are verifying your account. Wait for a few minutes.",
  publishWarning: "Your product is still in draft! Publish it on the button above to start selling.",
  Title: "My purchases",
  Error: "Error searching for products. Please reload the page.",
  Drafts: "Drafts",
  Publisheds: "Published",
  Actives: "Actives",
  Purchases: "Purchases",
  Subscriptions: "Subscriptions",
  Inactive: "Inactives",
  Ready: "ready",
  Sale: "sale",
  InviteTitle: "Invite a creator and earn up to R$500",
  InviteText:
    "Do you know anyone who would create a great product? Invite them to Hubla and earn up to 5% of their earnings in the first month.",
  InviteButton: "Invite",
  ClosedSales: "Closed Sales",
  expirationDate: "DD [of] MMMM",
  product: "product",
  productOwnership: "<0>By {{ownerName}}</0>",
  renewalDate: "<0>Renewal Date: {{renewalDate}}</0>",
  seeMore: "See more",
  labels: {
    active: "Published",
    draft: "Draft",
    disabled: "Closed Sales",
  },
  EmptyState: {
    title: "Welcome to Hubla!",
    subtitle:
      "We help all types of people to create a paid community where they can deliver content to their audience quickly, easily and without bureaucracy.",
    creatorTitle: "If you are a content creator.",
    creatorSubtitle: "Start creating a product with us!",
    buyerTitle: "We can also refer you to several successful products!",
    buyerSubtitle: "Check out some of the products we've set up specifically for you",
    purchaseTitle: "There is nothing here :)",
  },
  AccountVerificationSteps: {
    0: {
      title: "Check your account to start selling",
      subtitle: "We need a bank account for you to receive payments from your members",
    },
    1: {
      title: "We are processing your information",
      subtitle: "Once everything is in order, you will be able to sell your product",
    },
    2: {
      title: "There was an error in your data",
      subtitle: "Please make sure your details are correct or contact support",
    },
    3: {
      title: "Hello, ",
      subtitle: "Now you can activate it and start selling!",
    },
    cantVerifyYet: "Before, we need some information",
    button: "Verify account",
    modalTitle: "Check your bank account so we can pay you",
    modalSubtitle:
      "Before we start selling, we need to know your bank details. All your billing will be transferred to this bank account.",
  },
  JoinGroupSteps: {
    title: "All set!",
    subtitle: "We have already prepared your {{resourceName}}, now you can enter it.",
  },
  CompletionStepsTitle: "Your product page is still weak. Finish setting it up here:",
  CompletionStepsAltTitle1: "Now you can have an optimized sales page!",
  CompletionStepsAltTitle2: "Click here",
  CompletionStepsAltTitle3: "to edit it!",
  CompletionStepsInfo: "Basic info",
  CompletionStepsAssets: "Sales Page Media",
  CompletionStepsDescription: "Add a description",
  CompletionStepsAdvantages: "Add a list of advantages",
  CompletionStepsFAQ: "Add an FAQ",
  active: "Actives",
  previousPurchases: "Inactives",
  Button: {
    access: "Access",
    join: "Join",
    pay: "Pay",
    pending: "Pending",
    failed: "Failed",
    unavailable: "Unavailable",
    month: "per month",
    renew: "Purchase again",
  },
  Dropdown: {
    subscription: "Purchase details",
    changePayment: "Change payment method",
    changePlan: "Change subscription plan",
  },
  Feedback: {
    problemWithPaymentMethod:
      "<0>Update your subscription payment method!</0> We have identified a problem with your subscription payment method. Please change your payment method by <1>23:59 today</1> to avoid suspension of your subscription.",
    suspendedSubscription:
      "<0>Your subscription has been suspended!</0> You have lost your access to the product. To return, renew your subscription.",
    pixExpiring:
      "<0>Renew your subscription!</0> Your subscription will expire <1>{{ days }}</1>. To prevent your subscription from being suspended, pay via Pix.",
    actions: {
      changePaymentMethod: "Change payment method",
      renew: "Purchase again",
      payViaPix: "Pay via Pix",
      stopCommunications: "Stop communications",
    },
    Snackbar: {
      stopCommunications: "Communications were canceled with success",
    },
    format: {
      inXDays: "in {{ days }} day(s)",
      atMidnight: "soon",
    },
  },
  GroupStatus: {
    isDraft: {
      current: "Draft",
      action: "put in draft",
      next: "Edit",
    },
    isPublished: {
      current: "Published",
      action: "Publish this",
      next: "Activate Waitlist",
    },
    isSelling: {
      current: "Selling",
      action: "Start sales",
      next: "Activate Waitlist",
    },
    isWaitlisted: {
      current: "Waitlisted",
      action: "Start Waitlist",
      next: "Activate sales",
    },
    isNotEnabled: {
      current: "Disabled",
      action: "Disable",
      next: "Activate sales",
    },
    isNotSelling: {
      current: "Disabled",
      action: "Stop sales",
      next: "Activate",
    },
  },
}
