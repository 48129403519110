import { Box, Button, Card, Divider, Grid, Stack, Text } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import DefaultGroupImage from "../../../../../assets/images/default-group-avatar.png"
import "moment/locale/pt-br"
import { Ticket, TicketForm } from "@hub-la/fe-event"
import { FormatAddress } from "modules/user-events/domain/vos/format-address"
import { PayerTicket } from "modules/user-events/domain/dtos/group"
import { Skeleton } from "@mui/material"

const Loading = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userEvents.back")}
        </Button>
      </Stack>
      <Stack direction="column" gap={4}>
        <Card variant="filled" hasHover={false} fullHeight>
          <>
            <Stack direction="row" alignItems="center" gap={2}>
              <Skeleton animation="wave" width={87} height={58} variant="rounded" />
              <Stack direction="column">
                <Skeleton animation="wave" width={170} height={28} />
                <Skeleton animation="wave" width={60} height={14} variant="rounded" />
              </Stack>
            </Stack>
            <Divider my={4} />
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Skeleton
                    animation="wave"
                    width={150}
                    height={24}
                    variant="rounded"
                    sx={{
                      mb: 1,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    width={80}
                    height={14}
                    variant="rounded"
                    sx={{
                      mb: 1,
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Skeleton
                    animation="wave"
                    width={140}
                    height={24}
                    variant="rounded"
                    sx={{
                      mb: 1,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={14}
                    variant="rounded"
                    sx={{
                      mb: 1,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    width={90}
                    height={14}
                    variant="rounded"
                    sx={{
                      mb: 1,
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </>
        </Card>
        <Card variant="filled" hasHover={false} fullHeight>
          <Stack alignItems="center" gap={14}>
            <Skeleton animation="wave" variant="rounded" width={314} height={424} />
          </Stack>
        </Card>
      </Stack>
    </Box>
  )
}

export { Loading }
