export const Dashboard = {
  Tabs: {
    home: "Home",
    communities: "Communities",
    sales: "Invoices",
    events: "Events",
    reports: "Reports",
    extras: "Extras",
    invoices: "Invoices",
    subscriptions: "Subscriptions",
    clients: "Clients",
    affiliates: "Affiliates",
    coupons: "Coupons",
    refunds: "Refunds",
    bankStatement: "Bank Statement",
    wallet: "Wallet",
    waitlist: "Waitlist",
    activity: "Activity",
    members: "Members",
    recurrence: "Recurrence",
    operation: "Operation",
  },
  Invoice: {
    popup: "Would you like to create a new invoice? We will send it to the buyer's e-mail. ",
    invoice: "Invoice",
    client: "Client",
    email: "Email",
    cellphone: "Phone Number",
    creationDate: "Creation date",
    expirationData: "Expiration date",
    group: "Group",
    paymentStatus: "Payment status",
    paymentMethod: "Payment method",
    eventType: "Event type",
    affiliate: "Affiliate",
    subscription: "Subscription",
    history: "History",
    date: "Date",
    description: "Description",
    notes: "Notes",
    button: {
      refund: "Refund",
      newInvoice: "New invoice",
      cancel: "Cancel",
    },
    EmptyState: "You have no transactions in this period.",
    total: "Price",
    taxes: "Hubla fee",
    AffiliateCommission: "Affiliate Commission",
    coupon: "Discount",
    quantity: "Net Value",
    paymentData: "Payment data",
    product: "Product",
  },
  Refund: {
    title: "Refund Request",
    client: "Client",
    email: "Email",
    cellphone: "Phone Number",
    subscriptionRenewal: "Subscription renewal?",
    yes: "Yes",
    no: "No",
    status: "Status",
    group: "Group",
    payedIn: "Payed in",
    requestedIn: "Requested in",
    paymentMethod: "Payment method",
    reason: "Reason",
    error: "Error",
    button: {
      refund: "Refund",
      refuse: "Refuse",
    },
  },
  Subscription: {
    title: "Subscription",
    client: "Client",
    plan: "Plan",
    nextPayment: "Next payment",
    pendingPayment: "Pending renewal payment.",
    startAt: "Started at",
    subscriptionStatus: "Subscription Status",
    invoices: "Invoices",
    expiresAt: "Ends at",
    status: "Status",
    price: "Price",
    detail: "Details",
    see: "More",
    history: "History",
    date: "Date",
    description: "Description",
    notes: "Notes",
    created: "Created",
    button: {
      activate: "Activate",
      suspend: "Suspend",
    },
    error: "Erro",
    EmptyState: "You have no subscriptions in this period.",
    tableTitle: {
      subscribers: "Subscribers",
      subscriber: "Subscriber",
      product: "Product",
      startedAt: "Started At",
      dueDate: "Due Date",
    },
    tableSearch: "Search by name or email",
    tabs: {
      active: "Active",
      all: "All",
    },
    Detail: {
      listItems: {
        plan: "Plan:",
        status: "Status:",
        start: "Start:",
        nextPayment: "Next Payment:",
        paymentMethod: "Method:",
        email: "Email:",
        whatsapp: "WhatsApp:",
        phoneNumber: "Telephone:",
        getInTouch: "Get in touch",
        price: "Price:",
      },
      currentStatus: {
        active: "Active",
        inactive: "Inactive",
      },
      transactionStatus: {
        pending: "Pending",
        processing: "Processing",
        registered: "Registered",
        none: "None",
        paid: "Paid",
        authorized: "Authorized",
        canceled: "Canceled",
        partiallyPaid: "Partially paid",
        refunded: "Refunded",
        expired: "Expired",
        inProtest: "In protest",
        chargeback: "Chargeback",
        inAnalysis: "In analysis",
        draft: "draft",
      },
      tableTitle: {
        date: "Date",
        receipt: "Status",
      },
    },
    ReasonWhyCantChangePlan: {
      title: " ",
      canceledOrSuspended: "Switching plans is unavailable because your subscription is ",
      noPlansAvailable: "Switching plans is unavailable because there are no plans available for switching",
      expiresAt: "Switching plans will only be available after the day ",
      featureFlag: "Switching plans is unavailable for this product",
      isChangingPlan: "Plan switching is unavailable because a plan switching is already happening at this time",
    },
  },
  SubscriptionDetails: {
    title: "Subscription Details",
    by: "By",
    plan: "Plan",
    endAt: "End at",
    renewalDate: "Renewal date",
    subscriptionStartedAt: "Subscription started at",
    changePaymentMethod: "Change payment method",
    changePlan: "Change plan",
    refund: "Request refund",
    cancel: "Cancel subscription",
    renewSubscription: "Renew subscription",
    suspended: "Suspended",
    pixIsAutogenerated: "The Pix code is autogenerated for renewal payments.",
    table: {
      date: "Date",
      method: "Payment method",
      status: "Status",
      amount_charged: "Amount charged",
    },
    transaction: {
      scheduled: "Scheduled",
      paid: "Paid",
      requested_refund: "Requested refund",
      refunded: "Refunded",
      suspended: "Suspended",
      canceled: "Canceled",
    },
  },
  SaleItem: {
    sale: "Sale",
    subscription: "Subscription",
  },
  Button: {
    newProduct: "New product",
  },
  Waitlist: {
    Empty: {
      Message: "You don't have any contacts on the waitlist yet. Share your page to capture new contacts.",
      Button: "Copy link",
    },
    Table: {
      Product: "Product",
      Contacts: "Contacts",
      Conversions: "Conversions",
    },
    GroupStatus: {
      isDraft: {
        current: "Draft",
        action: "put in draft",
        description: "",
      },
      isPublished: {
        current: "Published",
        action: "Publish this",
        description: "",
      },
      isSelling: {
        current: "Selling",
        action: "Activate sales",
        description: "People will be able to find and buy your product",
      },
      isWaitlisted: {
        current: "Waitlisted",
        action: "Activate waiting list",
        description: "People will be able to find your product and subscribe to the waiting list",
      },
      isNotEnabled: {
        current: "Disabled",
        action: "Disable",
        description: "You will disable your product sales and hide it from your profile",
      },
      isNotSelling: {
        current: "Disabled",
        action: "Stop sales",
        description: "You will disable your product sales and hide it from your profile",
      },
      isUnavailable: {
        current: "Unavailable",
        action: "",
      },
    },
    EmptyMessage: {
      active: "You don't have any active waitlists yet. To create a waiting list, go to My Groups",
      inactive: "You don't have any inactive waiting lists",
    },
    Tabs: { Active: "Actives", Inactive: "Inactives" },
    Detail: {
      HeadInfo: {
        conversionsAmount: "Converted",
        subscribersAmount: "Contacts",
      },
      Header: {
        Subtitle: "Created on ",
      },
      Dropbox: {
        allSubscribers: "All contacts",
        convertedMember: "Converted",
        notMember: "Not Converted",
      },
      Table: {
        Name: "Name",
        PhoneNumber: "Phone Number",
        Email: "Email",
        Date: "Date",
        Conversion: "Conversion",
        Status: "Status",
        Action: "Actions",
        Row: {
          Status: {
            notMember: "Not converted",
            convertedMember: "Converted",
          },
        },
      },
      PopupTips: "Displays the first group the member purchased after joining the waitlist.",
    },
    Title: "Waitlist",
    Subscribers: " Subscribers",
    Conversions: " Conversions",
    Subscriber: " Subscriber",
    Conversion: " Conversion",
  },
  productsTab: {
    yourProducts: "Your products",
    productsImPartner: "Products i'm a Partner",
  },
}
