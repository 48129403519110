"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Colors = void 0;
var Colors;
exports.Colors = Colors;

(function (Colors) {
  Colors["brown"] = "brown";
  Colors["blue"] = "blue";
  Colors["darkblue"] = "darkblue";
  Colors["darkgreen"] = "darkgreen";
  Colors["darkpurple"] = "darkpurple";
  Colors["darkwatergreen"] = "darkwatergreen";
  Colors["grey"] = "grey";
  Colors["lightgreen"] = "lightgreen";
  Colors["lightorange"] = "lightorange";
  Colors["lightpurple"] = "lightpurple";
  Colors["lightyellow"] = "lightyellow";
  Colors["olive"] = "olive";
  Colors["orange"] = "orange";
  Colors["pink"] = "pink";
  Colors["purple"] = "purple";
  Colors["red"] = "red";
  Colors["watergreen"] = "watergreen";
  Colors["yellow"] = "yellow";
})(Colors || (exports.Colors = Colors = {}));