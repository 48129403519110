import { useFormikContext } from "formik"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Envs } from "../../envs"
import { useField } from "./use-field"
import {
  cvvStyles,
  expirationStyles,
  panStyles,
} from "../pages/user-subscription-detail/components/change-payment-method-modal/yuno-credit-card.styles"
import { Values } from "../pages/user-subscription-detail/components/change-payment-method-modal"
import { TokenizationError } from "@hub-la/fe-tokenizer"

export const useYunoSecureFields = (sessionId?: string) => {
  const [isPanValid, setIsPanValid] = useState(false)
  const [isPanTouched, setIsPanTouched] = useState(false)
  const [isExpirationValid, setIsExpirationValid] = useState(false)
  const [isExpirationTouched, setIsExpirationTouched] = useState(false)
  const [isCvvValid, setIsCvvValid] = useState(false)
  const [isCvvTouched, setIsCvvTouched] = useState(false)

  const { t } = useTranslation()
  const { values, setFieldValue, setFieldError, setFieldTouched } = useFormikContext<Values>()
  const { hasError } = useField()

  const secureFields = useMemo(() => {
    if (!sessionId) return
    const yuno = window.Yuno.initialize(Envs.YUNO_PUBLIC_API_KEY)

    return yuno.secureFields({
      countryCode: "BR",
      customerSession: sessionId,
    })
  }, [sessionId])

  useEffect(() => {
    if (!secureFields) return
    const secureNumber = secureFields.create({
      name: "pan",
      options: {
        styles: panStyles,
        label: t("userSubscriptions.changePaymentMethodModal.creditCard.number"),
        onChange: ({ error, isDirty }) => {
          setIsPanValid(!error)
          setIsPanTouched(isDirty)
        },
      },
    })

    const secureExpiration = secureFields.create({
      name: "expiration",
      options: {
        styles: expirationStyles,
        label: "MM/YY",
        onChange: ({ error, isDirty }) => {
          setIsExpirationValid(!error)
          setIsExpirationTouched(isDirty)
        },
      },
    })

    const secureCvv = secureFields.create({
      name: "cvv",
      options: {
        styles: cvvStyles,
        label: "CVV",
        onChange: ({ error, isDirty }) => {
          setIsCvvValid(!error)
          setIsCvvTouched(isDirty)
        },
      },
    })

    secureNumber.render("#pan")
    secureExpiration.render("#expiration")
    secureCvv.render("#cvv")
  }, [secureFields])

  const generateToken = async () => {
    try {
      const vaultedToken = await secureFields.generateVaultedToken({
        cardHolderName: values.creditCard.holder,
        customer: {
          document: {
            document_number: values.document,
            document_type: "CPF",
          },
        },
      })
      setFieldValue("creditCard.token", vaultedToken)
    } catch (error) {
      const e = new TokenizationError()
      setFieldTouched("creditCard.token", true)
      setFieldError("creditCard.token", e.message)
    }
  }

  const error = () => {
    switch (true) {
      case !isPanValid && isPanTouched:
        return "Número do cartão inválido"
      case !isExpirationValid && isExpirationTouched:
        return "Expiração inválida"
      case !isCvvValid && isCvvTouched:
        return "Código de segurança inválido"
      case hasError("creditCard.token"):
        return "Erro ao tokenizar cartão de crédito"
      default:
        return ""
    }
  }

  return {
    isPanValid,
    isExpirationValid,
    isCvvValid,
    generateToken,
    error: error(),
  }
}
