import { reducer as Reducer } from "./GroupsSlice"
import { IState } from "./GroupsState"

import { fetchGroups } from "./GroupsActions"

const Actions = {
  fetchGroups,
}

export { Actions, Reducer }
export type { IState }
