"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.randomEnumValue = exports.iuguIdFixture = exports.groupLinkFixture = exports.groupIdFixture = exports.firebaseIdFixture = exports.IMFixture = exports.FirebaseInjections = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _TimestampFactory = require("../../Helpers/TimestampFactory");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var firebaseIdFixture = function firebaseIdFixture() {
  return faker.random.alphaNumeric(28);
};

exports.firebaseIdFixture = firebaseIdFixture;

var iuguIdFixture = function iuguIdFixture() {
  return faker.random.alphaNumeric(32).toUpperCase();
};

exports.iuguIdFixture = iuguIdFixture;

var groupIdFixture = function groupIdFixture(type) {
  if (type === "app") {
    return "app";
  }

  return type === "whatsapp" ? "".concat(faker.phone.phoneNumber("##########"), "-").concat(faker.phone.phoneNumber("#######")) : faker.datatype.number({
    min: 100000,
    max: 9999999
  }).toString();
};

exports.groupIdFixture = groupIdFixture;

var groupLinkFixture = function groupLinkFixture(type) {
  if (type === "app") {
    return "app";
  }

  return type === "whatsapp" ? "https://chat.whatsapp.com/".concat(faker.random.alphaNumeric(10)) : "https://t.me/joinchat/".concat(faker.random.alphaNumeric(10));
};

exports.groupLinkFixture = groupLinkFixture;

var IMFixture = function IMFixture() {
  return {
    whatsapp: faker.phone.phoneNumber("############"),
    telegram: faker.datatype.number({
      min: 100000,
      max: 9999999
    }).toString(),
    telegramUsername: faker.internet.userName(faker.name.firstName(), faker.name.firstName())
  };
};

exports.IMFixture = IMFixture;

var randomEnumValue = function randomEnumValue(xnum) {
  return faker.random.arrayElement(Object.values(xnum));
};

exports.randomEnumValue = randomEnumValue;
var FirebaseInjections = {
  timestampFromDate: function timestampFromDate(date) {
    return _TimestampFactory.TimestampInject.TimestampFactory.createTimestampFromDate(date);
  }
};
exports.FirebaseInjections = FirebaseInjections;