"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Resource = require("../Resource");

var _faker = _interopRequireDefault(require("faker"));

var _Group = _interopRequireWildcard(require("../Group"));

var _Plan = require("../Plan");

var _Sellable = _interopRequireDefault(require("./Sellable"));

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var groupFixture = function groupFixture(args) {
  var groupId = _faker["default"].datatype.number({
    min: 100000,
    max: 9999999
  }).toString();

  return new _Group["default"](_objectSpread(_objectSpread({}, (0, _Sellable["default"])({
    parentId: null
  })), {}, {
    id: groupId,
    description: "<p>".concat(_faker["default"].commerce.productDescription(), "</p>"),
    subtitle: _faker["default"].company.catchPhrase(),
    welcomeText: _faker["default"].random.words(20),
    picture: _faker["default"].image.imageUrl(),
    endAt: _faker["default"].random.arrayElement([null, _utils.FirebaseInjections.timestampFromDate(_faker["default"].date.future(1))]),
    maxMemberCount: _faker["default"].datatype.number({
      min: 2,
      max: _Resource.MaxMemberCount.whatsapp
    }),
    order: _faker["default"].datatype.number(5),
    hasPlans: _faker["default"].datatype["boolean"](),
    hasWhitelist: _faker["default"].datatype["boolean"](),
    hasMembersArea: _faker["default"].datatype["boolean"](),
    isDraft: _faker["default"].datatype["boolean"](),
    isPublished: _faker["default"].datatype["boolean"](),
    isVisible: _faker["default"].datatype["boolean"](),
    isSelling: _faker["default"].datatype["boolean"](),
    unavailable: {
      isOn: _faker["default"].datatype["boolean"](),
      reason: _faker["default"].datatype.string(100)
    },
    skipRemoval: _faker["default"].datatype["boolean"](),
    rulesFile: "".concat(_faker["default"].internet.url()).concat(_faker["default"].system.filePath()),
    memberCount: {
      total: _faker["default"].datatype.number(_Resource.MaxMemberCount.whatsapp),
      waitings: _faker["default"].datatype.number(_Resource.MaxMemberCount.whatsapp),
      elegibles: _faker["default"].datatype.number(_Resource.MaxMemberCount.whatsapp),
      participants: _faker["default"].datatype.number(_Resource.MaxMemberCount.whatsapp)
    },
    trackers: {
      facebook: {
        id: _faker["default"].datatype.number({
          min: 100000
        }).toString(),
        domain: _faker["default"].internet.domainName(),
        isVerified: _faker["default"].datatype["boolean"](),
        accessToken: _faker["default"].random.words(32).toString()
      }
    },
    plansForCreation: [{
      name: _faker["default"].random.word(),
      interval: _faker["default"].random.objectElement(_Plan.Interval),
      price: _faker["default"].datatype.number({
        precision: 2
      })
    }],
    acceptsBankSlipReceipt: _faker["default"].datatype["boolean"](),
    status: _Group.Status.approved,
    feedback: _faker["default"].lorem.words(),
    notificationSettings: _defineProperty({}, _Group.GroupNotificationAction.postCreated, _faker["default"].datatype["boolean"]()),
    invoiceDescription: undefined
  }, args));
};

var _default = groupFixture;
exports["default"] = _default;