import { Text, FeedbackBanner, Stack } from "@hub-la/design-system"
import { useGetPendencies } from "modules/user-groups/presentation/hooks/use-get-pendencies"
import ReplayIcon from "@mui/icons-material/Replay"
import React from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { routes } from "@chatpay/common"

const Card = ({ length, type }: { length: number; type: "overdue" | "unpaid" }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const redirectToPendenciesPage = () => history.push(routes.USER_PENDENCIES.LINK)
  return (
    <Stack direction="column" gap={2}>
      <Text variant="body2" fontWeight="bold">
        {t(`userGroups.${type}.title`)}
      </Text>
      <Text variant="body2">{t(`userGroups.${type}.subtitle`, { length })}</Text>
      <Stack mt={4} alignSelf="end" style={{ cursor: "pointer" }} onClick={redirectToPendenciesPage}>
        <Text variant="body2" fontWeight="bold">
          {t(`userGroups.${type}.cta`, { length })}
        </Text>
      </Stack>
    </Stack>
  )
}

export const Pendencies: React.FC = () => {
  const { data } = useGetPendencies()

  const overdueLength = data?.overdue.length ?? 0
  const unpaidLength = data?.unpaid.length ?? 0

  return (
    <Stack direction="column" gap={4} mb={4}>
      {overdueLength > 0 && (
        <FeedbackBanner variant="negative">
          <Card length={overdueLength} type="overdue" />
        </FeedbackBanner>
      )}
      {unpaidLength > 0 && (
        <FeedbackBanner icon={<ReplayIcon />}>
          <Card length={unpaidLength} type="unpaid" />
        </FeedbackBanner>
      )}
    </Stack>
  )
}
