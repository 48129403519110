import { HttpClient, HttpMethod, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { Envs } from "../envs"
import { GetPendingInvitesOutput } from "../domain/dtos/get-pending-invites-output"

export class GetPendingInvites {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<GetPendingInvitesOutput>,
  ) {}

  public async execute(): Promise<GetPendingInvitesOutput | undefined> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/invites/user-free-membership-pending-invites`,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new Error("Não foi possível buscar os convites pendentes")
  }
}
