import API from "./API"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"

interface IFile {
  type: FileAssetType
  name: string
  URL?: string
  file?: File
}

export enum FileAssetType {
  local = "Local",
  web = "Web",
}

export default class FileAPI extends API {
  private root: firebase.storage.Reference

  constructor() {
    super()
    this.root = firebase.storage().ref().child("private")
  }

  public async upload(file: File, path: string, onLoad?: (n: number) => any): Promise<IFile> {
    return new Promise((resolve, reject) => {
      /** Add a file to firebase */
      const uploadTask = this.root.child(path).child(file.name).put(file)

      /**
       * @function on handles all the callbacks in firebase.
       */
      uploadTask.on(
        "state_changed",

        onLoad
          ? (snapshot) => {
              /**
               * When there is progress update, the @callback next function will be called.
               * The upload progress equal (bytes transfered / file size)
               */
              onLoad((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            }
          : undefined,

        /* Handle error */
        (error: Error) => reject(error),
        /**
         * On completion we will pass in the URL to the @callback complete
         */
        async () => {
          try {
            await uploadTask.snapshot.ref.getDownloadURL().then((URL) => {
              resolve({
                name: file.name,
                URL,
                type: FileAssetType.web,
              })
            })
          } catch (error) {
            reject(error as Error)
          }
        },
      )
    })
  }
}
