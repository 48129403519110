"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Type = exports.TokenType = exports.AutoAdvanceType = void 0;
var Type;
exports.Type = Type;

(function (Type) {
  Type["pf"] = "pf";
  Type["pj"] = "pj";
})(Type || (exports.Type = Type = {}));

var TokenType;
exports.TokenType = TokenType;

(function (TokenType) {
  TokenType["bearer"] = "bearer";
  TokenType["basic"] = "basic";
})(TokenType || (exports.TokenType = TokenType = {}));

var AutoAdvanceType;
exports.AutoAdvanceType = AutoAdvanceType;

(function (AutoAdvanceType) {
  AutoAdvanceType["daily"] = "daily";
  AutoAdvanceType["weekly"] = "weekly";
  AutoAdvanceType["monthly"] = "monthly";
  AutoAdvanceType["daysAfterPayment"] = "days_after_payment";
})(AutoAdvanceType || (exports.AutoAdvanceType = AutoAdvanceType = {}));