import { Divider, Stack, Text } from "@hub-la/design-system"
import React from "react"

type Props = {
  title: string
  content: React.ReactElement
  fontWeight?: "bold" | "normal"
  showDivider?: boolean
}

export const ContentRow: React.FC<Props> = (props) => {
  const { title, content, fontWeight = "normal", showDivider = false } = props
  return (
    <Stack direction="column" width="100%" gap={2}>
      <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={2}>
          <Text variant="body1" fontWeight={fontWeight}>
            {title}
          </Text>
        </Stack>
        {content}
      </Stack>
      {showDivider && <Divider />}
    </Stack>
  )
}
