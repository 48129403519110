import React from "react"
import { Form, FormTextAreaProps } from "semantic-ui-react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Text } from "typography"
import { isEqual, lt } from "lodash"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Area = styled(Form.TextArea)`
  width: 100%;
`

const Counter = styled(Text.Tiny)`
  margin-top: -10px;
  margin-bottom: 15px;
  opacity: 0.55;
`

const DEFAULT_MAX_LENGTH = 700

interface Props extends FormTextAreaProps {
  maxLength?: number
  minLength?: number
  isCounterVisible?: boolean
}

export const TextArea = ({ isCounterVisible = true, ...props }: Props) => {
  const { t } = useTranslation()
  const maxLength = props.maxLength ?? DEFAULT_MAX_LENGTH
  const minLength = props.minLength

  const length = props.value ? props.value?.toString().length : 0

  const getCounterText = () => {
    if (minLength && isEqual(length, 0)) {
      return t("TextArea.atLeast", {
        minLength,
      })
    }

    if (!isEqual(length, 0) && minLength && lt(length, minLength)) {
      return t("TextArea.writeMore", {
        length: minLength - length,
      })
    }

    return t("TextArea.charactersLeft", {
      length: maxLength - length,
      maxLength,
    })
  }

  return (
    <Container>
      <Area {...props} />

      {isCounterVisible && <Counter>{getCounterText()}</Counter>}
    </Container>
  )
}
