import { Interface } from "@chatpay/common"
import API from "./API"
import { Value, ValueSource } from "firebase/remote-config"

class FeatureFlag extends API {
  public async get(data: Interface.FeatureFlag.Function.IGet): Promise<unknown> {
    const response = await this.call(Interface.FeatureFlag.Function.Name.get, data)
    return response.data
  }

  public async getAll(data?: Interface.FeatureFlag.Function.IGetAll): Promise<Record<string, Value>> {
    const response = await this.call(Interface.FeatureFlag.Function.Name.getAll, data)
    const configs = response.data as Record<string, Interface.FeatureFlag.RawFlag>

    const parsedConfigs: Record<string, Value> = {}
    for (const [key, value] of Object.entries(configs)) {
      parsedConfigs[key] = new FlagValue(value)
    }

    return parsedConfigs
  }

  private async call(func: Interface.FeatureFlag.Function.Name, params?: Interface.FeatureFlag.Function.Params) {
    return await this.callFunction(`featureFlag/${func}`, params)
  }
}

class FlagValue implements Value {
  constructor(private data: Interface.FeatureFlag.RawFlag) {}

  asBoolean(): boolean {
    return Boolean(this.data?.defaultValue?.value)
  }
  asNumber(): number {
    return Number(this.data?.defaultValue?.value)
  }
  asString(): string {
    return String(this.data?.defaultValue?.value)
  }
  getSource(): ValueSource {
    return "remote"
  }
}

export default FeatureFlag
