import { Spacer } from "components"
import React from "react"

export const BasicHeader = (title: string, subtitle: string) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Spacer />
      <div className="cp text black bold huge">{title}</div>
      <Spacer size="small" />
      <div className="cp text big">{subtitle}</div>
      <Spacer />
    </div>
  )
}
