"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Type = void 0;
var Type;
exports.Type = Type;

(function (Type) {
  Type["cc"] = "cc";
  Type["cp"] = "cp";
})(Type || (exports.Type = Type = {}));