export enum FileAssetType {
  local = "Local",
  web = "Web",
}

export interface IFileAsset {
  name: string
  type: FileAssetType
  file?: File
  URL?: string
}
