"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["create"] = "create";
  Name["update"] = "update";
  Name["delete"] = "delete";
  Name["retrieve"] = "retrieve";
})(Name || (exports.Name = Name = {}));