import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from "@hub-la/fe-core-http-client"
import { inject } from "inversify"
import { isNil } from "lodash"
import { GetOffersOutput } from "../domain/dtos/get-offers-output"
import { Envs } from "../envs"

export class GetOffers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<GetOffersOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/payer/history-products`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response)
    }

    throw new Error("Falha ao buscar compras")
  }

  private makeOutput(response: HttpResponse<GetOffersOutput>): GetOffersOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    if (data.length === 1) {
      return [
        {
          id: data[0].id,
          name: data[0].name,
        },
      ]
    }

    data.unshift({
      id: "all",
      name: "Todos",
    })

    return data
  }
}
