import { Interface } from "@chatpay/common"
import API from "./API"

class Payments extends API {
  private async call(func: Interface.Payments.Function.Name, params?: Interface.Payments.Function.Params) {
    return await this.callFunction(`payments/${func}`, params)
  }
  public async refundInvoice(params: Interface.Payments.Function.Params): Promise<void> {
    return (await this.call(Interface.Payments.Function.Name.refundInvoice, params)).data
  }
}

export default Payments
