import { Document } from "@chatpay/common"
import { API, Service } from "@chatpay/components"
import { Analytics } from "@chatpay/components"
import React, { useCallback, useEffect, useState } from "react"
import { Button, Popup } from "semantic-ui-react"
import useSwr from "swr"
import { FileButton } from "./FileButton"
import { IUploadStatus } from "./SelectGroupAsset"

interface IFileSelectorProps {
  value?: Document.Material
  disabled?: boolean
  onChange?: (status: IUploadStatus, material?: Document.Material) => any
  onYoutubeOption?: () => any
  setProgress?: (progress: number) => any
  children?: React.ReactElement
}

export const FileSelector: React.FunctionComponent<IFileSelectorProps> = (props) => {
  const { onChange, disabled, value, onYoutubeOption, setProgress } = props

  const [assetRefreshInterval, setAssetRefreshInterval] = useState(5000)

  const [uploadId, setUploadId] = useState<string | null>(null)
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>(IUploadStatus.none)
  const [cover, setCover] = useState<Document.Material>()
  const [videoUpload, setVideoUpload] = useState<any>(undefined)

  const [open, setOpen] = useState<boolean>(false)

  const setMaterialChange = useCallback(
    (status: IUploadStatus, material?: Document.Material) => {
      setCover(material)
      setUploadStatus(status)
      onChange?.(status, material)
    },
    [onChange],
  )

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(cover)) {
      setCover(value)
      setUploadStatus(IUploadStatus.finished)
    }
  }, [value, cover, setCover, setUploadStatus])

  const fetcher = async (uploadId: string) => await new API.Video().getLatestAssetStateFromUpload({ id: uploadId })

  const { data, error } = useSwr(() => (uploadId ? uploadId : null), fetcher, {
    refreshInterval: assetRefreshInterval,
  })

  const getVideoThumbnail = (playbackId: string): string =>
    `${process.env.REACT_APP_MUX_IMAGE_URL}/${playbackId}/thumbnail.png`

  const getVideoURL = (playbackId: string): string => `${process.env.REACT_APP_MUX_STREAM_URL}/${playbackId}.m3u8`

  const isVideoAssetReady = (data?: Document.VideoAsset | null): boolean =>
    data && data.status === "ready" ? true : false

  const createVideoMaterial = useCallback(
    async (data: Document.VideoAsset) => {
      setUploadStatus(IUploadStatus.finished)
      const playbackId = data.playback_ids![0].id
      const videoMaterial = await new API.Material().create({
        name: "",
        location: Document.MaterialLocation.groupAsset,
        type: Document.MaterialFormat.video,
        owner: Service.Firebase.currentUser!.toReference(),
        url: getVideoURL(playbackId),
        data: {
          assetId: data.id,
          thumbnailUrl: getVideoThumbnail(playbackId),
        } as Document.VideoMaterialData,
      })
      setCover(videoMaterial)
      onChange?.(IUploadStatus.finished, videoMaterial)
      setMaterialChange(IUploadStatus.finished, videoMaterial)
      setAssetRefreshInterval(0)
      Analytics.track("VideoUploadSuccess", { videoId: videoMaterial.id })
    },
    [onChange, setMaterialChange],
  )

  useEffect(() => {
    if (isVideoAssetReady(data) && uploadStatus !== IUploadStatus.finished) {
      createVideoMaterial(data!)
    }
  }, [data, createVideoMaterial, uploadStatus])

  useEffect(() => {
    if (error) {
      Analytics.track("VideoUploadError", { message: error.message })
    }
  }, [error])

  const uploadImage = async (file: File) => {
    setOpen(false)
    setMaterialChange(IUploadStatus.started, undefined)
    const imageMaterial = await new API.Material().upload(
      file,
      Document.MaterialLocation.groupAsset,
      Document.MaterialFormat.image,
      setProgress,
    )
    setMaterialChange(IUploadStatus.finished, imageMaterial)
  }

  const uploadVideo = async (file: File) => {
    setOpen(false)
    setMaterialChange(IUploadStatus.started, undefined)

    const video = new API.Video()
    setVideoUpload(video)

    await video.upload(
      file,
      (upload) => setUploadId(upload.id),
      (progress) => {
        console.log(`So far we've uploaded ${progress}% of this file.`)
        setProgress?.(progress)
      },
      (error) => {
        Analytics.track("VideoUploadError", { message: error.message })
        setMaterialChange(IUploadStatus.none, undefined)
      },
      () => {
        console.log("Wrap it up, we're done here. 👋")
        setMaterialChange(IUploadStatus.processing, undefined)
      },
    )
  }

  useEffect(() => {
    return () => {
      videoUpload?.upChunk?.abort()
    }
  }, [videoUpload])
  return (
    <Popup
      style={{ width: "150px" }}
      on="click"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={props.children}
      content={
        <React.Fragment>
          <div className="input-file">
            {uploadStatus === IUploadStatus.none && (
              <React.Fragment>
                <FileButton disabled={disabled} type="image" onFileChange={(data) => uploadImage(data)} />
                <FileButton disabled={disabled} type="video" onFileChange={(file) => uploadVideo(file)} />
                <Button
                  className="borderless"
                  disabled={disabled}
                  content="Youtube"
                  icon="youtube"
                  basic={true}
                  size="small"
                  onClick={() => {
                    onYoutubeOption?.()
                    setOpen(false)
                  }}
                />
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      }
    />
  )
}
