export const ProductForm = {
  create: {
    title: "What kind of product do you want to create?",
  },
  product: {
    permanent: {
      title: "Permanent",
      description: "Great for creating an environment of constant exchange that is very close to your audience.",
    },
    cohort: {
      title: "Cohort",
      description: "Great for a challenge product or a course with classes.",
    },
    channel: {
      title: "Transmission Channel",
      description: "Great for creating a signal room or copy trade.",
    },
  },
}
