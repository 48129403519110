export interface PaymentMethod {
  type: UpgradePaymentMethods
  // The installments amount used in the charge moment
  installments?: number
  installmentPrice?: number
  last4?: string
  cardBrand?: string
  text?: string
  url?: string
}

export enum UpgradePaymentMethods {
  /** PAYMENT_METHOD_UNSPECIFIED - Default value, do not use. */
  PAYMENT_METHOD_UNSPECIFIED = 0,
  /** PAYMENT_METHOD_CARD - Credit and Debit Cards. */
  PAYMENT_METHOD_CARD = 1,
  /** PAYMENT_METHOD_PIX - Pix (Brazil). */
  PAYMENT_METHOD_PIX = 2,
  /** PAYMENT_METHOD_BOLETO - Boleto (Brazil). */
  PAYMENT_METHOD_BOLETO = 3,
}
