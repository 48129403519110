export const aboutUs = {
  section1: {
    title: "Quem somos nós?",
    text1:
      "A Hubla permite que as pessoas retomem o protagonismo das suas carreiras ganhando dinheiro de maneira justa com aquilo que sabem fazer de melhor.",
    text2:
      "Sabe aquela pessoa que toda vez que você conversa com ela você descobre algo novo? Aquele cara interessado, que está sempre bem informado e que tem dicas preciosas que todos adoram.",
    text3:
      "Ele sempre sabe o que está acontecendo, onde achar as melhores coisas que você quer na internet, e com os melhores preços.",
    text4: `Ele não procura a informação pela informação, ele procura quem o faça ter segurança necessária pra seguir em frente com a sua escolha. Dessa forma ele se sente seguro com sua própria decisão e de que está com o protagonismo da sua vida, "fazendo acontecer".`,
  },
  section2: {
    title: "Como surgimos?",
    text1:
      "Nós começamos a Hubla em Janeiro de 2020, pois percebemos a dificuldade que as pessoas tinham para gerenciar seus grupos pagos.",
    text2:
      "A partir disso nós desenvolvemos uma plataforma no qual todas as pessoas poderiam facilmente monetizar seu conteúdo sem ter que se preocupar com a parte chata e burocrática, podendo focar somente no que entregar pra sua audiência.",
  },
  section3: {
    title: "Hubla é para todos!",
    text1: "Somos para todos que são inconformados com as relações tradicionais de trabalho e remuneração.",
    text2:
      "Pra todos aqueles que tem um propósito delimitado, que tem conhecimentos específicos mas que funcionam para um grande número de pessoas. Conhecimento esse que deve ser espalhado para o maior número de indivíduos possível, de maneira simples, direta e sem rodeios.",
    text3:
      "Seja quando for, levar para as pessoas uma possibilidade de transformar a vida delas nos assuntos que, isolados podem parecer inofensivos, mas que quando combinados a todo um ecossistema, faz a vida das pessoas menos complicada.",
    text4:
      "Todas as respostas ainda não existem, mas a Hubla com toda certeza é a plataforma que conecta pra valer quem tem algo pra ensinar com quem quer aprender.",
  },
  section4: {
    title: "O que você pode ganhar com a gente?",
    text1: "Suporte e cuidado",
    text2:
      "Estamos com você nos diferentes momentos de construção do infoproduto, buscando soluções para melhorar o desempenho da plataforma bem como resolver problemas pontuais afim de resolver diferentes necessidades em diferentes situações.",
    text3: "Segurança",
    text4:
      "A preocupação com a excelência na execução das principais funcionalidades da gestão da plataforma para você é primordial para a gente.",
    text5: "Conhecimento",
    text6:
      "Por se tratar de um produto novo e um novo ecossistema, desenvolver conhecimento para vocês e seus clientes é uma diretriz constante na nossa relação.",
  },
  section5: {
    title: "Venha fazer parte dessa jornada você também!",
  },
}
