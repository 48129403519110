import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import { Values } from "../pages/user-subscription-detail/components/change-payment-method-modal"
import { v4 } from "uuid"
import { GetSession } from "modules/user-subscriptions/usecases/get-session"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { PaymentMethod } from "modules/user-invoices/domain/enums/payment-method"

export const useGetSession = () => {
  const container = useContainer()
  const { values, setFieldValue } = useFormikContext<Values>()

  const fetch = async () =>
    await new GetSession(container.get(HttpClient))
      .execute({
        merchantOrderId: v4(),
        paymentMethod:
          values.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD
            ? PaymentMethod.CREDIT_CARD
            : PaymentMethod.PIX,
      })
      .then((data) => {
        setFieldValue("sessionId", data.sessionId)
        setFieldValue("customerId", data.customerId)
      })

  useEffect(() => {
    fetch()
  }, [values.paymentMethod])
}
