import { Analytics, AnalyticsProvider } from "@hub-la/fe-core-analytics"
import { Auth } from "@hub-la/fe-core-auth"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { AxiosHttpClient } from "@hub-la/fe-vendors-axios"
import { FirebaseAuth } from "@hub-la/fe-vendors-firebase"
import { RudderStackAnalytics } from "@hub-la/fe-vendors-rudderstack"
import { Container } from "inversify"

const container = new Container()

container.bind(Auth).to(FirebaseAuth)
container.bind(HttpClient).to(AxiosHttpClient)
container.bind(Analytics).to(Analytics)
container
  .bind(AnalyticsProvider)
  .toConstantValue(
    new RudderStackAnalytics(
      process.env.REACT_APP_RUDDERSTACK_TOKEN!,
      process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL!,
      false,
    ),
  )

export default container
