"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["create"] = "create";
  Name["invoiceCancel"] = "invoiceCancel";
  Name["invoiceDuplicate"] = "invoiceDuplicate";
  Name["invoiceLogs"] = "invoiceLogs";
  Name["insertReceipt"] = "insertReceipt";
})(Name || (exports.Name = Name = {}));