"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequesterType = exports.Name = exports.ListInvoicesRequestDirectionDto = exports.InvoiceStatus = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["getDetail"] = "getDetail";
  Name["list"] = "list";
  Name["getChart"] = "getChart";
})(Name || (exports.Name = Name = {}));

var RequesterType;
exports.RequesterType = RequesterType;

(function (RequesterType) {
  RequesterType["creator"] = "creator";
  RequesterType["affiliate"] = "affiliate";
})(RequesterType || (exports.RequesterType = RequesterType = {}));

var ListInvoicesRequestDirectionDto;
exports.ListInvoicesRequestDirectionDto = ListInvoicesRequestDirectionDto;

(function (ListInvoicesRequestDirectionDto) {
  ListInvoicesRequestDirectionDto["before"] = "before";
  ListInvoicesRequestDirectionDto["after"] = "after";
})(ListInvoicesRequestDirectionDto || (exports.ListInvoicesRequestDirectionDto = ListInvoicesRequestDirectionDto = {}));

var InvoiceStatus;
exports.InvoiceStatus = InvoiceStatus;

(function (InvoiceStatus) {
  InvoiceStatus["STATUS_UNSPECIFIED"] = "STATUS_UNSPECIFIED";
  InvoiceStatus["STATUS_DRAFT"] = "STATUS_DRAFT";
  InvoiceStatus["STATUS_UNPAID"] = "STATUS_UNPAID";
  InvoiceStatus["STATUS_PAID"] = "STATUS_PAID";
  InvoiceStatus["STATUS_PARTIALLY_PAID"] = "STATUS_PARTIALLY_PAID";
  InvoiceStatus["STATUS_OVERDUE"] = "STATUS_OVERDUE";
  InvoiceStatus["STATUS_VOID"] = "STATUS_VOID";
  InvoiceStatus["STATUS_RETURNED"] = "STATUS_RETURNED";
  InvoiceStatus["STATUS_PARTIALLY_RETURNED"] = "STATUS_PARTIALLY_RETURNED";
})(InvoiceStatus || (exports.InvoiceStatus = InvoiceStatus = {}));