"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Symbols = exports.Currency = void 0;
var Currency;
exports.Currency = Currency;

(function (Currency) {
  Currency["aed"] = "AED";
  Currency["afn"] = "AFN";
  Currency["all"] = "ALL";
  Currency["amd"] = "AMD";
  Currency["ang"] = "ANG";
  Currency["aoa"] = "AOA";
  Currency["ars"] = "ARS";
  Currency["aud"] = "AUD";
  Currency["awg"] = "AWG";
  Currency["azn"] = "AZN";
  Currency["bam"] = "BAM";
  Currency["bbd"] = "BBD";
  Currency["bdt"] = "BDT";
  Currency["bgn"] = "BGN";
  Currency["bhd"] = "BHD";
  Currency["bif"] = "BIF";
  Currency["bmd"] = "BMD";
  Currency["bnd"] = "BND";
  Currency["bob"] = "BOB";
  Currency["brl"] = "BRL";
  Currency["bsd"] = "BSD";
  Currency["btn"] = "BTN";
  Currency["bwp"] = "BWP";
  Currency["byr"] = "BYR";
  Currency["bzd"] = "BZD";
  Currency["cad"] = "CAD";
  Currency["cdf"] = "CDF";
  Currency["chf"] = "CHF";
  Currency["clp"] = "CLP";
  Currency["cny"] = "CNY";
  Currency["cop"] = "COP";
  Currency["crc"] = "CRC";
  Currency["cuc"] = "CUC";
  Currency["cup"] = "CUP";
  Currency["cve"] = "CVE";
  Currency["czk"] = "CZK";
  Currency["djf"] = "DJF";
  Currency["dkk"] = "DKK";
  Currency["dop"] = "DOP";
  Currency["dzd"] = "DZD";
  Currency["egp"] = "EGP";
  Currency["ern"] = "ERN";
  Currency["etb"] = "ETB";
  Currency["eur"] = "EUR";
  Currency["fjd"] = "FJD";
  Currency["fkp"] = "FKP";
  Currency["gbp"] = "GBP";
  Currency["gel"] = "GEL";
  Currency["ggp"] = "GGP";
  Currency["ghs"] = "GHS";
  Currency["gip"] = "GIP";
  Currency["gmd"] = "GMD";
  Currency["gnf"] = "GNF";
  Currency["gtq"] = "GTQ";
  Currency["gyd"] = "GYD";
  Currency["hkd"] = "HKD";
  Currency["hnl"] = "HNL";
  Currency["hrk"] = "HRK";
  Currency["htg"] = "HTG";
  Currency["huf"] = "HUF";
  Currency["idr"] = "IDR";
  Currency["ils"] = "ILS";
  Currency["imp"] = "IMP";
  Currency["inr"] = "INR";
  Currency["iqd"] = "IQD";
  Currency["irr"] = "IRR";
  Currency["isk"] = "ISK";
  Currency["jep"] = "JEP";
  Currency["jmd"] = "JMD";
  Currency["jod"] = "JOD";
  Currency["jpy"] = "JPY";
  Currency["kes"] = "KES";
  Currency["kgs"] = "KGS";
  Currency["khr"] = "KHR";
  Currency["kmf"] = "KMF";
  Currency["kpw"] = "KPW";
  Currency["krw"] = "KRW";
  Currency["kwd"] = "KWD";
  Currency["kyd"] = "KYD";
  Currency["kzt"] = "KZT";
  Currency["lak"] = "LAK";
  Currency["lbp"] = "LBP";
  Currency["lkr"] = "LKR";
  Currency["lrd"] = "LRD";
  Currency["lsl"] = "LSL";
  Currency["lyd"] = "LYD";
  Currency["mad"] = "MAD";
  Currency["mdl"] = "MDL";
  Currency["mga"] = "MGA";
  Currency["mkd"] = "MKD";
  Currency["mmk"] = "MMK";
  Currency["mnt"] = "MNT";
  Currency["mop"] = "MOP";
  Currency["mro"] = "MRO";
  Currency["mur"] = "MUR";
  Currency["mvr"] = "MVR";
  Currency["mwk"] = "MWK";
  Currency["mxn"] = "MXN";
  Currency["myr"] = "MYR";
  Currency["mzn"] = "MZN";
  Currency["nad"] = "NAD";
  Currency["ngn"] = "NGN";
  Currency["nio"] = "NIO";
  Currency["nok"] = "NOK";
  Currency["npr"] = "NPR";
  Currency["nzd"] = "NZD";
  Currency["omr"] = "OMR";
  Currency["pab"] = "PAB";
  Currency["pen"] = "PEN";
  Currency["pgk"] = "PGK";
  Currency["php"] = "PHP";
  Currency["pkr"] = "PKR";
  Currency["pln"] = "PLN";
  Currency["pyg"] = "PYG";
  Currency["qar"] = "QAR";
  Currency["ron"] = "RON";
  Currency["rsd"] = "RSD";
  Currency["rub"] = "RUB";
  Currency["rwf"] = "RWF";
  Currency["sar"] = "SAR";
  Currency["sbd"] = "SBD";
  Currency["scr"] = "SCR";
  Currency["sdg"] = "SDG";
  Currency["sek"] = "SEK";
  Currency["sgd"] = "SGD";
  Currency["shp"] = "SHP";
  Currency["sll"] = "SLL";
  Currency["sos"] = "SOS";
  Currency["spl"] = "SPL";
  Currency["srd"] = "SRD";
  Currency["std"] = "STD";
  Currency["svc"] = "SVC";
  Currency["syp"] = "SYP";
  Currency["szl"] = "SZL";
  Currency["thb"] = "THB";
  Currency["tjs"] = "TJS";
  Currency["tmt"] = "TMT";
  Currency["tnd"] = "TND";
  Currency["top"] = "TOP";
  Currency["try"] = "TRY";
  Currency["ttd"] = "TTD";
  Currency["tvd"] = "TVD";
  Currency["twd"] = "TWD";
  Currency["tzs"] = "TZS";
  Currency["uah"] = "UAH";
  Currency["ugx"] = "UGX";
  Currency["usd"] = "USD";
  Currency["uyu"] = "UYU";
  Currency["uzs"] = "UZS";
  Currency["vef"] = "VEF";
  Currency["vnd"] = "VND";
  Currency["vuv"] = "VUV";
  Currency["wst"] = "WST";
  Currency["xaf"] = "XAF";
  Currency["xcd"] = "XCD";
  Currency["xdr"] = "XDR";
  Currency["xof"] = "XOF";
  Currency["xpf"] = "XPF";
  Currency["yer"] = "YER";
  Currency["zar"] = "ZAR";
  Currency["zmw"] = "ZMW";
  Currency["zwd"] = "ZWD";
})(Currency || (exports.Currency = Currency = {}));

var Symbols = {
  USD: "$",
  // US Dollar
  EUR: "€",
  // Euro
  CRC: "₡",
  // Costa Rican Colón
  GBP: "£",
  // British Pound Sterling
  ILS: "₪",
  // Israeli New Sheqel
  INR: "₹",
  // Indian Rupee
  JPY: "¥",
  // Japanese Yen
  KRW: "₩",
  // South Korean Won
  NGN: "₦",
  // Nigerian Naira
  PHP: "₱",
  // Philippine Peso
  PLN: "zł",
  // Polish Zloty
  PYG: "₲",
  // Paraguayan Guarani
  THB: "฿",
  // Thai Baht
  UAH: "₴",
  // Ukrainian Hryvnia
  VND: "₫",
  // Vietnamese Dong
  BRL: "R$",
  // Brazil Real
  "default": "$" // default currency

};
exports.Symbols = Symbols;