"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Type = exports.Empty = exports.BrazilianState = void 0;
var Type;
exports.Type = Type;

(function (Type) {
  Type["charge"] = "charge";
  Type["local"] = "local";
})(Type || (exports.Type = Type = {}));

var BrazilianState;
exports.BrazilianState = BrazilianState;

(function (BrazilianState) {
  BrazilianState["AC"] = "AC";
  BrazilianState["AL"] = "AL";
  BrazilianState["AM"] = "AM";
  BrazilianState["AP"] = "AP";
  BrazilianState["BA"] = "BA";
  BrazilianState["CE"] = "CE";
  BrazilianState["DF"] = "DF";
  BrazilianState["ES"] = "ES";
  BrazilianState["GO"] = "GO";
  BrazilianState["MA"] = "MA";
  BrazilianState["MG"] = "MG";
  BrazilianState["MS"] = "MS";
  BrazilianState["MT"] = "MT";
  BrazilianState["PA"] = "PA";
  BrazilianState["PB"] = "PB";
  BrazilianState["PE"] = "PE";
  BrazilianState["PI"] = "PI";
  BrazilianState["PR"] = "PR";
  BrazilianState["RJ"] = "RJ";
  BrazilianState["RN"] = "RN";
  BrazilianState["RO"] = "RO";
  BrazilianState["RR"] = "RR";
  BrazilianState["RS"] = "RS";
  BrazilianState["SC"] = "SC";
  BrazilianState["SE"] = "SE";
  BrazilianState["SP"] = "SP";
  BrazilianState["TO"] = "TO";
})(BrazilianState || (exports.BrazilianState = BrazilianState = {}));

var Empty = {};
exports.Empty = Empty;