"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoUploadStatus = void 0;
var VideoUploadStatus;
exports.VideoUploadStatus = VideoUploadStatus;

(function (VideoUploadStatus) {
  VideoUploadStatus[VideoUploadStatus["none"] = 0] = "none";
  VideoUploadStatus[VideoUploadStatus["uploading"] = 1] = "uploading";
  VideoUploadStatus[VideoUploadStatus["processing"] = 2] = "processing";
  VideoUploadStatus[VideoUploadStatus["ready"] = 3] = "ready";
})(VideoUploadStatus || (exports.VideoUploadStatus = VideoUploadStatus = {}));