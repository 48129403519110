import { useQuery } from "@tanstack/react-query"
import { InitUpgradePlan } from "../../usecases/init-upgrade-plan"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useContainer } from "@hub-la/fe-container"
import { QueryKey } from "modules/user-subscriptions/domain/enums/query-key"

export const useInitUpgradePlan = (subscriptionId: string, enabled: boolean) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.initUpgradePlan, subscriptionId],
    () => new InitUpgradePlan(container.get(HttpClient)).execute(subscriptionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!subscriptionId && enabled,
      retry: false,
    },
  )
}
