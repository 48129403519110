"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var faker = _interopRequireWildcard(require("faker"));

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var documentFixture = function documentFixture(args) {
  var _args$createdAt$toDat, _args$createdAt, _args$updatedAt$toDat, _args$updatedAt;

  var createdAt = (_args$createdAt$toDat = args === null || args === void 0 ? void 0 : (_args$createdAt = args.createdAt) === null || _args$createdAt === void 0 ? void 0 : _args$createdAt.toDate()) !== null && _args$createdAt$toDat !== void 0 ? _args$createdAt$toDat : faker.date.recent();
  var updatedAt = (_args$updatedAt$toDat = args === null || args === void 0 ? void 0 : (_args$updatedAt = args.updatedAt) === null || _args$updatedAt === void 0 ? void 0 : _args$updatedAt.toDate()) !== null && _args$updatedAt$toDat !== void 0 ? _args$updatedAt$toDat : faker.date.between(createdAt, new Date());
  return _objectSpread({
    id: (0, _utils.firebaseIdFixture)(),
    parentId: (0, _utils.firebaseIdFixture)(),
    isEnabled: true,
    isDeleted: false,
    createdAt: _utils.FirebaseInjections.timestampFromDate(createdAt),
    updatedAt: _utils.FirebaseInjections.timestampFromDate(updatedAt),
    clientRequest: {
      ip: faker.internet.ip(),
      ua: faker.internet.userAgent(),
      ref: faker.internet.url()
    }
  }, args);
};

var _default = documentFixture;
exports["default"] = _default;