import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { GetPayerProduct } from "../../usecases/get-payer-product"
import { QueryKey } from "../../domain/enums/query-key"

export const useGetPayerProduct = ({
  productId,
  onError,
}: {
  productId: string
  onError?: (error: unknown) => void
}) => {
  const container = useContainer()

  return useQuery([QueryKey.getPayerProduct], () => new GetPayerProduct(container.get(HttpClient)).execute(productId), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    onError,
  })
}
