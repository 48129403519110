import { Reducer } from "redux"

import { GroupsOrderAction, GroupsOrderActionTypes } from "./GroupsOrderActions"
import { IGroupsOrderState } from "./GroupsOrderState"
import arrayMove from "array-move"

const initialGroupsOrderState: IGroupsOrderState = {
  isLoading: false,
  isSaving: false,
  isSaved: false,
  canSave: false,
  groups: [],
  error: null,
}

export const GroupsOrderReducer: Reducer<IGroupsOrderState, GroupsOrderAction> = (
  state: IGroupsOrderState = initialGroupsOrderState,
  action: GroupsOrderAction,
) => {
  switch (action.type) {
    case GroupsOrderActionTypes.LOAD:
      return {
        ...state,
        isLoading: true,
        canSave: false,
      }
    case GroupsOrderActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.groups,
      }
    case GroupsOrderActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        groups: [],
        error: action.reason,
      }
    case GroupsOrderActionTypes.SAVE:
      return {
        ...state,
        isSaving: true,
      }
    case GroupsOrderActionTypes.SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        canSave: false,
      }
    case GroupsOrderActionTypes.SAVE_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.reason,
      }
    case GroupsOrderActionTypes.SORT:
      return {
        ...state,
        isSaved: false,
        groups: arrayMove(state.groups, action.oldIndex, action.newIndex),
        canSave: true,
      }
    case GroupsOrderActionTypes.DISMISS_BUTTON:
      return {
        ...state,
        isSaved: false,
      }
    default:
      return state
  }
}
