import { shape, spacing, styled, withUtility } from "@hub-la/design-system"

export const StatusIcon = styled("img")`
  width: 56px;
  height: auto;
  object-fit: contain;
  object-position: center;
`

export const Card = styled("div")`
  padding: ${spacing(6)};
  border-radius: ${shape("medium")};
  background-color: ${withUtility(({ palette }) => palette("surfaceAt1"))};
`
