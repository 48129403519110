"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HubspotUseCase = exports.HubspotSalesSource = exports.HubspotFunnelStage = void 0;
var HubspotUseCase;
exports.HubspotUseCase = HubspotUseCase;

(function (HubspotUseCase) {
  HubspotUseCase["informationAgility"] = "information_agility";
  HubspotUseCase["habitFormation"] = "habit_formation";
  HubspotUseCase["community"] = "community";
})(HubspotUseCase || (exports.HubspotUseCase = HubspotUseCase = {}));

var HubspotFunnelStage;
exports.HubspotFunnelStage = HubspotFunnelStage;

(function (HubspotFunnelStage) {
  HubspotFunnelStage["accountCreated"] = "account_created";
  HubspotFunnelStage["groupCreated"] = "group_created";
  HubspotFunnelStage["groupPublished"] = "group_published";
  HubspotFunnelStage["groupSold"] = "group_sold";
})(HubspotFunnelStage || (exports.HubspotFunnelStage = HubspotFunnelStage = {}));

var HubspotSalesSource;
exports.HubspotSalesSource = HubspotSalesSource;

(function (HubspotSalesSource) {
  HubspotSalesSource["inbound"] = "inbound";
  HubspotSalesSource["outbound"] = "outbound";
})(HubspotSalesSource || (exports.HubspotSalesSource = HubspotSalesSource = {}));