import React from "react"
import { AssistChip, Stack, Text, Tooltip } from "@hub-la/design-system"
import { AvailableCarriers, useMultigatewayHandler } from "@hub-la/fe-tokenizer"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import { useFormikContext } from "formik"
import { Installment } from "modules/user-subscriptions/domain/dtos/installment"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { useGetSession } from "modules/user-subscriptions/presentation/hooks/use-get-session"
import { useTranslation } from "react-i18next"
import { Values } from "."
import { CPF } from "./cpf"
import { FillCreditCard } from "./fill-credit-card"
import { YunoCreditCard } from "./yuno-credit-card"

type Props = {
  paymentMethods: UpgradePaymentMethods[]
  offerId: string
  installments: Installment[]
  isSmartInstallmentsInProgress: boolean
}

export const ChoosePaymentMethods = ({
  paymentMethods,
  offerId,
  installments,
  isSmartInstallmentsInProgress,
}: Props) => {
  useGetSession()
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<Values>()
  const gateway = useMultigatewayHandler(offerId)
  const shouldDisablePix = (paymentMethod: UpgradePaymentMethods) =>
    paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX && isSmartInstallmentsInProgress

  const paymentMethodText = {
    [UpgradePaymentMethods.PAYMENT_METHOD_PIX]: t("userSubscriptions.changePaymentMethodModal.paymentMethod.pix"),
    [UpgradePaymentMethods.PAYMENT_METHOD_CARD]: t(
      "userSubscriptions.changePaymentMethodModal.paymentMethod.creditCard",
    ),
  }

  const paymentMethodIcon = {
    [UpgradePaymentMethods.PAYMENT_METHOD_PIX]: <QrCodeScannerIcon />,
    [UpgradePaymentMethods.PAYMENT_METHOD_CARD]: <CreditCardIcon />,
  }

  const creditCardField = () => {
    switch (gateway) {
      case AvailableCarriers.YUNO:
        return <YunoCreditCard installments={installments} />
      case AvailableCarriers.IUGU:
        return <FillCreditCard installments={installments} offerId={offerId} />
      case AvailableCarriers.MULTI:
        return <FillCreditCard installments={installments} offerId={offerId} />
      default:
        return <FillCreditCard installments={installments} offerId={offerId} />
    }
  }

  return (
    <>
      <Stack paddingBottom={2} gap={1} direction="row">
        {paymentMethods.map((paymentMethod) =>
          shouldDisablePix(paymentMethod) ? (
            <Tooltip
              trigger="hover"
              title={<Text>{t("userSubscriptions.changePaymentMethodModal.pixDisabled")}</Text>}
              arrow
              placement="top"
            >
              <div>
                <AssistChip
                  key={paymentMethod}
                  icon={paymentMethodIcon[paymentMethod]}
                  variant={values.paymentMethod === paymentMethod ? "filled" : "outlined"}
                  label={paymentMethodText[paymentMethod]}
                  size="medium"
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <AssistChip
              key={paymentMethod}
              onClick={() => setFieldValue("paymentMethod", paymentMethod)}
              icon={paymentMethodIcon[paymentMethod]}
              variant={values.paymentMethod === paymentMethod ? "filled" : "outlined"}
              label={paymentMethodText[paymentMethod]}
              size="medium"
            />
          ),
        )}
      </Stack>

      {values.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD && creditCardField()}
      {values.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX && <CPF />}
    </>
  )
}
