import * as React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

export const BarcodeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 14V0H2V14H0ZM3 14V0H5V14H3ZM6 14V0H7V14H6ZM9 14V0H11V14H9ZM12 14V0H15V14H12ZM16 14V0H17V14H16ZM19 14V0H22V14H19Z"
          fill="#8E9388"
        />
      </svg>
    </SvgIcon>
  )
}
