import { FormHeader as Header } from "./FormHeader"
import { BasicHeader as Basic } from "./BasicHeader"
import { SectionHeader as Section } from "./SectionHeader"
import { StepHeader as Step } from "./StepHeader"

const Headers = {
  Basic,
  Header,
  Section,
  Step,
}

export { Headers }
