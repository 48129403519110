import { Events } from "@chatpay/common"
import { Session } from "@chatpay/components"
import { ThemeProvider, theme, Stack, SnackbarContext, SnackbarProvider } from "@hub-la/design-system"
import { CssBaseline } from "@mui/material"
import * as React from "react"
import { ScreenTrack } from "utils/AnalyticsTags"
import { withUserAuthenticated } from "utils/SessionUtils"
import { ContainerProvider } from "../../../container"
import AppHeader from "../../components/app-header"
import EventDetails from "./event-details"

const EventDetailsComponent: React.FC = () => (
  <ContainerProvider>
    <ThemeProvider theme={theme.dark}>
      <SnackbarProvider>
        <CssBaseline />
        <ScreenTrack screenName={Events.MEMBER_DASHBOARD.PAGE_VIEWED}>
          <Session.Context.Consumer>
            {(state) =>
              state.authUser && (
                <Stack direction="column" gap={8}>
                  <AppHeader />
                  <EventDetails />
                </Stack>
              )
            }
          </Session.Context.Consumer>
        </ScreenTrack>
      </SnackbarProvider>
    </ThemeProvider>
  </ContainerProvider>
)

export default withUserAuthenticated(EventDetailsComponent)
