export const checkout = {
  Providence: "Estado / Província / Região",
  PostalCode: "Código Postal",
  Country: "País",
  Plan: "Plano",
  Name: "Nome",
  Email: "Email",
  SinglePayment: "à vista",
  UpTo: "em até",
  Of: "de",
  Off: "de desconto",
  StartsAt: "Início",
  CreditCard: "Cartão de Crédito",
  BankSlip: "Boleto",
  BankSlipTooltip: `Importante: Na compra por boleto, seu pagamento pode demorar até 48h para ser compensado. Após a compensação, enviaremos o link de acesso ao grupo por email, e também no "Meus Produtos".`,
  Pix: "Pix",
  PixTooltip: `O QR Code e código Pix será gerado assim que você clicar em "Finalizar Compra".`,
  PaymentDisabled: "Meio de pagamento desabilitado temporariamente",
  Total: "total",
  Month: "mês",
  BankSlipHeader: "Dados para a Emissão do Boleto",
  PersonalInfo: "Informações pessoais",
  PurchaseDetail: "Detalhes da compra",
  TermsAgreed:
    "Confirmo que tenho pelo menos 18 anos. Menores podem usar os nossos Serviços somente com o envolvimento, a supervisão e a aprovação dos pais ou responsáveis. Essa é a",
  TermsAgreedLink: "Política de Privacidade da Hubla",
  TermsAgreedBefore: "Ao prosseguir com a compra, você concorda com a",
  TermsAgreedAfter:
    "Menores de 18 anos podem usar nossos Serviços somente com a supervisão e aprovação dos pais ou responsáveis.",
  CardHeader: "Dados do cartão",
  CardNumber: "Número do Cartão",
  CardName: "Nome do Titular",
  CardNameError: "Nome inválido",
  CardExpires: "Validade",
  CardExpiresError: "Validade inválida",
  CardCVV: "CVV",
  CardDocument: "CPF do Titular",
  IsForeign: "Eu não sou um cidadão Brasileiro",
  Installments: "Parcelamento",
  Installment: "Parcela",
  BillingAddress: "Endereço de Cobrança",
  Zip: "CEP",
  ZipError: "CEP inválido",
  Neighborhood: "Bairro",
  Address: "Endereço",
  Number: "Número",
  Complement: "Complemento (opcional)",
  City: "Cidade",
  State: "Estado",
  Finish: "Finalizar compra",
  PlanFooter: "Esse plano é renovado automaticamente após o tempo de sua assinatura se esgotar.",
  BillingFooter:
    "Suas informações de pagamento são processadas através do Iugu. Para concluir as transações, armazenamos o tipo de cartão, o últimos quatro dígitos, expiração e nome no cartão. Ao continuar, você concorda com os Termos de uso do Hubla, Política de Privacidade e Política de Cookies. Nossas políticas explicam como usaremos e armazenaremos seus dados, e como você pode controlar esse uso.",
  Loading: "Estamos finalizando sua compra",
  AlreadyPurchased: "Já comprou?",
  Access: "Acesse",
  BankSlipModal: {
    button: "Ok, entendi!",
    title: "Boletos bancários",
    text1: "1. Boletos bancários levam até",
    text1bold: "48 horas para serem compensados",
    text1_2: "e assim liberar seu acesso ao grupo.",
    text2: "2. Depois do pagamento compensado, você receberá um e-mail com acesso ao grupo.",
    text3: "3. Você também pode acessar sua compra no painel de",
    text3bold: "meus grupos",
  },
  MigrationPlan: "Migração de plano",
}
