export const createGroup = {
  draft: {
    Edit: "Editar",
    BasicTitle: "Informações básicas",
    BasicSubtitle: "Edite as informações necessárias para começar a vender.",
    BasicButton: "Adicionar informação",
    DescriptionTitle: "Descrição",
    DescriptionSubtitle: "Complete sua página de vendas com maiores detalhes sobre seu produto.",
    DescriptionButton: "Adicionar descrição",
    MaterialTitle: "Material",
    MaterialSubtitle: "Inclua e-book, regras ou instruções que serão enviadas aos novos membros.",
    MaterialButton: "Adicionar material",
    AffiliatesTitle: "Afiliados",
    AffiliatesSubtitle: "Ofereça uma comissão para quem divulgar seu grupo.",
    AffiliatesButton: "Habilitar afiliados",
    MembersAreaTitle: "Área de Membros",
    MembersAreaSubtitle: "Adicione videos, aulas e conteúdos que vão ser disponibilizados somente para seus clientes.",
    MembersAreaButton: "Habilitar área de membros",
    Publish: "Publicar",
    Preview: "Pré-visualizar",
  },
  basic: {
    Title: "Criar grupo",
    Subtitle: "Informações básicas.",
    GroupImage: "Imagem",
    GroupName: "Título",
    GroupSubtitle: "Subtítulo",
    Pricing: "Precificação",
    Price: "Preço",
    Plan: "Plano",
    EnableInstallment: "Habilitar Parcelamento",
    OneInstallment: "Somente à vista",
    UpTo: "Em até",
    OneTime: "Pagamento Único",
    Subscription: "Mensalidade & Assinatura",
    MonthlyPrice: "Preço por mês",
    TotalPrice: "Preço total",
    Platform: "Plataforma",
    Footer:
      "Esse valor será cobrado para que qualquer membro possa entrar no grupo. Nosso Smart-bot estará no grupo para evitar que qualquer pessoa que não tenha pago entre no grupo. Uma taxa de conveniência 10,9% será cobrada do valor total.",
  },
  info: {
    Title: "Informações Básicas",
    Subtitle: "1. Edite as informações básicas de seu grupo, como título, subtítulo, precificação, etc.",
  },
  description: {
    Title: "Descrição",
    Subtitle:
      "2. Descreva, em detalhe, o valor que será entregue em seu grupo. Sua descrição deve explicar tudo que seus clientes precisam saber para convence-los a comprar uma vaga. Procure incluir imagens, videos, depoimentos, etc…",
  },
  material: {
    Title: "Material",
    Subtitle:
      "3. Escreva uma mensagem de boas vindas para todos os novos membros que comprarem uma vaga em seu grupo. É importante auxiliá-los com instruções e/ou normas do grupo.",
    NewMemberMsg: "Mensagem para novos membros",
    NewMemberMsgHint: "Obrigada pelo interesse no grupo! Essas são algumas instruções e regras sobre o grupo…",
    RulesFile: "PDF de regras",
  },
  affiliates: {
    Affiliates: "Afiliados",
    Title: "Afiliados",
    Subtitle: "4. Venda mais oferecendo uma comissão para as pessoas que promoverem seu grupo.",
    DisableAffiliates: "Não permitir afiliados",
    AllowAffiliates: "Permitir afiliados",
    CommissionPerPurchase: "Comissão por venda",
    CommissionPerPlanPurchase: "Por venda do plano",
    GroupCost: "Seu grupo custa",
    AffiliatesCommission: "Seus afiliados receberão",
    CommissionPerRenewal: "Comissão por renovação",
    Sale: "venda",
  },
  membersArea: {
    Title: "Área de Membros",
    Subtitle:
      "5. Aqui você pode adicionar videos, aulas e conteúdos que vão ser disponibilizadas somente para seus clientes.",
  },
  done: {
    GroupCreated: "Seu grupo foi publicado com sucesso!",
    NextSteps: "Próximos passos",
    Step1Title: `Já está tudo pronto!`,
    Step1Text: `Você acabou de criar seu grupo pago mas ainda não entrou nele. Clique no botão abaixo para entrar no grupo.`,
    Step2Title: `Compartilhe o link do grupo.`,
    Step2Text: `Para as pessoas conseguirem comprar a vaga em seu grupo clique abaixo no botão de Copiar Link e divulgue para seu público.`,
    Step3Title: `Saiba que quem comprar uma vaga receberá o link assim que o pagamento for comprovado.`,
    Step3Text: `A Hubla libera o link do grupo logo após a confirmação do pagamento.`,
    Step4Title: `Deixe o grupo no “apenas administradores podem enviar mensagem".`,
    Step4Text: `Só para esperar todo mundo entrar e se situar dentro do grupo.`,
    Step5Title: `Receba, veja e retire seu saldo tudo aqui na Hubla.`,
    Step5Text: `Tudo que ganhar no seus grupos vai ser armazenado na sua conta aqui na Hubla.`,
    Step6Title: `Acompanhe suas vendas em tempo real.`,
    Step6Text: `Temos um painel onde você pode acompanhar suas vendas, inscrições e tudo mais que acontece em seu grupo.`,
    Step7Title: `Você pode contar com a gente para o que precisar!`,
    Step7Text: `Temos um guia para te ajudar a ter um produto de muito sucesso.`,
  },
}
