import { Document, ResourceType } from "@chatpay/common"
import _ from "lodash"

export const isDebug = () => {
  return process.env.REACT_APP_CUSTOM_NODE_ENV === "staging" || process.env.REACT_APP_CUSTOM_NODE_ENV === "development"
}

export const cookieDomain = (): string => {
  return process.env.REACT_APP_COOKIE_DOMAIN as string
}

export type CreatorParams = {
  creatorId: string
  creatorName: string
  creatorEmail: string | null
}

export type GroupParams = {
  groupId: string
  groupName: string
  groupCategory: string | null
  groupHasWaitlisted: boolean
  groupType?: string | null
}

type Group = {
  id: string
  name: string
  productCategory: string
  isWaitlisted: boolean
  groupResourcesType: ResourceType[]
}

export class Utils {
  static creatorProperties = (user: Document.User | null): CreatorParams | null => {
    if (!user) return null
    return {
      creatorId: user.id,
      creatorName: user.name,
      creatorEmail: user.email,
    }
  }

  static groupProperties = (group: Group | null | undefined): GroupParams | null => {
    if (!group) return null
    return {
      groupId: group.id,
      groupName: group.name,
      groupCategory: group.productCategory,
      groupHasWaitlisted: group.isWaitlisted,
      groupType: group.groupResourcesType.length ? group.groupResourcesType[0] : null,
    }
  }
}
