import { FileAsset, FileAssetType } from "components/Interfaces"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Image } from "semantic-ui-react"
import "./InputCover.scss"

interface IProps {
  style?: React.CSSProperties
  value?: FileAsset
  aspectRatio: 0.52 | 1
  helperText?: string
  onChange: (file?: FileAsset) => any
  "data-testid"?: string
}

const InputCover: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, aspectRatio, helperText, "data-testid": dataTestId } = props

  const [file, setFile] = useState<FileAsset>()
  const [blob, setBlob] = useState<string>()
  const [hovered, setHovered] = useState<boolean>()

  useEffect(() => {
    if (JSON.stringify(file) !== JSON.stringify(value)) {
      if (value !== undefined && value?.type === FileAssetType.local) {
        setFileBlob(value.file!)
      }
      setFile(value)
    }
  }, [value, file])

  const { t } = useTranslation()

  // Deduce div dimensions from aspectRatio
  const style = { paddingTop: String(aspectRatio * 100) + "%", ...props.style }

  const inputRef = useRef<HTMLInputElement>(null)

  const updateFile = (file: File) => {
    setFileBlob(file)

    const newFileData = {
      file,
      name: file.name,
      type: FileAssetType.local,
    }

    setFile(newFileData)

    if (onChange) {
      onChange(newFileData)
    }
  }

  const setFileBlob = (file: File) => {
    const reader = new FileReader()
    reader.onloadend = (event: ProgressEvent<FileReader>) => {
      setBlob(event.target?.result as string)
    }
    reader.readAsDataURL(file)
  }

  const onButtonClick = () => {
    inputRef.current?.click()
  }

  const onRemoveClick = () => {
    setFile(undefined)
    if (onChange) {
      onChange(undefined)
    }
  }

  if (inputRef.current) {
    inputRef.current.value = ""
  }

  return (
    <React.Fragment>
      <div className="input-cover" data-testid={"input-cover"} style={style}>
        <div
          className="content"
          onTouchEnd={() => setHovered(true)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {file ? (
            <React.Fragment>
              {file.type === FileAssetType.local ? <Image className="cover-pic" src={blob} /> : null}
              {file.type === FileAssetType.web ? <Image className="cover-pic" src={file.URL} /> : null}
            </React.Fragment>
          ) : null}
          <div className="inner-div">
            {hovered || !file ? (
              <Button
                icon="camera retro"
                hidden={!hovered && file}
                basic={true}
                size="small"
                onClick={() => onButtonClick()}
              />
            ) : null}
            {file && hovered ? <Button icon="trash" basic={true} size="small" onClick={() => onRemoveClick()} /> : null}
          </div>
        </div>
        <input
          data-testid={dataTestId}
          disabled={false}
          ref={inputRef}
          hidden={true}
          multiple={false}
          type="file"
          accept="image/png, image/gif, image/jpeg, image/bmp, image/svg+xml"
          onChange={(event) => updateFile(event.target.files![0])}
        />
      </div>
      <div style={{ marginTop: "2px" }} className="cp text small">
        {helperText ? helperText : t("InputCover.pictureSize")}
      </div>
    </React.Fragment>
  )
}

export default InputCover
