export const FreeMembers = {
  title: "Membros gratuitos",
  generateInviteLink: "Gerar link do convite",
  resourceFull: "Recurso lotado",
  errors: {
    resourceNotFound: "Recurso não encontrado",
    nameNotRegistered: "Nome não cadastrado",
  },
  table: {
    Name: "Nome",
    PhoneNumber: "Telefone",
    Action: "Ações",
    CreatedAt: "Entrou em",
  },
  modal: {
    remove: {
      title: "Remover usuário dos membros gratuitos?",
      description: "Esta ação é irreversível. Não será possível recuperar o usuário.",
      buttons: { remove: "Remover", cancel: "Cancelar" },
    },
  },
  generateLink: {
    title: "Compartilhe o link de convite para seus membros gratuitos",
    description: "Este link só pode ser usado uma vez. Para dar acesso a mais pessoas você precisa gerar vários links.",
  },
}
