import React from "react"
import ReactCookieConsent from "react-cookie-consent"
import { useTranslation } from "react-i18next"
import { useMedia } from "react-use"
import { Text } from "typography"
import { cookieDomain } from "utils/SessionUtils"

export const CookieConsent: React.FC = () => {
  const { t } = useTranslation()
  const isDesktop: boolean = useMedia("(min-width: 768px)")
  return (
    <div data-testid="cookie-consent">
      <ReactCookieConsent
        location="bottom"
        buttonText={t("cookies.Button")}
        style={{
          background: "#f3f3f3",
          display: "block",
          alignItems: "center",
          border: "1px solid rgba(0,0,0,0.15)",
          borderRadius: "12px",
          maxWidth: isDesktop ? "460px" : "270px",
          marginBottom: "16px",
          marginLeft: isDesktop ? "16px" : "8px",
        }}
        contentStyle={{
          flex: "inherit",
        }}
        buttonStyle={{
          background: "#d8ff5f",
          color: "#000",
          borderRadius: "4px",
          fontSize: "14px",
          padding: "11px 21px",
          fontWeight: 500,
          border: "1px solid #adcc4c",
        }}
        expires={150}
        extraCookieOptions={{ sameSite: "lax", domain: cookieDomain() }}
      >
        <Text.Normal color="black">{t("cookies.Title")}</Text.Normal>
      </ReactCookieConsent>
    </div>
  )
}
