import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Card, Modal, Stack, Text, useIsMobile } from "@hub-la/design-system"
import isNil from "lodash/isNil"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { UpgradeStatus } from "modules/user-subscriptions/domain/enums/upgrade-status"
import { formatCurrency, formatCurrencyWithInstallments } from "modules/user-subscriptions/domain/vos/format-currency"
import { useTranslation } from "react-i18next"
import { ContentRow } from "./content-row"
import { CreditCard } from "./credit-card"
import { PaymentFailed } from "./payment-failed"
import { Pix } from "./pix"
import { Success } from "./success"
import { Loading } from "./loading"
import { IconButton } from "@mui/material"
import { useCancelUpgradePlan } from "modules/user-subscriptions/presentation/hooks/use-cancel-upgrade-plan"
import { GetUpgradeStateOutput } from "modules/user-subscriptions/domain/dtos/get-upgrade-state-output"

type Props = {
  open: boolean
  onClose: () => void
  onCancel: () => void
  subscriptionId: string
  upgradeState?: GetUpgradeStateOutput
}

export const Submitted = ({ open, onClose, onCancel, subscriptionId, upgradeState }: Props) => {
  const { mutateAsync: cancelUpgradePlan, isLoading } = useCancelUpgradePlan()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isCompleted = upgradeState?.status === UpgradeStatus.SUCCESS
  const isError = upgradeState?.status === UpgradeStatus.ERROR

  if (isNil(upgradeState)) {
    return <Loading onClose={onClose} />
  }

  if (isCompleted) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <Success />
      </Modal>
    )
  }

  if (isError) {
    return (
      <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
        <PaymentFailed />
      </Modal>
    )
  }

  const render = {
    [UpgradePaymentMethods.PAYMENT_METHOD_PIX]: (
      <Pix qrcode={upgradeState?.paymentMethod.url ?? ""} code={upgradeState?.paymentMethod.text ?? ""} />
    ),
    [UpgradePaymentMethods.PAYMENT_METHOD_CARD]: (
      <CreditCard
        cardBrand={upgradeState?.paymentMethod.cardBrand ?? ""}
        last4={upgradeState?.paymentMethod.last4 ?? ""}
      />
    ),
  }[upgradeState.paymentMethod.type]

  return (
    <Modal open={open} onClose={onClose} size={isMobile ? "full" : "medium"}>
      <Stack direction="column" gap={4}>
        <Stack direction="column" gap={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Text variant="h4">{t("userSubscriptions.upgradePlanModal.title")}</Text>
            <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Card hasHover={false} variant="filled" selected fullHeight>
          <Stack flexDirection="column" gap={6}>
            <Text variant="body2" color="outline">
              {t("userSubscriptions.upgradePlanModal.summary", {
                from: t(`userSubscriptions.planInterval.${upgradeState.currentPlan?.billingCycle.toLowerCase()}`),
                to: t(`userSubscriptions.planInterval.${upgradeState.upgradePlan?.billingCycle.toLowerCase()}`),
              })}
            </Text>
            <Stack direction="column" width="100%" gap={2}>
              <ContentRow
                title={
                  upgradeState?.upgradePlan?.items[0]?.description +
                  " - " +
                  t(`userSubscriptions.planType.${upgradeState.upgradePlan?.billingCycle.toLowerCase()}`)
                }
                content={
                  <Text variant="body1">
                    {formatCurrencyWithInstallments(
                      upgradeState.upgradePlan?.installments,
                      upgradeState.upgradePlan?.priceInCents / 100 + upgradeState.upgradePlan?.prorataCents / 100,
                      (upgradeState.upgradePlan?.priceInCents / 100 + upgradeState.upgradePlan?.prorataCents / 100) /
                        upgradeState.upgradePlan.installments,
                    )}
                  </Text>
                }
              />
              <ContentRow
                showDivider
                title={t("userSubscriptions.upgradePlanModal.credits", {
                  plan: t(`userSubscriptions.planType.${upgradeState?.currentPlan?.billingCycle.toLowerCase()}`),
                })}
                content={
                  <Text variant="body1">- {formatCurrency((upgradeState.upgradePlan?.prorataCents ?? 0) / 100)}</Text>
                }
              />
              <ContentRow
                fontWeight="bold"
                title="Total"
                content={
                  <Text variant="body1">
                    {formatCurrencyWithInstallments(
                      upgradeState.upgradePlan?.installments,
                      upgradeState.upgradePlan?.priceInCents / 100,
                      upgradeState.upgradePlan?.priceInCents / 100 / upgradeState.upgradePlan.installments,
                    )}
                  </Text>
                }
              />
            </Stack>
          </Stack>
        </Card>

        <Card hasHover={false} variant="filled" selected fullHeight>
          {render}
        </Card>

        <Button
          hierarchy="secondary"
          variant="text"
          loading={isLoading}
          onClick={() => {
            cancelUpgradePlan(subscriptionId).then(() => {
              onClose()
              onCancel()
            })
          }}
        >
          {t("userSubscriptions.upgradePlanModal.cancelUpgrade")}
        </Button>
      </Stack>
    </Modal>
  )
}
