import { Session } from "@chatpay/components"
import { Spacer } from "components"
import AppHeader from "components/AppHeader"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { withUserAuthenticated } from "utils/SessionUtils"
import WhitelistContainer from "./WhitelistContainer"

interface IParams {
  groupId: string
}

const WhitelistPage: React.FC<RouteComponentProps<IParams>> = (props: RouteComponentProps<IParams>) => (
  <div className="UserGroups">
    <AppHeader />
    <Spacer />
    <Session.Context.Consumer>
      {(state) => (state.authUser ? <WhitelistContainer groupId={props.match.params.groupId} /> : null)}
    </Session.Context.Consumer>
  </div>
)

export default withUserAuthenticated(WhitelistPage)
