import React, { FunctionComponent } from "react"
import { FirebaseContext } from "./withFirebase"

export interface IProps {
  bucket: string
}

export const withExperiment =
  <T extends IProps>(name: string, Component: FunctionComponent<T>) =>
  (props: any) => {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => <Component {...props} bucket={firebase.getExperimentBucket(name)} />}
      </FirebaseContext.Consumer>
    )
  }
