"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserSentNotification = exports.PostNotificationType = exports.Name = void 0;

/** What occurred that the user needs to be notified about */
var PostNotificationType;
exports.PostNotificationType = PostNotificationType;

(function (PostNotificationType) {
  PostNotificationType["NEW_SECTION"] = "new_section";
  PostNotificationType["NEW_POST"] = "new_post";
  PostNotificationType["NEW_LIVE"] = "new_live";
  PostNotificationType["UPDATED_LIVE"] = "updated_live";
  PostNotificationType["REMINDER_LIVE"] = "reminder_live";
})(PostNotificationType || (exports.PostNotificationType = PostNotificationType = {}));

var UserSentNotification;
exports.UserSentNotification = UserSentNotification;

(function (UserSentNotification) {
  UserSentNotification["feedPostCreated"] = "feedPostCreated";
  UserSentNotification["newPostCreated"] = "newPostCreated";
  UserSentNotification["reminderPostLive"] = "reminderPostLive";
  UserSentNotification["updatePostLive"] = "updatePostLive";
})(UserSentNotification || (exports.UserSentNotification = UserSentNotification = {}));

var Name;
exports.Name = Name;

(function (Name) {
  Name["send"] = "send";
})(Name || (exports.Name = Name = {}));