"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Identifier = void 0;
var Identifier;
exports.Identifier = Identifier;

(function (Identifier) {
  Identifier["invoiceReminder"] = "invoice_reminder";
  Identifier["invoiceDue"] = "invoice_due";
  Identifier["invoiceReceipt"] = "invoice_receipt";
  Identifier["invoiceCanceled"] = "invoice_canceled";
  Identifier["invoiceRefund"] = "invoice_refund";
  Identifier["subscriptionChargeFailure"] = "subscription_charge_failure";
  Identifier["subscriptionCreated"] = "subscription_created";
  Identifier["subscriptionChanged"] = "subscription_changed";
  Identifier["subscriptionSuspended"] = "subscription_suspended";
  Identifier["subscriptionTrialRemainder"] = "subscription_trial_remainder";
  Identifier["subscriptionTrialEnded"] = "subscription_trial_ended";
})(Identifier || (exports.Identifier = Identifier = {}));