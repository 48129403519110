import { Box, Button, Snackbar, Stack, Text } from "@hub-la/design-system"
import ArrowBack from "@mui/icons-material/ArrowBack"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useGetPendencies } from "../../hooks/use-get-pendencies"
import { UserDoesNotHavePendencies } from "./components/user-does-not-have-pendencies"
import { Loading } from "./components/loading"
import { PendingCard } from "./components/pending-card"
import { formatCurrencyWithInstallments } from "modules/user-pendencies/domain/vos/format-currency"

const UserPendencies: React.FC = () => {
  const { data = { overdue: [], unpaid: [] }, error, isFetching } = useGetPendencies()
  const { t } = useTranslation()
  const history = useHistory()
  const isDataEmpty = isEmpty(data.overdue) && isEmpty(data.unpaid)
  const canRenderEmptyState = !isFetching && isDataEmpty

  if (isFetching && isDataEmpty) {
    return <Loading />
  }

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack marginBottom={8} alignSelf="start" width="fit-content">
        <Button startIcon={<ArrowBack />} variant="text" hierarchy="secondary" onClick={() => history.goBack()}>
          {t("userPendencies.back")}
        </Button>
      </Stack>
      <Stack marginBottom={4}>
        <Text variant="h1">{t("userPendencies.title")}</Text>
      </Stack>

      {canRenderEmptyState && <UserDoesNotHavePendencies />}

      {!canRenderEmptyState && (
        <>
          {data.overdue.length > 0 && (
            <Stack direction="column" my={6} gap={4}>
              <Stack>
                <Text variant="body1">{t("userPendencies.overdue.title", { length: data.overdue.length })}</Text>
              </Stack>
              <Stack direction="column" gap={4}>
                {data.overdue.map(
                  (data) =>
                    data && (
                      <PendingCard
                        key={data.invoiceId}
                        id={data.invoiceId}
                        type="overdue"
                        dueDate={data.dueDate}
                        name={data.items[0].description}
                        price={formatCurrencyWithInstallments(
                          data.installmentsAmount,
                          data.priceInCents / 100,
                          data.installmentsPriceInCents / 100,
                        )}
                      />
                    ),
                )}
              </Stack>
            </Stack>
          )}

          {data.unpaid.length > 0 && (
            <Stack direction="column" my={6} gap={4}>
              <Stack>
                <Text variant="body1">{t("userPendencies.unpaid.title", { length: data.unpaid.length })}</Text>
              </Stack>
              <Stack direction="column" gap={4}>
                {data.unpaid.map(
                  (data) =>
                    data && (
                      <PendingCard
                        key={data.invoiceId}
                        id={data.invoiceId}
                        type="unpaid"
                        dueDate={data.dueDate}
                        name={data.items[0].description}
                        price={formatCurrencyWithInstallments(
                          data.installmentsAmount,
                          data.priceInCents / 100,
                          data.installmentsPriceInCents / 100,
                        )}
                      />
                    ),
                )}
              </Stack>
            </Stack>
          )}
        </>
      )}
      <Snackbar open={!!error} variant="negative" closeable={false}>
        {t((error as Error)?.message) ?? ""}
      </Snackbar>
    </Box>
  )
}

export default UserPendencies
