import { Document } from "@chatpay/common"
import { Spacer } from "components"
import React from "react"
import { Dimmer, Image, Segment } from "semantic-ui-react"
import { SemanticSIZES } from "semantic-ui-react/dist/commonjs/generic"

interface IProps {
  size?: SemanticSIZES
  text?: string
  group?: Document.Group
}
export const CPLoader: React.FunctionComponent<IProps> = (props) => {
  return (
    <Segment basic={true}>
      <Dimmer active={true} inverted={true}>
        <div style={{ textAlign: "center" }}>
          {props.group ? (
            <>
              <Image
                src={props.group?.picture}
                style={{
                  maxWidth: "157px",
                  maxHeight: "90px",
                  borderRadius: "0.5em",
                  margin: "auto",
                  marginBottom: "1em",
                }}
              />
              <div className="cp text bold huge center black" style={{ margin: "auto" }}>
                {props.group.name}
              </div>
              <div className="cp text large black center">
                <i>{props.group.owner?.firstName + " " + props.group.owner?.lastName}</i>
              </div>
              <Spacer />
            </>
          ) : null}
          <Image
            wrapped={true}
            avatar={true}
            verticalAlign="middle"
            size={props.size ?? "huge"}
            spaced="right"
            src={require("assets/images/loading.gif")}
            style={{ margin: "auto" }}
          />
          {props.text ? (
            <div className="cp text large black bold" style={{ marginTop: "1em" }}>
              {props.text}
            </div>
          ) : null}
        </div>
      </Dimmer>
    </Segment>
  )
}
