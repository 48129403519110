export const aboutUs = {
  section1: {
    title: "Who are we?",
    text1:
      "Hubla allows people to resume the main role of their careers by making money fairly with what they know how to do best.",
    text2:
      "Do you know that person that every time you talk to them you discover something new? That interested guy, who is always well informed and has precious tips that everyone loves.",
    text3:
      "He always knows what's going on, where to find the best things you want on the internet, and at the best prices.",
    text4: `He doesn’t look for information for information, he looks for anyone who makes him have the necessary security to move forward with his choice. That way he feels secure with his own decision and that he has the leading role in his life, "making it happen" .`,
  },
  section2: {
    title: "How did we get started?",
    text1:
      "We started Hubla in January 2020, as we realized how difficult it was for people to manage their paid groups.",
    text2:
      "From that we developed a platform in which all people could easily monetize their content without having to worry about the boring and bureaucratic part, being able to focus only on what to deliver to your audience.",
  },
  section3: {
    title: "Hubla is for everyone!",
    text1: "We are for everyone who is uncomfortable with traditional work and remuneration relationships.",
    text2:
      "For all those who have a limited purpose, who have specific knowledge but that work for a large number of people. Knowledge that should be spread to as many individuals as possible, in a simple, direct and straightforward way.",
    text3:
      "Whenever it is, giving people a chance to transform their lives into subjects that, isolated may seem harmless, but that when combined with an entire ecosystem, makes people's lives less complicated.",
    text4:
      "All the answers do not yet exist, but Hubla certainly is the platform that connects for real who has something to teach with those who want to learn.",
  },
  section4: {
    title: "What can you gain with us?",
    text1: "Support and care",
    text2:
      "We are with you at different times in the construction of the infoproduct, looking for solutions to improve the platform's performance as well as solve specific problems in order to solve different needs in different situations.",
    text3: "Security",
    text4:
      "The concern with excellence in the execution of the main functionalities of the platform management for you is paramount for us.",
    text5: "Knowledge",
    text6:
      "As it is a new product and a new ecosystem, developing knowledge for you and your customers is a constant guideline in our relationship.",
  },
  section5: {
    title: "Come and be part of this journey too!",
  },
}
