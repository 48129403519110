import React from "react"
import { Card, Stack, Text } from "@hub-la/design-system"
import { Phone } from "../../../../domain/vos/phone"

type ChargeDetailProps = {
  payer: {
    fullName: string
    email: string
    phone: string
  }
  seller: {
    fullName: string
  }
}

export const ChargeDetail = ({ payer, seller }: ChargeDetailProps) => {
  return (
    <Stack height="100%" width="100%">
      <Stack direction={["column", "row"]} gap={4}>
        <Card hasHover={false} variant="outlined" fullHeight>
          <Stack flexDirection="column">
            <Stack direction="row" alignItems="center" width="100%" gap={2}>
              <Text variant="body1">Cobrança</Text>
            </Stack>
            <Stack direction="column" width="100%" gap={1}>
              <Text variant="body1" color="outline">
                {payer?.fullName}
              </Text>
              <Text variant="body1" color="outline">
                {payer?.email}
              </Text>
              {payer?.phone && (
                <Text variant="body1" color="outline">
                  {Phone.build(payer?.phone).getFormattedNumber()}
                </Text>
              )}
            </Stack>
          </Stack>
        </Card>
        <Card hasHover={false} variant="outlined">
          <Stack flexDirection="column">
            <Stack direction="row" alignItems="center" width="100%" gap={2}>
              <Text variant="body1">Faturamento</Text>
            </Stack>
            <Stack direction="column" width="100%" gap={1}>
              <Text variant="body1" color="outline">
                {seller?.fullName}
              </Text>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  )
}
