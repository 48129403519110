import React from "react"
import { Checkbox, FilterChipSelect, MenuItem, Stack, Text, styled } from "@hub-la/design-system"

const CheckableFilter = styled(Stack)`
  width: 100%;

  label {
    margin-right: 0;
  }
`

export const SelectOffers = ({ offers, filters, setFilters }) => {
  const { offerIds } = filters

  const handleOfferIds = (event) => {
    const offerIds = event.target.value as string[]
    const oldOfferIds = filters.offerIds
    const selectedAll = offerIds.includes("all") && !oldOfferIds.includes("all")
    let newOfferIds = offerIds.filter((id) => id !== "all")
    if (selectedAll) {
      newOfferIds = ["all"]
    }
    setFilters({ ...filters, offerIds: newOfferIds })
  }

  const getOfferIdsVariant = () => {
    return offerIds.length > 0 ? "selected" : "unselected"
  }

  const shouldIncludeAllOffers = offerIds.includes("all") || offerIds.length === 0

  return (
    <CheckableFilter>
      <FilterChipSelect
        variant={getOfferIdsVariant()}
        multiple
        fullWidth
        disablePortal={true}
        placeholder={"Todos os produtos"}
        renderValue={(value) => {
          const offerIds = value as string[]
          if (shouldIncludeAllOffers) {
            return <Text variant="body2">Todos os produtos</Text>
          }
          return <Text variant="body2">Produtos ({offerIds.length})</Text>
        }}
        value={offerIds}
        onChange={handleOfferIds}
      >
        {offers.map(({ id, name }) => {
          const checked = id === "all" ? shouldIncludeAllOffers : !shouldIncludeAllOffers && offerIds.includes(id)
          return (
            <MenuItem key={id} value={id}>
              <Checkbox checked={checked} onClick={(event) => event.preventDefault()} />
              {name}
            </MenuItem>
          )
        })}
      </FilterChipSelect>
    </CheckableFilter>
  )
}
