import React from "react"
import { Stack, Text, useUtility } from "@hub-la/design-system"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { useTranslation } from "react-i18next"

export const Success = () => {
  const { t } = useTranslation()
  const { palette } = useUtility()
  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1}>
        <CheckCircleOutlineIcon style={{ color: palette("successContainer"), fontSize: "40px" }} />
        <Text variant="h3">{t("userSubscriptions.upgradePlanModal.success.title")}</Text>
      </Stack>
      <Text variant="body1" color="onSurfaceVariant">
        {t("userSubscriptions.upgradePlanModal.success.title")}
      </Text>
    </Stack>
  )
}
