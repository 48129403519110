import { Document } from "@chatpay/common"
import React, { useState } from "react"
import { useMedia } from "react-use"
import { Button, Image, Progress } from "semantic-ui-react"
import { FileSelector } from "./FileSelector"
import "./SelectGroupAsset.scss"

export enum IUploadStatus {
  none,
  started,
  processing,
  finished,
}

interface MediaPickerProps {
  asset: Document.GroupAboutAsset
  removeCallback: () => void
  materialsCallback: (status: IUploadStatus, asset: Document.Material | undefined) => void
  youtubeCallback: () => void
}

const MediaPicker: React.FC<MediaPickerProps> = ({ asset, removeCallback, materialsCallback, youtubeCallback }) => {
  const [hover, setHover] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const isMobile = useMedia("(max-width: 600px)")
  return asset.id ? (
    <div className="media-picker" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Image wrapped={true} src={asset.type === Document.GroupAboutAssetType.image ? asset.url : asset.thumbnailUrl} />
      {hover || isMobile ? (
        <Button
          className="media-picker-button"
          icon="trash alternate"
          basic={true}
          size="small"
          onClick={removeCallback}
        />
      ) : null}
    </div>
  ) : (
    <div className="media-picker">
      <FileSelector onChange={materialsCallback} onYoutubeOption={youtubeCallback} setProgress={setProgress}>
        <Button
          className="media-picker-button"
          icon="plus"
          basic={true}
          size="small"
          loading={asset.name === "in_progress"}
          disabled={asset.name === "in_progress"}
        />
      </FileSelector>
      {asset.name === "in_progress" ? (
        <Progress className="media-picker-progress" size="tiny" value={progress} total={120} indicating={true} />
      ) : null}
    </div>
  )
}

export default MediaPicker
