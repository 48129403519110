/** Card brands. */
export enum CardBrand {
  /** CARD_BRAND_UNSPECIFIED - Default value, do not use. */
  CARD_BRAND_UNSPECIFIED = 0,
  /** CARD_BRAND_OTHER - Other card brands. */
  CARD_BRAND_OTHER = 1,
  /** CARD_BRAND_MASTERCARD - Mastercard and Maestro. */
  CARD_BRAND_MASTERCARD = 2,
  /** CARD_BRAND_VISA - Visa. */
  CARD_BRAND_VISA = 3,
  /** CARD_BRAND_ELO - Elo. */
  CARD_BRAND_ELO = 4,
  /** CARD_BRAND_AMEX - American Express. */
  CARD_BRAND_AMEX = 5,
}
