import { Document } from "@chatpay/common"
import firebase from "firebase/compat"
import { AnalyticsEventParams, AnalyticsProviderType, IAnalyticsProvider } from "./Tracker"

export class LogProvider implements IAnalyticsProvider {

  identifyFB = (user: firebase.User) => console.log("[Analytics] identify firebase", user)

  page = (name: string) => console.log("[Analytics] page", name)

  reset = () => console.log("[Analytics] reset")

  init = () => console.log("init")

  identify = (user?: Document.User | null) => console.log("[Analytics] identify", user)

  track = (event: string, params?: AnalyticsEventParams) => console.log("[Analytics] track", event, params)

  alias = (id: string) => console.log("[Analytics] alias", id)

  type = ():AnalyticsProviderType => AnalyticsProviderType.LOG

}