"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = void 0;
var Name;
exports.Name = Name;

(function (Name) {
  Name["listPartnersForGroupOwner"] = "listPartnersForGroupOwner";
  Name["listForPartner"] = "listForPartner";
  Name["get"] = "get";
  Name["edit"] = "edit";
  Name["accept"] = "accept";
  Name["reject"] = "reject";
  Name["invite"] = "invite";
  Name["requestCancellation"] = "requestCancellation";
  Name["acceptCancellation"] = "acceptCancellation";
  Name["rejectCancellation"] = "rejectCancellation";
  Name["cancelRequestCancellation"] = "cancelRequestCancellation";
})(Name || (exports.Name = Name = {}));