import { Document } from "@chatpay/common"
import { DB } from "../Service"
import API from "./API"

class Tag extends API {
  private db = new DB(Document.Tag)

  public async fetch(id: string): Promise<Document.Tag | null> {
    return this.db.getById(id)
  }

  public async getTags(): Promise<Document.Tag[] | null> {
    return this.db.get({
      where: [
        { field: "ownerId", op: "==", value: API.currentUser?.id },
        { field: "isDeleted", op: "==", value: false },
      ],
      order: {
        by: "createdAt",
        direction: "desc",
      },
    })
  }
}

export default Tag
