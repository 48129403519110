import React from "react"
import { Stack, TableCell, TableRow } from "@hub-la/design-system"
import { Skeleton } from "@mui/material"

export const InvoiceTableLoading = () => {
  return (
    <TableRow hover={false}>
      <TableCell density="compact" padding="none" scope="row">
        <Stack direction="row" gap={2}>
          <Skeleton width={20} height={24} />
          <Skeleton width={62} height={24} />
        </Stack>
      </TableCell>
      <TableCell density="compact" padding="none" scope="row">
        <Skeleton width={44} height={24} />
      </TableCell>
      <TableCell density="compact" padding="none" scope="row">
        <Skeleton width="30%" height={24} />
      </TableCell>
    </TableRow>
  )
}
