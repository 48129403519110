import React from "react"
import { Stack, Text } from "@hub-la/design-system"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { useTranslation } from "react-i18next"

export const PaymentFailed = () => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1}>
        <ErrorOutlineIcon style={{ fontSize: "40px" }} />
        <Text variant="h3">{t("userSubscriptions.upgradePlanModal.paymentFailed.title")}</Text>
      </Stack>
      <Text variant="body1" color="onSurfaceVariant">
        {t("userSubscriptions.upgradePlanModal.paymentFailed.subtitle")}
      </Text>
    </Stack>
  )
}
