import React from "react"
import "./HorizontalSelect.scss"

interface IHorizontalSelectProps {
  selected: boolean | null
  leftTitle: string
  rightTitle: string
  onSelect: (option: boolean | null) => any
}

export const HorizontalSelect: React.FunctionComponent<IHorizontalSelectProps> = (props) => {
  const { selected, leftTitle, rightTitle, onSelect } = props

  return (
    <div className="horizontal_select">
      {HorizontalSelectOption(leftTitle, selected === false, (clickedToSelect) =>
        onSelect(clickedToSelect ? false : null),
      )}
      {HorizontalSelectOption(rightTitle, selected === true, (clickedToSelect) =>
        onSelect(clickedToSelect ? true : null),
      )}
    </div>
  )
}

function HorizontalSelectOption(
  title: string,
  isSelected: boolean,
  onClick?: (clickedToSelect: boolean) => any,
): React.ReactElement {
  return (
    <div
      className={`horizontal_select_option ${isSelected ? "selected" : "not_selected"}`}
      onClick={() => onClick?.(isSelected ? false : true)}
    >
      <div style={{ padding: "8.5px 0em" }}>
        <div className="cp text horizontal_select_option_text">{title}</div>
      </div>
    </div>
  )
}
