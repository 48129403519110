import React from "react"
import { AuthProvider, AuthRedirectProvider, RecoverPasswordPage as AuthRecoverPasswordPage } from "@hub-la/fe-auth"
import { Service } from "@chatpay/components"

const RecoverPasswordPage = () => {
  const getCurrentUser = () => {
    return Service.Firebase.currentUser
  }

  return (
    <AuthProvider>
      <AuthRedirectProvider getCurrentUser={getCurrentUser}>
        <AuthRecoverPasswordPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}

export default RecoverPasswordPage
