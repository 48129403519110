import { Document } from "@chatpay/common"
import { Spacer } from "components"
import { default as React, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Checkbox, Form } from "semantic-ui-react"

type IForm = Document.NotificationSettingsOptions

interface IProps {
  value: Document.NotificationSettingsOptions
  onChange: (data: Document.NotificationSettingsOptions, valid: boolean) => any
}

const NotificationOptions: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange } = props

  const [form, setForm] = useState<IForm>(value)

  const { t } = useTranslation()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const setFormValue = (name: string, value: boolean) => {
    let newData: IForm = {
      ...form,
      [name]: value,
    }

    console.log({ newData })

    setForm(newData)
    if (onChange) {
      onChange(newData, true)
    }
  }

  return (
    <Form>
      <Checkbox
        checked={form?.adminJoined}
        label={t("EditNotification.adminJoined")}
        onChange={(_, data) => setFormValue("adminJoined", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.memberJoined}
        label={t("EditNotification.memberJoined")}
        onChange={(_, data) => setFormValue("memberJoined", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.nonMemberJoined}
        label={t("EditNotification.nonMemberJoined")}
        onChange={(_, data) => setFormValue("nonMemberJoined", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.purchased}
        label={t("EditNotification.purchased")}
        onChange={(_, data) => setFormValue("purchased", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.removed}
        label={t("EditNotification.removed")}
        onChange={(_, data) => setFormValue("removed", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.whitelisted}
        label={t("EditNotification.whitelisted")}
        onChange={(_, data) => setFormValue("whitelisted", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.postOwnerCommentAlert}
        label={t("EditNotification.postOwnerCommentAlert")}
        onChange={(_, data) => setFormValue("postOwnerCommentAlert", data.checked ?? false)}
      />
      <Spacer size="tiny" />
      <Checkbox
        checked={form?.threadParticipationReplyAlert}
        label={t("EditNotification.threadParticipationReplyAlert")}
        onChange={(_, data) => setFormValue("threadParticipationReplyAlert", data.checked ?? false)}
      />
    </Form>
  )
}

export { NotificationOptions as Form }
export type { IProps, IForm }
