import React from "react"
import { Stack, Text, shape, spacing, styled, withUtility } from "@hub-la/design-system"
import { svgIconClasses } from "@mui/material"
import { PaymentMethodTextBuilder } from "modules/user-subscriptions/usecases/payment-method-text-builder"
import { UpgradePaymentMethods } from "modules/user-subscriptions/domain/dtos/payment-method"
import { useTranslation } from "react-i18next"
import CreditCardIcon from "@mui/icons-material/CreditCard"

type Props = {
  cardBrand: string
  last4: string
}

export const CreditCard = ({ cardBrand, last4 }: Props) => {
  const { t } = useTranslation()
  return (
    <Stack spacing={2} alignItems="center" textAlign="center">
      <EmptyIcon>
        <CreditCardIcon />
      </EmptyIcon>

      <Text variant="h4" marginTop={6} textAlign="center">
        {t("userSubscriptions.upgradePlanModal.creditCard.title")}
      </Text>
      <Text variant="body2" color="onSurfaceVariant" marginTop={1} textAlign="center">
        {t("userSubscriptions.upgradePlanModal.creditCard.subtitle", {
          method: new PaymentMethodTextBuilder().execute(UpgradePaymentMethods.PAYMENT_METHOD_CARD, last4, cardBrand),
        })}
      </Text>
      <Text variant="body2" color="onSurfaceVariant" marginTop={1} textAlign="center">
        {t("userSubscriptions.upgradePlanModal.creditCard.footer")}
      </Text>
    </Stack>
  )
}

const EmptyIcon = styled(Stack)`
  padding: ${spacing(2)};
  border-radius: ${shape("medium")};
  border: 1px solid ${withUtility(({ palette }) => palette("surfaceVariant"))};
  background: ${withUtility(({ palette }) => palette("surfaceAt1"))};
  align-self: center;
  .${svgIconClasses.root} {
    color: ${withUtility(({ palette }) => palette("onSurfaceVariant"))};
  }
`
