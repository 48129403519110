import { Document, Interface } from "@chatpay/common"
import { Order, Where } from "Service/DB"
import { DB } from "../Service"
import API from "./API"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"

const FETCH_LIMIT = 20

class GroupActivity extends API implements Interface.GroupActivity.Function.ITemplate {
  private activityDB = new DB(Document.GroupActivity)

  private async call(func: Interface.GroupActivity.Function.Name, params?: Interface.GroupActivity.Function.Params) {
    return await this.callFunction(`groupActivity/${func}`, params)
  }

  public async remove(data: Interface.GroupActivity.Function.IRemove): Promise<Document.GroupMember[]> {
    const result = (await this.call(Interface.GroupActivity.Function.Name.remove, data)).data.map((it: any) => {
      it.lastActivity.timestamp = DB.makeTimestamp()
      return new Document.GroupMember(it)
    })
    return result
  }
  public async whitelist(data: Interface.GroupActivity.Function.IWhitelist): Promise<Document.GroupMember[]> {
    const result = (await this.call(Interface.GroupActivity.Function.Name.whitelist, data)).data.map((it: any) => {
      it.lastActivity.timestamp = DB.makeTimestamp()
      return new Document.GroupMember(it)
    })
    return result
  }

  public async fetch(
    filter?: { by: "group.id" | "user.ims.whatsapp" | "user.ims.telegram"; value: string },
    limit: number = FETCH_LIMIT,
    startAfter?: firebase.firestore.Timestamp,
    startDate?: Date,
    endDate?: Date,
    orderDirection: "desc" | "asc" = "desc",
  ): Promise<Document.GroupActivity[]> {
    const whereData: Where[] = [{ field: "group.owner", op: "==", value: API.currentUser?.id }]

    if (filter) {
      whereData.push({ field: filter.by, op: "==", value: filter.value })
    }

    if (startDate) {
      whereData.push({ field: "timestamp", op: ">=", value: DB.makeTimestamp(startDate) })
    }

    if (endDate) {
      whereData.push({ field: "timestamp", op: "<=", value: DB.makeTimestamp(endDate) })
    }

    const order: Order[] = [{ by: "timestamp", direction: orderDirection }]

    return await this.activityDB.get({ where: whereData, pagination: { limit, startAfter }, order })
  }

  public async getById(id: string, include: string[] = []): Promise<Document.GroupActivity | null> {
    const activity = await this.activityDB.getById(id, include)
    return activity?.isDeleted ? null : activity
  }
}

export default GroupActivity
