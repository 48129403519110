import React, { useEffect, useState } from "react"
import { Accordion, Checkbox, DropdownItemProps, Form } from "semantic-ui-react"
import { Document, Model } from "@chatpay/common"
import * as Fields from "components/Fields"
import { useTranslation } from "react-i18next"
import { Service } from "@chatpay/components"
import styled from "styled-components"

const MIN_PRICE = parseInt(process.env.REACT_APP_IUGU_MIN_PRICE ?? "0", 10)
const MAX_PRICE = parseInt(process.env.REACT_APP_IUGU_MAX_PRICE ?? "0", 10)

export enum Plan {
  onetime,
  subscription,
}

const InputPlanAccordion = styled(Accordion)`
  font-family: var(--secondary-font) !important;

  .title {
    font-family: var(--secondary-font) !important;
  }

  div.active.title {
    margin-left: 10px;
  }
  .active.title {
    margin-left: 10px;
  }
`

type Intervals =
  | Document.PlanInterval.monthly
  | Document.PlanInterval.quarterly
  | Document.PlanInterval.semiannually
  | Document.PlanInterval.annually

export interface IField {
  type: Plan
  price: number
  installment: number
  accessDays: number
  plans: Partial<Record<Intervals, ISubscription>>
  checked: Partial<Record<Intervals, boolean>>
}
interface ISubscription {
  price: number
  interval: Document.PlanInterval
  renewalToleranceDays: number
}

interface IProps {
  currency?: string
  value?: Partial<IField>
  onChange?: (plans: Partial<IField>, valid: boolean) => any
  disabled?: Partial<Record<Plan, boolean>>
}

interface IErrors {
  price: string
  plans: Partial<Record<Intervals, string>>
}

const InputPlan: React.FunctionComponent<IProps> = (props) => {
  const { currency, value, disabled, onChange } = props

  const [data, setData] = useState<Partial<IField> | undefined>(value)
  const [instOptions, setInstOptions] = useState<DropdownItemProps[]>()
  const [errors, setErrors] = useState<Partial<IErrors>>()

  const { t } = useTranslation()

  const [interval] = useState({
    [Document.PlanInterval.monthly]: t("InputPlan.monthly"),
    [Document.PlanInterval.quarterly]: t("InputPlan.quarterly"),
    [Document.PlanInterval.semiannually]: t("InputPlan.semiannually"),
    [Document.PlanInterval.annually]: t("InputPlan.annually"),
  })

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(data)) {
      setData(value)
      setErrors(undefined)
    }
  }, [value, data])

  const price = data?.price

  const accessMonthsOptions: DropdownItemProps[] = [
    { key: 0, text: t("InputPlan.accessMonths.0"), value: 0 },
    { key: 1, text: t("InputPlan.accessMonths.1"), value: 30 },
    { key: 2, text: t("InputPlan.accessMonths.3"), value: 3 * 30 },
    { key: 3, text: t("InputPlan.accessMonths.6"), value: 6 * 30 },
    { key: 4, text: t("InputPlan.accessMonths.9"), value: 9 * 30 },
    { key: 5, text: t("InputPlan.accessMonths.12"), value: 365 },
    { key: 6, text: t("InputPlan.accessMonths.24"), value: 2 * 365 },
    { key: 7, text: t("InputPlan.accessMonths.36"), value: 3 * 365 },
  ]

  useEffect(() => {
    const installments = calcInstallments(data?.price ?? 0)
    const inst = [{ key: 0, text: t("InstallmentOptions.disabled"), value: 1 }]
    for (let i = 2; i <= installments; i++) {
      inst.push({ key: i, text: `${t("InstallmentOptions.upTo")} ${i}x`, value: i })
    }
    setInstOptions(inst)
  }, [price, data, t])

  const calcInstallments = (price: number): number => {
    const max = Math.floor(price / 5)
    return max > 12 ? 12 : max
  }

  const switchActive = (interval: Document.PlanInterval) => {
    const value = data?.checked?.[interval] ? false : true
    updateData({
      ...data,
      plans: {
        ...data?.plans,
        [interval]: value ? data?.plans?.[interval] : undefined,
      },
      checked: {
        ...data?.checked,
        [interval]: value,
      },
    })
  }

  const onTypeUpdate = (type: Plan) => {
    updateData({
      ...data,
      type,
      // plans: type === Plan.subscription ? data?.plans : undefined,
      // price: type === Plan.onetime ? data?.price : undefined,
    })
  }

  const onInstallmentUpdate = (installment: number) => {
    updateData({
      ...data,
      installment: installment === 1 ? undefined : installment,
    })
  }

  const onAccessDaysUpdate = (accessDays: number) => {
    updateData({
      ...data,
      accessDays: accessDays,
    })
  }

  const onPriceUpdate = (price: number) => {
    updateData({
      ...data,
      price: price === 0 ? undefined : price,
    })
  }

  const onPlanUpdate = (interval: Document.PlanInterval, price?: number) => {
    updateData({
      ...data,
      plans: {
        ...data?.plans,
        [interval]: {
          price: price === 0 ? undefined : price,
          interval,
          renewalToleranceDays: null,
        },
      },
    })
  }

  const updateData = (data: Partial<IField>) => {
    const { price, plans } = data
    const monthly = plans?.monthly?.price
    const quarterly = plans?.quarterly?.price
    const semiannually = plans?.semiannually?.price
    const annually = plans?.annually?.price

    const errors = {
      price: price ? checkPriceError(price) : undefined,
      plans: {
        monthly: monthly ? checkPriceError(monthly) : undefined,
        quarterly: quarterly ? checkPriceError(quarterly) : undefined,
        semiannually: semiannually ? checkPriceError(semiannually) : undefined,
        annually: annually ? checkPriceError(annually) : undefined,
      },
    }

    setErrors(errors)

    let isValid = false
    if (data.type === Plan.onetime) {
      isValid = data.price !== undefined && errors?.price === undefined
    } else if (data.type === Plan.subscription) {
      isValid =
        data?.plans !== undefined &&
        errors.plans.monthly === undefined &&
        errors.plans.quarterly === undefined &&
        errors.plans.semiannually === undefined &&
        errors.plans.annually === undefined &&
        /**
         * make sure that at least one of the prices exist
         */
        (data?.plans?.monthly?.price !== undefined ||
          data?.plans?.quarterly?.price !== undefined ||
          data?.plans?.semiannually?.price !== undefined ||
          data?.plans?.annually?.price !== undefined) &&
        /**
         * Make sure that if an option is selected, that same field is valid
         */
        (data.checked?.monthly ?? false) === (data?.plans?.monthly?.price !== undefined) &&
        (data.checked?.quarterly ?? false) === (data?.plans?.quarterly?.price !== undefined) &&
        (data.checked?.semiannually ?? false) === (data?.plans?.semiannually?.price !== undefined) &&
        (data.checked?.annually ?? false) === (data?.plans?.annually?.price !== undefined)
    }

    if (onChange) {
      onChange(data, isValid)
    }
    setData(data)
  }

  const isActive = (plan: Intervals): boolean => {
    return data?.checked?.[plan] ?? false
  }

  const checkPriceError = (price: number): string | undefined => {
    const tooLow = `${t("InputPlan.errors.higher")} ${Service.Global.Price.currencyFormat(
      MIN_PRICE,
      currency ?? Model.Currency.brl,
    )}`
    const tooHigh = `${t("InputPlan.errors.lower")}  ${Service.Global.Price.currencyFormat(
      MAX_PRICE,
      currency ?? Model.Currency.brl,
    )}`
    return price < MIN_PRICE ? tooLow : price > MAX_PRICE ? tooHigh : undefined
  }

  return (
    <InputPlanAccordion
      styled={true}
      fluid={true}
      panels={[
        disabled?.[Plan.onetime]
          ? undefined
          : {
              key: Plan.onetime,
              title: AccordionTitle(
                t("InputPlan.onetime"),
                "radio",
                data?.type === Plan.onetime,
                "input-plan-one-time",
              ),
              content: {
                content: (
                  <>
                    <Fields.InputLabel size="small" title={t("InputPlan.price")} errorText={errors?.price}>
                      <Fields.Price
                        data-testid="input-price-one-time"
                        currency={currency}
                        error={errors?.price !== undefined}
                        value={data?.price ?? 0}
                        fluid={true}
                        placeholder={"0,00"}
                        onFieldChange={(_, data) => onPriceUpdate(data.price)}
                      />
                    </Fields.InputLabel>
                    {currency === Model.Currency.brl ? (
                      <Fields.InputLabel size="small" title={t("InputPlan.installments")}>
                        <Form.Select
                          upward={true}
                          search={true}
                          type="hidden"
                          id="Form"
                          value={data?.installment ?? 1}
                          name="installments"
                          fluid={true}
                          options={instOptions ?? []}
                          closeOnBlur={true}
                          onChange={(_, data) => onInstallmentUpdate(data.value as number)}
                        />
                      </Fields.InputLabel>
                    ) : null}
                    <Fields.InputLabel size="small" title={t("InputPlan.chooseAccessTime")}>
                      <Form.Select
                        upward={true}
                        search={true}
                        type="hidden"
                        id="accessDaysForm"
                        value={data?.accessDays ?? 0}
                        name="accessDays"
                        fluid={true}
                        options={accessMonthsOptions ?? []}
                        closeOnBlur={true}
                        onChange={(_, data) => onAccessDaysUpdate(data.value as number)}
                      />
                    </Fields.InputLabel>
                  </>
                ),
              },
              active: data?.type === Plan.onetime,
              onTitleClick: () => onTypeUpdate(Plan.onetime),
            },
        disabled?.[Plan.subscription]
          ? undefined
          : {
              key: Plan.subscription,
              title: AccordionTitle(
                t("InputPlan.subscription"),
                "radio",
                data?.type === Plan.subscription,
                "input-plan-subscription",
              ),
              content: {
                content: (
                  <InputPlanAccordion
                    styled={true}
                    fluid={true}
                    exclusive={false}
                    panels={Object.values(Document.PlanInterval).map((plan) => {
                      return {
                        key: plan,
                        active: isActive(plan),
                        title: AccordionTitle(`${t("InputPlan.plan")}: ${interval[plan]}`, "checkbox", isActive(plan)),
                        content: AccordionContent(
                          plan,
                          currency,
                          data?.plans?.[plan]?.price,
                          errors?.plans?.[plan],
                          onPlanUpdate,
                        ),
                        onTitleClick: () => switchActive(plan),
                      }
                    })}
                  />
                ),
              },
              active: data?.type === Plan.subscription,
              onTitleClick: () => onTypeUpdate(Plan.subscription),
            },
      ]}
    />
  )
}

const AccordionTitle = (title: string, type: "checkbox" | "radio", checked?: boolean, dataTestId?: string) => {
  return {
    content: <span style={{ marginLeft: "10px" }}>{title}</span>,
    icon: <Checkbox radio={type === "radio"} data-testid={dataTestId} checked={checked} />,
  }
}

const AccordionContent = (
  plan: Document.PlanInterval,
  currency?: string,
  price?: number,
  error?: string,
  onChange?: (interval: Document.PlanInterval, price?: number) => any,
) => {
  const { t } = useTranslation()

  let factor = 0
  switch (plan) {
    case Document.PlanInterval.monthly:
      factor = 1
      break
    case Document.PlanInterval.quarterly:
      factor = 3
      break
    case Document.PlanInterval.semiannually:
      factor = 6
      break
    case Document.PlanInterval.annually:
      factor = 12
      break
  }

  return {
    content: (
      <React.Fragment>
        {plan !== Document.PlanInterval.monthly ? (
          <Fields.InputLabel size="small" title={t("InputPlan.monthly")}>
            <Fields.Price
              currency={currency}
              error={error !== undefined}
              value={Number(((price ?? 0) / factor).toFixed(2))}
              fluid={true}
              placeholder={"0,00"}
              onFieldChange={(_, data) => (onChange ? onChange(plan, Number((data.price * factor).toFixed(2))) : null)}
            />
          </Fields.InputLabel>
        ) : null}
        <Fields.InputLabel size="small" title={t("InputPlan.totalPrice")} errorText={error}>
          <Fields.Price
            currency={currency}
            error={error !== undefined}
            value={price ?? 0}
            fluid={true}
            placeholder={"0,00"}
            onFieldChange={(_, data) => (onChange ? onChange(plan, data.price) : null)}
          />
        </Fields.InputLabel>
      </React.Fragment>
    ),
  }
}

export { InputPlan }
