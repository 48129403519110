/* eslint-disable react-hooks/rules-of-hooks */
import { AnalyticsEventParams, AnalyticsProviderType, IAnalyticsProvider, IAnalyticsTrackableItem } from "./Tracker"
import { Document } from "@chatpay/common"
import * as rudderanalytics from "rudder-sdk-js"
import _ from "lodash"
import moment from "moment"
import { Firebase } from "../Service"
import { isDebug, cookieDomain } from "./Utils"
import Cookies from "js-cookie"

type IdentifyTraits = Record<string, string | number | boolean>
export class RudderStackProvider implements IAnalyticsProvider {
  static readonly CACHE_COOKIE_NAME: string = "anonIdRudder"
  static readonly DEBUG_COOKIE_NAME: string = "debug_analytics"
  private readonly platformDetect = require("platform-detect")

  init = (): void => {
    if (isDebug()) {
      Firebase.shared.getAnalyticsDebugRemoteConfig((users: string) => {
        this.setDebugCookie(users)
      })
    }
    if (this.isAnalyticsTrackingEnabledInDebug()) {
      LOG(
        "load",
        "init",
        process.env.REACT_APP_RUDDERSTACK_TOKEN as string,
        process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL as string,
      )
      rudderanalytics.load(
        process.env.REACT_APP_RUDDERSTACK_TOKEN as string,
        process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL as string,
      )
      LOG("load", "finish")
    }
  }

  page = (name: string, params?: AnalyticsEventParams): void => {
    if (this.isAnalyticsTrackingEnabledInDebug() && !_.isEmpty(name)) {
      /**
       * If the user is logged in and for some reason his identify was not executed, perform the identify
       */
      //this.callIdentifyIfNeeded()
      LOG("page", name, this.addDefaultProperties(params), rudderanalytics.getAnonymousId())
      let apiObject = this.removeNullProperties(params)
      rudderanalytics.page(name, name, this.addDefaultProperties(apiObject))
    }
  }

  identify = (userParam?: Document.User): void => {
    if (this.isAnalyticsTrackingEnabledInDebug()) {
      const anonId = rudderanalytics.getAnonymousId()
      const isUUIDv4 = anonId?.indexOf("-") !== -1
      const user = userParam ?? Firebase.currentUser
      if (user) {
        this.validateIfOtherUserHasSameAnonymousId(anonId, user.id)
        if (!isUUIDv4 && user.id !== anonId) {
          this.reset()
        }
        rudderanalytics.identify(user.id, this.makeIdentifyTraitsByUser(user))
        this.addToCache(rudderanalytics.getAnonymousId(), user.id)
      }
    }
  }

  identifyFB = (user: firebase.default.User): void => {
    // const anonId = rudderanalytics.getAnonymousId()
    // const isUUIDv4 = anonId?.indexOf('-')!==-1
    // this.validateIfOtherUserHasSameAnonymousId(anonId, user.uid)
    // if(!isUUIDv4 && user.uid !== anonId){
    //   this.reset()
    // }
    // if(isDebug()) console.log("RudderStackProvider identifyFB", "anonymoudId", rudderanalytics.getAnonymousId(), "user.id", user.uid)
    // rudderanalytics.identify(user.uid);
  }

  track = (event: string, params?: AnalyticsEventParams, entities?: IAnalyticsTrackableItem[]): void => {
    if (this.isAnalyticsTrackingEnabledInDebug()) {
      params = params || {}
      if (entities !== undefined) {
        for (const entity of entities) {
          params = {
            ..._.omitBy(params, _.isNil),
            ..._.omitBy(entity.toTrack(), _.isNil),
          }
        }
      }

      //LOG("track", event, this.addDefaultProperties(params))
      rudderanalytics.track(event, this.addDefaultProperties(params))
    }
  }

  reset = (): void => {
    if (this.isAnalyticsTrackingEnabledInDebug()) {
      LOG("reset")
      rudderanalytics?.reset(true)
      this.clearCache()
    }
  }

  type = (): AnalyticsProviderType => {
    return AnalyticsProviderType.RUDDER_STACK
  }

  makeIdentifyTraitsByUser = (user: Document.User): IdentifyTraits => {
    const address = user.address?.local || user.address?.charge
    return this.removeNullProperties({
      firstName: user?.firstName,
      lastName: user?.lastName,
      username: user?.username as string,
      email: user.email as string,
      city: address?.city,
      country: address?.country as string,
      state: address?.state as string,
      zip: address?.zip as string,
      gender: user?.gender as string,
      birthday: moment(user?.birthdate?.toDate()).format("YYYY-MM-DD"),
      phoneNumber: user?.phoneNumber as string,
      userIdentifier: user?.id,
      referredBy: user?.referralId,
    })
  }

  private callIdentifyIfNeeded() {
    const currentUser = Firebase.currentUser
    if (currentUser) {
      // This method is not exposed in RudderStack SDK JS module, but is present in the library
      // @ts-ignore
      let traits = rudderanalytics.getUserTraits()
      if (traits?.userIdentifier == null) {
      }

      if (traits?.userIdentifier && traits.userIdentifier !== currentUser?.id) {
        this.identify(currentUser)
      }
    }
  }

  /**
   * Checks if on this device, another user logged in and identified with the same anonymous_id
   * If this happened, force changing the anonymous_id before triggering an identify
   *
   * This was created because internal Hubla users can log in via a link in the admin dashboard, directly into a creator's account, without going through the logout process.
   * This causes the same anonymous_id to be used by more than one user, which causes these users' profiles to be merged into MixPanel
   * The same anonymous_id should NEVER be used for more than one user_id
   * @param currentAnonymousId
   * @param user
   */
  private validateIfOtherUserHasSameAnonymousId(currentAnonymousId: string, userId: string): void {
    let anonIdUsage = this.getFromCache()
    //if existe um outro usuário que usou esse anonId nessa máquina, reseta o anonymousId
    if (anonIdUsage[currentAnonymousId] && anonIdUsage[currentAnonymousId] !== userId) {
      this.reset()
    } else {
      this.addToCache(currentAnonymousId, userId)
    }
  }

  private isAnalyticsTrackingEnabledInDebug(): boolean {
    return true
  }

  private setDebugCookie(users: string): void {
    Cookies.set(RudderStackProvider.DEBUG_COOKIE_NAME, users, {
      domain: cookieDomain(),
      sameSite: "lax",
      expires: 30,
    })
  }

  private getDebugCookie(): string[] {
    const str = Cookies.get(RudderStackProvider.DEBUG_COOKIE_NAME) || "[]"
    LOG("debugCookie", JSON.parse(str))
    return JSON.parse(str)
  }
  private addToCache(distinctId: string, userId: string): void {
    LOG("addToCache", distinctId, userId)
    let anonIdUsage = this.getFromCache()
    anonIdUsage[distinctId] = userId
    Cookies.set(RudderStackProvider.CACHE_COOKIE_NAME, JSON.stringify(anonIdUsage), {
      domain: cookieDomain(),
      sameSite: "lax",
      expires: 30,
    })
  }

  private getFromCache(): Record<string, unknown> {
    const str = Cookies.get(RudderStackProvider.CACHE_COOKIE_NAME) || "{}"
    return JSON.parse(str)
  }

  private clearCache = (): void => {
    LOG("clearCache", rudderanalytics.getAnonymousId())
    Cookies.set(RudderStackProvider.CACHE_COOKIE_NAME, "")
  }

  private removeNullProperties = (obj: any): IdentifyTraits => {
    let ret: IdentifyTraits = {}
    if(!obj || typeof obj != 'object') return {}
    Object.keys(obj).forEach((key) => {
      let value = obj[key]
      let hasProperties = value && Object.keys(value).length > 0
      if (value === null || value === undefined || (typeof value == "string" && value?.trim() == "")) {
        delete obj[key]
      } else if (typeof value !== "string" && hasProperties) {
        this.removeNullProperties(value)
      } else {
        ret[key] = value
      }
    })
    return ret
  }

  private addDefaultProperties = (params?: AnalyticsEventParams | null) => {
    const deviceType = this.platformDetect?.formfactor
    const deviceOS = this.getOS(this.platformDetect)
    return this.removeNullProperties({ ...params, deviceType, deviceOS })
  }

  private getOS = (os: any): string => {
    let result = "unknown"
    if (os?.windows) result = "windows"
    else if (os?.android) result = "android"
    else if (os?.chromeos) result = "chromeos"
    else if (os?.macos) result = "macos"
    else if (os?.ios) result = "ios"
    else if (os?.tizen) result = "tizen"
    else if (os?.linux || os?.linuxBased) result = "linux"
    return result
  }
}
function LOG(message?: any, ...optionalParams: any[]) {
  if (isDebug()) console.log("RudderStackProvider", message, ...optionalParams)
}
