export const FreeMembers = {
  title: "Free members",
  generateInviteLink: "Generate invite link",
  resourceFull: "Resource full",
  errors: {
    resourceNotFound: "Resource not found",
    nameNotRegistered: "Name not registered",
  },
  table: {
    Name: "Name",
    PhoneNumber: "PhoneNumber",
    Action: "Action",
  },
  modal: {
    remove: {
      title: "Remove user from free members?",
      description: "This action is irreversible. It will not be possible to retrieve the user.",
      buttons: { remove: "Remove", cancel: "Cancel" },
    },
  },
  generateLink: {
    title: "Share the invite link to your free members",
    description: "This link can only be used once. To give access to more people you need to generate several links.",
  },
}
