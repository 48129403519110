export const subscription = {
  created: "Criada",
  active: "Ativa",
  activated: "Ativa",
  due: "Renovação",
  suspended: "Inativa",
  renewed: "Ativa",
  canceled: "Cancelada",
  expired: "Expirada",
  statusView: {
    created: "Pendente",
    active: "Assinatura Ativa",
    activated: "Assinatura Ativa",
    due: "Renovação",
    suspended: "Assinatura Inativa",
    renewed: "Assinatura Ativa",
    canceled: "Assinatura Cancelada",
    expired: "Assinatura Expirada",
  }
}
